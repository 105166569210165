/**
Este componente é o datatable para listagem de modais como calendário por exemplo

-> Propriedades:
1- titles - Array - Array de titulos da TH na listagem - EX: [{'number':'index no array de data', 'name':''}]
2- data - Array - Array de objetos seguindo o index do title para ser mostrado
3- hasSearch - Boolean - Se a listagem terá pesquisa - EX: true/false
4- hasAction - Boolean - Se a listagem terá a coluna de ações - EX: true/false

-> Slots:
acoes: Última coluna da tabela

-> Eventos:
1- excluirCadastro - Emitido quando botão de exclusão for clicado - Parametros:
1.1- cod - Código do item cadastrado
*/
<template>
    <div class="col-sm-12 nopadding">
        <div v-if="hasSearch" style="margin-bottom: 10px">
            <el-row>
                <el-col :span="6">
                    <el-input placeholder="Buscar" v-model="filters[0].value"></el-input>
                </el-col>
            </el-row>
        </div>
        <div class="col-sm-12">
            <data-tables :data="data" :filters="filters" :pagination-props="{ pageSizes: [5, 10, 15] }">
                <el-table-column v-for="title in titles" :prop="title.number" :label="title.name" :key="title.number"
                    sortable="custom"></el-table-column>
                <el-table-column v-if="hasAction" :label="titleScope" max-width="100px">
                    <template slot-scope="scope">
                        <slot name='acoes' :row='scope.row' :scope='scope'>
                            <basicButtonsListagemD @excluirCadastro="excluirClick" :id="scope.row['codigo']">
                            </basicButtonsListagemD>
                        </slot>
                    </template>
                </el-table-column>
            </data-tables>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    components: {
        'basicButtonsListagemD': require('../../../components/Atom/Buttons/BasicButtonsListagemD').default,
    },
    props: {
        titles: {
            type: Array,
            required: true,
            default: function () { return [{ 'number': '', 'name': '' }] }
        },
        data: {
            type: Array,
            required: true,
            default: function () { return [] }
        },
        hasSearch: {
            type: Boolean,
            required: false,
            default: false
        },
        hasAction: {
            type: Boolean,
            required: false,
            default: true
        },
        titleScope: {
            type: String,
            default: 'Ações'
        }
    },
    data() {
        var optionsFilter: any = [];
        var info: any = this.titles;
        var sz = info.length;
        for (var a = 0; a < sz; a++) {
            // var number:any = info[a].number;
            optionsFilter.push(info[a].number);
        }
        return {
            filters: [{
                prop: optionsFilter,
                value: ''
            }],
        }
    },
    methods: {
        excluirClick(cod: any) {
            this.$emit('excluirCadastro', cod);
        },
    }
})
</script>
<style lang="scss">
.el-col-6 {
    float: right !important;
    padding: 0 10px;
}

.el-table td,
.el-table th {
    padding: 1px 0 !important;
}

.el-table {
    margin-bottom: 10px;

    .cell {
        padding-right: 5px !important;
    }
}

.el-table th {
    background-color: #f5f5f5 !important;
    color: #514e4e;
    font-weight: 700;
}

.el-table__empty-block {
    padding: 0px !important;
    font-size: 14px;
    color: #FFF;
    text-align: center;
    background: #E04B4A;

    .el-table__empty-text {
        color: #FFF !important;
    }
}

.el-table__row {
    td {
        border: 1px solid #f5f5f5 !important;
    }
}

.el-input__inner {
    border-radius: 8px !important;
}

.pagination-bar {
    .pagination-wrap {
        text-align: right;

        .el-pagination {
            .el-pagination__jump {
                display: none;
            }
        }
    }
}
</style>