<template>
    <panelEagle id="PainelAlertas" :loading="loadingPanel">
        <div class="col-sm-12 divDontPrint nopadding">
            <div class="col-sm-12 row">
                <div class="col-sm-6 row nopadding">
                    <tituloPage titulo="Alertas" :icon="mdiAlert" />
                </div>
            </div>

            <div class="col-sm-12">
                <hr />
            </div>

            <div class="col-sm-12 row nopadding">
                <div class="col-lg-4 row nopadding col-md-12">
                    <selectAll @changeSelect="changeSelect" nameForRadio="Empresas" :selected="cliSelecionado"
                        :isMultiple="true" :hasSelectAll="true"
                        :labels="[{ indexDFH: 'EM', description: 'Empresas *' }]" :optionsArray="optEmpresa" />

                    <div class="mt-3 col-sm-12 nopadding">
                        <div class="d-flex justify-content-start col-sm-12" style="margin-bottom: -7px">
                            <!-- Radio de Veículos -->
                            <div class="d-flex justify-content-start" style="margin-left: 20px">
                                <input style="cursor: pointer;" class="form-check-input" type="radio"
                                    name="grupoVeiculoTag" id="radioVeiculos" v-model="selectedRadio" :value="1"
                                    @change="selecionaTipo(1)">
                                <label class="select-none" for="radioVeiculos" style="cursor: pointer">
                                    Veículos
                                </label>
                            </div>

                            <!-- Radio de Grupos -->
                            <div class="d-flex justify-content-start" style="margin-left: 30px;">
                                <input style="cursor: pointer;" class="form-check-input" type="radio"
                                    name="grupoVeiculoTag" id="radioGrupos" v-model="selectedRadio" :value="2"
                                    @change="selecionaTipo(2)">

                                <label class="select-none" for="radioGrupos" style="cursor: pointer">
                                    Grupos
                                </label>
                            </div>

                            <!-- Radio de Tags -->
                            <div class="d-flex justify-content-start" style="margin-left: 30px;">
                                <input style="cursor: pointer;" class="form-check-input" type="radio"
                                    name="grupoVeiculoTag" id="radioTags" v-model="selectedRadio" :value="3"
                                    @change="selecionaTipo(3)">

                                <label class="select-none" for="radioTags" style="cursor: pointer">
                                    Tags
                                </label>
                            </div>
                        </div>

                        <div>
                            <!-- Select de Veículos -->
                            <div v-if="tipoSelecionado == 1">
                                <selectAll :selected="selectedVeiculos" nameForRadio='veiculos'
                                    :loading='carregandoVeiculos' :optionsArray='optionsVeiculos' ref='selectV'
                                    :hasSelectAll='true' :isMultiple='true' @changeSelect='changeVeiculos'
                                    :disabled="disabledVeiculos" />
                            </div>

                            <!-- Select de Grupos -->
                            <div v-if="tipoSelecionado == 2">
                                <selectAll nameForRadio='grupos' :loading='carregandoGrupos'
                                    :optionsArray='optionsGrupos' ref='selectGrupos' :hasSelectAll='true'
                                    :isMultiple='true' @changeSelect='changeGrupo' :disabled="disabledGrupos"
                                    :selected="selectedGrupos" />
                            </div>

                            <!-- Select de Tags -->
                            <div v-if="tipoSelecionado == 3">
                                <selectAll nameForRadio='inputTags' :loading='carregandoTags'
                                    :optionsArray='optionsTags' ref='selectTags' :hasSelectAll='true' :isMultiple='true'
                                    @changeSelect='changeTags' :disabled="disabledTags" :selected="selectedTags" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12 row nopadding">
                    <div class="col-sm-2 fundo-azul div-estilo nopadding">
                        <div class="div-conteudo">
                            <div v-if="qtdVeiculos == null">
                                <h1>—</h1>
                            </div>
                            <h1 v-else>
                                {{ qtdVeiculos }}
                            </h1>
                            <div v-if="loadingBotaoAlertas" class="loading-info-veiculos">
                                <b-spinner small type="grow" label="Small Spinner" />
                            </div>
                        </div>
                        <span class="footer-div col-sm-12"> Veiculos </span>
                    </div>

                    <div @click="filtroTipoAlerta('verde')" class="col-sm-2 fundo-verde div-estilo bs nopadding"
                        :class="{ pressed: filtroNormal }">
                        <!-- :class='"col-sm-2 filtro-click fundo-verde div-estilo nopadding "+(filtroNormal? "pressed": "")'  -->
                        <!-- :class='{filtroNormal:"pressed"}' -->
                        <div class="div-conteudo">
                            <div v-if="qtdVeiculosNormal == null">
                                <h1>—</h1>
                            </div>
                            <h1 v-else>
                                {{ qtdVeiculosNormal }}
                            </h1>
                            <div v-if="loadingBotaoAlertas" class="loading-info-veiculos">
                                <b-spinner small type="grow" label="Small Spinner" />
                            </div>
                        </div>
                        <span class="footer-div col-sm-12"> Normal </span>
                    </div>

                    <div @click="filtroTipoAlerta('amarelo')" class="col-sm-2 fundo-amarelo div-estilo bs nopadding"
                        :class="{ pressed: filtroAtencao }">
                        <div class="div-conteudo" :title="titleAtencao">
                            <div v-if="qtdVeiculosAtencao == null">
                                <h1>—</h1>
                            </div>
                            <h1 v-else>
                                {{ qtdVeiculosAtencao }}
                            </h1>
                            <div v-if="loadingBotaoAlertas" class="loading-info-veiculos">
                                <b-spinner small type="grow" label="Small Spinner" />
                            </div>
                        </div>
                        <span class="footer-div col-sm-12"> Atenção </span>
                    </div>

                    <div @click="filtroTipoAlerta('vermelho')" class="col-sm-2 fundo-vermelho div-estilo bs nopadding"
                        :class="{ pressed: filtroGrave }" :title="titleGrave">
                        <div class="div-conteudo">
                            <div v-if="qtdVeiculosGrave == null">
                                <h1>—</h1>
                            </div>
                            <h1 v-else>
                                {{ qtdVeiculosGrave }}
                            </h1>
                            <div v-if="loadingBotaoAlertas" class="loading-info-veiculos">
                                <b-spinner small type="grow" label="Small Spinner" />
                            </div>
                        </div>
                        <span class="footer-div col-sm-12"> Grave </span>
                    </div>

                    <div @click="filtroTipoAlerta('cinza')" class="col-sm-2 fundo-cinza div-estilo bs nopadding"
                        :class="{ pressed: filtroSemModulo }">
                        <div class="div-conteudo">
                            <div v-if="qtdVeiculosSemModulo == null">
                                <h1>—</h1>
                            </div>
                            <h1 v-else>
                                {{ qtdVeiculosSemModulo }}
                            </h1>
                            <div v-if="loadingBotaoAlertas" class="loading-info-veiculos">
                                <b-spinner small type="grow" label="Small Spinner" />
                            </div>
                        </div>
                        <span class="footer-div sem-quebra text-left col-sm-12">
                            Sem Módulo
                        </span>
                    </div>
                </div>

                <div class="col-sm-12">
                    <hr />
                </div>

                <div class="col-sm-12 row" v-show="mostraTabela">
                    <template v-for="(dado, i) in tableFiltrada">
                        <div v-if="true" :key="dado.veplaca + i" class="div-veiculo bs"
                            :class="'cursor-pointer icone-' + dado['cor']" @click="clickVeiculo(dado)">
                            <div v-if="dado.contador" class="contadorAlertas" :class="'alerta-' + dado['cor']">
                                {{ dado.contador }}
                            </div>
                            <h1 class="tamanho-icone col-sm-12 p-0 pl-2 pr-2" :class="[
                                dado.vetipo + 'espaco',
                                { 'dois-icones': dado.icone_extra },
                            ]">
                                <baseIcon v-if="dado.icone_extra" size="28" :icon="retornaIconeAlerta(dado.icone_extra)"
                                    style="color: red !important" />
                                <baseIcon size="28" :icon="retornaIcone(dado.vetipo)" style="color: black !important" />
                            </h1>
                            <span class="col-sm-12 p-0 pl-2 pr-2 text-bold">
                                {{ dado.veplaca }}
                            </span>
                            <div v-if="dado.veprefixo.length > 10" class="marquee text-bold">
                                <span class="col-sm-12 arruma-texto">
                                    {{ dado.veprefixo }}
                                </span>
                            </div>
                            <span v-else class="col-sm-12 p-0 pl-2 pr-2 text-bold">
                                {{ dado.veprefixo }}
                            </span>
                        </div>
                    </template>
                </div>

                <div class="col-sm-12">
                    <statusInformation v-show="!mostraTabela" msgCentro="Selecione ao menos uma empresa." typeBar="div"
                        :statusBar="statusBar" />
                </div>
            </div>

            <modalAlertasVeiculo :veiculo="veiculoSelecionado" :tipos="listaTiposAlertas" />

            <ModalSolicitaManutencaoVeiculos :veiculo="veiculoSelecionado" />
        </div>
    </panelEagle>
</template>

<script lang="js" scoped>
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { mapGetters } from 'vuex'
import { IconManager } from './Alertas'
import Vue from 'vue';
import { conectionError } from '@/Services/Helpers/swellHeper';

export default Vue.extend({
    name: 'AlertasMapaPlus',

    props: ['cliSelecionadoMapa', 'placaSelecionadaMapa'],

    components: {
        'baseIcon': require('@/components/Atom/Icon/BaseIcon.vue').default,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'statusInformation': require(
            '@/components/Atom/StatusInformation/StatusInformation').default,
        'ModalSolicitaManutencaoVeiculos': require(
            '@/components/Atom/ModalSpecific/ModalSolicitaManutencaoVeiculos').default,
        'modalAlertasVeiculo': require(
            '@/views/MapaPlus/Alertas/ModalAlertasVeiculo').default,
    },

    data() {
        return {
            url: '/telemetria/nao/menu/alerta/',
            ...IconManager.getIcons(),
            loadingBotaoAlertas: false,
            loadingPanel: false,
            loadingPanelTratativasSalvas: false,
            mostrarModal: false,
            qtdAlertas: [{}],
            loadingPanelModal: false,
            dataTable: [],
            statusBar: 'info',
            mostraTabela: false,
            optEmpresa: new EmpresasService().Get(),
            qtdVeiculos: null,
            qtdVeiculosNormal: null,
            qtdVeiculosAtencao: null,
            qtdVeiculosGrave: null,
            qtdVeiculosSemModulo: null,
            tabelaSemFiltro: [],
            valueEmpresa: [],
            intervalo30seg: undefined,
            titleAtencao: 'Aleras de Atenção: Aproximacao Ponto Referencia, Parada Proibida, Movimento Indevido, Excesso Velocidade.',
            titleGrave: 'Aleras graves: Colisão, Desvio de rota, Batida, Falha de energia externa, Sem Comunicação, Panico, Area de Risco.',
            listaTiposAlertas: [],
            cliSelecionado: [],
            buscaUser: '',
            filtroNormal: true,
            filtroAtencao: true,
            filtroGrave: true,
            filtroSemModulo: true,

            veiculoSelecionado: {},
            requestControl: false,

            carregandoVeiculos: false,
            carregandoGrupos: false,
            carregandoTags: false,

            selectedRadio: 1,
            tipoSelecionado: 1,

            veiculosSelecionados: [],
            tagsSelecionadas: [],
            gruposSelecionados: [],

            selectedVeiculos: [],
            selectedGrupos: [],
            selectedTags: [],

            optionsVeiculos: [],
            optionsGrupos: [],
            optionsTags: [],

            disabledVeiculos: true,
            disabledGrupos: true,
            disabledTags: true,
        }
    },

    methods: {
        ...mapGetters(['getMaster', 'getEditar', 'getCadastrar']),

        /**
         * @param {'verde'|'amarelo'|'vermelho'|'cinza'} cor
         * @description altera o valor dos filtros de acordo com
         * a cor passada como parametro.
         * @author Gui 🍺
         */
        filtroTipoAlerta(cor) {
            switch (cor) {
                case 'verde':
                    this.filtroNormal = !this.filtroNormal
                    break
                case 'amarelo':
                    this.filtroAtencao = !this.filtroAtencao
                    break
                case 'vermelho':
                    this.filtroGrave = !this.filtroGrave
                    break
                case 'cinza':
                    this.filtroSemModulo = !this.filtroSemModulo
                    break
            }
        },

        retornaIcone(icone) {
            // flaticon-icon165 carro
            // flaticon-icon166 moto
            // flaticon-icon167 trator
            // flaticon-icon168 onibus
            // flaticon-icon169 caminhao
            switch (icone) {
                case 'icon165': return this.mdiCarSports;
                case 'icon169': return this.mdiTruck;
                case 'icon167': return this.mdiTractor;
                case 'icon168': return this.mdiBusSide;
                case 'icon166': return this.mdiMoped;
                case 'icon164': return this.mdiCellphone;
                default: return '';
            }
        },

        retornaIconeAlerta(icone) {
            return IconManager.getIconeAlerta(icone)
        },

        clickVeiculo(veiculo) {
            this.veiculoSelecionado = veiculo ?? {}
            this.$bvModal.show('modalAlertasVeiculo')
        },

        /**
         * @param {string} value - O valor do campo
         * @author Lucas Eduardo
         */
        changeInput(value) {
            this.buscaUser = value
        },

        requestError() {
            this.toastShow('Não foi possível atualizar as informações!', 'danger', 'Painel')
        },

        requestSuccess() {
            this.toastShow('As informações foram atualizadas!', 'success', 'Painel')
        },

        toastShow(msg, type, title = '') {
            this.$bvToast.toast(`${msg}`, {
                autoHideDelay: 2500,
                variant: type,
                title: title
            })
        },

        async consulta30seg(intervalo = 60000) {
            clearInterval(this.intervalo30seg)
            this.intervalo30seg = setInterval(async () => {
                if (this.requestControl) {
                    return
                }
                this.requestControl = true
                await this.consultaTabela(this.valueEmpresa)
                    .then(({ data, status, code }) => {
                        if (status) {
                            this.dataTable = data.tabela
                            this.qtdVeiculos = data.contagemVeiculos.totalVeiculos
                            this.qtdVeiculosNormal = data.contagemVeiculos.veiculoNormal
                            this.qtdVeiculosAtencao = data.contagemVeiculos.veiculoAtencao
                            this.qtdVeiculosGrave = data.contagemVeiculos.veiculoGrave
                            this.qtdVeiculosSemModulo = data.contagemVeiculos.veiculoCinza
                            this.requestSuccess()
                        } else {
                            this.requestError()
                        }
                    })
                    .catch((e) => {
                        this.requestError()
                    })
                    .finally(() => {
                        this.requestControl = false
                    })
            }, intervalo);
        },

        /**
         * faz a consulta do alerta que aparece na tela, com os icones
         * @author Lucas Eduardo
         */
        async consultaTabela(value) {
            let url = `${this.url}busca/alerta`;
            let params = {
                'empresas': value,
                'veiculos': this.veiculosSelecionados,
                'grupos': this.gruposSelecionados,
                'tags': this.tagsSelecionadas
            };

            return await new HttpRequest().Post(url, params);
        },

        /**
         * Troca o status do statusBar e atribui os valores para os campos
         * @param Array - O id dos clientes
         * @author Lucas Eduardo
         */
        async changeSelect(value, zerar = true) {
            this.valueEmpresa = value;
            this.loadingBotaoAlertas = true;

            this.consultarVeiculos();
            this.consultarGrupos();
            this.consultarTags();

            if (zerar) {
                this.qtdVeiculos = null
                this.qtdVeiculosGrave = null
                this.qtdVeiculosNormal = null
                this.qtdVeiculosAtencao = null
                this.qtdVeiculosSemModulo = null
            }

            clearInterval(this.intervalo30seg);

            if (value.length > 0) {
                this.loadingPanel = true;
                this.requestControl = true;

                await this.consultaTabela(value)
                    .then(({ data, status, code }) => {
                        if (status) {
                            this.listaTiposAlertas = data.tipos ?? [];
                            this.dataTable = data.tabela;
                            this.tabelaSemFiltro = data.tabela;
                            this.qtdVeiculos = data.contagemVeiculos.totalVeiculos;
                            this.qtdVeiculosNormal = data.contagemVeiculos.veiculoNormal;
                            this.qtdVeiculosAtencao = data.contagemVeiculos.veiculoAtencao;
                            this.qtdVeiculosGrave = data.contagemVeiculos.veiculoGrave;
                            this.qtdVeiculosSemModulo = data.contagemVeiculos.veiculoCinza;
                            this.mostraTabela = true;
                            this.statusBar = 'info';
                            this.requestControl = false;
                            this.consulta30seg();
                        } else {
                            conectionError();
                        }
                    })
                    .catch((e) => {
                        conectionError();
                    })
                    .finally(() => {
                        this.loadingPanel = false;
                    })
            } else {
                this.mostraTabela = false;
                this.selectedVeiculos = [];
                this.selectedGrupos = [];
                this.selectedTags = [];
                this.veiculosSelecionados = [];
                this.gruposSelecionados = [];
                this.tagsSelecionadas = [];
                this.valueEmpresa = [];
                this.qtdVeiculos = null
                this.qtdVeiculosGrave = null
                this.qtdVeiculosNormal = null
                this.qtdVeiculosAtencao = null
                this.qtdVeiculosSemModulo = null
            }

            this.loadingBotaoAlertas = false;
        },

        /**
        Função para selecionar qual tipo de filtro foi 
        selecionado e mostra em tela para o usuário o inpput correspondente,
        variável que salva qual tipo foi selecionado é 
        this.tipoSelecionado.
        @param {tipo} number tipo para filtro selecionado, 
        1 = Placas
        2 = Tags
        @author Yuri 🇧🇷
        */
        selecionaTipo(tipo) {
            this.tipoSelecionado = tipo;
            this.veiculosSelecionados = [];
            this.gruposSelecionados = [];
            this.tagsSelecionadas = [];
        },

        changeTags(tag) {
            this.tagsSelecionadas = tag;

            if (this.valueEmpresa.length > 0) {
                this.changeSelect(this.valueEmpresa);
            }

            this.qtdVeiculos = null;
            this.qtdVeiculosGrave = null;
            this.qtdVeiculosNormal = null;
            this.qtdVeiculosAtencao = null;
            this.qtdVeiculosSemModulo = null;
            this.mostraTabela = false;

        },

        changeVeiculos(veiculos) {
            this.veiculosSelecionados = veiculos;

            if (this.valueEmpresa.length > 0) {
                this.changeSelect(this.valueEmpresa);
            }

            this.qtdVeiculos = null;
            this.qtdVeiculosGrave = null;
            this.qtdVeiculosNormal = null;
            this.qtdVeiculosAtencao = null;
            this.qtdVeiculosSemModulo = null;
            this.mostraTabela = false;

        },

        changeGrupo(grupos) {
            this.gruposSelecionados = grupos;

            if (this.valueEmpresa.length > 0) {
                this.changeSelect(this.valueEmpresa);
            }

            this.qtdVeiculos = null;
            this.qtdVeiculosGrave = null;
            this.qtdVeiculosNormal = null;
            this.qtdVeiculosAtencao = null;
            this.qtdVeiculosSemModulo = null;
            this.mostraTabela = false;
        },

        async consultarVeiculos() {
            try {
                this.disabledVeiculos = false;
                this.carregandoVeiculos = true;
                this.optionsVeiculos = [];

                const link = this.url + 'consultar/veiculos'
                const obj = {
                    codigoEmpresa: this.valueEmpresa
                }

                if (this.valueEmpresa.length > 0) {
                    await new HttpRequest().Post(link, obj).then((data) => {
                        if (data.data == null) {
                            this.disabledVeiculos = true;
                            this.optionsVeiculos = [];
                        } else {
                            this.optionsVeiculos = data.data;
                            this.disabledVeiculos = false;
                        }
                    }).catch((error) => {
                        this.carregandoVeiculos = false;
                    }).finally(() => {
                        this.carregandoVeiculos = false;
                    });
                } else {
                    this.disabledVeiculos = true;
                }

                this.carregandoVeiculos = false;
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
                this.carregandoVeiculos = false;
            }
        },

        async consultarGrupos() {
            try {
                this.carregandoGrupos = true;
                this.optionsGrupos = [];

                const link = this.url + 'consultar/grupos'
                const obj = {
                    codigoEmpresa: this.valueEmpresa
                }

                if (this.valueEmpresa.length > 0) {
                    await new HttpRequest().Post(link, obj).then((data) => {
                        if (data.data == null) {
                            this.disabledGrupos = true;
                            this.optionsGrupos = [];
                        } else {
                            this.optionsGrupos = data.data;
                            this.disabledGrupos = false;
                        }
                    }).catch((error) => {
                        this.carregandoGrupos = false;
                    }).finally(() => {
                        this.carregandoGrupos = false;
                    });
                } else {
                    this.disabledGrupos = true;
                }

                this.carregandoGrupos = false;

            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
                this.carregandoGrupos = false;
            }
        },

        async consultarTags() {
            try {
                this.carregandoTags = true;
                this.optionsTags = [];

                const link = this.url + 'consultar/tags'
                const obj = {
                    codigoEmpresa: this.valueEmpresa
                }

                if (this.valueEmpresa.length > 0) {
                    await new HttpRequest().Post(link, obj).then((data) => {
                        if (data.data == null) {
                            this.disabledTags = true;
                            this.optionsTags = [];
                        } else {
                            this.optionsTags = data.data;
                            this.disabledTags = false;
                        }
                    }).catch((error) => {
                        this.carregandoTags = false;
                    }).finally(() => {
                        this.carregandoTags = false;
                    });
                } else {
                    this.disabledTags = true;
                }

                this.carregandoTags = false;
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
                this.carregandoTags = false;
            }
        },
    },

    beforeDestroy() {
        clearInterval(this.intervalo30seg)
    },

    async mounted() {
        this.dataTable = []
        const cliSelecionadoMapa = this.$route.query.cliSelecionadoMapa;
        const placaSelecionadaMapa = this.$route.query.placaSelecionadaMapa;
        if (cliSelecionadoMapa) {
            this.buscaUser = placaSelecionadaMapa;
            this.$nextTick(async () => {
                this.cliSelecionado = [this.optEmpresa.find((e) => {
                    return e.value == cliSelecionadoMapa
                })]

                if (this.$route.params.placa)
                    this.buscaUser = this.$route.params.placa
                await this.changeInput(this.buscaUser);
                await this.consultaTabela(cliSelecionadoMapa)
                if (this.tableFiltrada && this.tableFiltrada.length > 0) {
                    this.clickVeiculo(this.tableFiltrada[0]);
                }
            });
        }

        if (this.$route.params.cli) {
            var cli = this.$route.params.cli
            this.cliSelecionado = [this.optEmpresa.find((e) => {
                return e.value == cli
            })]
            if (this.$route.params.placa)
                this.buscaUser = this.$route.params.placa
        } else if (!this.getMaster()) {
            this.cliSelecionado = this.optEmpresa
        }
    },

    computed: {
        /**
         * Filtra as informações dos veículos de acordo com
         * a busca o do usuário
         * @author Gui 🍺
         */
        tableFiltrada() {
            var reg = new RegExp(this.buscaUser, 'i')
            return this.tableFiltraTipos.filter((d) => {
                if (d.veplaca.match(reg))
                    return true
                if (d.veprefixo.match(reg))
                    return true
                return false
            })
        },

        /**
         * Filtra os alertas de acordo com os tipos
         * selecionados pelo usuário.
         * @author Gui 🍺
         */
        tableFiltraTipos() {
            return this.dataTable.filter((al) => {
                if (this.filtroNormal && al.cor === 'verde')
                    return true
                if (this.filtroAtencao && al.cor === 'amarelo')
                    return true
                if (this.filtroGrave && al.cor === 'vermelho')
                    return true
                if (this.filtroSemModulo && al.cor === 'cinza')
                    return true
                return false
            })
        }
    },
})
</script>

<style lang="scss" scoped>
@import "./Alertas.scss";
</style>
