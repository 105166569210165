<!-- /**
Componente que compoe a tabela do modal de alertas
*/ -->

<template>
    <div class="nopadding" v-if="veiculoInfos.contador != 0">
        <div id="cabecalho" class="col-sm-12 row nopadding pt-2 pb-2 mt-2 mb-2 rounded align-items-center">
            <span class="col-sm-2 pr-0 pl-0 text-right">Data e hora</span>
            <span class="col-sm-3 pr-0 pl-5 text-center">
                <span class="col-sm-6 ">Rota</span>
                <span class="col-sm-6 ">Carga</span>
            </span>
            <span class="col-sm-3 pl-4 pr-0 text-left">Informação</span>
            <span class="col-sm-3 nopadding text-left">Local</span>
            <span class="col-sm-1 pr-4 pl-0 text-right">Ações</span>
        </div>
        <div class="col-sm-12 row nopadding " v-for="(ta, index) in tiposAlertas" :key="index">
            <div id="principal" :class="classeTipoAlerta(ta.cor) + ' flex align-items-center mb-2'"
                @click="toggleExpand(index)" v-if="alertas[ta.value].contagem != 0">
                <div class="row font-bold align-items-center pl-2 pr-0" style="flex-basis: 4.166% !important;">
                    <b-icon class="h5" style="margin-bottom: 2px;"
                        :icon="indiceExpandido === index ? 'chevron-down' : 'chevron-right'" />
                </div>
                <div class="col row flex nopadding text-left align-items-center">
                    <div class="nopadding" style="flex-basis: 4.166% !important;">
                        <baseIcon :icon="mdiWifiOff" size="14" />
                    </div>
                    <span class="col nopadding">{{ ta.description + " " + alertas[ta.value].contagem }}</span>
                </div>
                <div class="col-sm-auto nopadding text-right">
                    <simpleButton extraClass="nopadding rounded" text="Ler tudo" type="green" :icon="mdiCheckBold"
                        sizeIcon="14" title="Marcar todos como lidos" event="MarcarTipoAlertaComoLido"
                        @MarcarTipoAlertaComoLido="MarcarTipoAlertaComoLido(ta.value)" />
                </div>
            </div>

            <!-- Sublinha Detalhando cada alerta -->
            <div v-if="indiceExpandido === index" class="col-sm-12 align-items-center">
                <div class="nopadding" v-for="(da, indexSub) in alertas[ta.value].dados">
                    <div id="expanded-content" :class="classeTipoAlerta(ta.cor) + ' nopadding'">
                        <div class="col-sm-11 row align-items-center" @click="toggleExpandSub(indexSub, da)">
                            <div class="col-sm-3 row nopadding align-items-center">
                                <b-icon class="h6 mb-0 nopadding col-sm-2"
                                    :icon="indiceExpandidoSubMenu === indexSub ? 'chevron-down' : 'chevron-right'" />
                                <span class="col-sm-10 nopadding w-100 pr-2">{{ " " + da.data + " " + da.hora }}</span>
                            </div>
                            <div class="col-sm-1 row nopadding align-items-center ">
                                <span class="w-100 text-center">{{ getRota(da.codigoRota) }}</span>
                            </div>
                            <div class="col-sm-1 row nopadding align-items-center ">
                                <span class="w-100 text-center">{{ getCarga(da.codigoCarga) }}</span>
                            </div>
                            <div class="col-sm-3 row pr-1 pl-1 text-center align-items-center ">
                                <span class="w-100">{{ da.alerta }}</span>
                            </div>
                            <div class="col row pl-1 pr-0 align-items-center ">
                                <span> {{da.endereco}}</span>
                            </div>
                        </div>
                        <div class="col-sm-1 nopadding flex align-items-center"
                            style="align-items: center; display: flex;">
                            <simpleButton extraClass=" nopadding rounded m-0" type="green" :icon="mdiCheckBold"
                                sizeIcon="10" title="Marcar como lidos" event="MarcarAlertaComoLido"
                                width="20px" @MarcarAlertaComoLido="MarcarAlertaComoLido(da.alcodigo, indexSub, alertas[ta.value], ta.value)" />
                            <simpleButton extraClass="nopadding rounded m-0 ml-1" type="orange" :icon="mdiCogOutline"
                                sizeIcon="10" title="Marcar todos como lidos" event="ExpandirTratarAlertas" width="20px"
                                @ExpandirTratarAlertas="toggleExpandSub(indexSub)" />
                        </div>
                    </div>

                    <!-- Menu tratativas do alerta(Susblinha) -->
                    <div id="menuTratativas" v-if="indiceExpandidoSubMenu === indexSub"
                        class="col-sm-12 row align-items-center nopadding mb-2 mt-2">
                        <div class="col-sm-6 nopadding">
                            <mapaSimples :rounded="true" ref="mapaSimples" :latitude="latitudeMapa" :longitude="longitudeMapa" >
                                <markerPoint :latitude="latitudeMapa" :longitude="longitudeMapa" />
                            </mapaSimples>
                        </div>
                        <div class="col-sm-6 ">
                            <div class="col-sm-12 row nopadding flex align-items-center">
                                <span class="col nopadding">Descreva a tratativa do alerta</span>
                                <div class="col-auto nopadding text-right">
                                    <simpleButton :disabled="!valueTratativa"  extraClass="nopadding rounded m-0" type="blue" :icon="mdiCheckBold"  
                                        sizeIcon="10" text="Salvar" title="Salvar tratativa"
                                        event="salvarTratativa"
                                        @salvarTratativa="salvarTratativaColapse(da)" />
                                </div>
                            </div>
                            <textarea id="text" rows="2" class="index-gg mt-2 rounded-sm" ref="refTextArea" 
                                v-model="valueTratativa" >
                                </textarea>

                            <div class="tableFixHead div-table p-0 col-sm-12 mt-1 rounded-sm" style="overflow-x:auto; height: 135px;">
                                <table class="table" style="height: 135px;">
                                    <thead>
                                        <tr>
                                            <th class="thTratativas">
                                                <div>Tratativas salvas</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="tratativas.length">
                                        <tr v-for="(item, i) of tratativas" :key="i + '_desc_tratativa'">
                                            <td class="p-1" style="font-size:10px;">
                                                {{ item.taldescritivo }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <div class="col-sm-12 text-center mt-4 mb-4">
                                            <span class="bota-no-meio">
                                                Nenhuma tratativa
                                            </span>
                                        </div>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'
import SlideUpDown from 'vue-slide-up-down'
import {
    mdiCheckBold,
    mdiCogOutline,
    mdiMapMarkerRemove,
    mdiWifiOff,
    mdiGaugeFull,
} from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'

export default Vue.extend({
    name: 'AlertasTable',

    components: {
        SlideUpDown,
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
        'simpleButton': require('@/components/Atom/Buttons/SimpleButton.vue').default,
        'mapaSimples': require('@/components/Atom/Mapa/MapaSimples.vue').default,
        'markerPoint': require('@/components/Atom/Mapa/MarkerPoint').default,

    },

    data() {
        return {
            rote: '/telemetria/nao/menu/alerta/',
            mdiCheckBold,
            mdiCogOutline,
            mdiMapMarkerRemove,
            mdiWifiOff,
            mdiGaugeFull,
            alertaResumido: {},
            indiceExpandido: null,
            indiceExpandidoSubMenu: null,
            latitudeMapa: -27.099379465910964,
            longitudeMapa: -52.62775020132031,

            valueTratativa: '',
            dadosMotorista: {},
            tratativas: [],
        }
    },

    props: {
        veiculoInfos: {
            type: Object,
            required: true,
            default: function () { return {} }
        },
        tiposAlertas: {
            type: Array,
            required: true,
            default: []
        },
        alertas: {
            type: Object,
            required: true,
            default: function () { return {} }
        }
    },

    emits: ["abrirModalEditar", "abrirModalFinalizar"],

    methods: {
        /**
         * Função para abrir o a listagem de alertas de um mesmo tipo
         * @param index 
         * @author Marco Duz
         */
        toggleExpand(index) {
            this.indiceExpandido = this.indiceExpandido === index ? null : index;
            this.indiceExpandidoSubMenu = null;
        },

        /**
         * @description Função para abrir o menu de tratativas do alerta
         * também chama as funções para buscar as trataivas e formatar a latitude e longitude do alerta aser exibido
         * @param index 
         * @param alerta 
         * @author Marco Duz
         */
        toggleExpandSub(index, alerta) {
            this.indiceExpandidoSubMenu === index ? null : this.buscaTratativas(alerta.alcodigo);
            this.indiceExpandidoSubMenu === index ? null : this.formataLatELng(alerta.latlng);
            this.indiceExpandidoSubMenu = this.indiceExpandidoSubMenu === index ? null : index;
        },

        /**
         * @description Função para separar a latitude e longitude
         * transformando em número para que possam ser utilizadas no mapa
         * @param {string} strLatLng 
         * @author Marco Duz
         */
        formataLatELng(strLatLng){
            let ArrLatLng = strLatLng.split(',')
            this.latitudeMapa = Number(ArrLatLng[0])
            this.longitudeMapa = Number(ArrLatLng[1])
        },

        /**
         * Função que altera a classe do alerta de acordo com o tipo para estilização
         * @param tipo 
         * @author Marco Duz
         */
        classeTipoAlerta(tipo) {
            var classe = 'col-sm-12 row rounded-lg';
            switch (tipo) {
                case 'vermelho':
                    classe += ' grave'
                    break;
                case 'amarelo':
                    classe += ' atencao'
                    break;
                default:
                    break;
            }

            return classe
        },

        /**
         * @description Função para buscar o código da carga do veículo selecionado
         * @param carga 
         * @author Marco Duz
         */
        getCarga(carga) {
            if (carga == null) {
                return "-"
            }
            else {
                return carga
            }
        },

        /**
         * @description Função para buscar o código da rota do veículo selecionado
         * @param rota 
         * @author Marco Duz
         */
        getRota(rota) {
            if (rota == null) {
                return "-"
            }
            else {
                return rota
            }
        },

        /**
         * @description Função para marcar todos os alertas de um mesmo tipo como lidos
         * Emite um evento passando o tipo de alerta para ser marcado como lido
         * @param tipoAlerta 
         * @author Marco Duz
         */
        MarcarTipoAlertaComoLido(tipoAlerta){
            this.$emit('lerTodos', tipoAlerta)
        },
        
        /**
         * @description Função para marcar o alerta como lido
         * chama a função que remove o alerta lido do array de alertas atualizando a informação localmente
         * e emite um evento passando o código do alerta para ser atualziado no backend
         * @param alcodigo -> codigo do alerta
         * @param index  -> posição do alerta no array de alertas do veículo
         * @param alertas -> array de alertas do veículo
         * @param value -> index referente ao tipo de alerta
         * @author Marco Duz
         */
        MarcarAlertaComoLido(alcodigo, index, alertas, value){
            this.removerAlerta(index, alertas, value)
            this.$emit('lerAlertaAtual', alcodigo)
        },

         /**
         * @description Função auxiliar para alterar as informações de alertas do veículo
         * no front.
         * @param { object } value alerta
         * @param { int } value.tipo
         * @param { int } index posição do alerta do veículo
         * @author  Rafael
         */
        removerAlerta(index, alertas, value) {
            if (alertas) {
                alertas.dados.splice(index, 1)
                this.alertas[value].contagem -= 1
            }
        },

        /**
         * @description Faz a consulta para buscar tratativas do alerta.
         * Função retirada do arquivo modalAlertasVeiculo
         * e trazida para dentro da tabele com as alterações necessárias -> Marco
         * @param { object } dados -> informações do alerta
         * @author Rafael
         * @author Marco Duz
         */
         async buscaTratativas(dados) {
            let url = `${this.rote}buscar/tratativa`
            const params = { 'alcodigo': dados }
            this.tratativas = []
            await new HttpRequest().Post(url, params)
                .then(({ data, status, code }) => {
                    if (status) {
                        this.tratativas = data
                    } else {
                        conectionError()
                    }
                })
                .catch(e => {
                    conectionError()
                })
        },

        /**
         * @description Salva uma tratativa no banco.
         * Função retirada do arquivo modalAlertasVeiculo
         * e trazida para dentro da tabele com as alterações necessárias -> Marco
         * @param { object } dados tratativa
         * @author Rafael
         * @author Marco Duz
         */
         async salvarTratativaColapse(dados) {
            let url = `${this.rote}salvar/uma/tratativa`
            let params = { 'dados': { ...dados, descricao: this.valueTratativa } }
            await new HttpRequest().Post(url, params)
                .then(async ({ data, status, code }) => {
                    if (status) {
                        this.tratativas?.push({ taldescritivo: this.valueTratativa })
                        this.valueTratativa = ''
                    } else {
                        conectionError()
                    }
                })
                .catch(e => {
                    conectionError()
                })
        },
    },
})
</script>

<style lang="scss">
#cabecalho {
    background-color: #f5f5f5;
}

#principal.grave {
    background-color: #f2b9b9;
    padding-block: 4px;
    padding-inline: 3px;
    font-weight: 600;

    div {
        span {
            font-size: 11px;
        }
    }
}

#principal.atencao {
    background-color: #fced69;
    padding-block: 4px;
    padding-inline: 3px;
    font-weight: 600;

    div {
        span {
            font-size: 11px;
        }
    }
}

#expanded-content.grave {
    border: solid 2px #ff0000;
    padding-block: 1px;
    font-weight: 500;
    margin-bottom: 3px;

    div {
        span {
            font-size: 10px;
        }
    }
}

#expanded-content.atencao {
    border: solid 2px #ffe600;
    padding-block: 1px;
    font-weight: 500;
    margin-bottom: 3px;

    div {
        span {
            font-size: 10px;
        }
    }
}

#menuTratativas {
    div {
        div {
            span {
                font-size: 10px;
            }

            div {
                button {
                    font-size: 10px;
                }
            }

            th {
                div {
                    font-size: 10px;
                }
            }
        }
    }
}

</style>