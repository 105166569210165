import { AuthService } from '../../../Services/auth/Auth.Service';
import Layout from '../../../components/Atom/Layout/Layout.vue'
import Relatorio from '../../Children.vue'
import NaoMenu from '../../Children.vue'
import RotaManual from '../NaoMenus/RotaManual.vue'
import ImportarCargas from '../NaoMenus/ImportarCargas.vue'
import DetalhesRota from '../Relatorios/DetalhesRota.vue'
import Pedagios from '../Relatorios/Pedagios.vue'
import MontagemCargas from '@/views/Roteirizador/NaoMenus/MontagemCargas.vue'
import ConferenciaIntegracao from '../Relatorios/ConferenciaIntegracao.vue'
import MonitoramentoRota from '../NaoMenus/MonitoramentoRota.vue'
import ControleCargas from '../NaoMenus/ControleCargas.vue'
import Entregas from '../Relatorios/Entregas.vue'
import ListagemVisitas from '../Cadastros/ListagemVisitas.vue'
import CadastroVisitas from '../Cadastros/CadastroVisitas.vue'
import OperacaoRotas from '@/views/Roteirizador/Relatorios/OperacaoRotas.vue'
import TipoColetasListagem from '../Cadastros/TipoColeta/TipoColetasListagem.vue'
import TipoColetasCadastro from '../Cadastros/TipoColeta/TipoColetasCadastro.vue'
import CadastroJustificativas from '../Cadastros/Justificativas/CadastroJustificativas.vue'
import ListagemJustificativas from '../Cadastros/Justificativas/ListagemJustificativas.vue'
import Cadastro from '../../Children.vue'
import { RouteConfig } from 'vue-router'
import RotasRegiao from '../Relatorios/RotasRegiao.vue'
import BancoTickets from '../Relatorios/BancoTickets.vue'
//////////////////
import ListagemPontos from '@/views/MapaPlus/Cadastros/Pontos/ListagemPontos.vue'
import importarPontos from '@/views/MapaPlus/Cadastros/Pontos/ImportarPontos.vue'
import CadastroPontos from '@/views/MapaPlus/Cadastros/Pontos/CadastroPontos.vue'

import CadastroRotas from "@/views/EagleCargo/Cadastros/CadastroRotas.vue"
import ListagemRotas from "@/views/EagleCargo/Cadastros/ListagemRotas.vue"

// essa tela ta em outro modulo pq disso: https://app.clickup.com/t/2ykugg1
//////////////////
import AprovacaoDePontos from '@/views/Roteirizador/NaoMenus/AprovacaoDePontos.vue'

export const ifAuthenticated = (_to: any, _from: any, next: any) => {
    if (new AuthService().isAuthenticated()) {
        next()
        return
    }
    next('/login')
}
export const roteirizadorRoutes: RouteConfig =
{
    path: '/roteirizador',
    name: 'Roteirizador',
    beforeEnter: ifAuthenticated,
    meta: {
        requiresAuth: true,
        Module: "roteirizador",
        breadcrumbName: "Roteirizador"
    },
    components: { default: Layout }, children: [
        {
            path: 'relatorios',
            meta: {
                Module: "roteirizador",
                breadcrumbName: "Relatórios"
            },
            components: {
                content: Relatorio
            },
            children: [
                {
                    path: 'detalhes/rota',
                    name: 'detalhesRota',
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Detalhes da rota"
                    }, component: DetalhesRota
                },
                {
                    path: 'conferencia/integracao',
                    name: 'relconferenciaintegracao',
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Conferencia Integracao"
                    }, component: ConferenciaIntegracao
                },
                {
                    path: 'operacao/rotas',
                    name: 'OperacaoRotas',
                    meta: {
                        Module: 'roteirizador',
                        breadcrumbName: 'Operação de Rotas'
                    },
                    component: OperacaoRotas
                },
                {
                    path: 'rotas/regiao',
                    name: 'rotasRegiao',
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Rotas Região"
                    }, component: RotasRegiao
                },
                {
                    path: 'banco/tickets',
                    name: 'bancoTickets',
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Banco de Tickets"
                    }, component: BancoTickets
                },
                {
                    path: 'entregas',
                    name: 'relentregas',
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Relatório de Entregas"
                    }, component: Entregas
                },
                {
                    path: 'pedagios',
                    name: 'pedagios',
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Gestão de Pedágios"
                    }, component: Pedagios
                },
            ],
        },
        {
            path: '',
            meta: {
                Module: "roteirizador",
                breadcrumbName: ""
            },
            components: { content: NaoMenu },
            children: [
                {
                    path: 'rota/manual',
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Rota manual"
                    },
                    component: RotaManual
                },
                {
                    path: 'importar/cargas',
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Importar cargas"
                    }, component: ImportarCargas
                },
                {
                    path: 'importar/pontos/cargas',
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Importar Pedidos + Cargas"
                    }, component: ImportarCargas
                },
                {
                    path: 'aprovacao/pontos',
                    name: 'roteirizadorAprovacaoDePontos',
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Aprovação de Pontos"
                    }, component: AprovacaoDePontos
                },
                {
                    path: 'monitoramento',
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Monitoramento"
                    }, component: MonitoramentoRota

                },
                {
                    path: 'controle/cargas',
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Controle de cargas"
                    }, component: ControleCargas

                },
                {
                    path: 'montagem/cargas',
                    name: 'montagemdecargas',
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Montagem de cargas"
                    }, component: MontagemCargas
                },
            ]
        },
        {
            path: 'cadastros',
            meta: {
                Module: "roteirizador",
                breadcrumbName: "Cadastros"
            },
            components: {
                content: Cadastro
            }, children: [
                {
                    path: 'novas/rotas/:id?',
                    name: "CadastroRotasWay",
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Rotas"
                    }, component: CadastroRotas
                },
                {
                    path: 'rotas',
                    name: "ListagemRotasWay",
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Rotas"
                    }, component: ListagemRotas
                },
                {
                    // essa tela ta em outro modulo pq disso: 
                    // https://app.clickup.com/t/2ykugg1
                    path: 'pontos',
                    name: "listagemPontosWay",
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Pontos"
                    }, component: ListagemPontos
                },
                {
                    // essa tela ta em outro modulo pq disso: 
                    // https://app.clickup.com/t/2ykugg1
                    path: 'importar/pontos',
                    name: "importarPontosWay",
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Pontos"
                    }, component: importarPontos
                },
                {
                    // essa tela ta em outro modulo pq disso: 
                    // https://app.clickup.com/t/2ykugg1
                    path: 'pontos/cadastrar/:id?',
                    name: 'cadastrarPontoWay',
                    meta:
                    {
                        Module: 'roteirizador',
                        breadcrumbName: 'Pontos'
                    },
                    component: CadastroPontos
                },

                {
                    path: 'visitas',
                    name: "listarVisitas",
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Visitas"
                    }, component: ListagemVisitas
                },
                {
                    path: 'visitas/novo/:id?',
                    name: "editarVisitas",
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Cadastro Visitas"
                    }, component: CadastroVisitas
                },
                {
                    path: "tipo/coletas",
                    name: "listarColeta",
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Tipo Coleta",
                    }, component: TipoColetasListagem
                },
                {
                    path: "tipo/coletas/cadastrar/:id?",
                    name: "cadastrarColeta",
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Tipo Coleta",
                    }, component: TipoColetasCadastro
                },
                {
                    path: "justificativas/cadastrar/:id?",
                    name: "cadastrarJustificativas",
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Justificativas",
                    }, component: CadastroJustificativas
                },
                {
                    path: "justificativas",
                    name: "listarJustificativas",
                    meta: {
                        Module: "roteirizador",
                        breadcrumbName: "Justificativas",
                    }, component: ListagemJustificativas
                },
            ],
        },
    ]
}
