/**
Este componente é um input de periodo com limite de tempo alterado para usar o el-date-picker

-> Propriedades:
1 -> id - String - Id do input - EX: 'inputRangeNoLimits'
2 -> name - String - Name do input - EX: 'inputRangeNoLimits'
3 -> isObrigatorio - Boolean - verifica se o campo é obrigatorio, se for ele coloca o (*) - EX: true/false
4 -> opens - String - Para onde o calendário abre
5 -> intervalo - Number - Valor máximo que o usuário pode selecionar - EX='7'
6 -> value - String - Data para alteração - Ex='01/08/2021 - 31/08/2021'
7 -> valueVazia - boolean - Quando true define o value da data como vazio - Ex= false = '04/01/2022 (dia que for aberto
o modal)'; true = ' - (vazio)'
7 -> dataMaxima - data no formato dd/mm/yyyy, sendo o valor máximo que o usuário pode selecionar

-> Slot:
- Sem slots

-> Eventos:
1- changeInput - Emite quando o mes é alterado - Parametros:
1.1- dateRangeFinal - data em formato 'DD/MM/YYYY - DD/MM/YYYY'

-> Obs:
1- format - Formato que o calendário mostra no input
2- value-format - Formato que o calendario envia valores
3- Type - Se o calendário será para selecionar periodo ou valor único
4- clearable - Se tem a opção de apagar a data
5- picker-options - Opções de seleção rápidas
*/

<template>
	<div :class="'col-sm-12 ' + extraClass">
		<div class="col-sm-12 subTitle nopadding" data-test='input-titulo'>
			{{ titulo }}<span v-if="isObrigatorio" data-test='input-obrigatorio'>*</span>
		</div>
		<div>
			<el-date-picker v-model='data.periodo' type='daterange' value-format='dd/MM/yyyy'
				style='width:100%; border-radius: 8px !important;' format='dd/MM/yyyy' size='large' range-separator='-'
				start-placeholder='' end-placeholder='' :picker-options="pickerOptions" :disabled="desabilitado"
				:clearable='false' @change='changeInput' @blur='closeCalendar' v-b-tooltip.hover.focus.top="erroPeriodo ?
					{ variant: erroPeriodo ? 'danger' : 'secondary' } : ''" :title="msgErroPeriodo" />
		</div>
	</div>
</template>
<script>
import Vue from 'vue'
import { DateTime } from 'luxon';
import { pickerOptions } from '@/components/Atom/Datas/datePiker.js'
export default Vue.extend({
	name: 'inputRangeWithLimit',
	data() {
		return {
			data: {
				periodo: [
					DateTime.now().toFormat('dd/LL/yyyy'),
					DateTime.now().toFormat('dd/LL/yyyy')],
			},
			pickerOptions: pickerOptions,
			erroPeriodo: false,
			msgErroPeriodo: '',
		}
	},

	methods: {
		emiteData() {
			this.$emit(
				'changeInput',
				this.data.periodo[0] + ' - ' + this.data.periodo[1]
			)
		},
		closeCalendar() {
			this.$emit('closeCalendar')
		},


		/**
		 * Função que verifica se o intervalo selecionado pelo usuário
		 * é de no máximo 31 dias ou o informado na prop intervalo,
		 * caso seja maior do que isso, a função
		 * faz com que a tooltip do seletor fique vermelha e seleciona
		 * os primeiros 31 dias ou o informado na prop (intervalo)
		 * depois da data inicial selecionada pelo
		 * usuário
		 * @param periodo array gerada pelo componente de seleção de data
		 * @author Gui 🍺🍺, Vitor Hugo alterou 🐨
		 */
		changeInput(periodo) {
			if (!periodo) return
			var com = DateTime.fromFormat(periodo[0], 'dd/LL/yyyy')
			var fim = DateTime.fromFormat(periodo[1], 'dd/LL/yyyy')
			var dif = fim.diff(com, 'days').toObject()
			if (this.dataMaxima) {
				var data_maxima = DateTime.fromFormat(this.dataMaxima, 'dd/LL/yyyy')
				if (com > data_maxima) {
					this.data.periodo[0] = data_maxima.toFormat('dd/LL/yyyy')
					this.data.periodo[1] = data_maxima.toFormat('dd/LL/yyyy')
					this.msgErroPeriodo = `Só é possível selecionar datas antes de ${this.dataMaxima}`
					this.erroPeriodo = true
					setTimeout(() => {
						this.msgErroPeriodo = ''
						this.erroPeriodo = false
						this.$emit(
							'changeInput',
							this.data.periodo[0] + ' - ' + this.data.periodo[1]
						)
					}, 6666);
					return
				} else if (fim > data_maxima) {
					this.data.periodo[1] = data_maxima.toFormat('dd/LL/yyyy')
					this.msgErroPeriodo = `Só é possível selecionar datas antes de ${this.dataMaxima}`
					this.erroPeriodo = true
					this.erroPeriodo = true
					setTimeout(() => {
						this.erroPeriodo = false
						this.$emit(
							'changeInput',
							this.data.periodo[0] + ' - ' + this.data.periodo[1]
						)
					}, 6666);
					return
				}
			}
			if (dif.days > this.intervalo - 1) {
				this.msgErroPeriodo = 'Período máximo de ' + this.intervalo + ' dias'
				fim = com.plus({ 'days': this.intervalo - 1 })
				this.data.periodo[1] = fim.toFormat('dd/LL/yyyy')
				this.erroPeriodo = true
				// setTimeout(() => {
				this.erroPeriodo = false
				this.msgErroPeriodo = ''
				this.$emit(
					'changeInput',
					this.data.periodo[0] + ' - ' + this.data.periodo[1]
				)
				// }, 6666);
				return
			} else {
				this.$emit(
					'changeInput',
					this.data.periodo[0] + ' - ' + this.data.periodo[1]
				)
				this.erroPeriodo = false
			}
		},
	},

	mounted() {
		// Acicionado pois, quando utilizado dentro de filtros
		// o watch não funcionava corretamente
		if (this.value !== '') {
			var data = this.value.split(' - ')
			this.data.periodo = data
			this.changeInput(data)
		} else if (this.valueVazia) {
			this.data.periodo = []
		} else {
			this.changeInput(this.data.periodo);
		}
	},

	watch: {
		/**
		 * Função para alterar valor da data, 
		 *  quando passado um valor pela prop value é alterado
		 * o valor da data e 
		 *  chamado o método change input para passar o valor para tela
		 * @author Vitor Hugo 🐨
		*/
		value: function () {
			var data = this.value.split(' - ')
			this.data.periodo = data
			this.changeInput(data)
		}
	},

	props: {
		opens: {
			type: String,
			default: 'rigth'
		},
		id: {
			type: String,
			required: false,
			default: '',
		},
		name: {
			type: String,
			required: true,
			default: '',
		},
		isObrigatorio: {
			type: Boolean,
			required: false,
			default: false
		},
		titulo: {
			type: String,
			required: false,
			default: 'Intervalo'
		},
		desabilitado: {
			type: Boolean,
			default: false,
		},
		intervalo: {
			type: Number,
			default: 31,
		},
		value: {
			type: String,
			default: '',
		},
		valueVazia: {
			type: Boolean,
			default: false,
		},
		dataMaxima: {
			type: [String, Boolean],
			default: false,
		},
		extraClass: {
			type: [String, Object],
			default: '',
		},
	}
})
</script>
<style lang="scss">
.subTitle {
	font-size: 13px;
	text-align: left;
	margin-bottom: 0px;
}

.vue-daterange-picker {
	display: block !important;
}
</style>