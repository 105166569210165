<template>
    <tableRelatorio>
        <slot slot="thead">
            <th style="width: 18%;">Linha</th>
            <th style="width: 5%;">Veiculo</th>
            <th style="width: 5%;">Prefixo</th>
            <th style="width: 5%;">Colaborador</th>
            <th style="width: 5%;">Turno</th>
            <th style="width: 8%;">Escala</th>
            <th style="width: 5%;">Tempo(PxR)</th>
            <th style="width: 5%;">Intervalo</th>
            <th style="width: 5%;">Percorrido</th>
            <th style="width: 1%;">Progresso</th>
            <th style="width: 1%;">Status</th>
        </slot>

        <slot slot="tbody">
            <statusInformation v-if="tamanhoRelatorio == 0" typeBar="tr" :statusBar="statusbar">
            </statusInformation>

            <!-- Totalizadores de todos os dias -->
            <tr v-if="tamanhoRelatorio > 0">
                <td colspan="12">
                    <div class="d-flex align-items-center justify-content-between w-full mt-1">
                        <div class="d-flex align-items-start">
                            <span class="mr-2 font-bold text-truncate" style="font-size: 14px;">Km Produtivo:</span>
                            <span>
                                {{ totalizadoresTodosDias.totalKmProdutivoDia }} Km
                            </span>
                        </div>

                        <div class="d-flex align-items-start">
                            <span class="mr-2 font-bold text-truncate" style="font-size: 14px;">Km Improdutivo:</span>
                            <span class="text-truncate">
                                {{ totalizadoresTodosDias.totalKmImprodutivoDia }} Km
                            </span>
                        </div>

                        <div class="d-flex align-items-start">
                            <span class="mr-2 font-bold text-truncate" style="font-size: 14px;">Peso:</span>
                            <span class="text-truncate">
                                {{ totalizadoresTodosDias.totalPesoDia }} Kg
                            </span>
                        </div>

                        <div class="d-flex align-items-start">
                            <span class="mr-2 font-bold text-truncate" style="font-size: 14px;">Tempo Produtivo:</span>
                            <span class="text-truncate">
                                {{ totalizadoresTodosDias.totalTempoProdutivoDia }}
                            </span>
                        </div>

                        <div class="d-flex align-items-start">
                            <span class="mr-2 font-bold text-truncate" style="font-size: 14px;">Tempo
                                Improdutivo:</span>
                            <span class="text-truncate">
                                {{ totalizadoresTodosDias.totalTempoImprodutivoDia }}
                            </span>
                        </div>

                        <div class="d-flex align-items-start">
                            <span class="mr-2 font-bold text-truncate" style="font-size: 14px;">Tempo Execução:</span>
                            <span class="text-truncate">
                                {{ totalizadoresTodosDias.totalTempoExecucaoDia }}
                            </span>
                        </div>
                    </div>
                </td>
            </tr>

            <template v-for="(data, d) in dadosRelatorio">
                <!-- Badge da data -->
                <tr :key="d + 'data'" class="trDataTabela ">
                    <td colspan="2" class="dataTabela cinzaClaro" style="width: 5%; background-color: #777777">
                        {{ d }}
                    </td>
                </tr>

                <!-- Totalizadores diário -->
                <tr :key="d + '_br2'">
                    <td colspan="12">
                        <div class="d-flex align-items-center justify-content-between w-full mt-1">
                            <div class="d-flex align-items-start">
                                <span class="mr-2 font-bold text-truncate" style="font-size: 14px;">Km Produtivo:</span>
                                <span>
                                    {{ data[data.length - 1].totalKmProdutivo }} Km
                                </span>
                            </div>

                            <div class="d-flex align-items-start">
                                <span class="mr-2 font-bold text-truncate" style="font-size: 14px;">Km
                                    Improdutivo:</span>
                                <span class="text-truncate">
                                    {{ data[data.length - 1].totalKmImprodutivo }} Km
                                </span>
                            </div>

                            <div class="d-flex align-items-start">
                                <span class="mr-2 font-bold text-truncate" style="font-size: 14px;">Peso:</span>
                                <span class="text-truncate">
                                    {{ data[data.length - 1].totalPeso }} Kg
                                </span>
                            </div>

                            <div class="d-flex align-items-start">
                                <span class="mr-2 font-bold text-truncate" style="font-size: 14px;">Tempo
                                    Produtivo:</span>
                                <span class="text-truncate">
                                    {{ data[data.length - 1].totalTempoProdutivo }}
                                </span>
                            </div>

                            <div class="d-flex align-items-start">
                                <span class="mr-2 font-bold text-truncate" style="font-size: 14px;">Tempo
                                    Improdutivo:</span>
                                <span class="text-truncate">
                                    {{ data[data.length - 1].totalTempoImprodutivo }}
                                </span>
                            </div>

                            <div class="d-flex align-items-start">
                                <span class="mr-2 font-bold text-truncate" style="font-size: 14px;">Tempo
                                    Execução:</span>
                                <span class="text-truncate">
                                    {{ data[data.length - 1].totalTempoExecucao }}
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>

                <template v-for="(linha, l) in data">
                    <!-- Dados principais -->
                    <tr :key="l + 'dadosTabela' + d" v-b-toggle="(linha.codigo + '' + l)"
                        @click="linha.clicado = !linha.clicado" :class="linha.classeTr == 'trEmAtrasoTolerado' ?
                            'dadosTabela sem-borda trEmAtrasoTolerado' :
                            linha.classeTr == 'trAtrasada' ?
                                'dadosTabela sem-borda trAtrasada' :
                                linha.classeTr == 'trDeAcordoComOProgramado' ?
                                    'dadosTabela sem-borda trDeAcordoComOProgramado' :
                                    'dadosTabela cinzaClaro sem-borda'">
                        <td class="fonteTabelaExterna">
                            <div class="chevronTop">
                                <b-icon :id="actived" class="h5 mb-0 fonteTabelaExterna" shift-v="-6" shift-h="0"
                                    style="font-weight: bold;" :icon="linha.clicado ?
                                        'chevron-down' : 'chevron-right'" />
                            </div>
                            <div class="fonteTabelaExterna">
                                {{ linha.linha }}
                            </div>
                        </td>
                        <td class="fonteTabelaExterna">{{ linha.placa }}</td>
                        <td class="fonteTabelaExterna">{{ linha.prefixo }}</td>
                        <td class="fonteTabelaExterna">{{ linha.motorista }}</td>
                        <td class="fonteTabelaExterna">{{ linha.turno }}</td>
                        <td class="fonteTabelaExterna">{{ linha.escala }}</td>
                        <td class="fonteTabelaExterna text-truncate">{{ linha.tempoProg }} | {{ linha.tempoReal }}</td>
                        <td class="fonteTabelaExterna">{{ linha.itensSemPontos.intervalo }}</td>
                        <td class="fonteTabelaExterna">{{ linha.percorrido }} Km</td>
                        <td class="fonteTabelaExterna">{{ linha.progresso + '%' }}</td>
                        <td class="fonteTabelaExterna">{{ linha.rcstatus }}</td>
                    </tr>

                    <!-- Chevron, dados ocultos  -->
                    <tr :key="l + 'chevron' + d">
                        <td colspan="11" class="sem-borda">
                            <b-collapse :id="(linha.codigo + '' + l)">
                                <div>
                                    <div class="pb-1" style="background-color: #A8BAF1;">
                                        <div class="row align-items-center">
                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <baseIcon size="20" class="mr-2" style="color: #000"
                                                    :icon="mdiClockCheckOutline" />
                                                <span class="font-bold" style="font-size: 14px;">Tempo em
                                                    execução</span>
                                            </div>

                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="mr-2 font-bold" style="font-size: 14px;">Tempo
                                                    produtivo:</span>
                                                <span
                                                    v-b-popover.hover.top="linha.itensSemPontos.tempoProdutivoImprodutivo.tempoProdutivo">
                                                    {{ linha.itensSemPontos.tempoProdutivoImprodutivo.tempoProdutivo }}
                                                </span>
                                            </div>

                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="mr-2 font-bold" style="font-size: 14px;">Tempo
                                                    improdutivo:</span>
                                                <span
                                                    v-b-popover.hover.top="linha.itensSemPontos.tempoProdutivoImprodutivo.tempoImprodutivo">
                                                    {{ linha.itensSemPontos.tempoProdutivoImprodutivo.tempoImprodutivo
                                                    }}
                                                </span>
                                            </div>

                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="mr-2 font-bold" style="font-size: 14px;">Tempo de
                                                    execução:</span>
                                                <span
                                                    v-b-popover.hover.top="linha.itensSemPontos.tempoProdutivoImprodutivo.tempoExecucao">
                                                    {{ linha.itensSemPontos.tempoProdutivoImprodutivo.tempoExecucao }}
                                                </span>
                                            </div>
                                        </div>

                                        <div class="row align-items-center">
                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <baseIcon size="20" class="mr-2" style="color: #000"
                                                    :icon="mdiCarTractionControl" />
                                                <span class="font-bold" style="font-size: 14px;">Km produtivo /
                                                    improdutivo</span>
                                            </div>

                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="font-bold mr-2" style="font-size: 14px;">Km
                                                    produtivo:</span>
                                                <span
                                                    v-b-popover.hover.top="linha.itensSemPontos.kmProdutivoImprodutivo.kmProdutivo + ' km'">
                                                    {{ linha.itensSemPontos.kmProdutivoImprodutivo.kmProdutivo }} km
                                                </span>
                                            </div>

                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="font-bold mr-2" style="font-size: 14px;">Km
                                                    improdutivo:</span>
                                                <span
                                                    v-b-popover.hover.top="linha.itensSemPontos.kmProdutivoImprodutivo.kmImprodutivo + ' km'">
                                                    {{ linha.itensSemPontos.kmProdutivoImprodutivo.kmImprodutivo }} km
                                                </span>
                                            </div>

                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="font-bold mr-2" style="font-size: 14px;">Média de
                                                    consumo:</span>
                                                <span
                                                    v-b-popover.hover.top="linha.itensSemPontos.kmProdutivoImprodutivo.mediaConsumo + ' km/L'">
                                                    {{ linha.itensSemPontos.kmProdutivoImprodutivo.mediaConsumo }} km/L
                                                </span>
                                            </div>
                                        </div>

                                        <div class="d-flex align-items-center">
                                            <div
                                                class="col-sm-3 d-flex align-items-center border-div border-blue-100 p-2">
                                                <baseIcon size="20" class="mr-2" style="color: #000"
                                                    :icon="mdiBusMarker" />
                                                <span class="font-bold" style="font-size: 14px;">Transbordo</span>
                                            </div>

                                            <div
                                                class="col-sm-3 d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="mr-2 font-bold" style="font-size: 14px;">Descarga
                                                    (ponto):</span>
                                                <span v-b-popover.hover.top="linha.totalDescargas + ' pontos'">
                                                    {{ linha.totalDescargas }} pontos
                                                </span>
                                            </div>

                                            <div
                                                class="col-sm-6 d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="mr-2 font-bold" style="font-size: 14px;">Peso:</span>
                                                <span v-b-popover.hover.top="linha.rctpesagem + ' Kg'">
                                                    {{ linha.rctpesagem }} Kg
                                                </span>
                                            </div>
                                        </div>

                                        <div class="d-flex align-items-center p-2 border-div border-blue-100"
                                            style="border-bottom: 1px solid black;">
                                            <div class="d-flex align-items-center" style="min-width: 120px;">
                                                <baseIcon size="20" class="mr-2" style="color: #000"
                                                    :icon="mdiAccountGroup" />
                                                <span class="font-bold" style="font-size: 14px;">Ajudantes</span>
                                            </div>

                                            <div class="flex-grow-1 pl-4">
                                                <template v-if="linha.ajudantes && linha.ajudantes.length > 0">
                                                    <div class="d-flex flex-wrap">
                                                        <span v-for="(ajudante, index) in linha.ajudantes" :key="index"
                                                            style="font-size: 10px;" class="mr-1 font-bold">
                                                            {{ ajudante }}{{ index < linha.ajudantes.length - 1 ? ','
                                                                : '' }} </span>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <span style="font-size: 10px; font-style: italic; color: #666;">
                                                        Sem ajudantes
                                                    </span>
                                                </template>
                                            </div>
                                        </div>

                                        <div class="row align-items-center" style="background-color: #819CF2;">
                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <baseIcon size="20" class="mr-2" style="color: #000"
                                                    :icon="mdiTransitConnectionVariant" />
                                                <span class="font-bold" style="font-size: 14px;">Setores (Região)</span>
                                            </div>

                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="mr-2 font-bold" style="font-size: 14px;">Tempo no
                                                    setor</span>
                                            </div>

                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="mr-2 font-bold" style="font-size: 14px;">Km
                                                    percorrido</span>
                                            </div>

                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="mr-2 font-bold" style="font-size: 14px;">Entrada</span>
                                            </div>

                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="mr-2 font-bold" style="font-size: 14px;">Hodômetro
                                                    entrada</span>
                                            </div>

                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="mr-2 font-bold" style="font-size: 14px;">Saída</span>
                                            </div>

                                            <div class="col d-flex align-items-center border-div border-blue-100 p-2">
                                                <span class="mr-2 font-bold" style="font-size: 14px;">Hodômetro
                                                    saída</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-for="(regiao, indexRegiao) in linha.itensSemPontos.dadosRegiao"
                                        :key="indexRegiao" style="margin-top: -5px;">
                                        <div @click="$set(regiao, 'clicadoRegiao', !regiao.clicadoRegiao)"
                                            style="background-color: #A8BAF1; cursor: pointer;">
                                            <div class="row align-items-center">
                                                <div
                                                    class="col d-flex align-items-center border-div border-blue-200 p-1 mb-1">
                                                    <baseIcon size="20" class="mr-2" style="color: #000"
                                                        :icon="regiao.clicadoRegiao ? (mdiChevronDown) : (mdiChevronRight)" />
                                                    <span style="font-size: 14px;">{{
                                                        regiao.informacoesRegiao.nomeRegiao }}</span>
                                                </div>

                                                <div
                                                    class="col d-flex align-items-center border-div border-blue-200 p-1 mb-1">
                                                    <span class="mr-2" style="font-size: 14px;"
                                                        v-b-popover.hover.top="regiao.informacoesRegiao.tempoNoSetor">{{
                                                        regiao.informacoesRegiao.tempoNoSetor
                                                        }}</span>
                                                </div>

                                                <div
                                                    class="col d-flex align-items-center border-div border-blue-200 p-1 mb-1">
                                                    <span class="mr-2 text-truncate" style="font-size: 14px;"
                                                        v-b-popover.hover.top="regiao.informacoesRegiao.kmPercorrido + 'Km'">{{
                                                        regiao.informacoesRegiao.kmPercorrido
                                                        }} Km</span>
                                                </div>

                                                <div
                                                    class="col d-flex align-items-center border-div border-blue-200 p-1 mb-1">
                                                    <span class="mr-2 text-truncate" style="font-size: 14px;"
                                                        v-b-popover.hover.top="regiao.informacoesRegiao.dataHoraEntrada">{{
                                                        regiao.informacoesRegiao.dataHoraEntrada }}</span>
                                                </div>

                                                <div
                                                    class="col d-flex align-items-center border-div border-blue-200 p-1 mb-1">
                                                    <span class="mr-2 text-truncate" style="font-size: 14px;"
                                                        v-b-popover.hover.top="regiao.informacoesRegiao.hodometroEntrada + 'Km'">{{
                                                        regiao.informacoesRegiao.hodometroEntrada }} Km</span>
                                                </div>

                                                <div
                                                    class="col d-flex align-items-center border-div border-blue-200 p-1 mb-1">
                                                    <span class="mr-2 text-truncate" style="font-size: 14px;"
                                                        v-b-popover.hover.top="regiao.informacoesRegiao.dataHoraSaida">{{
                                                        regiao.informacoesRegiao.dataHoraSaida }}</span>
                                                </div>

                                                <div
                                                    class="col d-flex align-items-center border-div border-blue-200 p-1 mb-1">
                                                    <span class="mr-2 text-truncate" style="font-size: 14px;"
                                                        v-b-popover.hover.top="regiao.informacoesRegiao.hodometroSaida + 'Km'">{{
                                                        regiao.informacoesRegiao.hodometroSaida }} Km</span>
                                                </div>
                                            </div>
                                        </div>

                                        <b-collapse :visible="regiao.clicadoRegiao">
                                            <div v-if="regiao.transbordos.length > 0"
                                                style="background-color: #819CF2; cursor: pointer;">
                                                <div class="row align-items-center p-2">
                                                    <div class="col d-flex align-items-center">
                                                        <baseIcon size="20" class="mr-2" style="color: #000"
                                                            :icon="mdiBusMarker" />
                                                        <span class="font-bold" style="font-size: 14px;">Nome</span>
                                                    </div>

                                                    <div class="col d-flex align-items-center">
                                                        <span class="mr-2 font-bold"
                                                            style="font-size: 14px;">Ticket</span>
                                                    </div>

                                                    <div class="col d-flex align-items-center">
                                                        <span class="mr-2 font-bold"
                                                            style="font-size: 14px;">Peso</span>
                                                    </div>

                                                    <div class="col d-flex align-items-center">
                                                        <span class="mr-2 font-bold" style="font-size: 14px;">Descarga
                                                            (ponto)</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="regiao.transbordos.length <= 0"
                                                style="background-color: #d6d9dd; cursor: pointer; padding-top: 10px; border-bottom: 1px solid rgb(156 163 175);">
                                                <p class="text-center">Nenhum transbordo registrado para essa
                                                    região!</p>
                                            </div>
                                            <div v-else>
                                                <div v-for="(transbordo, indexTransbordo) in regiao.transbordos"
                                                    v-bind:key="indexTransbordo">
                                                    <div
                                                        style="background-color: rgba(219, 221, 226, 0.757); cursor: pointer;">
                                                        <div class="row align-items-center">
                                                            <div
                                                                class="col d-flex align-items-center border-div border-gray-100 p-2">
                                                                <span style="font-size: 14px;">Transbordo {{
                                                                    indexTransbordo
                                                                    + 1
                                                                    }}</span>
                                                            </div>

                                                            <div class="col d-flex align-items-center border-div border-gray-100 p-2"
                                                                v-b-popover.hover.top="transbordo.ticket">
                                                                <span class="mr-2" style="font-size: 14px;">{{
                                                                    transbordo.ticket }}</span>
                                                            </div>

                                                            <div class="col d-flex align-items-center border-div border-gray-100 p-2"
                                                                v-b-popover.hover.top="transbordo.peso">
                                                                <span class="mr-2" style="font-size: 14px;">{{
                                                                    transbordo.peso }} Kg</span>
                                                            </div>

                                                            <div class="col d-flex align-items-center border-div border-gray-100 p-2"
                                                                v-b-popover.hover.top="transbordo.descarga">
                                                                <span class="mr-2" style="font-size: 14px;">{{
                                                                    transbordo.descarga }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-collapse>
                                    </div>
                                </div>
                            </b-collapse>
                        </td>
                    </tr>
                </template>
            </template>
        </slot>
    </tableRelatorio>
</template>
<script>
import Vue from 'vue'
import {
    mdiMapMarkerCheck,
    mdiMapMarkerRemoveVariant,
    mdiClockCheckOutline,
    mdiCarTractionControl,
    mdiBusMarker,
    mdiAccountGroup,
    mdiChevronRight,
    mdiChevronDown,
    mdiTransitConnectionVariant,
    mdiChartBoxOutline
} from '@mdi/js'

export default Vue.extend({
    name: 'TabelaDetalhesLinhasSemPontos',

    components: {
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
        'tableRelatorio': require('@/components/Atom/Table/TableRelatorio').default,
        'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
    },

    data() {
        return {
            mdiMapMarkerCheck: mdiMapMarkerCheck,
            mdiMapMarkerRemoveVariant: mdiMapMarkerRemoveVariant,
            mdiClockCheckOutline: mdiClockCheckOutline,
            mdiCarTractionControl: mdiCarTractionControl,
            mdiBusMarker: mdiBusMarker,
            mdiAccountGroup: mdiAccountGroup,
            mdiChevronDown: mdiChevronDown,
            mdiChevronRight: mdiChevronRight,
            mdiTransitConnectionVariant: mdiTransitConnectionVariant,
            mdiChartBoxOutline: mdiChartBoxOutline,
            regiaoAtual: 0,
        }
    },

    props: {
        tamanhoRelatorio: {
            type: Number,
            required: true,
            default: 0,
        },
        statusbar: {
            type: String,
            required: true,
            default: 'info'
        },
        dadosRelatorio: {
            required: true,
        },
        actived: {
            type: Boolean,
            required: true,
            default: false,
        },
        totalizadoresTodosDias: {
            required: true,
        }
    }
})
</script>

<style lang="css" scoped>
.trDataTabela {
    background: #fff !important;
}

.dataTabela {
    min-width: 30%;
    text-align: center;
    vertical-align: middle;
    background: #777777;
    height: 35px;
    margin-top: 20px !important;
    color: #fff;
    font-weight: 500;
    font-size: 1 !important;
}

.cinzaClaro {
    background: #dbdde2;
}

.trEmAtrasoTolerado {
    background-color: #f9c576 !important;
}

.dadosTabela {
    td {
        border: 1px solid #d5d5d5;
    }
}

.sem-borda {
    padding: 0px;
    border: none !important;
}

.trAtrasada {
    background-color: #f0a7a6 !important;
}

.trDeAcordoComOProgramado {
    background-color: #b5cd8e !important;
}

.fonteTabelaExterna {
    font-size: 12px !important;
}

.chevronTop {
    vertical-align: top;
    display: block;
}

.fonteTabelaInterna {
    font-size: 11px !important;
}

.cinzaEscuro {
    background: #b1b2b5;
}

.descPontoDL {
    text-align: center;
}

.dadosChevron {
    background: #fff !important;
    border-bottom: 1px solid #d5d5d5;
    border-left: 1px solid #d5d5d5;
    border-right: 1px solid #d5d5d5;
    padding-left: 0px;
}

.divAccordion {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.border-div {
    border-width: 1px;
    border-style: solid;
}

.border-blue-100 {
    border-color: #8797c8;
}

.border-gray-100 {
    border-color: #bfbfbf;
}

.border-blue-200 {
    border-color: #788ed0;
}
</style>
