<template>
    <b-navbar id="menuNavBar" type="dark" variant="dark" class="navbar">
        <b-collapse id="nav-collapse" is-nav v-b-toggle.collapse-1>
            <div class="input-pesquisa" v-if="$route.name == 'Dashboard'">
                <div :class="arrayFiltro.length ? 'div-geraral-input pesquisa' : 'div-geraral-input mensagem-hover'">
                    <div class="div-escritas text-left">
                        <div v-if="arrayFiltro.length == 0" class="col-sm-12 nopadding span-ajuda">
                            <div class="destaque col-sm-12 nopadding">Precisando de ajuda?</div>
                            <div class="descricao col-sm-12 nopadding">
                                Digite uma palavra chave da funcionalidade
                            </div>
                        </div>
                    </div>
                    <input v-model="valueFiltro" type="text" class="" @blur="fechaLista" @keyup.esc="fechaLista" />
                    <div class="div-icone">
                        <baseIcon size="35" :icon="mdiMagnify" />
                    </div>
                </div>
                <div class="div-filtros" v-show="arrayFiltro.length > 0">
                    <template v-for="(dados, index) in arrayFiltro">
                        <div :class="dados.piurl ? 'cursor-pointer' : ''" :key="index"
                            @click="direcionaRota(dados.piurl)">
                            <span class="destaque">{{ dados.descricao }}</span><br />
                            <span class="descricao">
                                {{ dados.pidescritivofuncionalidade }}
                            </span>
                        </div>
                    </template>
                </div>
            </div>
            <b-navbar-nav class="ml-auto btn col-sm-12" align="right">
                <div class="col-sm-6 my-auto pr-0" align="right" :style="{ 'max-width': 'none !important' }">
                    <div v-if="isAuth" style="border-radius: 8px !important;" class="versao col-sm-6 my-auto"
                        align="center">
                        <strong>Versão: {{ getVersao() ? getVersao() : '' }}</strong>
                    </div>
                </div>

                <centralNotificacao v-if="isAuth" />

                <b-nav-item-dropdown right>
                    <template v-slot:button-content>
                        <baseIcon size="25" class="iconTitulo" :icon="mdiAccountTie" />
                    </template>
                    <div class="col-sm-12 bg-branco pl-0 pr-0 pt-2 pb-2">
                        <h6 class="dropdown-header userName">
                            {{ Name }}
                        </h6>
                        <div class="dropdown-divider" />
                        <b-dropdown-item v-if="!isAuth" to="/login"> Entrar </b-dropdown-item>
                        <b-dropdown-item v-if="isAuth" to="/logout">
                            <baseIcon size="20" class="close" :icon="mdiExitToApp" />Sair
                        </b-dropdown-item>
                    </div>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>
<script>
import { Crypto } from '@/Services/auth/Crypto.Service'
import { comparaArrayString } from '@/Services/Helpers/StringHelper.js'
import {
    mdiAccountTie,
    mdiExitToApp,
    mdiMagnify,
} from '@mdi/js';
import Vue from 'vue'
import { mapGetters } from 'vuex';
// import {AuthService} from '../../../Services/auth/Auth.Service'
import vuex from '../../../store/index'
export default Vue.extend({
    name: 'menuNavBar',
    components: {
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
        centralNotificacao: require('@/components/Atom/Header/CentralNotificacao.vue').default,
        // inputSearch : require('@/components/Atom/Inputs/InputSearch').default,
    },
    data() {
        return {
            valueFiltro: '',
            versaoAtual: localStorage.getItem('versaoAtual'),
            mdiAccountTie: mdiAccountTie,
            mdiExitToApp: mdiExitToApp,
            mdiMagnify: mdiMagnify,
            url: '',
            arrayFiltro: [],
            fecharLista: false,
            direcionando: false,
            //Name: new AuthService().getUser()!.name || "Não Autenticado",
            //isAuth: new AuthService().isAuthenticated(),
        }
    },

    watch: {
        /**
         * fica observando se ouve um modificação
         * no input de filtro, faz a busca da tela mais proxima com o que o usuairio escreveu]
         * @auhor Lucas Eduardo
         */
        valueFiltro(val) {
            if (!val && this.fecharLista == false) {
                this.arrayFiltro = new Crypto().Get('palavraChave')
                return
            }
            this.fecharLista = false
            this.arrayFiltro = []
            var arrObj = [...new Crypto().Get('palavraChave')]
            var arrComparar = val.split(' ')
            arrComparar.forEach((str, i) => {
                arrObj.map((v) => {
                    let palavrasArr = v.pipalavraschave ? v.pipalavraschave.split(' - ') : []
                    if (comparaArrayString(palavrasArr, str)) {
                        v.pontos += 1
                    } else if (v.pontos >= 0) {
                        v.pontos -= 1
                    }
                })
            })
            // peugei da doc do mdn pra ter certeza que vai dar bom no google
            function isBigEnough(v) {
                return v.pontos > 0
            }
            arrObj.sort(function (a, b) {
                if (a.pontos < b.pontos) {
                    return 1
                }
                if (a.pontos < b.pontos) {
                    return -1
                }
                return 0
            })
            this.arrayFiltro = arrObj.filter(isBigEnough)
        },
    },

    methods: {

        direcionaRota(rota) {
            this.direcionando = true
            if (!rota) {
                return
            }
            this.$router.push(rota)
            this.fechaLista()
        },

        /**
         * @listens keyup.esc
         * @listens blur - quando pressionado fora do input
         * @description Método para limpar lista de filtros,
         *  a partir do blur fica escutando quando é clicado
         *  fora do input de pesquisa,
         *  utilizado setTimeout para dar tempo do evento
         *  direcionaRota ser executado
         * @author Vitor Hugo
         */
        fechaLista() {
            setTimeout(() => {
                this.arrayFiltro = []
                this.valueFiltro = ''
                this.fecharLista = true
            }, 500);
        },

        ...mapGetters(['getVersao']),
    },
    computed: {

        isAuth() {
            return vuex.state.isAuth
        },
        Name() {
            try {
                var user = vuex.state.user.name
                user = user.charAt(0).toUpperCase() + user.slice(1)
                return user
            } catch {
                return 'Não Autenticado'
            }
        },

        /**
         * @description Variável computada para atualizar a versão
         *  Pega valor do localStorage através do vuex
         *  É a versão em que o usuário esta no momento
         * @return {string} - Versão atual do usuário
         * @author Vitor Hugo 🐨
         */
        versao() {
            return this.getVersao()
        },
    },
})
</script>
<style lang="scss">
.input-pesquisa {
    z-index: 1;
    top: 0px;
    position: fixed;

    .div-geraral-input {
        z-index: 3;
        top: 50px;
        height: 40px;
        width: 270px;
        overflow: hidden;
        position: relative;
        border: 2px solid #054b5e;
    }

    .mensagem-hover:hover {
        .span-escritas {
            z-index: 3;
        }

        input {
            border: 1px solid red;
            z-index: 4;
        }
    }

    .pesquisa {
        input {
            border: 1px solid red;
            z-index: 4;
        }
    }

    .div-filtros {
        overflow-y: auto;
        max-height: 520px;
        width: 270px;
        top: 50px;
        position: relative;
        color: black;
        background-color: #FFF;
        border: 2px solid #054b5e;

        div {
            padding: 5px;
            text-align: left;
            border: 1px solid #054b5e;
            font-size: 10px;
        }
    }

    .div-escritas {
        overflow: hidden;
        height: 35px;
        width: 100%;
        top: 0.2vh;
        transform: translateX(1vw);
        color: black;
        z-index: 3;
        top: 1;
        right: 3%;
        position: relative;
        background-color: #FFF;

        .span-ajuda {
            font-size: 9px;
        }
    }

    .div-icone {
        height: 41px;
        padding-left: 5px;
        transform: rotateY(180deg);
        color: #3498db;
        width: 50px;
        background-color: #054b5e;
        top: -78px;
        left: calc(100% - 42px);
        z-index: 9;
        position: relative;
    }

    div>input {
        top: -40px;
        position: relative;
        height: 43px;
        width: 100%;
        padding-left: 10px;
        background-color: #FFF;
        border: 1px solid #2b9dc4;
        box-shadow: 0 0 .5em #2b9dc4, 0 0 .5em black;
        z-index: 1;

        &:focus {
            z-index: 3;
        }
    }

    .destaque {
        font-family: 'nexabold';
        font-size: 12px;
    }

    .descricao {
        font-size: 10px;
    }
}

.botoes-extra {
    max-width: none !important;

    .ajuda-eu {
        height: 33px;
        line-height: 34px;
        width: 200px !important;
        max-width: 100% !important;
        margin-top: 3px !important;
        background-color: #4285f4;

        &:hover {
            background-color: #4eabfc;
        }
    }

    .chamado {
        height: 34px;
        line-height: 35px;
        width: 340px !important;
        max-width: 100% !important;
        background-color: #ffa600;

        &:hover {
            background-color: #ffca67;
        }
    }

    a {
        color: #FFF;

        &:hover {
            text-decoration: none;
            color: #FFF;
        }
    }
}

.versao {
    height: 33px;
    line-height: 35px;
    width: 200px !important;
    max-width: 100% !important;
    background-color: #4285f4;
    color: #fff;
    cursor: auto;
}

.logo {
    align-items: center;
    max-width: 25px;
    margin-left: 50px;
}

.navbar {
    max-height: 40px !important;
}

.close {
    margin-left: 1%
}

.userName {
    text-align: center !important;
    font-size: 1.2rem !important;
}

.rounded-2 {
    border-radius: 100px !important;
}
</style>
