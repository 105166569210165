<template>
    <panelEagle id='OrdemDeServico' :loading='gerandoRel'>
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage :icon='mdiNoteText' titulo='Ordem de serviço'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 divDontPrint">
                <basicButtonsRelatoriosEIG exportaPersonalizado
                    :infoPersonalizado="{ text: 'OS', icon: mdiFileDownloadOutline }" :loading="loadingButton"
                    :disabled='$v.dadosEmpresa.$invalid' :disabledDropdown='!Object.keys(dadosRelatorio).length'
                    @exportarRelatorio="confereExportar" @gerarRelatorio='gerarRelatorio'>
                </basicButtonsRelatoriosEIG>
            </div>
        </div>
        <slideUandD>
            <div class="col-sm-12 col-md-12">
                <hr>
            </div>
            <div class='col-sm-12 row nopadding'>
                <div class="col-lg-3 nopadding">
                    <inputRangeWithLimit opens="right" id='Intervalo*' name='Intervalo' @changeInput='changeData'
                        :isObrigatorio='true' :isRadio="optRadioGroup" nameForRadio="radioData" @changeRadio="changeDataRadio"/>
                </div>
                <div class="col-lg-3 nopadding">
                    <selectAll nameForRadio='Empresas' :isMultiple='true' :optionsArray='optEmpresas'
                        :labels='labelsEmp' :hasSelectAll='true' @changeSelect='changeEmpresa' />
                </div>
                <div class="col-lg-3 nopadding">
                    <selectAll nameForRadio='Ordem de serviço' :loading="carregandoSelect" :isMultiple='true' :optionsArray='optOrdem'
                        :disabled='!optOrdem.length' :labels='labelsOrd' :hasSelectAll='true'
                        @changeSelect='changeOrdem' />
                </div>
                <div class="col-lg-3 nopadding">
                    <selectAll nameForRadio='Veículos' :loading="carregandoSelect"  :disabled='!optVeiculos.length' :isMultiple='true'
                        :optionsArray='optVeiculos' :labels='labelsVei' :hasSelectAll='true'
                        @changeSelect='changeVeiculo' />
                </div>
                <div class="col-12 my-2"></div>
                <div class='col-sm-12 row nopadding'>
                    <div class="col-lg-3 nopadding">
                        <selectAll nameForRadio='Módulo' :loading="carregandoSelect"  :disabled='(!optModulos.length) || disabledSelectModulo' :isMultiple='true'
                            :optionsArray='optModulos' :labels='labelsModulo' :hasSelectAll='true' @changeSelect='changeModulo' />
                    </div>
                    <div class="col-lg-3 nopadding">
                        <selectAll nameForRadio='Chip' :loading="carregandoSelect"  :disabled='(!optChips.length) || disabledSelectChip' :isMultiple='true'
                            :optionsArray='optChips' :labels='labelsChip' :hasSelectAll='true' @changeSelect='changeChip' />
                    </div>
                    <div id="filtrosStatus" class="col-lg-4 row align-items-center nopadding">
                        <bfilter :onlyOneSelected='false' :arrayButtons='optStatus'
									@buttonsFiltersChange='mudaFiltroStatus' />
                    </div>
                </div>
                <div class="col-12 mt-2"></div>
            </div>
        </slideUandD>
        <div class="col-sm-12 col-md-12">
            <hr>
        </div>
        <tableRelatorio>
            <slot slot="thead">
                <tr class="table">
                    <!-- NAO SAO ESPACOS EM BRANCO NA FRENTE DOS NOMES -->
                    <!-- SAO CARACTERES INVISIVEIS PARA DEIXAR ALINHADO -->
                    <th colspan="1" style="text-align: center !important;;">OS</th>
                    <th colspan="1" style="text-align: center !important;">Placa</th>
                    <th colspan="1" style="text-align: center !important;">Empresa</th>
                    <th colspan="1" style="text-align: center !important;">Tipo</th>
                    <th colspan="1" style="text-align: center !important;">De</th>
                    <th colspan="1" style="text-align: center !important;">Para</th>
                    <th colspan="1" style="text-align: center !important;">Motivo</th>
                    <th colspan="1" style="text-align: center !important;">Justificativa</th>
                    <th colspan="1" style="text-align: center !important;">Gerado</th>
                    <th colspan="1" style="text-align: center !important;">Agendado</th>
                    <th colspan="1" style="text-align: center !important;">Confirmado</th>
                    <th colspan="1" style="text-align: center !important;">Usuário</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <br>
                <template v-if="$v.dadosRelatorio.$invalid">
                    <statusInformation typeBar="tr" :statusBar="statusBar"></statusInformation>
                </template>
                <template v-else>
                    <tr v-for="(dadosTabela, d) in dadosRelatorio" :key="d" :id="'corTr-' + dadosTabela.osstatus"
                        class="paddingTd">
                        <td style="text-align: center !important;">
                            {{ dadosTabela.oscodigo }}
                        </td>
                        <td style="text-align: center !important;">
                            {{ dadosTabela.osplaca }}
                        </td>
                        <td style="text-align: center !important;">
                            {{ dadosTabela.clfantasia }}
                        </td>
                        <td style="text-align: center !important;">
                            {{ dadosTabela.ostdescricao }}
                        </td>
                        <td style="text-align: center !important;">
                            {{ dadosTabela.osatual }}
                        </td>
                        <td style="text-align: center !important;">
                            {{ dadosTabela.osnovo }}
                        </td>
                        <td style="text-align: center !important;">
                            {{ dadosTabela.osmotivo }}
                        </td>
                        <td style="text-align: center !important;">
                            {{ dadosTabela.osstatus == 'C' ? dadosTabela.osjustificativacancelamento : '' }}
                        </td>
                        <td style="text-align: center !important;">
                            {{ formatDataGerado(dadosTabela.osdataevento) }}
                        </td>
                        <td style="text-align: center !important;">
                            {{ formatData(dadosTabela.osdataagendado) }}
                        </td>
                        <td style="text-align: center !important;">
                            {{ formatData(dadosTabela.osdataconfirmacao) }}
                        </td>
                        <td style="text-align: center !important;">
                            {{ dadosTabela.name }}
                        </td>
                    </tr>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>
<script>
import Vue from 'vue'
import { DateTime } from 'luxon';
import { mdiNoteText, mdiFileDownloadOutline } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
export default Vue.extend({
    name: 'ordensDeServico',
    components: {
        'slideUandD': require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'basicButtonsRelatoriosEIG': require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'inputRangeWithLimit': require('@/components/Atom/Datas/inputRangeWithLimitRadio').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
        'tableRelatorio': require('@/components/Atom/Table/TableRelatorio').default,
        'bfilter': require('@/components/Atom/Buttons/ButtonsFilters').default,
    },

    validations: {
        dadosRelatorio: { required },
        dadosEmpresa: { required },
    },

    data() {
        return {
            mdiFileDownloadOutline: mdiFileDownloadOutline,
            loadingButton: [false, false, false],
            mdiNoteText: mdiNoteText,
            statusBar: 'info',
            dadosRelatorio: '',
            dadosData: '',
            exporta: '',
            dadosEmpresa: [],
            dadosOrdem: [],
            dadosVeiculo: [],
            optVeiculos: [],
            optOrdem: [],
            optEmpresas: new EmpresasService().Get(),
            labelsEmp: [{
                indexDFH: 'EM',
                description: 'Empresas*'
            }],
            labelsOrd: [{
                indexDFH: 'ORD',
                description: 'Ordem de serviço'
            }],
            labelsVei: [{
                indexDFH: 'VEI',
                description: 'Veículos'
            }],
            gerandoRel: false,
            idSelecionadosimprimirOS: [],

            carregandoSelect: false,
            optModulos: [],
            optChips: [],
            dadosModulo: [],
            dadosChip: [],

            labelsChip: [{
                indexDFH: 'CHIP',
                description: 'Chips'
            }],
            labelsModulo: [{
                indexDFH: 'MOD',
                description: 'Módulos'
            }],

            optStatus: [
				{ text: 'Agendadadas', value: 'A', width: '30%', extraClass: 'agendadoSelected', isSelected: false},
				{ text: 'Canceladas', value: 'C', width: '30%', extraClass: 'cancelado', isSelected: false },
				{ text: 'Concluídas', value: 'F', width: '30%', extraClass: 'confirmado', isSelected: false },
			],
			dadosStatus: [],
            disabledStatusAgendado: false,

            disabledSelectModulo: false,
            disabledSelectChip: false,

            optRadioGroup: [
                {indexDFH: 'A', label: 'Data Agendada'},
                {indexDFH: 'C', label: 'Data Confirmada'}
            ],
            dadosDataRadio: 'A',
        }
    },
    methods: {

        /**
		 * Função que alterna as classes dos filtors de status quando eles são marcados ou desmarcados
		 * Também altera o vlaor do filtro de status
		 * @param value 
		 * @author Marco Duz
		 */
		mudaFiltroStatus(value) {
			if (typeof value == 'undefined') {
                if(this.dadosDataRadio == 'C') {
                   this.optStatus[0].extraClass = 'disabled'
                   this.optStatus[0].isSelected = false
                } else {
                    this.optStatus[0].extraClass = 'agendado'
                }
				this.optStatus[1].extraClass = 'cancelado'
				this.optStatus[2].extraClass = 'confirmado'
                this.dadosStatus = []
				return
			}
            
            this.dadosStatus = value
			
            if(this.dadosDataRadio == 'C') {
                this.optStatus[0].extraClass = '= disabled'
                this.optStatus[0].isSelected = false
                const index = this.dadosStatus.indexOf('A');
                if (index !== -1) {
                    this.dadosStatus.splice(index, 1);
                }
            } else {
                if (this.optStatus[0].isSelected) {
                    this.optStatus[0].extraClass = 'agendadoSelected'
                }
                else {
                    this.optStatus[0].extraClass = 'agendado'
                }

            }

			if (this.optStatus[1].isSelected) {
				this.optStatus[1].extraClass = 'canceladoSelected'
			} else {
				this.optStatus[1].extraClass = 'cancelado'
			}
			if (this.optStatus[2].isSelected) {
				this.optStatus[2].extraClass = 'confirmadoSelected'
			} else {
				this.optStatus[2].extraClass = 'confirmado'
			}
		},

        /**
         * Função confere qual o botão do exportar foi acionado e chama o método necessário
         * @param {String} value
         * @author Marco Duz
         */
        confereExportar(value) {
            this.exporta = value
            switch (value) {
                case 'pdf':
                    this.loadingButton = [true, false, false, false]
                    this.exportarRelatorio()
                    break;
                case 'xls':
                    this.loadingButton = [false, true, false, false]
                    this.exportarRelatorio()
                    break;
                case 'personalizado':
                    this.loadingButton = [false, false, false, true]
                    this.imprimirOS()
                    break;
                default:
                    this.loadingButton = [false, false, true, false]
                    this.exportarRelatorio()
                    break;
            }
        },

        /**
         * Função que exporta o relatório de acordo com o tipo selecionado
         * para a impressão de OS outra função é utilizada
         * @author Desconhecido
         */
        async exportarRelatorio() {
            var root = process.env.VUE_APP_ROOT;
            var obj = {
                tipo: this.exporta,
                tabela: this.dadosRelatorio,
                data: this.dadosData,
                empresa: this.dadosEmpresa,
                ordem: this.dadosOrdem,
                veiculo: this.dadosVeiculo,
            }
            var html = '';
            html = await new HttpRequest()
                .Post('/administrativo/relatorios/pdf', { 'dados': obj })
            window.open(root + '/' + html.data.dados);
            this.loadingButton = [false, false, false, false]
        },

        /**
         * Função para gerar o pdf com as OS para impressão
         * Utiliza-se da mesma função da tela do agendamento
         * @author Marco Duz
         */
        async imprimirOS() {
            this.dadosRelatorio.forEach(e => {
                this.idSelecionadosimprimirOS.push(e.oscodigo)
            });

            this.gerandoRel = true
            var root = process.env.VUE_APP_ROOT;
            var dados = await new HttpRequest()
                .Post('/administrativo/cadastros/agendamento/imprimeOS',
                    { 'os': this.idSelecionadosimprimirOS },
                )
            if (dados.status) {
                window.open(root + '/' + dados.data)
            }
            this.gerandoRel = false
            this.idSelecionadosimprimirOS = []
            this.loadingButton = [false, false, false, false]
        },

        async gerarRelatorio() {
            this.gerandoRel = true

            let dadosChipFormat = []
            if(this.dadosChip.length > 0) {
                this.dadosChip.forEach(id => {
                    this.optChips.forEach(e => {
                        if (e.value == id) {
                            dadosChipFormat.push(e.description)
                        }
                    });
                })
            }

            var obj = {
                data: this.dadosData,
                empresa: this.dadosEmpresa,
                ordem: this.dadosOrdem,
                veiculo: this.dadosVeiculo,
                modulo: this.dadosModulo,
                chip: dadosChipFormat,
                status: this.dadosStatus,
                tipoData: this.dadosDataRadio,
            }
            var dados = '';
            dados = await new HttpRequest()
                .Post('/administrativo/relatorios/tabela', { 'dados': obj })
            if (dados.data.length == 0) {
                this.statusBar = 'error'
            }
            else if (dados.data != ' ') {
                this.dadosRelatorio = dados.data
            }
            this.gerandoRel = false
        },

        /**
         * Função altera o valor dos modulos selecionados
         * e bloqueia o select dos chips, pois não pode filtrar a consulta com os dois campos simultaneamente
         * @param value 
         * @author Marco Duz
         */
        changeModulo(value) {
            if(value.length){
                this.disabledSelectChip = true
                this.dadosChip = []
            }else{
                this.disabledSelectChip = false
            }
            this.dadosModulo = value
            this.dadosRelatorio = []
        },
         /**
         * Função altera o valor dos chips selecionados
         * e bloqueia o select dos modulos, pois não pode filtrar a consulta com os dois campos simultaneamente
         * @param value 
         * @author Marco Duz
         */
        changeChip(value) {
            if(value.length){
                this.disabledSelectModulo = true
                this.dadosModulo = []
            }else{
                this.disabledSelectModulo = false
            }
            this.dadosChip = value
            this.dadosRelatorio = []
        },
        changeVeiculo(value) {
            this.dadosVeiculo = value
            this.dadosRelatorio = []
        },
        /**
         * Função que altera o valor do radio do input da data selecionado
         * @param value 
         * @author Marco Duz
         */
        changeDataRadio(value){
            this.dadosDataRadio = value
            if(value == 'C'){
                this.disabledStatusAgendado = true
            }
            this.dadosRelatorio = []
            this.mudaFiltroStatus(this.dadosStatus)
        },
        changeOrdem(value) {
            this.dadosOrdem = value
            this.dadosRelatorio = []
        },
        changeData(value) {
            this.dadosData = value
            this.dadosRelatorio = []
        },
        changeEmpresa(value) {
            this.dadosEmpresa = value
            this.dadosRelatorio = []
            this.optVeiculos = []
            this.optOrdem = []
            this.optModulos = []
            this.optChips = []
            if(value.length > 0){
                this.getInfoSelect(value)
            }
        },

        async getInfoSelect(value) {
            var dados = [];
            this.carregandoSelect = true
            dados = await new HttpRequest()
                .Post('/administrativo/relatorios/options/selects', { 'valor': value })
                .then(dados => {
                    this.optVeiculos = dados.data.veiculo
                    this.optOrdem = dados.data.ordem
                    this.optModulos = dados.data.modulo
                    this.optChips = dados.data.chip
                }).finally(() => {
                    this.carregandoSelect = false
                })
        },

        formatDataGerado(data) {
            var dataPadrao = DateTime.fromFormat(data, 'yyyy-MM-dd HH:mm:ss').toFormat('dd/MM/yyyy')
            return dataPadrao
        },

        formatData(data) {
            if (data) {
                var dataPadrao = DateTime.fromFormat(data, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')
                return dataPadrao
            } else {
                return ''
            }
        },
    },
})
</script>
<style lang="scss">
.table {
    // margin-top: -20px !important;
    padding: 5px !important;
    font-size: 14px !important;
    text-align: left !important;
    border-bottom: 0 !important;
    background-color: #F5F5F5 !important;
    border-top: 1px solid #dddddd !important;

    th {
        text-align: left !important;
        padding: 5px !important;
    }
}

#OrdemDeServico {
    .fundo {
        background-color: #f2f2f2;
        display: flex;
        width: 100%;
        justify-content: space-around;

        div {
            margin: 10px;
        }

        .dot {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            display: inline-block;
        }

        .laranja {
            background-color: #f1b108;
        }

        .azul {
            background-color: #428bca;
        }

        .verde {
            background-color: #41b325;
        }

        .vermelho {
            background-color: #d87676;
        }
    }

    #corTr-P {
        background: #f0e2b1;
    }

    #corTr-A {
        background: #bad0ec;
    }

    #corTr-F {
        background: #c4ecba;
    }

    #corTr-C {
        background: #ecbaba;
    }

    .paddingTd {
        td {
            padding: 5px !important;
        }
    }
}

#filtrosStatus {
	.agendadoSelected {
		background-color: #499fdb;
		color: #fff;
		font-weight: bold !important;
	}

	.confirmadoSelected {
		background-color: #1caf34;
		color: #fff;
		font-weight: bold !important;
	}

	.canceladoSelected {
		background-color: #e2554a;
		color: #fff;
		font-weight: bold !important;
	}

	.agendado {
		border: 1px solid #499fdb;
	}

	.confirmado {
		border: 1px solid #1caf34;
	}

	.cancelado {
		border: 1px solid #e2554a;
	}
    .disabled{
        background-color: #a6a6a6;
        pointer-events: none;
        cursor: default;
        color: #fff;
    }
}
</style>