<template id="buttonImportar">
    <b-dropdown id="buttonImportar" size="sm" :class="disabledDropdown ? 'disabled' : 'btn-primary'"
        :disabled="disabledDropdown">
        <template id="buttonImportar" v-slot:button-content>
            <baseIcon :icon='iconImportar' size='18' />
            <p>Importar</p>
        </template>
        <b-dropdown-item id="buttonImportar" :disabled="loading.find(v => v == true)" class="importrastro "
            @click="ExportClick('R')">
            <b-spinner v-if='loading[0]' small />
            Rastro
        </b-dropdown-item>
        <b-dropdown-item id="buttonImportar" :disabled="loading.find(v => v == true)" class="importArquivo"
            @click="ExportClick('A')">
            <b-spinner v-if='loading[1]' small />
            Arquivo
        </b-dropdown-item>
    </b-dropdown>
</template>
<script lang="ts">
import { mdiPlus, } from '@mdi/js'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Vuex from '../../../store/index'
export default Vue.extend({
    components: {
        simpleButton: require('./SimpleButton').default,
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
    },
    props: {
        linkNew: {
            type: String,
            default: '',
        },
        userMasterMaster: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Array,
            default: function () { return Array(false, false, false) }
        },
        tipoNovo: {
            type: String,
            default: 'a'
        },
        disabledDropdown: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            iconImportar: mdiPlus,
        }
    },
    methods: {
        ...mapGetters(['getAdmCheckpoint']),
        ExportClick(type: string) {
            this.$emit('importar', type)
        }
    },
    computed: {
        cadastrarVal: function () {
            return Vuex.getters.getCadastrar;
        },
    }
})
</script>


<style lang="scss">
div#buttonImportar {
    margin-top: 2px !important;
    padding: 2.4px !important;
    margin-right: 3px;
    background-color: #1caf9a;
}

ul.dropdown-menu {
    margin-left: -3px;
}

#buttonImportar {

    .btn-group {
        position: inherit;
        background-color: #1caf9a !important;

        .btn-primary {
            font-size: 12px !important;
            // line-height: 15px !important;
            font-weight: 400 !important;
            // padding: 4px 3px !important;
            align-items: center;
            text-align: center !important;
            white-space: nowrap !important;
            border-radius: 8px !important;
            border: 1px solid #fff !important;
            vertical-align: middle !important;
            display: flex !important;
            margin-bottom: 0 !important;
            touch-action: manipulation !important;
            user-select: none !important;
            background-image: none !important;
            height: 30.5px !important;
            top: 1px;
            width: 100px !important;
            background-color: #1caf9a !important;
            margin-right: 2px !important;

            span {
                &::before {
                    font-size: 12px !important;
                    margin-left: 0px;
                }
            }
        }

        .dropdown-menu {
            padding: 0;
            border-radius: 0px;
            width: 100px !important;
            min-width: 100px;


            .dropdown-item {
                color: #22262e !important;
                font-size: 12px !important;
                border: 1px solid #d5d5d5;
                font-weight: normal !important;
                padding: 2px 10px;
                text-align: center;

                span {
                    &::before {
                        margin-left: 0px;
                    }
                }

                &:active {
                    background-color: #fff;
                }
            }
        }

        border: none !important;
        margin-inline: 2px !important;
    }

    .btn-sm {
        border: none !important;
        border-radius: 0px !important;
        display: flex !important;
        align-items: center !important;

        p {
            font-size: 12px;
            margin: 0 !important;
            line-height: normal !important;
            height: 12px;
        }
    }

    .btn-secondary {
        background-color: #1caf9a !important;

    }

    .hoverDropdown {
        .dropdown:hover {
            .dropdown-menu {
                display: block;
            }
        }
    }

    .disabled {
        pointer-events: none;
        background-color: #47b5a500 !important;
    }
}

div#buttonImportar.disabled {
    background-color: #1caf998e !important;
}
</style>
