/**
  Componente para exibir pop up dentro do mapa. dentro de uma região,
  Props:
      regiao => Objeto contendo descricão, velocidade(opcional), cor e codigo

  Eventos: 
      clickEditar : Emitido pelo botão 'Editar', propaga o código da região
      clickExcluir: Emitido pelo botão 'Excluir', propaga o código da região
  Slot
      Sem nome: conteudo do Objecto região exposto para o slot,
      botoes  : Caso seja necessário substituir os botões, regiões expostas
 */
<template>
  <LPopup v-on='$listeners' v-bind="$attrs">
  <div>
    <slot name='conteudo'>
    <div class="col-sm-12 row">
      <div class="col-sm-12 nopadding descricao">
        <b>Região: </b>{{regiao.descricao}}
      </div>
      <div class="col-sm-12 nopadding velocidade">
        <b>Categoria(s): </b>{{ mostrarCategorias(regiao) }}
      </div>
      <div class="col-sm-12 nopadding velocidade">
        <b>Velocidade: </b>{{mostrarVelocidade(regiao)}}
      </div>
      <div class="col-sm-12 row nopadding linha">
        <b>Cor: </b>
		<div class="square linha" :style="'background-color:'+regiao.color"/>
          <baseIcon 
            size='18' 
            style="color:red" 
            class="iconTitulo" 
            :icon="mdiAlert" 
            title='Área de risco' 
            v-if='regiao.risco=="S"'/>
      </div>
      <div class="col-sm-12 nopadding">
        <slot regiao:regiao></slot>
      </div>
      <slot name='botoes' regiao:regiao>
        <div class="col-sm-12 nopadding row botoes">
          <div class="col-sm-6 nopadding linha">
            <slot name='editar'>
              <simple-button
                v-if='getEditar()'
                id='popupEditar'
                text='Editar'
                event='click' 
                width='80px'
                type='blue'
                :icon='mdiPencil'
                @click='clickEditar(regiao.codigo)'
              />
            </slot>
          </div>
          <div class="col-sm-6 nopadding linha">
            <slot name='excluir'>
              <simple-button
                v-if='getExcluir()'
                id='popupExcluir'
                width='80px'
                type='red'
                text='Excluir'
                event='click' 
                :icon='mdiTrashCanOutline'
                @click='clickExcluir(regiao.codigo)'
              />
            </slot>
          </div>
        </div>
      </slot>
    </div>
    </slot>
  </div>
  </LPopup>
</template>

<script>
import Vue from 'vue'
import {mdiPencil, mdiTrashCanOutline, mdiAlert} from '@mdi/js'
import {LPopup} from 'vue2-leaflet';
import {mapGetters} from 'vuex'
export default Vue.extend({
	components: {
		baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
		LPopup,
		SimpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
	},
	props: {
		regiao: {
			type: Object,
			require: true
		}
	},
	data() {
		return {
			mdiAlert:mdiAlert,
			mdiTrashCanOutline:mdiTrashCanOutline,
			mdiPencil:mdiPencil,
		}
	},
	inheritAttrs: false,
	methods: {
		clickEditar(codigo) {
			this.$emit('clickEditar', codigo)
		},
		clickExcluir(codigo) {
			this.$emit('clickExcluir', codigo)
		},

		...mapGetters(['getEditar', 'getExcluir']),

    /**
     * Função para formatar o array de objetos das categorias
     * @param regiao -> objeto da regiao que contem regioes_categorias com um array de cada categoria
     * @author Marco Duz
     */
    mostrarCategorias(regiao){
      const categorias = regiao.regioes_categorias.map(categoria => categoria.lcdescricao);
      const categoriasFormatadas = categorias.join(", ");
      return categoriasFormatadas
  },

  /**
   * Função para formatar a velocidade
   * aso a informação da velocidade não exista (null) Mostra apenas o campo vazio
   * @param regiao -> objeto da regiao
   * @author Marco Duz
   */
  mostrarVelocidade(regiao){
    let velocidade;
    if(regiao.velocidade!=null){
      velocidade = Number(regiao.velocidade).toFixed(0)
      velocidade = velocidade + ' Km/h'
      return velocidade
    }
    else{
      return ''
    }
  }
	},

  
})
</script>

<style lang="scss" scoped>
.square { 
    width: 10px; 
    height: 10px; 
  }
.botoes {
  margin-top: 3%;
  display: inline-block;
}
.linha {
  display: inline-block;
}
</style>