<template>
    <div class='d-flex justify-content-around' id='FSeletores'>
        <div id='seletorCategoria' class='d-flex align-items-center'>
            <label for="categoriaMultiselect" class="seletorLabel">Categoria:</label>
            <multiselect id="categoriaMultiselect" class='seletorFiltro' 
                :options='categoriasFiltradas' 
                track-by='value' 
                label='description'
                placeholder='Selecione uma categoria' 
                :showLabels='false' 
                :allowEmpty='true' 
                :multiple="true"
                :close-on-select="true" 
                v-model='categoriaSelected' 
                @input="onCategoriaChange"
                :searchable="true" 
                :preserve-search="true" 
                :reset-after="false" 
                selectLabel="•" 
                selectedLabel="✔"
                deselectLabel="x" 
                :limit="1" 
                :limitText="(c) => ` e mais ${c}`">
                <template slot="noResult">
                    Nenhuma categoria encontrada
                </template>
                <template slot="noOptions">
                    Nenhuma categoria encontrada
                </template>
            </multiselect>
        </div>
        <div id='seletorRegiao' class='d-flex align-items-center'>
            <label for="regiaoMultiselect" class="seletorLabel">Região:</label>
            <multiselect id="regiaoMultiselect" class='seletorFiltro' 
                :options='regioesFiltradas' 
                track-by='value' 
                label='description'
                placeholder='Selecione uma região' 
                :preselectFirst='false' 
                :showLabels='false' 
                :allowEmpty='true'
                :multiple="true"
                :close-on-select="true"
                v-model='regiaoSelected' 
                @input="onRegiaoChange"
                :searchable="true" 
                :preserve-search="true" 
                :reset-after="false" 
                selectLabel="•" 
                selectedLabel="✔"
                deselectLabel="x"
                :limit="1"
                :limitText="(c) => ` e mais ${c}`">
                <template #noResult>
                    Nenhuma região encontrada
                </template>
                <template #noOptions>
                    Nenhuma região encontrada
                </template>
            </multiselect>
        </div>
    </div>
</template>

<script>
import simpleButton from '@/components/Atom/Buttons/SimpleButton.vue'
import Multiselect from 'vue-multiselect'
import { mdiCloseThick } from '@mdi/js'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';

export default {
    name: "PopupRegiaoCategoria",
    components: {
        simpleButton,
        Multiselect,
    },
    props: {
        empresaSelecionada: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            iconClose: mdiCloseThick,
            categoriaSelected: null,
            regiaoSelected: null,
            arrayCategorias: [],
            arrayRegioes: [],
            baseUri: '/finder/mapa/',
            mapeamentoRegiaoCategoria: {},
            mapeamentoCategoriaRegiao: {},
            todasCategorias: [],
            todasRegioes: []
        }
    },
    computed: {
        /**
         * Filtra as categorias com base nas regiões selecionadas
         * @returns {Array} Lista de categorias filtradas
         * @author Otávio 🦆 
         */
        categoriasFiltradas() {
            if (!this.regiaoSelected ||
                (Array.isArray(this.regiaoSelected) && this.regiaoSelected.length === 0)) {
                return this.arrayCategorias;
            }

            let categoriasSelecionadas = [];
            if (this.categoriaSelected) {
                if (Array.isArray(this.categoriaSelected)) {
                    categoriasSelecionadas = this.categoriaSelected.map(cat => cat.value);
                } else {
                    categoriasSelecionadas = [this.categoriaSelected.value];
                }
            }

            let regioesSelecionadas = [];
            if (Array.isArray(this.regiaoSelected)) {
                regioesSelecionadas = this.regiaoSelected.map(reg => reg.value);
            } else {
                regioesSelecionadas = [this.regiaoSelected.value];
            }

            const categoriasRelacionadas = new Set();
            let algumRelacionamentoEncontrado = false;

            for (const regiaoId of regioesSelecionadas) {
                const categoriasDaRegiao = this.mapeamentoRegiaoCategoria[regiaoId] || [];
                categoriasDaRegiao.forEach(catId => {
                    categoriasRelacionadas.add(catId);
                    algumRelacionamentoEncontrado = true;
                });

                const regiao = this.arrayRegioes.find(reg => reg.value === regiaoId);
                if (regiao && regiao.rccodigocategoria) {
                    categoriasRelacionadas.add(regiao.rccodigocategoria);
                    algumRelacionamentoEncontrado = true;
                }
            }

            if (categoriasRelacionadas.size === 0 || !algumRelacionamentoEncontrado) {
                return this.arrayCategorias;
            }

            return this.arrayCategorias.filter(categoria =>
                categoriasRelacionadas.has(parseInt(categoria.value)) ||
                categoriasRelacionadas.has(categoria.value) ||
                categoriasSelecionadas.includes(categoria.value) ||
                categoriasSelecionadas.includes(parseInt(categoria.value))
            );
        },
        
        /**
         * Filtra as regiões com base nas categorias selecionadas
         * @returns {Array} Lista de regiões filtradas
         * @author Otávio 🦆 
         */
        regioesFiltradas() {
            if (!this.categoriaSelected || 
                (Array.isArray(this.categoriaSelected) && this.categoriaSelected.length === 0)) {
                return this.arrayRegioes;
            }
            
            let categoriasIds = [];
            if (Array.isArray(this.categoriaSelected)) {
                categoriasIds = this.categoriaSelected.map(cat => cat.value);
            } else {
                categoriasIds = [this.categoriaSelected.value];
            }
            
            return this.arrayRegioes.filter(regiao => {
                if (regiao.rccodigocategoria && categoriasIds.includes(regiao.rccodigocategoria)) {
                    return true;
                }
                
                for (const catId of categoriasIds) {
                    const regioes = this.mapeamentoCategoriaRegiao[catId] || [];
                    if (regioes.includes(regiao.value) || regioes.includes(parseInt(regiao.value))) {
                        return true;
                    }
                }
                
                return false;
            });
        }
    },
    mounted() {
        this.carregarDadosIniciais();
    },
    methods: {
        /**
         * Carrega todos os dados iniciais necessários
         * @author Otávio 🦆 
         */
        async carregarDadosIniciais() {
            await this.carregarRegioes();
            await this.carregarCategorias();
            this.processarRelacionamentos();
        },
        
        /**
        * Carrega as categorias disponíveis para a empresa selecionada.
        * Obtém os dados via requisição HTTP e atualiza a lista de categorias.
        * Também extrai categorias das regiões para completar a lista.
        * @author Otávio 🦆 
        */
        async carregarCategorias() {
            try {
                if (!this.empresaSelecionada || !Array.isArray(this.empresaSelecionada) || this.empresaSelecionada.length === 0) {
                    this.arrayCategorias = [];
                    this.todasCategorias = [];
                    this.categoriaSelected = null;
                    return;
                }

                const clientes = this.empresaSelecionada.map(empresa => empresa.value);

                const payload = {
                    clientes: clientes
                }

                const httpRequest = new HttpRequest()
                const response = await httpRequest.Post(`${this.baseUri}buscar/categorias`, payload)

                const encontrarCategorias = (obj) => {
                    if (obj && obj.categorias && Array.isArray(obj.categorias)) {
                        return obj.categorias;
                    }

                    if (obj && obj.data && obj.data.categorias && Array.isArray(obj.data.categorias)) {
                        return obj.data.categorias;
                    }

                    if (obj && typeof obj === 'object') {
                        for (const key in obj) {
                            if (typeof obj[key] === 'object' && obj[key] !== null) {
                                const categorias = encontrarCategorias(obj[key]);
                                if (categorias) return categorias;
                            }
                        }
                    }

                    return null;
                };

                const categorias = encontrarCategorias(response);
                const categoriasMap = new Map();

                if (categorias && Array.isArray(categorias)) {
                    categorias.forEach(categoria => {
                        categoriasMap.set(categoria.lccodigo, {
                            value: categoria.lccodigo,
                            description: categoria.lcdescricao
                        });
                    });
                }
                
                if (this.arrayRegioes && Array.isArray(this.arrayRegioes)) {
                    this.arrayRegioes.forEach(regiao => {
                        if (regiao.rccodigocategoria && regiao.lcdescricao) {
                            categoriasMap.set(regiao.rccodigocategoria, {
                                value: regiao.rccodigocategoria,
                                description: regiao.lcdescricao
                            });
                        }
                    });
                }
                
                const categoriasFormatadas = Array.from(categoriasMap.values());
                
                this.arrayCategorias = categoriasFormatadas;
                this.todasCategorias = [...categoriasFormatadas];
                this.categoriaSelected = null;
                
            } catch (error) {
                this.arrayCategorias = [];
                this.todasCategorias = [];
                this.categoriaSelected = null;
                conectionError();
            }
        },
        
        /**
        * Carrega as regiões disponíveis para a empresa selecionada.
        * Obtém os dados via requisição HTTP e atualiza a lista de regiões.
        * Preserva os dados de categoria associados a cada região.
        * @author Otávio 🦆 
        */
        async carregarRegioes() {
            try {
                if (!this.empresaSelecionada || !Array.isArray(this.empresaSelecionada) || this.empresaSelecionada.length === 0) {
                    this.arrayRegioes = [];
                    this.todasRegioes = [];
                    this.regiaoSelected = null;
                    return;
                }

                const clientes = this.empresaSelecionada.map(empresa => empresa.value)

                const payload = {
                    clientes: clientes
                }

                const httpRequest = new HttpRequest()
                const response = await httpRequest.Post(`${this.baseUri}carregar/regioes`, payload)

                if (response.code === 200 || response.status) {
                    const regioesFormatadas = response.data.regioes.map(regiao => ({
                        value: regiao.recodigo,
                        description: regiao.redescricao,
                        color: regiao.recor,
                        rccodigocategoria: regiao.rccodigocategoria,
                        lcdescricao: regiao.lcdescricao
                    }));

                    this.arrayRegioes = regioesFormatadas;
                    this.todasRegioes = [...regioesFormatadas];
                    this.regiaoSelected = null;
                }
            } catch (error) {
                this.arrayRegioes = [];
                this.todasRegioes = [];
                this.regiaoSelected = null;
                conectionError();
            }
        },
        
        /**
         * Processa os dados de regiões e categorias para criar os mapeamentos de relacionamento
         * Esta função utiliza os dados que já vieram do endpoint de regiões, sem necessidade
         * de fazer uma requisição adicional
         * @author Otávio 🦆 
         */
        processarRelacionamentos() {
            try {
                this.mapeamentoRegiaoCategoria = {};
                this.mapeamentoCategoriaRegiao = {};

                this.arrayRegioes.forEach(regiao => {
                    if (!regiao.rccodigocategoria) return;

                    const regiaoId = parseInt(regiao.value);
                    const categoriaId = parseInt(regiao.rccodigocategoria);

                    if (!this.mapeamentoRegiaoCategoria[regiaoId]) {
                        this.mapeamentoRegiaoCategoria[regiaoId] = [];
                    }
                    if (!this.mapeamentoRegiaoCategoria[regiaoId].includes(categoriaId)) {
                        this.mapeamentoRegiaoCategoria[regiaoId].push(categoriaId);
                    }

                    if (!this.mapeamentoCategoriaRegiao[categoriaId]) {
                        this.mapeamentoCategoriaRegiao[categoriaId] = [];
                    }
                    if (!this.mapeamentoCategoriaRegiao[categoriaId].includes(regiaoId)) {
                        this.mapeamentoCategoriaRegiao[categoriaId].push(regiaoId);
                    }
                });
            } catch (error) {
                this.mapeamentoRegiaoCategoria = {};
                this.mapeamentoCategoriaRegiao = {};
            }
        },
        
        /**
         * Manipula a mudança na seleção de categoria
         * @author Otávio 🦆 
         */
        onCategoriaChange() {
            if (!this.categoriaSelected ||
                (Array.isArray(this.categoriaSelected) && this.categoriaSelected.length === 0)) {
                this.categoriaSelected = null;
            }

            if (this.regiaoSelected && this.categoriaSelected) {
                const categoriasIds = Array.isArray(this.categoriaSelected)
                    ? this.categoriaSelected.map(cat => cat.value)
                    : [this.categoriaSelected.value];
                
                if (Array.isArray(this.regiaoSelected)) {
                    this.regiaoSelected = this.regiaoSelected.filter(regiao => {
                        for (const catId of categoriasIds) {
                            const regioesDaCategoria = this.mapeamentoCategoriaRegiao[catId] || [];
                            if (regioesDaCategoria.includes(regiao.value)) {
                                return true;
                            }
                        }
                        return false;
                    });
                    
                    if (this.regiaoSelected.length === 0) {
                        this.regiaoSelected = null;
                    }
                } else {
                    const regiaoCompativel = categoriasIds.some(catId => {
                        const regioesDaCategoria = this.mapeamentoCategoriaRegiao[catId] || [];
                        return regioesDaCategoria.includes(this.regiaoSelected.value);
                    });

                    if (!regiaoCompativel) {
                        this.regiaoSelected = null;
                    }
                }
            }

            this.aplicarFiltrosAutomaticamente();
        },
        
        /**
         * Manipula a mudança na seleção de região
         * @author Otávio 🦆 
         */
         onRegiaoChange() {
            if (!this.regiaoSelected ||
                (Array.isArray(this.regiaoSelected) && this.regiaoSelected.length === 0)) {
                this.regiaoSelected = null;
            }
            this.aplicarFiltrosAutomaticamente();
        },
        
        /**
        * Emite um evento para aplicar automaticamente os filtros selecionados.
        * Envia os valores selecionados de categoria e região para o componente pai.
        * @author Otávio 🦆 
        */
        aplicarFiltrosAutomaticamente() {
            let regioesSelecionadas = null;
            
            if (this.regiaoSelected) {
                if (Array.isArray(this.regiaoSelected)) {
                    regioesSelecionadas = this.regiaoSelected.map(reg => reg.value);
                } else {
                    regioesSelecionadas = this.regiaoSelected.value;
                }
            }
            
            this.$emit('filtros-aplicados', {
                categoria: this.categoriaSelected ?
                    (Array.isArray(this.categoriaSelected) ?
                        this.categoriaSelected.map(cat => cat.value) :
                        this.categoriaSelected.value) :
                    null,
                regiao: regioesSelecionadas
            });
        },
        
    },

    watch: {
        /**
        * Observa mudanças na empresa selecionada para buscar filtros
        * @author Otávio 🦆 
        */
        empresaSelecionada() {
            this.carregarDadosIniciais();
        }
    }
}
</script>

<style lang="scss" scoped>
#FSeletores {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-radius: 5px;
    width: auto;
    max-width: 800px;

    .seletorLabel {
        font-weight: 500;
        margin-right: 8px;
        white-space: nowrap;
        font-weight: bold;
    }

    .seletorFiltro {
        width: 250px;
        opacity: 1;
        margin: 0 10px;
    }

    #botoes {
        display: flex;
        gap: 12px;
        margin-left: 10px;
    }

    #seletorCategoria, #seletorRegiao {
        margin: 0 5px;
    }
}
</style>