import { AuthService } from '@/Services/auth/Auth.Service'
import { RouteConfig } from 'vue-router'
import Cadastros from '@/views/Children.vue'
import Relatorio from '@/views/Children.vue'
import NaoMenu from '@/views/Children.vue';
import Layout from '@/components/Atom/Layout/Layout.vue'

import DetalhesTelemetria from '@/views/Performance/Relatorios/DetalhesTelemetria.vue'
import MotoristaEconomico from '@/views/Performance/Relatorios/MotoristaEconomico.vue'
import MediaConsumo from '@/views/Performance/Relatorios/MediaConsumo.vue'
import Alertas from '@/views/Performance/Relatorios/Alertas.vue'

export const ifAuthenticated = (_to: any, _from: any, next: any) => {
	if (new AuthService().isAuthenticated()) {
		next()
		return
	}
	next('/login')
}

export const performanceRouter: RouteConfig = {
	path: '/performance',
	name: 'performance',
	beforeEnter: ifAuthenticated,
	meta: {
		requiresAuth: true,
		Module: 'performance',
		breadcrumbName: 'Performance'
	},
	component: Layout,
	children: [
		{
			path: 'cadastros',
			meta:
			{
				Module: 'performance',
				breadcrumbName: 'Cadastros'
			},
			components:
			{
				content: Cadastros
			},
			children: [
			]
		},
		{
			path: '',
			meta: {
				Module: 'performance',
				breadcrumbName: ''
			},
			components: {
				content: NaoMenu
			},
			children: [
			]
		},
		{
			path: 'relatorios',
			meta:
			{
				Module: 'performance',
				breadcrumbName: 'Relatórios'
			},
			components:
			{
				content: Relatorio
			},
			children: [
				{
					path: 'alertas',
					name: 'Alertas',
					meta:
					{
						Module: 'performance',
						breadcrumbName: 'Alertas'
					},
					component: Alertas
				},
				{
					path: 'detalhes/telemetria',
					name: 'Detalhes Telemetria',
					meta:
					{
						Module: 'performance',
						breadcrumbName: 'Detalhes Telemetria'
					},
					component: DetalhesTelemetria
				},
				{
					path: 'motorista/economico',
					name: 'Motorista Econômico',
					meta:
					{
						Module: 'performance',
						breadcrumbName: 'Motorista Econômico'
					},
					component: MotoristaEconomico
				},
				{
					path: 'media/consumo',
					name: 'Média de consumo',
					meta:
					{
						Module: 'performance',
						breadcrumbName: 'Média de consumo'
					},
					component: MediaConsumo
				},
			]
		},
	]
}