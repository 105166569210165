/**
Componente para ser utilizado onde necessita mostrar a tag do veículo
Props: 
    ---------OBRIGATORIAS------------
    text: Texto a ser aprsentado dentro da tag, recebe um valor, string. Ex: 'Manutenção'
    color: Cor do fundo da tag, recebe um valor, string. OBS: precisa ser em hexadecimal e conter o #. Ex: '#FFFFFF'

    ---------OPCIONAIS------------
    fontSize: Tamanho da fonte, recebe um valor, number ou string, em px. Ex: 12, '15'.
    height: Altura da tag, rcebe um valor, number ou string, em px. Ex: 10, '20'.
    rounded: Define se a tag será arredondada, recebe um valor, boolean. Ex: true, false.
*/

<template>
    <div id="tag" class="text-center px-3" :style="defineStyle()">
        <p class="m-0 p-0" :style="defineTextColor(color)">{{ text }}</p>
    </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
    props: {
        text: {
            required: true,
            type: String,
            default: ''
        },
        color: {
            required: true,
            type: String,
            default: '#FFFFFF'
        },
        fontSize: {
            required: false,
            type: [String, Number],
            default: 13
        },
        rounded: {
            required: false,
            type: Boolean,
            default: true
        },
        height: {
            required: false,
            type: [String, Number],
            default: 25
        },
    },

    methods: {
        /**
         * Função retorna o estilo a ser utilizado na div da tag
         * Define a cor de fundo, sua altura, tamanho da fonte e se deverá ser arredondada
         * @author Marco Duz
         */
        defineStyle() {

            let estilo = `background-color: ${this.color}; height: ${this.height}px; font-size: ${this.fontSize}px;`
            if (this.rounded) {
                estilo += `border-radius: 12px;`
            }

            return estilo;
        },

        /**
         * Função recebe a cor converte de hexadecimal para RGB
         * Efetua um calculo do brilho da cor (perceptual luminance) --> chatGpt que fez a formula
         * Retorna o estilo da cor do texto da tag, 
         * se o brilho da cor de fundo for maior que 128 (cor clara) retorna preto, se não (cor escura) branco
         * @param hexColor -> Cor de fundo da div
         * @author Marco Duz
         */
        defineTextColor(hexColor) {
            let estilo = `line-height: 1.2;`;
            hexColor = hexColor.replace('#', '');

            let r = parseInt(hexColor.substring(0, 2), 16);
            let g = parseInt(hexColor.substring(2, 4), 16);
            let b = parseInt(hexColor.substring(4, 6), 16);

            let brightness = (r * 299 + g * 587 + b * 114) / 1000;

            if (brightness > 128) {
                estilo += `color: #000000;`
            } else {
                estilo += `color: #FFFFFF;`
            }

            return estilo
        },
    }
})
</script>

<style lang="scss">
#tag {
    display: flex;
    align-items: center;
}
</style>