/**
Este componente é um calendario de meses e anos

-> Propriedades:
1- label - String - Subtitulo do input - EX: Mes
2- inputClass - String - classe extra do input - EX: 'inputMonth'
3- inputDisabled - Boolean - input desabilitado? - EX: true/false
4- name - String - name do input - EX: 'inputMonth'
5- id - String - id do input - EX: 'inputMonth'
6- isObrigatorio - Boolean - verifica se o campo é obrigatorio, se for ele coloca o (*) - EX: true/false

-> Slot:
- Sem slots

-> Eventos:
1- changeInput - Emite quando o mes é alterado - Parametros:
1.1- selectedMonth - data em formato 'MM/YYYY'
*/

<template>
    <div class="col-sm-12">
        <div class="col-sm-12 subtitle nopadding">
            {{ label }}
            <template v-if="isObrigatorio"> *</template>
        </div>
        <vue-monthly-picker :name="name" class="inputClass" :class="{ 'inputDisabled': inputDisabled }"
            :disabled="inputDisabled" dateFormat="MM/YYYY" :monthLabels="monthLabel" v-model="selectedMonth"
            @selected="changeInput">
        </vue-monthly-picker>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import VueMonthlyPicker from 'vue-monthly-picker'
import moment from 'moment';
export default Vue.extend({
    components: {
        VueMonthlyPicker,
    },
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },
        inputClass: {
            type: String,
            required: false,
            default: '',
        },
        inputDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        name: {
            type: String,
            required: true,
            default: ''
        },
        id: {
            type: String,
            required: false,
            default: ''
        },
        isObrigatorio: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            type: moment,
            required: false
        },
    },
    data() {
        return {
            monthLabel: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            selectedMonth: moment(),
        }
    },
    methods: {
        changeInput() {
            this.$emit('changeInput', this.selectedMonth);
        }
    },
    mounted() {
        this.$emit('changeInput', this.selectedMonth);
    },
    watch: {
        value(value) {
            this.selectedMonth = value ?? moment()
        }
    },
})
</script>

<style lang="scss">
.subtitle {
    font-size: 13px;
    text-align: left;
    margin-bottom: 2px;
}

.vue-monthly-picker {

    // padding-top: 2px;
    .month-picker-wrapper {
        min-width: unset !important;

        .input {
            box-shadow: none;
            border-radius: 8px !important;
            height: 39px;
        }
    }
}

.month-year-display {
    span {
        display: none !important;
    }
}

.inputDisabled {
    .month-year-display {
        background-color: #e5e5e5 !important;
    }
}
</style>