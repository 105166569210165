<template>
	<div class='filtro-painel-coletivos py-1' style='background-color: whitesmoke'>
		<div class='container-fluid px-2'>
			<div class='row gx-2'>
				<!-- Empresa -->
				<div class='col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2 px-1'>
					<div class='filtro-item'>
						<div class='filtro-campo flex-grow-1'>
							<Multiselect placeholder='Empresa' :multiple='true' :close-on-select='true'
								:options='optClientes' :allow-empty='true' track-by='value' label='description'
								v-model='modelCliente' id='seletorEmpresaMapa' selectLabel="•" selectedLabel="✔"
								deselectLabel="x" :limit='1' :limitText="c => ` e mais ${c}`" @input='mudaCliente'>
								<template #noResult>
									Nenhum resultado encontrado
								</template>
								<template #noOptions>
									Nenhuma opção disponível
								</template>
								<template #clear>
									<div v-if='modelCliente.length' class="multiselect__clear mr-4">
										<b-button size='sm' variant='danger' @click='limpaSeletorCliente'>
											<base-icon size='16' :icon='iconClear' />
										</b-button>
									</div>
								</template>
							</Multiselect>
						</div>
					</div>
				</div>

				<!-- Status -->
				<div class='col-12 col-sm-6 col-md-4 col-lg-2 col-xl mb-2 px-1'>
					<div class='filtro-item status-item'>
						<span class='filtro-label'>Status:</span>
						<div class='filtro-campo status-checkboxes flex-grow-1'>
							<div class='checkbox-row'>
								<b-checkbox v-model="modelStatus.P"
									@change="onStatusChange('P', $event)">Pendente</b-checkbox>
							</div>
							<div class='checkbox-row'>
								<b-checkbox v-model="modelStatus.I"
									@change="onStatusChange('I', $event)">Iniciada</b-checkbox>
							</div>
							<div class='checkbox-row'>
								<b-checkbox v-model="modelStatus.F"
									@change="onStatusChange('F', $event)">Finalizada</b-checkbox>
							</div>
						</div>
					</div>
				</div>

				<!-- Turno -->
				<div class='col-12 col-sm-6 col-md-4 col-lg-2 col-xl mb-2 px-1'>
					<div class='filtro-item turno-item'>
						<span class='filtro-label'>Turno:</span>
						<div class='filtro-campo turno-checkboxes flex-grow-1'>
							<div class='checkbox-row' v-for="(turno, index) in turnos" :key="index">
								<b-checkbox v-model="turno.checked" @change="onTurnoChange(turno)">
									{{ turno.label }}
								</b-checkbox>
							</div>
						</div>
					</div>

				</div>

				<!-- Categoria -->
				<div class='col-12 col-sm-6 col-md-4 col-lg-2 col-xl mb-2 px-1'>
					<div class='filtro-item'>
						<span class='filtro-label'>Categoria:</span>
						<div class='filtro-campo flex-grow-1'>
							<Multiselect placeholder='Selecione...' :options='optCategoria' :allow-empty='true'
								track-by='value' label='text' v-model='modelCategoria' selectLabel="•" selectedLabel="✔"
								deselectLabel="x" @input='onCategoriaChange' :multiple="true" :close-on-select="true"
								:limit="1" :limitText="c => ` e mais ${c}`">
								<template #noResult>
									Nenhum resultado encontrado
								</template>
								<template #noOptions>
									Nenhuma opção disponível
								</template>
							</Multiselect>
						</div>
					</div>
				</div>

				<!-- Região -->
				<div class='col-12 col-sm-6 col-md-4 col-lg-2 col-xl mb-2 px-1'>
					<div class='filtro-item'>
						<span class='filtro-label'>Região:</span>
						<div class='filtro-campo flex-grow-1'>
							<Multiselect placeholder='Selecione...' :options='optRegiao' :allow-empty='true'
								track-by='value' label='text' v-model='modelRegiao' selectLabel="•" selectedLabel="✔"
								deselectLabel="x" @input='onRegiaoChange' :multiple="true" :close-on-select="true"
								:limit="1" :limitText="c => ` e mais ${c}`">
								<template #noResult>
									Nenhum resultado encontrado
								</template>
								<template #noOptions>
									Nenhuma opção disponível
								</template>
							</Multiselect>
						</div>
					</div>
				</div>

				<!-- Data -->
				<div class='col-12 col-sm-6 col-md-4 col-lg-2 col-xl mb-2 px-1'>
					<div class='filtro-item'>
						<span class='filtro-label'>Data:</span>
						<div class='filtro-campo flex-grow-1'>
							<el-date-picker id="periodoPicker" style="height: 40px; width: 225px;" v-model="modelData"
								:picker-options="pickerOptions" class="datePicker tamanhoFonteInfo" type="daterange"
								size="mini" range-separator="-" value-format="dd/MM/yyyy" format="dd/MM/yyyy"
								start-placeholder="" end-placeholder="" @change="mudaDataFiltro">
							</el-date-picker>
						</div>
					</div>
				</div>

				<!-- Busca -->
				<div class='col-12 col-sm-6 col-md-4 col-lg-2 col-xl mb-2 px-1'>
					<div class='filtro-item'>
						<span class='filtro-label'>Busca:</span>
						<div class='filtro-campo flex-grow-1'>
							<input type='text' v-model='buscaUsuario' placeholder='Buscar' class='form-control'>
						</div>
					</div>
				</div>

				<!-- Botão expandir -->
				<div class='col-auto d-flex align-items-center ml-auto mb-2 px-1'>
					<b-button squared class='botaoFiltro' variant='outline-secondary' @click='emiteExpandir'>
						<baseIcon :icon='iconResize' size='20' />
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import datePicker from '@/components/Atom/Datas/InputSingleDay.vue'
import Multiselect from 'vue-multiselect';
import { DateTime } from 'luxon';
import { pickerOptions } from "@/components/Atom/Painel/datePickerHelper.js";

import {
	mdiResize,
	mdiMoveResizeVariant,
	mdiCloseThick
} from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { conectionError } from '@/Services/Helpers/swellHeper'
import { mapGetters } from "vuex";

export default {
	name: 'filtrosPainelColetivo',
	components: {
		baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
		Multiselect,
		datePicker,
	},
	props: {
		clienteSelecionado: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			optSelectEmpresa: new EmpresasService().Get(),
			modelCliente: [],
			buscaUsuario: '',
			iconResize: mdiResize,
			iconClear: mdiCloseThick,
			modelData: '',
			statusRota: [],
			modelCategoria: null,
			modelRegiao: null,
			optCategoria: [],
			optRegiao: [],
			pickerOptions: pickerOptions,
			modelData: [
				DateTime.now().toFormat("dd/MM/yyyy"),
				DateTime.now().toFormat("dd/MM/yyyy")
			],
			msgErroPeriodo: "",
			turnos: [
				{ codigo: 'P', label: '1º Turno', checked: false },
				{ codigo: 'S', label: '2º Turno', checked: false },
				{ codigo: 'T', label: '3º Turno', checked: false }
			],
			turnosRota: [],
			statusOptions: {
				'P': { model: 'modelStatusPendente', label: 'Pendente' },
				'I': { model: 'modelStatusIniciado', label: 'Iniciada' },
				'F': { model: 'modelStatusFinalizado', label: 'Finalizada' }
			},
			modelStatus: {
				P: false,
				I: false,
				F: false,
				C: false
			},
			baseUri: '/finder/mapa/',
			mapeamentoRegiaoCategoria: {},
			mapeamentoCategoriaRegiao: {},
			todasCategorias: [],
			todasRegioes: [],
			modelHoraIncio: '00:00:00',
			modelHoraFinal: '23:59:59',
		}
	},
	inject: ['mapaRequisicaoViagem'],
	methods: {
		...mapGetters(["getMaster"]),
		limpaSeletorCliente() {
			this.$emit("fecha-info");
			this.modelCliente = []
		},
		/**
		 * @listens input - alteração no cliente selecionado.
		 * @param {object[]} cliente
		 * @param {string}   cliente[].description - nome do cliente.
		 * @param {number}   cliente[].value       - código do cliente.
		 * @description caso houver pelo menos 1 cliente selecionado
		 * emite os filtros.
		 * @author Otávio 🦆
		 */
		mudaCliente(cliente) {
			var select_all = cliente.some((c) => { return c.value === 'SA' })
			if (select_all) {
				this.modelCliente = this.optSelectEmpresa
			}
			this.emiteFiltros()
			this.carregarRegioes()
		},

		/**
		 * @param {string} data - dd/mm/yyyy
		 * @description atribui o valor do filtro a moda.
		 * @author Otávio 🦆
		 */
		mudaDataFiltro(data) {
			if (typeof data === 'object' && data !== null && data.data !== undefined) {
				this.modelData = Array.isArray(data.data) && data.data.length === 2
					? data.data
					: [data.data, data.data];

				if (data.horaInicio !== undefined) {
					this.modelHoraIncio = data.horaInicio;
				}

				if (data.horaFinal !== undefined) {
					this.modelHoraFinal = data.horaFinal;
				}
			} else if (Array.isArray(data)) {
				this.modelData = data;
			} else {
				this.modelData = [data, data];
			}

			this.verificaPeriodo();
			this.emiteFiltros();
		},

		emiteExpandir() {
			if (this.iconResize === mdiResize)
				this.iconResize = mdiMoveResizeVariant
			else
				this.iconResize = mdiResize
			this.$emit('expandir')
		},

		/**
		 * @description verifica se o periodo selecionado pelo usuário
		 * for maior que o período válido de 7 dias, a função
		 * força um periodo válido começando da primeira data.
		 * @author Otávio 🦆
		 */
		verificaPeriodo() {
			if (!this.modelData || !Array.isArray(this.modelData) || this.modelData.length < 2) return true;

			var dataInicio = DateTime.fromFormat(this.modelData[0], "dd/MM/yyyy");
			var dataFim = DateTime.fromFormat(this.modelData[1], "dd/MM/yyyy");
			var diff = dataFim.diff(dataInicio, "days").toObject();

			if (diff.days > 6) {
				var novaDataFim = dataInicio.plus({ days: 6 });
				this.modelData = [this.modelData[0], novaDataFim.toFormat("dd/MM/yyyy")];
				this.msgErroPeriodo = 'Intervalo máximo de 7 dias';

				this.emiteFiltros();
				return false;
			}

			return true;
		},

		/**
		 * Manipula a mudança de estado dos checkboxes de turno
		 * @param {Object} turno - Objeto do turno contendo codigo e checked
		 * @description Adiciona ou remove o código do turno do array turnosRota
		 * baseado no estado do checkbox e emite os filtros atualizados
		 * @author Otávio 🦆
		 */
		onTurnoChange(turno) {
			if (turno.checked) {
				if (!this.turnosRota.includes(turno.codigo)) {
					this.turnosRota.push(turno.codigo);
				}
			} else {
				const index = this.turnosRota.findIndex(t => t === turno.codigo);
				if (index >= 0) {
					this.turnosRota.splice(index, 1);
				}
			}

			this.emiteFiltros();
		},

		/**
		 * @description função  para emitir o evento de filtros
		 * @author Otávio 🦆
		 */
		emiteFiltros() {
			this.$emit('filtros-coletivos', this.objFiltros)
		},

		/**
		* Carrega as categorias disponíveis para a empresa selecionada.
		* Obtém os dados via requisição HTTP e atualiza a lista de categorias.
		* @author Otávio 🦆
		*/
		obterCategoriasDeRegioes() {
			if (!this.optRegiao || !Array.isArray(this.optRegiao) || this.optRegiao.length === 0) {
				this.optCategoria = [];
				this.todasCategorias = [];
				this.modelCategoria = null;
				return;
			}

			const categoriasMap = new Map();
			this.optRegiao.forEach(regiao => {
				if (regiao.rccodigocategoria && regiao.lcdescricao) {
					categoriasMap.set(regiao.rccodigocategoria, {
						value: regiao.rccodigocategoria,
						text: regiao.lcdescricao
					});
				}
			});

			const categoriasFormatadas = Array.from(categoriasMap.values());

			this.optCategoria = categoriasFormatadas;
			this.todasCategorias = [...categoriasFormatadas];
			this.modelCategoria = null;
		},

		/**
		* Carrega as regiões disponíveis para a empresa selecionada.
		* Obtém os dados via requisição HTTP e atualiza a lista de regiões.
		* @author Otávio 🦆
		*/
		async carregarRegioes() {
			try {
				if (!this.modelCliente || !Array.isArray(this.modelCliente) || this.modelCliente.length === 0) {
					this.optRegiao = [];
					this.todasRegioes = [];
					this.modelRegiao = null;
					return;
				}

				const clientes = this.modelCliente.map(empresa => empresa.value)

				const payload = {
					clientes: clientes
				}

				const httpRequest = new HttpRequest()
				const response = await httpRequest.Post(`${this.baseUri}carregar/regioes`, payload)

				if (response.code === 200 || response.status) {
					const regioesFormatadas = response.data.regioes.map(regiao => ({
						value: regiao.recodigo,
						text: regiao.redescricao,
						color: regiao.recor,
						rccodigocategoria: regiao.rccodigocategoria,
						lcdescricao: regiao.lcdescricao
					}));

					this.optRegiao = regioesFormatadas;
					this.todasRegioes = [...regioesFormatadas];
					this.modelRegiao = null;

					this.obterCategoriasDeRegioes();
					this.processarRelacionamentos();
				}
			} catch (error) {
				this.optRegiao = [];
				this.todasRegioes = [];
				this.modelRegiao = null;
				conectionError();
			}
		},

		/**
		 * Processa os dados de regiões e categorias para criar os mapeamentos de relacionamento
		 * Esta função utiliza os dados que já vieram do endpoint de regiões
		 * @author Otávio 🦆
		 */
		processarRelacionamentos() {
			try {
				this.mapeamentoRegiaoCategoria = {};
				this.mapeamentoCategoriaRegiao = {};

				this.optRegiao.forEach(regiao => {
					if (!regiao.rccodigocategoria) return;

					const regiaoId = parseInt(regiao.value);
					const categoriaId = parseInt(regiao.rccodigocategoria);

					if (!this.mapeamentoRegiaoCategoria[regiaoId]) {
						this.mapeamentoRegiaoCategoria[regiaoId] = [];
					}
					if (!this.mapeamentoRegiaoCategoria[regiaoId].includes(categoriaId)) {
						this.mapeamentoRegiaoCategoria[regiaoId].push(categoriaId);
					}

					if (!this.mapeamentoCategoriaRegiao[categoriaId]) {
						this.mapeamentoCategoriaRegiao[categoriaId] = [];
					}
					if (!this.mapeamentoCategoriaRegiao[categoriaId].includes(regiaoId)) {
						this.mapeamentoCategoriaRegiao[categoriaId].push(regiaoId);
					}
				});
			} catch (error) {
				this.mapeamentoRegiaoCategoria = {};
				this.mapeamentoCategoriaRegiao = {};
			}
		},

		/**
		 * Filtra as regiões com base nas categorias selecionadas
		 * @returns {Array} regiões filtradas
		 * @author Otávio 🦆
		 */
		filtrarRegioesPorCategoria() {
			if (!this.modelCategoria ||
				(Array.isArray(this.modelCategoria) && this.modelCategoria.length === 0)) {
				return this.todasRegioes;
			}

			let categoriasIds = [];
			if (Array.isArray(this.modelCategoria)) {
				categoriasIds = this.modelCategoria.map(cat => cat.value);
			} else {
				categoriasIds = [this.modelCategoria.value];
			}

			const regioesAssociadas = new Set();

			categoriasIds.forEach(categoriaId => {
				const regioes = this.mapeamentoCategoriaRegiao[categoriaId] || [];
				regioes.forEach(regiaoId => regioesAssociadas.add(regiaoId));

				this.todasRegioes.forEach(regiao => {
					if (regiao.rccodigocategoria === categoriaId) {
						regioesAssociadas.add(regiao.value);
					}
				});
			});

			return this.todasRegioes.filter(regiao =>
				regioesAssociadas.has(regiao.value)
			);
		},

		/**
		 * Filtra as categorias com base nas regiões selecionadas
		 * @returns {Array} categorias filtradas
		 * @author Otávio 🦆
		 */
		filtrarCategoriasPorRegiao() {
			if (!this.modelRegiao ||
				(Array.isArray(this.modelRegiao) && this.modelRegiao.length === 0)) {
				return this.todasCategorias;
			}

			let categoriasSelecionadas = [];
			if (this.modelCategoria) {
				if (Array.isArray(this.modelCategoria)) {
					categoriasSelecionadas = this.modelCategoria.map(cat => cat.value);
				} else {
					categoriasSelecionadas = [this.modelCategoria.value];
				}
			}

			let regioesIds = [];
			if (Array.isArray(this.modelRegiao)) {
				regioesIds = this.modelRegiao.map(reg => reg.value);
			} else {
				regioesIds = [this.modelRegiao.value];
			}

			const categoriasAssociadas = new Set();

			regioesIds.forEach(regiaoId => {
				const categorias = this.mapeamentoRegiaoCategoria[regiaoId] || [];
				categorias.forEach(categoriaId => categoriasAssociadas.add(categoriaId));
			});

			if (categoriasAssociadas.size === 0) {
				return this.todasCategorias;
			}

			return this.todasCategorias.filter(categoria =>
				categoriasAssociadas.has(categoria.value) ||
				categoriasAssociadas.has(parseInt(categoria.value)) ||
				categoriasSelecionadas.includes(categoria.value) ||
				categoriasSelecionadas.includes(parseInt(categoria.value))
			);
		},

		/**
		 * @description Manipula a mudança na seleção de categoria
		 * @author Otávio 🦆
		 */
		onCategoriaChange() {
			if (!this.modelCategoria ||
				(Array.isArray(this.modelCategoria) && this.modelCategoria.length === 0)) {
				this.modelCategoria = null;
			}

			if (this.modelRegiao && this.modelCategoria) {
				const regioesIds = Array.isArray(this.modelRegiao)
					? this.modelRegiao.map(reg => reg.value)
					: [this.modelRegiao.value];

				const categoriasIds = Array.isArray(this.modelCategoria)
					? this.modelCategoria.map(cat => cat.value)
					: [this.modelCategoria.value];
				if (Array.isArray(this.modelRegiao)) {
					const regioesCompativeis = this.modelRegiao.filter(regiao => {
						for (const catId of categoriasIds) {
							const regioesDaCategoria = this.mapeamentoCategoriaRegiao[catId] || [];
							if (regioesDaCategoria.includes(regiao.value)) {
								return true;
							}
						}
						return false;
					});

					this.modelRegiao = regioesCompativeis.length > 0 ? regioesCompativeis : null;
				} else {
					const regiaoCompativel = categoriasIds.some(catId => {
						const regioesDaCategoria = this.mapeamentoCategoriaRegiao[catId] || [];
						return regioesDaCategoria.includes(this.modelRegiao.value);
					});

					if (!regiaoCompativel) {
						this.modelRegiao = null;
					}
				}
			}

			if (this.modelCategoria) {
				this.optRegiao = this.filtrarRegioesPorCategoria();
			} else {
				this.optRegiao = this.todasRegioes;
			}

			this.emiteFiltros();
		},

		/**
		 * @description Manipula a mudança na seleção de região
		 * @author Otávio 🦆
		*/
		onRegiaoChange() {
			if (!this.modelRegiao ||
				(Array.isArray(this.modelRegiao) && this.modelRegiao.length === 0)) {
				this.modelRegiao = null;
			}

			if (this.modelRegiao) {
				this.optCategoria = this.filtrarCategoriasPorRegiao();
			} else {
				this.optCategoria = this.todasCategorias;
			}

			this.emiteFiltros();
		},

		/**
		 * Manipula mudanças em qualquer status de rota
		 * @param {string} statusCode - Código do status (P, I, F, C)
		 * @param {boolean} value - Novo valor do checkbox
		 * @author Otávio 🦆
		 */
		onStatusChange(statusCode, value) {
			this.modelStatus[statusCode] = value;

			if (value) {
				if (!this.statusRota.includes(statusCode)) {
					this.statusRota.push(statusCode);
				}
			} else {
				const index = this.statusRota.findIndex(x => x === statusCode);
				if (index >= 0) {
					this.statusRota.splice(index, 1);
				}
			}

			this.emiteFiltros();
		},
	},
	watch: {
		/**
		 * @description monitora mudancas do cliente e emite para o mapa
		 * @param value 
		 * @author desconhecido
		 */
		modelCliente(value) {
			this.$emit('muda-cliente', value)
		},

		/**
		 * @description emite os filtros do cliente selecionado
		 * @param value 
		 * @author desconhecido
		 */
		clienteSelecionado(value) {
			this.modelCliente = value
			this.emiteFiltros()
			this.carregarRegioes()
		},


		/**
		 * @fires filtrar-coletivos
		 * Quando o usuário digita no campo de busca, emite o evento com o texto
		 * para filtrar por placa, prefixo, linha, escala e motorista
		 * @author Otávio 🦆
		 */
		buscaUsuario(newValue) {
			this.$emit('filtro-usuario', newValue)
		}
	},
	computed: {
		optClientes(){
			var sa = {
				value:'SA',
				description:'Selecionar Todos'
			}
			return [sa, ...this.optSelectEmpresa]
		},

		/**
		 * Faz a validação dos filtros e retorna o objeto
		 * @author Otávio 🦆
		 */
		objFiltros() {
			let categorias = [];
			let regioes = [];

			if (this.modelCategoria) {
				categorias = Array.isArray(this.modelCategoria)
					? this.modelCategoria.map(cat => cat.value)
					: [this.modelCategoria.value];
			}

			if (this.modelRegiao) {
				regioes = Array.isArray(this.modelRegiao)
					? this.modelRegiao.map(reg => reg.value)
					: [this.modelRegiao.value];
			}

			return {
				clientes: this.modelCliente.map(c => c.value),
				data: this.modelData,
				horaInicio: this.modelHoraIncio || '00:00:00',
				horaFinal: this.modelHoraFinal || '23:59:59',
				statusRotas: this.statusRota,
				turnosRota: this.turnosRota,
				categoria: categorias,
				regiao: regioes
			};
		},

		requisicaoViagem() {
			return this.mapaRequisicaoViagem.fechaViagem;
		},
	},
	mounted() {
		if (this.getMaster()) {
			this.modelCliente = [];
		} else {
			this.modelCliente = this.clienteSelecionado;
		}

		if (this.modelCliente && this.modelCliente.length > 0) {
			this.carregarRegioes();
		}
	}
}
</script>

<style lang="scss">
#filtro-painel-coletivos {
	height: auto !important;
	min-height: 50px;
	background-color: rgba(245, 245, 245, 0.90) !important;
}

.filtro-painel-coletivos {
	width: 100%;
	min-height: 65px;
	height: auto !important;
	background-color: rgba(245, 245, 245, 0.90) !important;
}

.filtro-painel-coletivos .row {
	display: flex;
	flex-wrap: nowrap;
	min-width: 1100px;
}

@media (min-width: 1200px) {
	.filtro-painel-coletivos .row>div:not(.col-auto) {
		flex: 1;
	}
}

.filtro-item {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	margin-right: 0;
}

.filtro-label {
	margin-right: 5px;
	white-space: nowrap;
	font-weight: 500;
	min-width: 55px;
}

.filtro-campo {
	min-width: 100px;
	flex: 1;
}

.status-item,
.turno-item {
	align-items: flex-start;
	padding-top: 8px;
}

.status-checkboxes,
.turno-checkboxes {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.checkbox-row {
	margin-bottom: 2px;
	line-height: 1;
}

.status-item .filtro-label,
.turno-item .filtro-label {
	margin-top: 1px;
}

.filtro-campo .multiselect {
	font-size: 14px;
	min-height: 34px;
	width: 100%;
}

.divDatePicker {
	width: 100%;
}

.el-date-editor .el-range-separator {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	height: 100% !important;
	line-height: 1 !important;
	vertical-align: middle !important;
	padding: 0 5px !important;
}

.el-date-editor.el-range-editor {
	display: flex !important;
	align-items: center !important;
}

#periodoPicker.el-date-editor .el-range-separator {
	padding-bottom: 0 !important;
}

@media (max-width: 768px) {
	.filtro-painel-coletivos .row {
		flex-wrap: wrap;
		min-width: 0;
	}

	.filtro-painel-coletivos {
		overflow-x: visible;
	}

	.filtro-painel-coletivos [class*="col-"] {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
</style>