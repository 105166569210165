<template>
	<painelEagle id="cadastroRegioes" :loading="salvando">
		<div class="col-sm-12 row">
			<div class="col-sm-6 nopadding">
				<titulo titulo="Regiões" :icon="mdiEarth" />
			</div>
			<div class="col-sm-6 nopadding">
				<botoesSC @salvarCadastro="salvarRegiao" :disabled="$v.$invalid || multiploPoli" tipoCancela="button"
					linkCancel="listagemRegioes" />
			</div>
		</div>
		<div class="col-sm-12">
			<hr />
		</div>
		<div class="col-sm-12"></div>
		<div class="col-sm-12 row">
			<div class="col-sm-3 nopadding">
				<selectAll :selected="valueEmpresa" ref="selectEmpresa" :inputClass="{
					'border border-danger': $v.regiao.recliente.$anyError,
				}" @close="$v.regiao.recliente.$touch()" @changeSelect="mudaEmpresa" :optionsArray="optEmpresas"
					:isMultiple="false" :labels="[{ description: 'Empresa*' }]" nameForRadio="selecionarEmpresa" />
			</div>
			<div class="col-sm-2 nopadding">
				<inputS :inputClass="{
					'border border-danger': $v.regiao.redescricao.$anyError,
				}" name="descricaoRegiao" :value="regiao.redescricao" ref="descricao"
					@blur="$v.regiao.redescricao.$touch()" @changeInput="(value) => (regiao.redescricao = value)"
					label="Descrição*" />
			</div>
			<div class="col-sm-2 nopadding">
				<inputS ref="velocidade" @changeInput="(value) => (regiao.revelocidade = value)" name="velocidadeRegiao"
					:value="transformaVelocidade(regiao.revelocidade)" :hasMask="true" :mask="['NNN']"
					label="Velocidade" />
			</div>
			<div class="col-sm-2 nopadding">
				<inputCor ref="inputCor" name="corRegiao" :value="regiao.recor" @changeInput="mudaCor" label="Cor" />
			</div>
			<div class="col-sm-2 nopadding">
				<inputS disabled ref="inputArea" name="areaRegiao" :value="areaValue" label="Área" />
			</div>
			<div class="col-sm-1 nopadding center">
				<span class="cursor-pointer" @click="areaDeRisco" @click.right.prevent event="click"
					:class="{ risco: valorBotao }">
					<baseIcon size="18" :icon="mdiAlert" />
					<br />Área de Risco
				</span>
			</div>
		</div>
		<br>
		<div class="col-sm-12 row">
			<div class="col-sm-3 nopadding">
				<selectAll :selected="categoriaRegiaoSelecionada" ref="selectCategoria"
					:disabled="!optCategoriaRegiao.length > 0" @changeSelect="mudaCategoriaRegiao"
					:optionsArray="optCategoriaRegiao" :isMultiple="true" :labels="[{ description: 'Categoria' }]"
					nameForRadio="selectCategoria" :loading="loadingCategoriaRegiao" />
			</div>
		</div>
		<div class="col-sm-12 row">
			<div class="col-sm-4 nopadding">
				<required />
			</div>
			<div class="col-sm-8 divMultiPoli">
				<span style="color: red" v-show="multiploPoli">
					Existe mais de uma região desenhada no mapa, remova uma para
					continuar. * Para remover, {{ editando ? "" : "edite e" }} clique na
					região.
				</span>
			</div>
		</div>
		<div class="col-sm-12">
			<hr />
		</div>
		<div class="col-sm-12 traz">
			<mapa height="650" :initialZoom="15" id="cadastroRegMapa" ref="map">
				<markers id="matriz" icon="img/matriz.png" :latitude="local.latMarker" :longitude="local.longMarker" />
				<draw ref="desenho" :regiao="desenhoRegiao" @desenho="desenhoAlterado" @editando="editandoDesenho">
					<div v-show="!editando" class="search-field">
						<div class="col-sm-12 row nopadding">
							<div class="col-sm-10 nopadding">
								<input placeholder="Buscar..." ref="campoBusca" class="form-search"
									@keyup.enter="procuraRegiao" v-model="valorBuscaRegiao" />
							</div>
							<div class="col-sm-2 nopadding">
								<b-button :disabled="valorBuscaRegiao == '' ? true : false" @click="procuraRegiao"
									class="search-button ml-1" variant="info">
									<b-spinner v-if="searchRegiao" label="Spinning" />
									<baseIcon v-else :size="25" :icon="mdiMapSearchOutline" />
								</b-button>
							</div>
						</div>
						<div class="search-result">
							<ul class="search-list">
								<li v-show="mostraListaPesquisa" v-for="(reg, index) in resultadosOpt" :key="index"
									event="click" class="search-item" @click="selecionaResultado(index)">
									{{ reg.endereco }}
								</li>
							</ul>
						</div>
					</div>
				</draw>
			</mapa>
		</div>
	</painelEagle>
</template>

<script lang="js">
import Vue from 'vue'
import Swal from 'sweetalert2'
import { mdiEarth, mdiAlert, mdiMapSearchOutline } from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { required, minLength } from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import L from 'leaflet'
import { FiltrosService } from '@/Services/filtros/filtros.Service'

export default Vue.extend({
	name: 'CadastrosRegioes',
	components: {
		baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
		'draw': require('@/components/Atom/Mapa/freeDraw').default,
		'painelEagle': require('@/components/Atom/Panel/PanelEagle').default,
		'titulo': require('@/components/Atom/Header/Titulo').default,
		'botoesSC':
			require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
		'selectAll': require('@/components/Atom/Select/SelectAll').default,
		'inputS': require('@/components/Atom/Inputs/InputSimple').default,
		'inputCor': require('@/components/Atom/Inputs/InputColor').default,
		'mapa': require('@/components/Atom/Mapa/MapaSimples').default,
		'required':
			require('@/components/Atom/Footer/RequiredFields').default,
		'markers': require('@/components/Atom/Mapa/MarkerPoint').default,
	},
	data() {
		return {
			mdiAlert: mdiAlert,
			mdiEarth: mdiEarth,
			mdiMapSearchOutline: mdiMapSearchOutline,
			optEmpresas: [],
			optCategoriaRegiao: [],
			valorBotao: false,
			desenho: [],
			descricaoC: Object,
			rota: '/telemetria/cadastros/regioes/',
			funcao: 'salvar',
			regiao: {
				redescricao: '',
				revelocidade: '',
				recor: '#000000',
				recliente: '',
				rerisco: 'N',
				rearea: '',
				regeojson: '',
			},
			editando: false,
			nomeRegiao: '',
			valueEmpresa: [],
			local: {
				lat: -27.1001389,
				long: -52.6270369,
				latMarker: -27.1001389,
				longMarker: -52.6270369,
			},
			desenhoRegiao: [],
			resultadosOpt: [],
			reultados: [],
			pesquisa: false,
			multiploPoli: false,
			salvando: false,
			mostraListaPesquisa: false,
			valorBuscaRegiao: '',
			searchRegiao: false,
			regioesOnMap: [],
			categoriaRegiaoSelecionada: [],
			loadingCategoriaRegiao: false,
			recodigocategorias: [],
			regiaoCategoria: {
				rccodigocategoria: '',
				rcDelete: '',
				rcCreate: '',
			},
			listaEditRegiaoCreates: [],
			listaEditRegiaoDeletes: [],
		}
	},
	validations: {
		desenho: { required },
		regiao: {
			redescricao: { required },
			recliente: { required },
		}
	},
	methods: {
		editandoDesenho() {
			this.editando = true
		},

		salvarRegiao() {
			var caminho = this.rota + this.funcao
			var obj = this.montaRegiao()
			this.salvando = true
			new HttpRequest().Post(caminho, obj).then(data => {
				if (data.data.sucess) {
					this.$router.push({ name: 'listagemRegioes' })
				} else {
					this.salvando = false
					conectionError()
				}
			})
		},

		montaRegiao() {
			this.regiaoCategoria.rcCreate = this.listaEditRegiaoCreates
			this.regiaoCategoria.rcDelete = this.listaEditRegiaoDeletes
			var obj = {
				'regiao': {
					...this.regiao
				},
				'regiaoCategoria': {
					...this.regiaoCategoria
				},
				'pontos': this.arrumaLatLeng()
			}
			if (this.funcao === 'atualizar') {
				obj['recodigo'] = this.$route.params.id
			}
			return obj;
		},

		mudaCor(value) {
			this.regiao.recor = value
		},

		desenhoAlterado(pontos, extra) {
			if (extra === 'stop') {
				this.editando = false
			} else if (extra === 'delete') {
				this.editando = false
				this.desenho = []
				this.regiao.rearea = 0
				this.multiploPoli = false
			}
			if (pontos.length > 1) {
				this.multiploPoli = true
				this.pontos = []
			} else if (pontos.length === 1) {
				this.multiploPoli = false
				this.desenho = pontos
				this.getArea(pontos)
			}
			this.excluirRegiao()
			if (extra !== 'delete') {
				this.criarRegiaoFreeDraw(pontos)
			}
		},

		criarRegiaoFreeDraw(coordenadas) {
			this.excluirRegiao()
			var reg = L.polygon(coordenadas, {
				color: "#63a3db",
				interactive: false,
			});
			this.addToMapa(reg);
			this.regioesOnMap.push(reg);
		},

		excluirRegiao() {
			this.regioesOnMap.forEach((r) => {
				return r.remove()
			})
			this.regioesOnMap = []
		},

		addToMapa(object) {
			if (!this.$refs.map) return;
			let mapa = this.$refs.map.returnMapObject();
			if (!mapa) return;
			object.addTo(mapa);
		},

		getArea(pontos) {
			this.regiao.rearea = 0
			new HttpRequest()
				.Post(this.rota + 'area', { 'coord': JSON.stringify(pontos) })
				.then(data => {
					var area = data.data.arrayPontos[0].area
					this.regiao.rearea = area
				})
		},
		arrumaLatLeng() {
			var a = this.$refs.desenho.getLatLng()
			this.desenho = a[0].map(par => {
				var aux = {
					'rclatitude': par.lat,
					'rclongitude': par.lng
				}
				if (this.funcao === 'atualizar') {
					aux['rcregiao'] = this.$route.params.id
				}
				return aux
			})
			return this.desenho
		},
		procuraRegiao(value) {
			this.searchRegiao = true
			new HttpRequest()
				.Post(this.rota + 'procurar', { 'q': this.valorBuscaRegiao })
				.then(data => {
					this.pesquisa = true
					this.resultadosOpt = data.data.info
				})
				.catch(error => { '' })
				.finally(() => {
					this.mostraListaPesquisa = true
					this.searchRegiao = false
				})
		},
		mudaEmpresa(arr, value, description) {
			this.regiao.recliente = arr[0]
			if(arr>0){
				this.centralizaCliente(arr[0])
			}
			this.getOptCategoriaRegiao(arr)
		},

		selecionaResultado(index) {
			var regiao = this.resultadosOpt[index]
			if (_.isArray(regiao.centro)) {
				this.$refs.map.flyTo(regiao.centro.reverse(), 12)
			}
			this.mostraListaPesquisa = false
			this.valorBuscaRegiao = ''
			if (_.isArray(regiao.coordenadas[0])) {
				// this.$refs.desenho.deletarDesenho()
				this.desenhoRegiao = regiao.coordenadas[0].map(e => [e[1], e[0]])
			}
		},

		criaDesenho(reg) {
			var a = reg.regioes_coordenadas.map((e) =>
				[Number(e.rclatitude), Number(e.rclongitude)])
			this.desenhoRegiao = a
			this.$refs.map.flyToBounds(a)
			this.$nextTick(function () {
				this.local.lat = a[0][0]
				this.local.long = a[0][1]
			})
		},

		/**
		 * @description Verifica local da matriz do cliente e retorna
		 * a latitude e longitude do local
		 *  Adicionado a variavel local para inserir o marker
		 * @param Number cliente = codigo cliente
		 * @author Vitor Hugo
		 */
		centralizaCliente(cliente) {
			var cam = this.rota + 'localCliente'
			new HttpRequest().Post(cam, { 'clientes': [cliente] }).then(data => {
				if (data.data.centro[0].cllatitude != null) {
					this.local.latMarker = data.data.centro[0].cllatitude
					this.local.longMarker = data.data.centro[0].cllongitude
					if (this.desenhoRegiao.length === 0) {
						this.$refs.map.flyTo(
							[this.local.latMarker, this.local.longMarker])
					}
				}
			})
		},
		areaDeRisco() {
			this.valorBotao = !this.valorBotao
			if (this.valorBotao) {
				this.regiao.rerisco = 'S'
				return
			}
			this.regiao.rerisco = 'N'
		},
		arrumaCampos(regiao) {
			Object.keys(this.regiao).forEach(key => {
				this.regiao[key] = regiao[key]
			});
			if (this.regiao.rerisco === 'S') {
				this.valorBotao = true
			}
			this.arrumaEmpresa()
			this.criaDesenho(regiao)
		},
		arrumaEmpresa() {
			this.optEmpresas.forEach(cliente => {
				if (cliente.value === this.regiao.recliente) {
					this.valueEmpresa.push(cliente)
					return
				}
			})
		},

		/**
		 * @listens value - input de velocidade
		 * @description Método para transformar o valor float vindo do
		 * back para inteiro,
		 *  já que a velocidade deve ser um valor inteiro
		 * @param  string velocidade        = valor da velocidade
		 * @return string velocidadeEditada = velocidade em formato intero
		 * @author Vitor Hugo
		 */
		transformaVelocidade(velocidade) {
			if (velocidade != null) {
				var velocidadeEditada = velocidade.split('.')
				return velocidadeEditada[0]
			}

		},

		/**
		 * 
		 * @param value 
		 * @author Marco Duz
		 */
		confereInsercao(value) {
			var wasRemoved = false
			this.listaEditRegiaoDeletes.forEach(el => {
				if (el == value) {
					var index = this.listaEditRegiaoDeletes.indexOf(value);
					this.listaEditRegiaoDeletes.splice(index, 1)
					wasRemoved = true
				}
			});
			if (!wasRemoved) {
				this.listaEditRegiaoCreates.push(value)
			}
		},

		/**
		 * Confere se a remoção deve ser efetuada pois caso o usuário selecione uma categoria
		 * e logo em seguida a remova não é necessário efetuar o delete da tabela apenas remover do array de criação
		 * caso ela realmente deva ser removida do abnco o id da categoria é adicionado ao array listaEditRegiaoDeletes
		 * @param value 
		 * @author Marco Duz
		 */
		confereRemocao(value) {
			var wasRemoved = false
			this.listaEditRegiaoCreates.forEach(el => {
				if (el == value) {
					var index = this.listaEditRegiaoCreates.indexOf(value);
					this.listaEditRegiaoCreates.splice(index, 1)
					wasRemoved = true
				}
			});
			if (!wasRemoved) {
				this.listaEditRegiaoDeletes.push(value)
			}
		},

		/**
		 * Recebe os valores do select e salva os ids da opção selecionada no array rccodigocategoria
		 * caso seja uma atualização da região, se houver value uma categoria foi adicionada
		 * se value for undefined uma categoria foi removida, para descobrir qual o id da categoria removida
		 * é comparada o array ainda não atualizado de (regiaoCategoria.rccodigocategoria)
		 * com o atualizado que retornou do select (arr)
		 * @param value -> contém o último elemento selecionado (lccodigo)
		 * @param arr -> conteúdo presente no select (lccodigo)
		 * @author Marco Duz
		 */
		mudaCategoriaRegiao(arr, value, description) {

			if (this.$route.params.id) {
				if (value) {
					this.confereInsercao(value)
				}
				else if (typeof value == 'undefined') {
					if (Array.isArray(this.regiaoCategoria.rccodigocategoria)) {
						const set1 = new Set(this.regiaoCategoria.rccodigocategoria);
						const set2 = new Set(arr);

						// Encontrar os elementos que estão em set1, mas não em set2
						const elRemoved = [...set1].filter(item => !set2.has(item));

						if (typeof elRemoved[0] == 'number') {
							this.confereRemocao(elRemoved[0])
						}

					}

				}
			}

			this.regiaoCategoria.rccodigocategoria = arr
		},

		/**
		 * Método para retornar as categorias do banco filtrado de acordo com a empresa
		 * 	Utiliza o FiltrosService
		 * @param array cliente
		 * @author Marco Duz
		 */
		getOptCategoriaRegiao(cliente) {
			this.loadingCategoriaRegiao = true
				this.categoriaRegiaoSelecionada = []
				new FiltrosService()
					.dados_filtros(cliente, ['CL'])
					.then((data) => {
						if (data) {
							this.optCategoriaRegiao = data.CL ?? [];
						}
					})
					.catch(() => conectionError())
					.finally(() => (this.loadingCategoriaRegiao = false));
			this.loadingCategoriaRegiao = false
		},

		/**
		 * formata o retorno do banco de dados para apresentar as categorias que já estão cadastradas desta região
		 * Salvando as formatadas na categoriaoRegiaoSelecionada 
		 * e salva os codigos das categorias no array rccodigocategoria que será utilizado no mudaCategoriaRegiao
		 * @parameters rccodigoregiao -> objeto com os codigos das categorias
		 * @author Marco Duz
		 */
		formataRetornoCategoriaSelecionada(rccodigoregiao) {
			Object.entries(rccodigoregiao).forEach(([key, data]) => {
				this.optCategoriaRegiao.forEach((el) => {
					if (el['value'] == data.rccodigocategoria) {
						this.regiao.recodigocategoria = el['value'];
						this.categoriaRegiaoSelecionada.push(el)
						this.regiaoCategoria.rccodigocategoria.push(data)
					}
				})
			});

		},
		/**
		 * função efetua um request das categorias associadas a região a ser editada
		 * os dados são retornados como objeto fora do padrão do select e precisam ser formatados antes de serem apresentados
		 * por isso  utilizada a função formataRetornoCategoriaSelecionada
		 * @author Marco Duz
		 */
		buscaCategoriaRegiaoSelecionada() {
			new HttpRequest()
				.Get(this.rota + 'get/categoria/regiao', { 'rccodigoregiao': this.$route.params.id })
				.then((data) => {
					this.formataRetornoCategoriaSelecionada(data.data.rccodigoregiao)
					this.salvando = false
				})
		}
	},
	mounted() {
		this.optEmpresas = new EmpresasService().Get();
		if (this.$route.params.id) {
			this.salvando = true
			this.funcao = 'atualizar'
			new HttpRequest()
				.Post(this.rota + 'getregiao', { 'regiao': this.$route.params.id })
				.then((data) => {
					this.arrumaCampos(data.data.regiao)
					this.getOptCategoriaRegiao([data.data.regiao.recliente]);
					this.buscaCategoriaRegiaoSelecionada();
				})
		}
	},
	computed: {
		areaValue() {
			if (this.regiao.rearea > 1000) {
				return (this.regiao.rearea / 1000000).toFixed(2) + ' Km²'
			} else if (this.regiao.rearea > 0) {
				return Number(this.regiao.rearea).toFixed(2) + ' m²'
			} else {
				return ''
			}
		}
	},
})
</script>
<style lang="sass" scoped>
.frente
  position: relative;
  z-index: 1;

.traz
  position: relative;
  z-index: 0;

.risco
  color: red;

.center
  text-align: center;
  vertical-align: center;


.listaResultadosProcura
  text-align: left;
  overflow-y:  auto;
  height: 20vh;
  padding: 0px
  margin-left: 3%;

  li
    background-color: white;
    list-style-type: none;
    size: 100%;
    border: solid gray 1px;
    margin: 1%;
    padding: 1%;
    &:hover
      background-color: lightgray;
      cursor: pointer;

.divMultiPoli
      padding-top: 15px;
      padding-bottom: 5px;
      padding-left: 20px;
      font-size: 12px;
      text-align: left;

.search-button
  height: 35px;
  padding: 5px;

.form-search
  padding-left: 10px;
  width: 90%;
  height: 35px !important;

.search-field
  padding: 5px;
  background: whitesmoke;
  z-index: 200 !important;
  position: absolute;
  width: 300px;
  top: 2%;
  border: 1px solid lightgray;
  border-radius: 3px;

.search-result
  max-height: 200px;
  overflow-y: auto;
  justify-content: center;

.search-item
  margin-bottom: 1px;
  cursor: pointer;
  height: 25px;
  width: 263px;
  white-space: nowrap;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;

ul
  list-style-type: none;
  display: table;
  margin: 0 auto;
  margin-left: 8px;
  padding-left: 2px;
  width: 95%;

li
  border: 1px solid grey !important;

li:hover
  background-color: gray;
  color: white;
  border: 1px solid whitesmoke !important;

input
  font-family: nexabook, sans-serif !important;
</style>
