<!-- eslint-disable vue/require-v-for-key -->
<template>
  <tableRelatorio htmlRel="" id="tabelaRelatorioCustoPedagio">
    <slot slot="thead">
      <tr>
        <th style="width: 5% !important; text-align: center !important;">Veículo</th>
        <th class="text-truncate" style="min-width: 100px !important; text-align: center !important">Data de passagem
        </th>
        <th class="text-truncate" style="min-width: 100px !important; text-align: center !important">Horário chegada
        </th>
        <th class="text-truncate" style="min-width: 100px !important; text-align: center !important">Horário saída
        </th>
        <th style="width: 80px !important; text-align: center !important">Gasto</th>
        <th style="text-align: center !important">Concessionária</th>
        <th style="text-align: center !important">Endereço</th>
      </tr>
    </slot>
    <slot slot="tbody">
      <statusInformation v-if="relatorioCustoPedagio.length <= 0" typeBar="tr" :statusBar="statusbar">
      </statusInformation>

      <template v-for="(itens) in relatorioCustoPedagio">
        <div style="padding: 6px;">
          <td colspan="2" style="text-align: center; background-color: #b1b2b5; border-radius: 10px; padding: 6px;">
            <p style="margin: 0; min-width: 100px; font-size: 15px;">{{ itens.praca }}</p>
          </td>
        </div>

        <tr>
          <td colspan="4" style="text-align: center; background-color: #A6A6A6; padding: 6px; font-size: 14px;">
          </td>

          <td colspan="1" class="d-flex justify-content-center"
            style="text-align: center; background-color: #A6A6A6; padding: 6px; font-size: 14px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            R$ {{ itens.totalGasto }}
          </td>

          <td colspan="2" style="text-align: center; background-color: #A6A6A6; padding: 6px; font-size: 14px;">
          </td>
        </tr>

        <template v-for="(item) in itens.passagens">
          <tr style="background-color: #D3D3D3">
            <td class="nopadding" style="text-align: center;">
              <p class="text-truncate nopadding mt-3" :title="item.veiculo">
                {{ item.veiculo }}
              </p>
            </td>
            <td class="nopadding" style="text-align: center;">
              <p class="text-truncate nopadding mt-3">
                {{ item.entrada.split(" ")[0] }}
              </p>
            </td>
            <td class="nopadding" style="text-align: center;">
              <p class="text-truncate nopadding mt-3">
                {{ item.entrada.split(" ")[1] }}
              </p>
            </td>
            <td class="nopadding" style="text-align: center;">
              <p class="text-truncate nopadding mt-3">
                {{ item.saida.split(" ")[1] }}
              </p>
            </td>
            <td class="nopadding" style="text-align: center;">
              <p class="text-truncate nopadding mt-3"
                style="color: #B46628 !important; font-weight: 700 !important; margin-left: 10px !important; margin-right: 10px !important;">
                R$ {{ item.gasto }}
              </p>
            </td>
            <td class="nopadding" style="text-align: center;" v-b-popover.hover.top="itens.concessionaria">
              <p class="text-truncate nopadding mt-3"
                style="margin-left: 10px !important; margin-right: 10px !important; max-width: 250px;">
                {{ itens.concessionaria }}
              </p>
            </td>
            <td class="nopadding d-flex justify-content-center" style="text-align: center;"
              v-b-popover.hover.top="itens.endereco">
              <p class=" text-truncate nopadding mt-3" style="margin-left: 10px !important; max-width: max-content;">
                {{ itens.endereco }}
              </p>

              <span @click="direcionarGoogleMaps(itens)" style="cursor: pointer;">
                <baseIcon size="20" class="mt-3 nopadding" :icon="mdiTarget"
                  style="color: #5064A7; margin-left: 10px" />
              </span>
            </td>
          </tr>
        </template>
      </template>
    </slot>
  </tableRelatorio>
</template>

<script lang="js">
import Vue from 'vue'
import {
  mdiTarget
} from '@mdi/js'

export default Vue.extend({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "tabelaPracas",

  components: {
    tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
    'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
    baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
  },

  data() {
    return {
      mdiTarget: mdiTarget
    }
  },

  props: {
    statusbar: {
      type: String,
      required: true,
      default: 'info'
    },
    relatorioCustoPedagio: {
      required: true,
    },

  },

  methods: {
    /**
     * Abre o Google Maps em uma nova aba direcionado para a latitude e longitude informadas.
     * 
     * A função recebe um objeto `local`, extrai as coordenadas `latitude` e `longitude` e 
     * gera um link para o Google Maps. Se os valores forem válidos, a URL é aberta em uma nova aba.
     * 
     * @param {Object} local - Objeto contendo as coordenadas do local.
     * @param {number} local.latitude - Latitude do local.
     * @param {number} local.longitude - Longitude do local.
     * 
     * @author Yuri 🇧🇷
     */
    direcionarGoogleMaps(local) {
      const latitude = local.latitude;
      const longitude = local.longitude;

      if (!latitude || !longitude) {
        return;
      }

      const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
      window.open(url, '_blank');
    }
  }
})
</script>