<template>
    <b-nav-item-dropdown right id="centralNotificacao">
        <template v-slot:button-content>
            <span v-show="qtdNtfNLidas && !loading" :style="`--contador-notificacao:'${qtdNtfNLidas}';`"
                class="qtd-notificacao bg-e-borda-red" />
            <span v-show="loadingIcone" class="qtd-notificacao">
                <b-spinner small label="Small Spinner" type="grow"></b-spinner>
            </span>
            <baseIcon size="25" class="" :icon="mdiBell" />
        </template>
        <div class="col-sm-12 row nopadding div-notificacoes" style="border-radius: 8px !important;">
            <b-overlay :show="loading" rounded="sm">
                <div id="teste" class="col-sm-12 row nopadding div-titulo">
                    <div style="border-radius: 8px 0px 0px 0px !important;" @click="listaLidas = false"
                        :class="{ 'selecionado': !listaLidas }" class="
                            cursor-pointer
                            col-sm-6 
                            nopadding 
                            text-center 
                            div-botao
                            d-flex
                            align-items-center
                            justify-content-center
                        ">
                        Não Lidas
                    </div>
                    <div style="border-radius: 0px 8px 0px 0px !important;" @click="listaLidas = true"
                        :class="{ 'selecionado': listaLidas }" class="
                            cursor-pointer
                            col-sm-6 
                            nopadding 
                            text-center 
                            div-botao
                            d-flex
                            align-items-center
                            justify-content-center
                        ">
                        Lidas
                    </div>
                </div>
                <div class="col-sm-12 row nopadding div-dados-notificacao mt-1">
                    <div class="
                        cursor-default
                        col-sm-12 
                        mt-2 
                        mb-2
                        row
                        cor-limpa-tudo
                        sticky
                    ">
                        <div v-if="notificacaoAtual.length" :title="!listaLidas ? 'Ler todas as notificações' : ''"
                            @click="lerTodasNtf(notificacoesNLidas)" class="
                                row 
                                nopadding
                                col-sm-12  
                            " :class="{ 'cursor-pointer': !listaLidas }">
                            <div v-show="listaLidas" class="col-sm-12 cursor-default" />
                            <div v-show="!listaLidas" class="col-sm-11 nopadding">
                                <span class="nowrap" style="min-width:100%;"> Limpar tudo </span>
                            </div>
                            <div v-show="!listaLidas" class="col-sm-1 nopadding">
                                <baseIcon size="20" class="iconTitulo cor-azul"
                                    :icon="mdiCheckboxMultipleMarkedCircle" />
                            </div>
                        </div>
                        <div @click="pegaNotificacao" v-else class="
                                row 
                                nopadding
                                col-sm-12 
                                cursor-pointer 
                            ">
                            <div class="col-sm-11 nopadding text-center">Atualizar</div>
                        </div>
                    </div>
                    <ul id="ul-notificacao" class="cursor-default">
                        <li v-for="(ntf, index) in notificacaoAtual" :key="index">
                            <div class="
                                    cursor-pointer
                                    col-sm-12 
                                    row
                                    div-notificacao
                                    d-flex 
                                    justify-content-between
                                    align-items-center
                                ">
                                <!-- 
                                    @click="direcionaRota"
                                    title="Ir para ..." 
                                -->
                                <div class="bold pl-0" :class="{
                                    'col-sm-11': !listaLidas,
                                    'col-sm-12 nopadding': listaLidas,
                                }">
                                    <div class="rounded div-dados">
                                        <div class="
                                                nopadding
                                                div-titulo-notificacao
                                                d-flex 
                                                justify-content-between
                                                align-items-center
                                            ">
                                            {{ ntf.titulo }}
                                            <!-- <baseIcon 
                                                size='18' 
                                                class="iconTitulo cor-branca" 
                                                :icon="mdiCarSettings"
                                            /> -->
                                        </div>
                                        <div v-html="ntf.mensagem"></div>
                                        <div class="text-right">
                                            <!-- 
                                                por algum motivo se eu 
                                                por a classe small na div
                                                o texto "Data:" e a data em si
                                                nao ficam do mesmo tamanho 
                                            -->
                                            <span class="small"> Data: </span>
                                            <span class="small" v-text="ntf.criado" v-if="!listaLidas" />
                                            <span class="small" v-text="ntf.atualizado" v-else />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!listaLidas" title="Marcar como lida" @click="marcaNtfLida(ntf, index)"
                                    class="col-sm-1 nopadding">
                                    <baseIcon size="18" class="iconTitulo cor-azul" :icon="mdiCheckboxMarkedCircle" />
                                </div>
                            </div>
                        </li>
                        <li v-show="!notificacaoAtual.length">
                            <div class="
                                    col-sm-12 
                                    row
                                    div-notificacao
                                    d-flex 
                                    justify-content-between
                                    align-items-center
                                ">
                                <div class="
                                        nopadding
                                        col-sm-12 
                                        d-flex 
                                        justify-content-between
                                    ">
                                    <span>
                                        Você não possui notificações
                                        <span v-show="!listaLidas"> para ler. </span>
                                        <span v-show="listaLidas"> lidas. </span>
                                    </span>
                                    <baseIcon size="18" class="iconTitulo cor-branca" :icon="mdiEmoticonHappy" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </b-overlay>
        </div>
    </b-nav-item-dropdown>
</template>
<script>
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { conectionError } from '@/Services/Helpers/swellHeper';
import {
    mdiBell,
    mdiCheckboxMarkedCircle,
    mdiCheckboxMultipleMarkedCircle,
    mdiCarSettings,
    mdiEmoticonHappy,
} from '@mdi/js';

export default {
    name: 'CentralNotificacao',
    components: {
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
    },
    data() {
        return {
            mdiBell: mdiBell,
            mdiCheckboxMarkedCircle: mdiCheckboxMarkedCircle,
            mdiCheckboxMultipleMarkedCircle: mdiCheckboxMultipleMarkedCircle,
            mdiEmoticonHappy: mdiEmoticonHappy,
            mdiCarSettings: mdiCarSettings,
            listaLidas: false,
            notificacoesLidas: [],
            notificacoesNLidas: [],
            intervaloNtf: undefined,
            loading: false,
            loadingIcone: false,

        }
    },

    mounted() {
        this.pegaNotificacao()
        this.pegaNotificacaoIntervalo()
    },
    beforeDestroy() {
        clearInterval(this.intervaloNtf)
    },
    methods: {
        /**
         * faz a atualizacao dos obj de notificacoes
         * hj utiliza um setInterval mas futuramente
         * seria loco fazer isso de um forma melhor
         * ---------------------------------------
         * Marcola do futuro aqui! No caso o uso do
         * set interval estava trabalhando muito o
         * funcionanmento do get de notificacoes,
         * então por ventura eu removi ele e deixei apenas
         * a request normal que usamos. Talvez em um futuro
         * distante mas não muito além
         * seja massa usar um websocket e tudo mais =)
         * @author Lucar Eduardo && Marcola ;)
        */
        pegaNotificacao() {
            if (!this.loadingIcone) {
                this.loadingIcone = true
                new HttpRequest().Get("/pega/notificacao")
                    .then(({ data, status }) => {
                        if (status) {
                            this.notificacoesLidas = data.lidas
                            this.notificacoesNLidas = data.naoLidas
                        }
                    }).finally(() => this.loadingIcone = false)
            }
        },

        /**
         * Essa aqui é a memsa função da de cima
         * só que com o setInterval, ainda vai ser usada para
         * para gerar verificações de 1 em 1 minuto
         * Marcola 🍺 >.< && Gui 🍺🍺
        */
        pegaNotificacaoIntervalo() {
            clearInterval(this.intervaloNtf)
            const minuto = 60000
            this.intervaloNtf = setTimeout(() => {
                if (!this.loadingIcone) {
                    this.loadingIcone = true
                    new HttpRequest().Get("/pega/notificacao")
                        .then(({ data, status }) => {
                            if (status) {
                                this.notificacoesLidas = data.lidas
                                this.notificacoesNLidas = data.naoLidas
                            }
                        }).finally(() => {
                            this.loadingIcone = false
                            this.pegaNotificacaoIntervalo()
                        })
                }
            }, minuto)
        },

        /**
         * le todas as notificacoes de uma vez
         * @param { array } ntf - a array das notificacoes
         * @author Lucar Eduardo | alterado pelo Marcos
         */
        async lerTodasNtf(ntf) {
            this.loading = true
            await this.lerNotificacao(ntf)
                .catch(() => {
                    conectionError()
                }).finally(() => {
                    this.pegaNotificacao()
                    this.loading = false
                })
        },

        /**
         * marca uma notificacao com lida, removendo ela da array e inicindo as att
         * @param { array } ntf - a notificacao em si
         * @param { string|number } index - posicao que o obj ta na array
         * @author Lucar Eduardo
        */
        marcaNtfLida(ntf, index) {
            this.loading = true
            this.lerNotificacao(ntf).then(({ data }) => {
                if (!data) {
                    conectionError()
                }
            }).catch(() => {
                conectionError()
            }).finally(() => {
                this.notificacoesNLidas.splice(index, 1)
                this.pegaNotificacao()
                this.loading = false
            })
        },

        /**
         * Faz a request para alterar o status da notificacao
         * @param { array } ntf - id da notificacao
         * @return promise da request
         * @author Lucar Eduardo
         */
        lerNotificacao(ntf) {
            let obj = { 'notificacoes': ntf }
            return new HttpRequest().Post('/ler/notificacao', obj)
        },

        direcionaRota() {
            this.$router.push({ name: "listarVeiculo" })
        }
    },
    computed: {
        /**
         * retorna qual tipo de notificacao ta sendo mostrada no momento
         * de acordo com a this.listaLidas
         * @return { array }
         * @author Lucar Eduardo
         */
        notificacaoAtual() {
            if (this.listaLidas) {
                return this.notificacoesLidas
            }
            return this.notificacoesNLidas
        },

        /**
         * tamanho da array this.notificacoesNLidas, so isso mesmo
         * show de bola
         */
        qtdNtfNLidas() {
            return this.notificacoesNLidas.length
        },
    }
}
</script>
<style lang="scss">
#centralNotificacao {

    .b-overlay-wrap,
    .position-relative {
        width: 100% !important;
    }

    .bold {
        font-family: nexabold;
    }

    .cor-limpa-tudo {
        color: #8f8f8f;
    }

    .cor-branca {
        color: #FFF;
    }

    .cor-azul {
        color: #4184f2;
    }

    .bg-branco {
        background-color: #FFF;
    }

    .ma-0 {
        margin: 0px !important;
    }

    .btn:not(:disabled):not(.disabled) {
        cursor: default;
    }

    .dropdown-menu {
        padding: none;
        margin: none;
        background-color: transparent !important;
        border: none !important;
    }

    .div-dados-notificacao {
        overflow: auto;
        max-height: 300px;
        position: relative;

        .sticky {
            background-color: #343333;
            top: -1px;
            z-index: 5;
            position: sticky;
            min-height: 10px;
            padding-top: 2px;
            padding-bottom: 2px;

        }
    }

    .qtd-notificacao {
        font-family: nexabold;
        font-weight: bold;
        font-size: 11px;
        height: 17px;
        width: 17px;
        border-radius: 100%;
        top: 5px;
        z-index: 5;
        color: #FFF;
        left: calc(70% - 17px);
        position: absolute;

        &::after {
            content: var(--contador-notificacao);
        }
    }

    .bg-e-borda-red {
        border: 1px solid red;
        background-color: red;
    }

    .div-notificacoes {
        color: #FFF;
        margin: 0px !important;
        background-color: #343333;
        border-radius: 0px !important;
        width: 300px;

        .div-botao {
            height: 40px;
            font-size: 17px;
            font-family: nexabold;
            background-color: #95989a;
        }

        .selecionado {
            background-color: #6ba1fb !important;
        }

        .div-notificacao {
            padding: 0px;

            .div-dados {
                padding: 10px;
                background-color: #777777;
            }
        }
    }

    #ul-notificacao {
        width: 100% !important;
        margin: 0px !important;
        padding: 0px !important;

        li {
            transform: ro;
            margin: 0px !important;
            padding: 10px !important;
            width: 100% !important;
            list-style: none;
        }
    }
}
</style>
