<template>
    <modalEagle @modalOpen="modalOpen" size="lg" :title="defineTitleModal" :id="id">
        <slot slot="modal-header">
            <div class="col-sm-12 row">
                <div class="col-sm-auto tituloModalHeaderAlertas p-0">
                    <template v-for="(total, tipo) of veiculoData.totais">
                        <div v-if="total && (typeof total != 'string')" :key="tipo + '_total_cor'"
                            :class="`numero-alerta alerta-${tipo}`">
                            {{ total }}
                        </div>
                    </template>
                    <div class="veiculo-alerta pl-3">{{ defineTitleModal }}</div>
                </div>
                <div class="col row nopadding px-1">
                    <div v-if="tags.length != 0" class="col-auto row d-flex nopadding" style="gap: 5px;">
                        <div v-for="(tag, index) of tags" :key="index">
                            <simpleTag :text="tag.tadescricao" :color="tag.tacor" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 solicitarManutencao nopadding" v-if="getCadastrar()">
                    <simpleButton text="Solicitar Manutenção" type="orange" :icon="mdiCarCog"
                        title="Solicitar Manutenção" width="170px" event="solicitarManutencao"
                        @solicitarManutencao="modalSolicitarManutencao" :disabled="loadingPanelModal" />
                </div>
            </div>
        </slot>
        <slot slot="modalBody">
            <div class="col-sm-12 row nopadding">
                <span class="col-sm-4">
                    <strong>Empresa: </strong>{{ dadosMotorista.empresa }}
                </span>
                <span class="col-sm-4">
                    <strong>Condutor: </strong>{{ dadosMotorista.nome }}
                </span>
                <span class="col-sm-4">
                    <strong>Telefone: </strong>{{ dadosMotorista.telefone }}
                </span>
            </div>
            <div>
                <hr />
            </div>
            <panelEagle :id="'id-modal-' + defineTitleModal" class="divListagemLinhas col-sm-12 minH"
                :loading="loadingPanelModal">
                <alertaTable v-if="!loadingPanelModal" :veiculoInfos="veiculoData" :tiposAlertas="tipos"
                    :alertas="dadosAlertas" ref="alertaTable" @lerTodos="clickLerTodosAlertas"
                    @lerAlertaAtual="clickLerAlertaAtual">
                </alertaTable>
            </panelEagle>
        </slot>
        <slot slot="modalFooter">
            <simpleButton class="" :icon="mdiArrowLeftThick" text="Voltar" event="click"
                @click="fecharModal" extraClass="voltarAmarelo">
            </simpleButton>
        </slot>
    </modalEagle>
</template>

<script lang="js" scoped>

import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { mapGetters } from 'vuex'
import { IconManager } from './Alertas'
import Vue from 'vue';
import { conectionError } from '@/Services/Helpers/swellHeper';
import PanelEagle from '@/components/Atom/Panel/PanelEagle.vue';
import { mdiArrowLeftThick } from '@mdi/js'

export default Vue.extend({
    name: 'ModalAlertasVeiculo',
    components: {
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'modalEagle': require('@/components/Atom/Modal/ModalEagle').default,
        'simpleButton': require('@/components/Atom/Buttons/SimpleButton.vue').default,
        'tabsEagle': require('@/components/Atom/Tabs/TabsEagle').default,
        'tipoAlerta': require('@/views/MapaPlus/Alertas/TipoAlerta').default,
        'mapaSimples': require('@/components/Atom/Mapa/MapaSimples.vue').default,
        'markerPoint': require('@/components/Atom/Mapa/MarkerPoint').default,

        'alertaTable': require('@/components/Atom/SpecificComponents/Alertas/AlertasTable').default,
        'simpleTag': require('@/components/Atom/Tags/SimpleTag.vue').default,
    },

    props: {
        id: {
            type: String,
            default: 'modalAlertasVeiculo',
        },
        veiculo: {
            required: true,
            type: Object,
            default: () => { return [] }
        },
        tipos: {
            required: true,
            type: [Array, Object],
            default: () => { return [] }
        },
    },

    data() {
        return {
            mdiArrowLeftThick: mdiArrowLeftThick,
            
            url: '/telemetria/nao/menu/alerta/',
            ...IconManager.getIcons(),
            loadingBotaoAlertas: false,
            latitudeMapa: 0,
            longitudeMapa: 0,
            disablePortal: false,
            mostrarMapa: false,
            mostrarTratativas: false,
            tabs: [
                { 'title': 'Descrição', 'value': 'desc' },
                { 'title': 'Ações', 'value': 'acoes' },
            ],
            dadosAlertas: [],
            loadingPanelModal: false,
            veiculoData: {},
            valueTratativa: '',
            tratativas: [],
            tratativa: {},
            textoDescritivoTratativa: '',
            textoDataHoraTratativa: '',
            indexAtual: null,

            dadosMotorista: {
                nome: 'Carregando...',
                telefone: 'Carregando...',
                empresa: 'Carregando...'
            },

            tags: [],
        }
    },

    methods: {
        ...mapGetters(['getMaster', 'getEditar', 'getCadastrar']),

        salvarTratativa() {
            this.salvarTratativaColapse(this.tratativa)
        },

        modalOpen() {
            this.fecharTratativaEmapa()
            this.limpaTratativas()
        },

        /**
         * @description Limpa algumas variáveis do modal.
         * @author  Rafael
         */
        resetModal() {
            this.tratativa = {}
            this.valueTratativa = ''
            this.tratativas = []
            this.dadosAlertas = []
        },

        limpaTratativas() {
            this.tratativa = {}
        },

        fecharTratativaEmapa() {
            this.mostrarMapa = false
            this.mostrarTratativas = false
        },

        fecharModal() {
            this.$bvModal.hide('modalAlertasVeiculo')
            this.fecharTratativaEmapa()
        },

        modalSolicitarManutencao() {
            this.$bvModal.show('modal-solicitacao-manutencao');
        },

        /**
         * @description Faz validacao da latitude e longitude do alerta.
         * @param { string } locationString
         * @return {string}
         * @author  Rafael
         */
        getLatLng(locationString) {
            const locationRegex = /^([-]?\d{1,2}\.\d+),?\s*([-]?\d{1,3}\.\d+)$/;
            const match = locationString.match(locationRegex);
            if (match) {
                const latitude = parseFloat(match[1]);
                const longitude = parseFloat(match[2]);
                return { lat: latitude, lng: longitude };
            } else {
                return null;
            }
        },

        /**
         * @description Mostra uma posicao no mapa, conforme latlng do alerta.
         * @param { object } value alerta
         * @param { string? } value.latlng
         * @listens clickIconeLocalizar
         * @author  Rafael
         */
        clickIconeLocalizar(value) {
            const locationString = value.latlng
            const latLng = this.getLatLng(locationString ?? '')
            if (latLng) {
                this.latitudeMapa = latLng.lat
                this.longitudeMapa = latLng.lng
                this.mostrarTratativas = false
                this.mostrarMapa = true
                this.disablePortal = true
                this.$nextTick(() => {
                    this.disablePortal = false
                })
            } else {
                this.toastShow(
                    'A latitude e longitude deste alerta é inválida!',
                    'danger',
                    'Localização'
                )
            }
        },

        /**
         * @description Evento de click no ícone de tratativa.
         * @param { object } value alerta
         * @param { int } index
         * @listens clickIconeTratativas
         * @author  Rafael
         */
        clickIconeTratativas(alerta, index) {
            this.tratativa = alerta
            this.indexAtual = index
            this.mostrarTratativas = true
            this.mostrarMapa = false
            this.$refs.refTabEagle.$refs.refTabBootstrap.$data.currentTab = 0
            this.$root.$emit('bv::toggle::collapse', `${alerta.value}_tacodigo`)
            this.buscaTratativas()
        },

        defineIconeAlerta(tipo) {
            return IconManager.getIconeAlerta(tipo)
        },

        /**
         * @description Função auxiliar para alterar as informações de alertas do veículo
         * no front.
         * @param { int } tipoAlerta
         * @author  Rafael
         */
        removerAlertas(tipoAlerta) {
            let at = this.dadosAlertas[tipoAlerta]
            this.veiculoData.contador -= at.contagem
            let tipo = this.tipos.filter(e => e.value == tipoAlerta)
            if (tipo && tipo.length) {
                tipo = tipo[0]
                this.veiculoData.totais[tipo.cor] -= at.contagem
            }
            this.veiculo = this.veiculoData
            at.dados = []
            at.contagem = 0
            this.dadosAlertas[tipoAlerta] = at
            this.$forceUpdate()
        },

        clickLerAlertaAtual(alcodigo) {
            // this.fecharTratativaEmapa()
            this.clickLerAlerta(alcodigo)
        },

        showCollapseAlerta(tipo) {
            this.clickLerTodosAlertas(tipo, 'M')
        },

        /**
         * @description Define o texto do tipo de alerta.
         * @param { object } value alerta
         * @param { int? } value.tipo
         * @return {string}
         * @author  Rafael
         */
        defineTextoDescritivoTratativa(value) {
            let texto = ''
            if (this.tipos.length && value?.tipo) {
                texto = this.tipos.find(e => e.value == value.tipo).description ?? ''
            }
            return `Descreva a tratativa do alerta: </br>${texto}`
        },

        /**
         * @description Define o texto de data e hora do alerta.
         * @param { object } value alerta
         * @param { string? } value.data
         * @param { string? } value.hora
         * @return {string}
         * @author  Rafael
         */
        defineTextoDataHoraTratativa(value) {
            let texto = ''
            if (value) {
                texto = value?.data + ' ' + value?.hora
            }
            return `Data: ${texto}`
        },

        toastShow(msg, type, title = '') {
            this.$bvToast.toast(`${msg}`, {
                autoHideDelay: 2500,
                variant: type,
                title: title
            })
        },

        requestError() {
            this.toastShow('Não foi possível concluir a operação!', 'danger', 'Erro')
        },

        requestSuccess(msg = '') {
            if (!msg) msg = 'Operação concluída com sucesso!'
            this.toastShow(msg, 'success', 'Sucesso')
        },

        /**
         * @description Marca todos os registros de alertas como Lido ou Mostrado no 
         * banco, pertencentes ao [veiculo]. 
         * Verifica e altera os dados no front.
         * @param { number } tipoAlerta
         * @param { string } statusAlerta 'M'|'L'
         * @listens clickLerTodosAlertas
         * @author  Rafael
         */
        async clickLerTodosAlertas(tipoAlerta, statusAlerta = 'L') {
            this.loadingPanelModal = true
            let url = `${this.url}ler/todas/tratativas`
            let params = { tipo: tipoAlerta, status: statusAlerta, 'veiculo': this.veiculo }
            await new HttpRequest().Post(url, params)
                .then(({ data, status, code }) => {
                    if (status) {
                        if (statusAlerta == 'L') {
                            this.removerAlertas(tipoAlerta)
                            this.consultaAlerta()
                            this.requestSuccess('Alertas lidos com sucesso!')
                        }
                    } else {
                        this.requestError()
                    }
                })
                .catch((e) => {
                    this.requestError()
                })
                .finally(() => {
                    this.loadingPanelModal = false
                })
        },

        /**
         * @description Marca o registro de alerta como Lido ou Mostrado no banco. 
         * Verifica e altera os dados do registro alterado no front.
         * @param { object } alerta
         * @listens clickLerAlerta
         * @author  Rafael
         */
        async clickLerAlerta(alcodigo) {
            this.loadingPanelModal = true
            let url = `${this.url}ler/uma/tratativa`
            let params = { 'dados': alcodigo }
            await new HttpRequest().Post(url, params)
                .then(async ({ data, status, code }) => {
                    if (status) {
                        this.veiculoData.contador -= 1
                        this.requestSuccess('Alerta lido com sucesso!')
                    } else {
                        this.requestError()
                    }
                })
                .catch(() => {
                    this.requestError()
                })
                .finally(() => {
                    this.loadingPanelModal = false
                })
        },

        /**
         * @description Faz a consulta para buscar os alertas do veículo.
         * @param { object } value alerta
         * @author Rafael
         */
        async consultaAlerta(value) {
            this.loadingPanelModal = true
            let url = `${this.url}busca/alerta/veiculo`
            let params = { 'dados': { ...value, ...this.veiculoData } }
            await new HttpRequest().Post(url, params)
                .then(({ data, status, code }) => {
                    if (status) {
                        this.dadosAlertas = data.alertas
                    } else {
                        conectionError()
                    }
                })
                .catch(e => {
                    conectionError()
                })
                .finally(() => {
                    this.loadingPanelModal = false
                })
        },

        /**
         * @description Faz a consulta para buscar tratativas do alerta.
         * @author Rafael
         */
        async buscaTratativas() {
            let url = `${this.url}buscar/tratativa`
            let params = { 'dados': this.tratativa }
            this.tratativas = []
            await new HttpRequest().Post(url, params)
                .then(({ data, status, code }) => {
                    if (status) {
                        this.tratativas = data
                    } else {
                        conectionError()
                    }
                })
                .catch(e => {
                    conectionError()
                })
        },

        /**
         * @description Salva uma tratativa no banco.
         * @param { object } dados tratativa
         * @author Rafael
         */
        async salvarTratativaColapse(dados) {
            this.loadingPanelModal = true
            let url = `${this.url}salvar/uma/tratativa`
            let params = { 'dados': { ...dados, descricao: this.valueTratativa } }
            await new HttpRequest().Post(url, params)
                .then(async ({ data, status, code }) => {
                    if (status) {
                        this.tratativas?.push({ taldescritivo: this.valueTratativa })
                        this.valueTratativa = ''
                    } else {
                        conectionError()
                    }
                })
                .catch(e => {
                    conectionError()
                })
                .finally(() => {
                    this.loadingPanelModal = false
                })
        },


        /**
         * Função para buscar as informações do motorista do veículo selecionado.
         * @param vecodigo 
         * @param veproprietario 
         * @author Marco Duz
         */
        async getDadosMotorista(vecodigo, veproprietario) {
            const obj = {
                vecodigo: vecodigo,
                veproprietario: veproprietario
            }
            await new HttpRequest()
                .Get(this.url + 'consultar/dados/motorista', obj)
                .then(({ data }) => {
                    this.dadosMotorista = {
                        nome: data.dadosMotorista.mtnome ?? 'Sem informação',
                        telefone: data.dadosMotorista.mttelefone ?? 'Sem informação',
                        empresa: data.empresa.clfantasia ?? 'Sem informação',
                    }
                })
        },

        async getTags(placa) {
            this.tags = []
            await new HttpRequest()
                .Get(this.url + 'consultar/tags/titulo', { 'placa': placa })
                .then(({ data }) => {
                    this.tags = data
                })
        },

    },

    computed: {
        defineTitleModal() {
            let placa = this.veiculoData.veplaca ?? ''
            let prefixo = this.veiculoData.veprefixo ?? placa
            let divisor = placa && prefixo ? '|' : ''
            return `${placa} ${divisor} ${prefixo}`
        },

        defineContadorModal() {
            return this.veiculoData?.contador ?? ''
        },

        temAlertas() {
            return this.veiculoData?.contador > 0
        },
    },

    watch: {
        veiculo(value) {
            if (this.veiculoData?.vecodigo != value?.vecodigo) {
                this.resetModal()
            }
            this.veiculoData = value
            this.consultaAlerta(value)
            this.getDadosMotorista(value.vecodigo, value.veproprietario)
            this.getTags(value.veplaca)
        },

        tratativa(value) {
            this.textoDescritivoTratativa = this.defineTextoDescritivoTratativa(value)
            this.textoDataHoraTratativa = this.defineTextoDataHoraTratativa(value)
        },
    }
})
</script>

<style lang="scss">
.voltarAmarelo {
    background-color: #f4b610 !important;
    font-weight: bold !important;
}
#modalAlertasVeiculo {
    .div-table {
        max-height: 189px !important;
        border: 1px solid #428bca;

        tbody td {
            max-width: 0 !important;
            word-wrap: break-word !important;
        }
    }

    .thTratativas {
        padding: 2px 2px 2px 5px !important;

        .divChild {
            display: inline-flex;
            width: 100%;
            justify-content: space-between;

            div {
                align-self: center;
            }

            button {
                margin: 0;
            }
        }
    }

    .minH {
        min-height: 100px !important;
    }

    .tituloModalHeaderAlertas {
        display: inline-flex;
        align-self: center;
        margin-top: 3px !important;

        .veiculo-alerta {
            align-self: center;
            padding-right: 10px;
        }

        .numero-alerta {
            text-align: center;
            color: white;
            min-width: 22px;
            min-height: 26px;
            padding: 6px 6px 2px 6px;
            margin-left: 5px;
        }

        .alerta-vermelho {
            background-color: red;
        }

        .alerta-amarelo {
            background-color: #d8b606;
        }

        .alerta-cinza {
            background-color: grey;
        }
    }

    .solicitarManutencao {
        text-align: end;
        padding-right: 13px;
    }

    .alinhar-botao-x {
        z-index: 2000;
        right: 0;
        top: -1px;
        position: absolute;
    }

    .div-tratativa {
        contain: content;
        z-index: 2;
        margin-bottom: auto;
    }

    .index-gg {
        width: 100%;
        max-height: 133px;
        border-color: #428bca;
    }

    .titulo-tratativa {
        background-color: #f5f3ee;
        padding: 5px;
        margin-top: 10px;
    }

    .borda-desc-tratativa {
        margin-top: 10px;
        border: 10px solid rgb(245, 239, 239);
        // border-top: 15px solid rgb(245, 239, 239);
        // border-bottom: 15px solid rgb(245, 239, 239);
        background-color: #fff;

        padding-top: 5px;
    }

    .position-relative-tratativa {
        position: relative;
        // margin-bottom: 5px;
    }

    .alinha-botao-salvar-tratativa {
        right: 0px;
        top: 0px;
        position: absolute;
    }

    .alinhar-texto {
        margin: 20px;
    }

    .arruma-caixa {
        text-align: left !important;
    }

    .arrumar-botao-data {
        position: absolute;
        left: 90%;
    }

    #mapaSimples {
        .vue2leaflet-map {
            max-height: 220px !important;
        }
    }
}
</style>
