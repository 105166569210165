<template>
	<panelEagle id="listagemRegioes" :loading="carregandoRegioes">
		<div class="col-sm-12 row divDontPrint">
			<div class="col-sm-6 nopadding">
				<titulo titulo="Regiões" :icon="mdiEarth" />
			</div>

			<div class="col-sm-6 nopadding">
				<botao @novo="novaRegiao" />
			</div>
		</div>

		<div class="col-sm-12">
			<hr />
		</div>

		<div class="col-sm-12 row nopadding">
			<div class="col-sm-4 nopadding frente">
				<selectAll nameForRadio="seletorEmpresa" :hasSelectAll="true" :isMultiple="true" :selected="cliente"
					:labels="[{ description: 'Empresas' }]" :optionsArray="optSelectEmpresa"
					@changeSelect="mudaEmpresa" />
			</div>

			<div class="col-sm-4 nopadding frente">
				<selectAll nameForRadio="seletorRegiao" :hasSelectAll="true" :isMultiple="true"
					:selected="selectedRegiao" :labels="[{ description: 'Regiões' }]"
					:disabled="regioesListagem.length <= 0" :optionsArray="regioesListagem"
					@changeSelect="changeRegiao" />
			</div>
			<div class="col-sm-4 nopadding frente">
				<selectAll :selected="selectedCategoriaRegiao" ref="selectCategoria"
					:disabled="!optCategoriaRegiao.length > 0" @changeSelect="mudaCategoriaRegiao"
					:optionsArray="optCategoriaRegiao" :isMultiple="true" :labels="[{ description: 'Categoria' }]"
					nameForRadio="selectCategoria" :loading="loadingCategoriaRegiao" />
			</div>
		</div>

		<div class="col-sm-12">
			<hr />
		</div>

		<div class="col-sm-12">
			<div class="col-sm-12 nopadding traz">
				<mapa height="700" id="listagemRegMapa" ref="map">
					<span v-if="empresaSelecionada.length > 0">
						<span v-for="(local, index) in locais" v-bind:key="index">
							<markers id="matriz" icon="img/matriz.png" :latitude="local.lat" :longitude="local.long">
								<PopupMarker>
									<div class="col-sm-12">
										<div class="d-flex justify-content-between">
											<span class="font-bold ml-2"> {{ local.nome }}
											</span>
										</div>
									</div>
								</PopupMarker>
							</markers>
						</span>
					</span>

					<poligono v-for="(poli, index) in regioes" :key="index" :poligono="poli">
						<popUpRegiao :regiao="poli" @clickExcluir="excluirRegiao" @clickEditar="editarRegiao">
						</popUpRegiao>
					</poligono>
				</mapa>
			</div>
		</div>

	</panelEagle>
</template>

<script>
import Vue from "vue";
import { mdiEarth } from "@mdi/js";
import { EmpresasService } from "@/Services/auth/Empresas.service";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { conectionError } from "@/Services/Helpers/swellHeper";
import { mapGetters } from "vuex";
import { FiltrosService } from "@/Services/filtros/filtros.Service";

export default Vue.extend({
	name: "ListagemRegioes",

	components: {
		botao: require("@/components/Atom/Buttons/BasicButtonsIN").default,
		mapa: require("@/components/Atom/Mapa/MapaSimples").default,
		popUpRegiao: require("@/components/Atom/Popup/PopupRegiao").default,
		poligono: require("@/components/Atom/Mapa/Poligonos").default,
		panelEagle: require("@/components/Atom/Panel/PanelEagle.vue").default,
		titulo: require("@/components/Atom/Header/Titulo").default,
		selectAll: require("@/components/Atom/Select/SelectAll").default,
		markers: require("@/components/Atom/Mapa/MarkerPoint").default,
		'PopupMarker': require('@/components/Atom/Mapa/PopupMarker').default,
	},

	data() {
		return {
			mdiEarth: mdiEarth,
			arrayInformacoesRastro: "",
			desenhando: true,
			optSelectEmpresa: [],
			empresaSelecionada: [],
			rota: "/telemetria/cadastros/regioes/",
			regioes: [],
			regioesInicial: [],
			selectedRegiao: [],
			regioesListagem: [],
			regioesSelecionadas: [],
			cliente: [],
			carregandoRegioes: false,
			locais: [],

			optCategoriaRegiao: [],
			categoriaRegiaoSelecionada: [],
			selectedCategoriaRegiao: [],
			loadingCategoriaRegiao: false,
		};
	},

	methods: {
		...mapGetters(["getMaster"]),

		novaRegiao() {
			this.$router.push({ name: "cadastroRegioes" });
		},

		editarRegiao(codigo) {
			this.$router.push({ name: "cadastroRegioes", params: { id: codigo } });
		},

		/**
		 * Exclui uma região com base no código informado.
		 *
		 * @author Desconhecido
		 * @param {number|string} codigo - Código da região a ser excluída.
		 */
		excluirRegiao(codigo) {
			new HttpRequest()
				.Post(this.rota + "deletar", { codigo: codigo })
				.then((data) => {
					if (data.data.status) {
						this.buscaRegioes();
						this.selectedRegiao = [];
					} else {
						conectionError();
					}
				});
		},

		/**
	 * Altera a empresa selecionada e recarrega as regiões associadas.
	 * acrescentado a parte para recarregar as categorias associadas -> Marco Duz
	 *
	 * @author Desconhecido
	 * @param {Array} array - Lista de empresas selecionadas.
	 */
		mudaEmpresa(array) {
			this.regioes = [];
			this.empresaSelecionada = array;
			this.selectedRegiao = [];
			this.regioesSelecionadas = [];
			this.regioesInicial = [];
			this.regioesListagem = [];
			this.local = [];
			this.categoriaRegiaoSelecionada = [];
			this.selectedCategoriaRegiao = [];
			this.optCategoriaRegiao = [];

			if (array.length > 0) {
				this.centralizaCliente(array);
				this.buscaRegioes();
				this.getOptCategoriaRegiao(array);
			} else {
				this.carregandoRegioes = false;
			}
		},

		/**
		 * Busca e carrega as regiões associadas à empresa selecionada.
		 *
		 * @author Desconhecido
		 */
		buscaRegioes() {
			this.regioes = [];
			this.carregandoRegioes = true;
			var funcao = "listar/regioes";
			var obj = { cliente: this.empresaSelecionada };

			new HttpRequest().Post(this.rota + funcao, obj).then((data) => {
				this.regioesListagem = [];

				data.data.regioes.map((regiao) => {
					const objRegiao = {
						value: regiao.recodigo,
						description: regiao.redescricao,
						rearea: regiao.rearea,
						recodigo: regiao.recodigo,
						recor: regiao.recor,
						redescricao: regiao.redescricao,
						regioes_coordenadas: regiao.regioes_coordenadas,
						rerisco: regiao.rerisco,
						revelocidade: regiao.revelocidade,
						regiao_categorias: regiao.regiao_categoria,
					};

					this.regioesListagem.push(objRegiao);
				})

				this.regioesInicial = data.data.regioes;
				this.arrumaRegioes(data.data.regioes);
				this.carregandoRegioes = false;
			});
		},

		arrumaRegioes(regs) {
			var a = [];
			this.regioes = [];

			a = regs.map(
				(e) =>
					new Object({
						latlngs: e.regioes_coordenadas.map((f) => [
							f.rclatitude,
							f.rclongitude,
						]),
						color: e.recor,
						descricao: e.redescricao,
						codigo: e.recodigo,
						velocidade: e.revelocidade,
						risco: e.rerisco,
						regioes_categorias: e.regioes_categorias
					})
			);

			this.regioes = a;
		},

		/**
	 * Centraliza o mapa na localização do cliente selecionado.
	 * 
	 * Envia uma requisição para obter a localização do(s) cliente(s) e,
	 * se os dados forem retornados corretamente, ajusta o mapa para 
	 * centralizá-lo(s).
	 * 
	 * @author Desconhecido
	 * @param {Array|string} clientes - Identificação do(s) cliente(s) a serem centralizados no mapa.
	 */
		centralizaCliente(clientes) {
			var cam = this.rota + "localCliente";

			new HttpRequest().Post(cam, { clientes: clientes }).then((data) => {
				if (
					data.status &&
					data.code == 200 &&
					data.data.centro.length > 0
				) {
					const locaisConsulta = data.data.centro;
					const locaisMostrarMapa = [];

					locaisConsulta.map((local) => {
						const objLocal = {
							lat: local.cllatitude,
							long: local.cllongitude,
							nome: local.clnome,
						};
						locaisMostrarMapa.push(objLocal);
					});

					this.locais = locaisMostrarMapa;

					this.$refs.map.flyTo([
						locaisConsulta[0].cllatitude,
						locaisConsulta[0].cllongitude,
					]);
				}
			});
		},

		/**
		 * Atualiza as regiões selecionadas 
		 * e ajusta a lista de regiões exibidas. - foi removida dessa função e criada uma nova específica filtroRegiao() -> Marco Duz
		 *
		 * @author Yuri 🇧🇷
		 * @param {Array} regiao - Lista de códigos das regiões selecionadas.
		 */
		changeRegiao(regiao) {
			this.regioesSelecionadas = regiao;
			this.filtrar()
		},

		/**
		 * Atualiza as categorias selecionadas e chama a função para ajustar a listagem
		 * @param {Array} categorias  - lista de códigos das categorias
		 */
		mudaCategoriaRegiao(categorias) {
			this.categoriaRegiaoSelecionada = categorias
			this.filtrar()
		},

		/**
		 * Lista todas as regiões disponíveis, resetando as regiões selecionadas
		 * e organizando-as a partir das regiões iniciais.
		 *
		 * @author Yuri 🇧🇷
		 */
		listarTodasRegioes() {
			this.regioesSelecionadas = [];
			this.arrumaRegioes(this.regioesInicial);
		},

		/**
		 * Método para retornar as categorias do banco filtrado de acordo com a empresa
		 * 	Utiliza o FiltrosService
		 * @param array cliente
		 * @author Marco Duz
		 */
		getOptCategoriaRegiao(cliente) {
			this.categoriaRegiaoSelecionada = []
			this.loadingCategoriaRegiao = true
			new FiltrosService()
				.dados_filtros(cliente, ['CL'])
				.then((data) => {
					if (data) {
						this.optCategoriaRegiao = data.CL ?? [];
					}
				})
				.catch(() => conectionError())
				.finally(() => (this.loadingCategoriaRegiao = false));
		},

		/**
		 * Separa cada caso de seleção, (região selecionada ou/e categoria selecionada, ou nenhuma)
		 * nenhum selecionado -> llistar todas as regiões
		 * apenas região selecionado -> filtroRegiao()
		 * outra combinação -> filtroCategoria, que já utiliza das regioes selecionadas 
		 * @author Marco Duz
		 */
		filtrar() {

			if (this.regioesSelecionadas.length <= 0 && this.categoriaRegiaoSelecionada <= 0) {
				this.listarTodasRegioes();
				return
			} else if (this.categoriaRegiaoSelecionada.length <= 0) {
				this.arrumaRegioes(this.filtroRegiao());
			} else {
				this.arrumaRegioes(this.filtroCategoria());
			}
		},

		/**
		 * Atualiza  listagem de acordo com a categoria e a região selecionada
		 * Caso nenhuma região tenha sido selecionada a variável regSel é populada com todas as regiões da respectiva empresa
		 * assim filtrando apenas pela categoria
		 * @author Marco Duz
		 */
		filtroCategoria() {
			let regioesFiltradas = [];

			const regSel = [...this.regioesSelecionadas]
			if (regSel.length <= 0) {
				this.regioesListagem.forEach((list) => {
					regSel.push(list['value'])
				});
			}

			this.regioesInicial.forEach((regiaoInicial) => {
				if (regSel.includes(regiaoInicial.recodigo)) {
					if (!(Object.keys(regiaoInicial.regioes_categorias).length === 0)) {
						regiaoInicial.regioes_categorias.forEach(cat => {
							if (this.categoriaRegiaoSelecionada.includes(cat.rccodigocategoria)) {
								if (!regioesFiltradas.some((r) => r.recodigo === regiaoInicial.recodigo)) {
									regioesFiltradas.push(regiaoInicial);
								}
							}
						});
					}
				}

			});

			return regioesFiltradas
		},

		/**
		 * Atualiza a listagem de acordo com a região selecionada.
		 * código retirado da função changeRegiao
		 * @author Yuri 🇧🇷 e Marco Duz
		 */
		filtroRegiao() {
			let regioesFiltradas = [];

			this.regioesInicial.forEach((regiaoInicial) => {
				if (this.regioesSelecionadas.includes(regiaoInicial.recodigo)) {
					if (!regioesFiltradas.some((r) => r.recodigo === regiaoInicial.recodigo)) {
						regioesFiltradas.push(regiaoInicial);
					}
				}
			});

			return regioesFiltradas
		},
	},

	mounted() {
		this.optSelectEmpresa = new EmpresasService().Get();
		if (!this.getMaster()) {
			this.cliente = this.optSelectEmpresa;
		}
		this.centralizaCliente(this.$store.getters.getUser.idUser);
	},
});
</script>

<style lang="scss" scoped>
.frente {
	position: relative;
	z-index: 1;
}

.traz {
	position: relative;
	z-index: 0;
}
</style>
