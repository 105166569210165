<template>
  <PanelEagle id="pedagios" :loading="loadingPanel">
    <div class="col-sm-12 row nopadding" style="color: black">
      <div class="col-sm-12 row nopadding">
        <div class="col-sm-6" style="color:black">
          <tituloPage :icon="mdiBoomGateUp" titulo="Gastos com Pedágio" />
        </div>

        <div class="col-sm-6">
          <basicButtonsRelatoriosEIG :disabled="$v.$invalid || loadingPanel" :loading="loadingButton"
            @exportarRelatorio="exportarRelatorio" @gerarRelatorio="gerarRelatorio" :exportaTickets="false">
          </basicButtonsRelatoriosEIG>
        </div>

        <!-- Inputs -->
        <slideUpAndDown>
          <slot slot="filters">
            <div class="col-sm-12">
              <hr />
            </div>

            <div class="col-sm-12 row nopadding divDontPrint">
              <!-- Input de Intervalo -->
              <div class="col-sm-3 nopadding">
                <InputRangeWithLimit name="selectData" titulo="Intervalo" :isObrigatorio="true"
                  @changeInput="changeData" />
              </div>

              <!-- Input de Empresas -->
              <div class="col-sm-3 nopadding">
                <SelectAll ref="selectEmpresas" nameForRadio="seletorEmpresas" :labels="[{
                  indexDFH: 'E',
                  description: 'Empresas*'
                }]" :optionsArray="optionsSelectEmpresas" firstSelected="E" :hasSelectAll="true" :isMultiple="true"
                  @changeSelect="changeEmpresas" />
              </div>

              <!-- Input de Veículos -->
              <div class="col-sm-3 nopadding">
                <selectAll :selected="selectedVeiculos" ref="srefSelectAllVeiculos" @changeSelect="changeSelectVeiculos"
                  :isMultiple="true" :hasSelectAll="true" :optionsArray="veiculosDoBanco" :loading="loadingVeiculos"
                  :disabled="loadingVeiculos || veiculosDoBanco.length <= 0" nameForRadio="" :labels="[{
                    indexDFH: 'VEL',
                    description: 'Veículos'
                  }]" />
              </div>

              <!-- Input de Pontos de Pedágio -->
              <div class="col-sm-3 nopadding">
                <selectAll nameForRadio="" :selected="selectedPontosPedagio" ref="srefSelectAllPontosPedagio"
                  @changeSelect="changePontosPedagio" :isMultiple="true" :hasSelectAll="true"
                  :optionsArray="pontosPedagio" :loading="loadingPontosPedagio"
                  :disabled="loadingPontosPedagio || pontosPedagio.length <= 0" :labels="[{
                    indexDFH: 'PP',
                    description: 'Pontos de Pedágio'
                  }]" />
              </div>

              <!-- Input de Região -->
              <div class="col-sm-3 nopadding mt-3 mb-1">
                <selectAll nameForRadio="" :selected="selectedRegiao" ref="srefSelectAllRegiao"
                  @changeSelect="changeRegioes" :isMultiple="true" :hasSelectAll="true" :optionsArray="regioes"
                  :loading="loadingRegiao" :disabled="loadingRegiao || regioes.length <= 0" :labels="[{
                    indexDFH: 'RE',
                    description: 'Região'
                  }]" />
              </div>

              <!-- Forma de Visualizar Relatório -->
              <div class="col-sm-3 nopadding mt-3 mb-1 ml-4">
                <p class="nopadding" style="text-align: start;">Forma de Visualização</p>

                <div class="d-flex justify-content-start nopadding"
                  style="margin-top: -5px; gap: 40px; margin-left: 10px;">
                  <!-- Radio de Veículos -->
                  <div class="d-flex justify-content-start">
                    <input style="cursor: pointer;" class="form-check-input" type="radio" name="veiculoGrupoTag"
                      id="radioVeiculos" v-model="selectedRadio" :value="1" @change="selecionaTipo(1)">
                    <label class="select-none" for="radioVeiculo" style="cursor: pointer">Veículos</label>
                  </div>

                  <!-- Radio de Grupos -->
                  <div class="d-flex justify-content-start">
                    <input style="cursor: pointer;" class="form-check-input" type="radio" name="veiculoGrupoTag"
                      id="radioPracas" v-model="selectedRadio" :value="2" @change="selecionaTipo(2)">
                    <label class="select-none" for="radioGrupo" style="cursor: pointer">Praças</label>
                  </div>
                </div>
              </div>

            </div>
          </slot>
        </slideUpAndDown>

        <div class="col-sm-12 col-md-12">
          <hr>
        </div>

        <!-- Indicadores -->
        <div v-if="relatorioCustoPedagio.length > 0" class="d-flex justify-content-center mt-1"
          style="width: 100%; margin: auto; gap: 10px">
          <div class="d-flex justify-content-center"
            style="background-color: #2D49AF; border-radius: 10px; padding: 7px; min-width: 300px; max-width: 300px; width: 300px; gap: 50px; align-items: center; height: 110px;">
            <div>
              <baseIcon class="text-white opacity-50" size="75" :icon="mdiMapMarkerRadius" />
            </div>
            <div>
              <p class="text-white text-2xl font-bold nopadding text-start text-truncate"
                style="margin-bottom: -15px; max-width: 150px;" v-b-popover.hover.top="totalPracas">
                {{ totalPracas
                }}</p>
              <p class="text-white text-lg font-mediun nopadding text-start">Praças</p>
            </div>
          </div>

          <div class="d-flex justify-content-center"
            style="background-color: #E1641E; border-radius: 10px; padding: 7px; min-width: 300px; max-width: 300px; width: 300px; gap: 45px; align-items: center; height: 110px;">
            <div>
              <baseIcon class="text-white opacity-50" size="75" :icon="mdiTruck" />
            </div>
            <div>
              <p class="text-white text-2xl font-bold nopadding text-start text-truncate"
                style="margin-bottom: -15px; max-width: 150px;" v-b-popover.hover.top="totalVeiculos">
                {{ totalVeiculos }}</p>
              <p class="text-white text-lg font-mediun nopadding text-start">Veículos</p>
            </div>
          </div>

          <div class="d-flex justify-content-center"
            style="background-color: #1D6623; border-radius: 10px; padding: 7px; min-width: 300px; max-width: 300px; width: 300px; gap: 35px; align-items: center; height: 110px;">
            <div>
              <baseIcon class="text-white opacity-50" size="75" :icon="mdiAccountCash" />
            </div>
            <div>
              <p class="text-white text-2xl font-bold nopadding text-start text-truncate" style="margin-bottom: -15px;"
                v-b-popover.hover.top="totalGasto">
                {{ totalGasto }}</p>
              <p class="text-white text-lg font-mediun nopadding text-start">Gasto total</p>
            </div>
          </div>
        </div>

        <!-- Tabelas -->
        <span v-if="tipoSelecionado == 1" class="col-sm-12 mt-3">
          <TabelaVeiculos :relatorioCustoPedagio="relatorioCustoPedagio" :statusbar="statusbar" />
        </span>
        <span v-if="tipoSelecionado == 2" class="col-sm-12 mt-3">
          <TabelaPracas :relatorioCustoPedagio="relatorioCustoPedagio" :statusbar="statusbar" />
        </span>
      </div>
    </div>

    <div class="col-lg-2 campoObri">
      <span class="text-truncate">(*) Campos obrigatórios</span>
    </div>

    <div class="col-sm-12 col-md-12">
      <hr>
    </div>
  </PanelEagle>
</template>

<script lang="js">
import {
  mdiCashMarker,
  mdiMonitorDashboard,
  mdiEyePlusOutline,
  mdiThumbUpOutline,
  mdiMapMarkerRadius,
  mdiTruck,
  mdiAccountCash,
  mdiBoomGateUp
} from '@mdi/js'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import slideUpAndDown from '@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue';
import InputRangeWithLimit from '@/components/Atom/Datas/InputRangeWithLimit.vue';
import SelectAll from '@/components/Atom/Select/SelectAll.vue';
import { EmpresasService } from '@/Services/auth/Empresas.service';
import PanelEagle from '@/components/Atom/Panel/PanelEagle.vue';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { required } from 'vuelidate/lib/validators'
import { FiltrosService } from '../../../Services/filtros/filtros.Service'

export default Vue.extend({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "pedagios",

  validations: {
    dataIntervalo: { required },
    empresaSelecionada: { required },
  },

  components: {
    'tituloPage': require('@/components/Atom/Header/Titulo').default,
    slideUpAndDown,
    InputRangeWithLimit,
    SelectAll,
    PanelEagle,
    'basicButtonsRelatoriosEIG': require(
      '@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
    baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
    TabelaPracas: require('@/components/Atom/SpecificComponents/Pedagios/TabelaPracas.vue').default,
    TabelaVeiculos: require('@/components/Atom/SpecificComponents/Pedagios/TabelaVeiculos.vue').default,
  },

  data() {
    return {
      urlBase: '/roteirizador/relatorios/pedagio',
      mdiCashMarker: mdiCashMarker,
      mdiMonitorDashboard: mdiMonitorDashboard,
      mdiEyePlusOutline: mdiEyePlusOutline,
      mdiThumbUpOutline: mdiThumbUpOutline,
      mdiMapMarkerRadius: mdiMapMarkerRadius,
      mdiAccountCash: mdiAccountCash,
      mdiBoomGateUp: mdiBoomGateUp,
      mdiTruck: mdiTruck,
      loadingPanel: false,
      dataIntervalo: '',
      empresaSelecionada: [],
      optionsSelectEmpresas: new EmpresasService().Get(),
      tipoSelecionado: 1,
      selectedVeiculos: [],
      selectedPontosPedagio: [],
      selectedRegiao: [],
      valueVeiculos: [],
      veiculosDoBanco: [],
      pontosPedagio: [],
      regioes: [],
      loadingVeiculos: false,
      loadingPontosPedagio: false,
      loadingRegiao: false,
      relatorioCustoPedagio: [],
      statusbar: 'info',
      iconFlecha: "chevron-right",
      relatorioReturn: [],
      mostrarSegundaTabela: false,
      loadingButton: [false, false, false],
      pontosPedagioSelecionados: [],
      regioesSelecionadas: [],
      selectedRadio: 1,
      totalPracas: 1,
      totalVeiculos: 2,
      totalGasto: '0',
      totalizadores: []
    }
  },

  methods: {
    ...mapGetters(['getMaster']),

    /**
    * Pega o valor da data do input de Data e salva na variável 
    this.dataIntervalo e limpa as demais informações do filtro e mapa
    * @param Data String
    * @author Yuri 🇧🇷
    */
    changeData(data) {
      this.dataIntervalo = data;
      this.limpaTabela();
      this.selectedVeiculos = [];
      this.selectedPontosPedagio = [];
      this.selectedRegiao = [];
    },

    /**
    * @description Atualiza o estado da aplicação ao trocar a empresa 
    selecionada
    * @param {string} empresa - O identificador da nova empresa selecionada.
    * @author Yuri 🇧🇷
    */
    changeEmpresas(empresa) {
      this.empresaSelecionada = empresa;
      this.selectedVeiculos = [];
      this.selectedPontosPedagio = [];
      this.selectedRegiao = [];

      this.limpaTabela();
      this.dadosFiltros();
    },

    /**
    * @description Adiciona/Remove o veículo selecionado no input veículos.
    * @param {string} veiculo - Veículo selecionado no input.
    * @author Yuri 🇧🇷
    */
    changeSelectVeiculos(veiculo) {
      this.valueVeiculos = veiculo;
      this.limpaTabela();
    },

    /**
    * @description Adiciona/Remove o ponto do pedágio selecionado no input ponto do pedágio.
    * @param {string} ponto - Ponto selecionado no input.
    * @author Yuri 🇧🇷
    */
    changePontosPedagio(ponto) {
      this.pontosPedagioSelecionados = ponto;
    },

    /**
    * @description Adiciona/Remove a região selecionada no input região.
    * @param {string} regiao - Veículo selecionada no input.
    * @author Yuri 🇧🇷
    */
    changeRegioes(regiao) {
      this.regioesSelecionadas = regiao;
    },

    /**
   * @description Método para mostrar o toast na tela
   * @param {string} msg   - mensagem que vai aparecer no corpo do toast
   * @param {string} type  - qual o tipo do toast
   * @param {string} title - título para inserir no cabeçalho
   * @author Vitor Hugo 🐨
   */
    toastShow(title, msg, type) {
      this.$bvToast.toast(msg, {
        autoHideDelay: 2500,
        variant: type,
        title: title
      });
    },

    /**
     * @description Função realiza a consulta do 
     * relatório no back-end e salva as informações na 
     * variável this.relatorioCustoPedagio().
     * @author Yuri 🇧🇷
     */
    async gerarRelatorio() {
      try {
        this.loadingPanel = true;
        const url = this.urlBase + '/gerar'
        const dataInicio = this.formatDate(this.dataIntervalo.split(" - ")[0]) + " 00:00";
        const dataFim = this.formatDate(this.dataIntervalo.split(" - ")[1]) + " 23:59";
        const tipoVisualizacao = this.tipoSelecionado == 1 ? ('V') : ('P');

        const obj = {
          empresas: this.empresaSelecionada,
          veiculos: this.valueVeiculos,
          data_inicio: dataInicio,
          data_fim: dataFim,
          tipo_visualizacao: tipoVisualizacao,
          regioes: this.regioesSelecionadas,
          pontos_pedagio: this.pontosPedagioSelecionados,
        }
        this.relatorioCustoPedagio = [];
        this.statusbar = 'info';

        await new HttpRequest().Post(url, obj).then((data) => {
          const dados = data.data;
          this.totalizadores = data.data.totalizadores;

          this.relatorioCustoPedagio = dados.data;

          this.totalPracas = this.totalizadores.totalPracas;
          this.totalGasto = this.totalizadores.totalGasto;
          this.totalVeiculos = this.totalizadores.totalVeiculos;

          if (data.data.relatorio.length <= 0) {
            this.statusbar = 'error';
          }
        }).catch(() => {
          this.loadingPanel = false;
        }).finally(() => {
          this.loadingPanel = false;
        });
      } catch (error) {
        this.toastShow('Tente novamente mais tarde', 'danger')
        this.loadingPanel = false;
      }
    },

    /**
     * Converte uma data de "DD/MM/YYYY" para "YYYY-MM-DD".
     * 
     * @param {string} dateString - A data no formato "DD/MM/YYYY".
     * @returns {string|null} - A data formatada como "YYYY-MM-DD" ou `null` se o formato for inválido.
     * @author Yuri 🇧🇷
     */
    formatDate(dateString) {
      const [dia, mes, ano] = dateString.split('/');
      return `${ano}-${mes}-${dia}`;
    },

    /**
     * @description Função para limpar os dados da tabela 
     * this.relatorioCustoPedagio.
     * @author Yuri 🇧🇷
     */
    limpaTabela() {
      this.relatorioCustoPedagio = [];
      this.totalPracas = [];
      this.totalGasto = [];
      this.totalVeiculos = [];
    },

    /**
     * @description Função para exportar o relatório, em PDF,
     * Excel ou CSV e ao final abre ou faz o download do mesmo.
     * @param string, com o tipo da exportação que será o relatório.
     * @author Yuri 🇧🇷
     */
    async exportarRelatorio(tipo) {
      try {
        this.loadingButton = [tipo == 'pdf', tipo == 'xls', tipo == 'csv']
        this.loadingPanel = true;
        const url = this.urlBase + '/exportar'
        var root = process.env.VUE_APP_ROOT
        const tipoVisualizacao = this.tipoSelecionado == 1 ? ('V') : ('P');

        const obj = {
          'arrayDados': JSON.stringify(this.relatorioCustoPedagio),
          'tipo': tipo,
          'tipoVisualizacao': tipoVisualizacao,
          'totalizadores': this.totalizadores,
          'intervalo': this.dataIntervalo
        }

        await new HttpRequest().Post(url, obj).then((data) => {
          if (data.status) {
            window.open(root + '/' + data.data.local)
          }
        }).catch(() => {
          this.loadingPanel = false;
          this.loadingButton = [false, false, false, false]
        }).finally(() => {
          this.loadingPanel = false;
          this.loadingButton = [false, false, false, false]
        });
      } catch (error) {
        this.toastShow('Tente novamente mais tarde', 'danger')
        this.loadingPanel = false;
        this.loadingButton = [false, false, false, false]
      }
    },

    /**
     * Busca e armazena os dados de filtro das regiões para a empresa selecionada.
     * 
     * A função define `loadingRegiao` como `true` para indicar o início do carregamento. 
     * Em seguida, faz uma requisição ao serviço `FiltrosService` para obter os dados filtrados 
     * da empresa selecionada, armazenando as regiões retornadas na variável `regioes`. 
     * Após a conclusão, define `loadingRegiao` como `false`.
     * 
     * @async
     * @author Yuri 🇧🇷
     */
    async dadosFiltros() {
      this.loadingRegiao = true;
      this.loadingVeiculos = false;
      var dados = [];

      dados = await new FiltrosService().dados_filtros(this.empresaSelecionada, ['RE', 'PP', 'V'])
      this.regioes = dados['RE'];
      this.pontosPedagio = dados['PP'];

      this.veiculosDoBanco = dados['V'].map((veiculo) => {
        return {
          description: veiculo.description,
          value: veiculo.description.split(' | ')[0]
        }
      });

      this.loadingRegiao = false;
      this.loadingVeiculos = false;
    },

    /**
     * A função seleciona o tipo de visualização
     * 1 = Veículos
     * 2 = Praças
     * 
     * @author Yuri 🇧🇷
    */
    selecionaTipo(tipo) {
      this.tipoSelecionado = tipo;
      this.limpaTabela();
    }
  },
})
</script>

<style lang="css" scoped>
.styleDivCabecalho {
  background-color: #ececec;
  font-weight: bold;
}

.campoObri {
  margin-top: 14px;
  font-size: 14px;
  text-align: left;
  margin-left: 10px;
}

.styleDivMarcador {
  color: white;
  font-weight: bold;
  padding: 5px
}

.styleDivColumEntregas {
  width: 20%;
  color: black;
  font-weight: bold;
}

.styleDivCabecalhoEntregas {
  padding: 10px;
  background-color: #b4b1b5;
}

.styleDivBodyColunsEntregas {
  width: 20%;
  color: black;
  padding: 5px;
  height: 100%;
  width: 100%;
}

.text-white {
  color: white
}

.opacity-50 {
  opacity: 50%;
}

.text-2xl {
  font-size: 35px;
}

.text-3xl {
  font-size: 50px;
}

.text-lg {
  font-size: 15px;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-justify {
  text-align: justify;
}

.font-bold {
  font-weight: bold !important;
}

.font-mediun {
  font-weight: 500;
}
</style>