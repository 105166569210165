<template>
    <div id="painel-rotas">
        <filtros-rota :clienteSelecionado="clienteSelecionado" :loadingEmpresa="requestControl"
            :title="requestControl ? 'Carregando informações' : ''" ref="filtrosRotas" @muda-cliente="mudaCliente"
            @expandir="emiteExpandir" @rastro-rota="compararComRota" @busca-rotas="filtrarRotas"
            @exibir-legenda-painel="exibeLegenda" @filtros-rotas="procuraRotas" @fecha-info="$emit('fecha-info')"
            @exibe-alertas-lidos="emiteAlertasLidos" />
        <el-table v-if="rotasUsuario.length" ref="tableRotas" :row-style="tableRowStyle" :max-height="tamTable"
            @select="selectRow" :border="false" @select-all="enviaTodas" :data="rotasToShow" style="width: 100%;">

            <el-table-column header-align="center" type="selection" :selectable="desativarSelect" align="center"
                width="40" />

            <el-table-column label="Código" :sortable="true" prop="rocodigo" align="center" min-width="100px" />

            <el-table-column label="Placa | Prefixo" align="center" :sortable="true" min-width="180px">
                <template slot-scope="scope">
                    <div style="display: flex; justify-content: center; align-items: center;">
                        <div style="margin-right: 5px;">{{ scope.row.roplaca }}</div>
                        <div style="margin: 0 5px;">|</div>
                        <div style="margin-left: 5px;">{{ scope.row.veprefixo }}</div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Carga" :sortable="true" prop="rocodigoexterno" align="center" min-width="100px">
                <template slot-scope="scope">
                    <div style="display: flex; justify-content: center; align-items: center;">
                        <b-popover :target="'carga-popover-' + scope.$index" triggers="hover" placement="right"
                            custom-class="cargas-popover">
                            <template v-slot:title>Cargas</template>
                            <div>
                                <div
                                    v-if="scope.row.cargaNormal && scope.row.cargaNormal.length > 0 && scope.row.roembarcador == null">
                                    {{ scope.row.cargaNormal.map(carga => carga.cacodigo).join(', ') }}
                                </div>
                                <div
                                    v-else-if="scope.row.cargas && scope.row.cargas.length > 0 && scope.row.roembarcador == null">
                                    {{ scope.row.cargas.map(carga => carga.cacodigo).join(', ') }}
                                </div>
                                <div v-else-if="scope.row.roembarcador != null">
                                    {{ scope.row.rocodigoexterno }}
                                </div>
                            </div>
                        </b-popover>

                        <div v-if="scope.row.cargaNormal && scope.row.cargaNormal.length > 0 && scope.row.roembarcador == null"
                            :id="'carga-popover-' + scope.$index" style="margin-right: 5px;">
                            {{ scope.row.cargaNormal[0].cacodigo }}
                        </div>

                        <div v-else-if="scope.row.cargas && scope.row.cargas.length > 0 && scope.row.roembarcador == null"
                            :id="'carga-popover-' + scope.$index" style="margin-right: 5px;">
                            {{ scope.row.cargas[0].cacodigo }}
                        </div>

                        <div v-else-if="scope.row.roembarcador != null" :id="'carga-popover-' + scope.$index"
                            style="margin-right: 5px;">
                            {{ scope.row.rocodigoexterno }}
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Status" :sortable="true" prop="statusrota" align="center" min-width="130px" />

            <el-table-column label="Data/Hora início" align="center" :sortable="true" min-width="175px">
                <template slot-scope="scope">
                    <div
                        style="display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
                        <div style="margin-bottom: 5px;">
                            <b>P: </b>{{ formataData(scope.row.rodata) }}
                        </div>
                        <div>
                            <b>R: </b>{{ formataDataHora(scope.row.rodatahorainicio) }}
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Saída | Retorno" align="center" :sortable="true" min-width="250px">
                <template slot-scope="scope">
                    <div
                        style="display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
                        <div><b>S: &nbsp; </b>{{ scope.row.ponto_saida ? scope.row.ponto_saida.podescricao : '' }}</div>
                        <div><b>R: &nbsp;</b>{{ scope.row.ponto_retorno ? scope.row.ponto_retorno.podescricao : '' }}</div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column align="center" min-width="180px" :sortable="true" label="Itens Prog. | Real">
                <template slot-scope="{ row }">
                    <progresso-rotas :rota="row" />
                </template>
            </el-table-column>

            <el-table-column label="Tempo Prog. | Real" align="center" :sortable="true" min-width="200px">
                <template slot-scope="scope">
                    <div style="display: flex; justify-content: center; align-items: center;">
                        <div style="margin-right: 5px;">{{ validaRoTempo(scope.row) }}</div>
                        <div style="margin: 0 5px;">|</div>
                        <div style="margin-left: 5px;">{{ scope.row.tempoProgramado }}</div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Km Prog. | Real." min-width="180px" align="center" prop="kmRealEstimado" />

            <el-table-column label="Peso Ini. | Peso Atual" align="center" :sortable="true" min-width="180px">
                <template slot-scope="scope">
                    <div style="display: flex; justify-content: center; align-items: center;">
                        <div style="margin-right: 5px;">{{ scope.row.ropeso }}</div>
                        <div style="margin: 0 5px;">|</div>
                        <div style="margin-left: 5px;">{{ scope.row.pesoIrRotaFinalizada }}</div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Ações" align="center" min-width="300px">
                <template slot-scope="{ row }">
                    <acoes-rotas :optSelectMotivo="justificativas" @rota-excluida="removeRota" :row="row" />
                </template>
            </el-table-column>
        </el-table>
        <div v-else class="statusBar">
            <status-information typeBar="div" :statusBar="info" :msgCentro="msgCentro" />
        </div>

    </div>
</template>
<script>

import AcoesRotas from "@/components/Atom/Buttons/AcoesRotas.vue";
import ProgressoRotas from "@/components/Atom/Painel/ProgressoRotas.vue";
import IndicadorPesoVeiculo from "@/components/Atom/Painel/IndicadorPesoVeiculo.vue";
import StatusInformation from "@/components/Atom/StatusInformation/StatusInformation.vue";
import filtrosRota from "@/components/Atom/Painel/FiltrosRota.vue";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { Duration, DateTime } from "luxon";

export default {
    name: "painelRotas",
    components: {
        ProgressoRotas,
        IndicadorPesoVeiculo,
        StatusInformation,
        filtrosRota,
        AcoesRotas,
    },

    props: {
        clienteSelecionado: {
            type: Array,
            default: () => {
                return [];
            },
        },
        alertaLido: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            baseUri: "/finder/rotas/",
            msgCentro: "Selecione uma empresa",
            info: "info",
            /**filtros que são emitidos pelo componente de filtros de rota. */
            filtros: {},
            /**Rotas a serem exibidas na tabela */
            rotasUsuario: [],
            justificativas: [],
            buscaUsuario: "",
            tamTable: 200,
            attTempo: 30000,
            intervaloRotas: "",
            requestControl: false,
            loadingPanel: false,
            rotasSelecionadas: [],
            selectMarcadoresAlerta: {},
            exibirLegenda: false
        };
    },

    methods: {
        /**
         * @param string data
         * @description Formata data para o padrão do Brasil
         * @return string data - formatada
         * @author Otávio 🦆
        */
        formataData(data) {
            if (data) {
                return DateTime.fromISO(data).toFormat('dd/MM/yyyy');
            }
            return '';
        },

        /**
         * @param string data com hora
         * @description Formata data e hora para o padrão do Brasil
         * @return string data - formatada
         * @author Otávio 🦆
        */
        formataDataHora(dataHora) {
            if (dataHora) {
                return DateTime.fromFormat(dataHora, 'yyyy-MM-dd HH:mm:ss').toFormat('dd/MM/yyyy HH:mm:ss');
            }
            return '';
        },

        /**
        * @description Emite o evento com o status se true ou false para exibir ou não a legenda
        * @author Otávio 🦆
        */
        exibeLegenda(status){
            this.$emit('exibe-legenda-painel', status);
        },
		mudaCliente(v){
			this.$emit('muda-cliente', v)
            this.emiteLimparTodosMarcadores();
        },
        
        compararComRota(value) {
            let status_comparar = ["F", "I"];
            let rotas_comparar = this.rotasUsuario
                .filter((r) => {
                    return (
                        r.rodatahorainicio &&
                        r.selecionado &&
                        status_comparar.includes(r.rostatus)
                    );
                })
                .map((r) => {
                    return {
                        placa: r.roplaca,
                        dataIni: r.rodatahorainicio,
                        dataFim:
                            r.rodatahorafim ??
                            r.rodatahorainicio.replace(/\d\d:\d\d:\d\d/, "23:59:59"),
                        rocodico: r.rocodigo,
                        status: r.rostatus,
                    };
                });
            if (value) {
                let url = `${this.baseUri}rastro/rota`;
                new HttpRequest()
                    .Post(url, {
                        objs: rotas_comparar,
                    })
                    .then((res) => {
                        var rastros = res.data;
                        this.emiteRastrosRotas(rastros);
                    });
            } else this.$emit("remove-rastros-rotas");

        },

        /**
         * @description Emite o evento com os dados de alerta para criar os marcadores de alerta
         * @author Otávio 🦆
        */
        emiteAlertasRotas(alertas){
            this.$emit("alertas-rotas", alertas);
        },

        /**
         * @description Emite o evento com os dados de paradas para criar os marcadores de parada
         * @author Otávio 🦆
        */
        emiteParadasRotas(paradas, id){
            this.$emit("paradas-veiculo-rota", { paradas, id });
        },

        /**
         * @description Emite o evento com os dados de velocidade para criar os marcadores de velocidade
         * @author Otávio 🦆
        */
        emiteVelocidadeRotas(excessoVelocidade, id = null){
            if(id !== null){
                this.$emit("excesso-velocidade", {excessoVelocidade, id});
            }else{
                this.$emit("excesso-velocidade", excessoVelocidade);
            }
        },

        /**
         * @description Emite o evento com os dados de alertas do status L para criar os marcadores lidos
         * @author Otávio 🦆
        */
        emiteAlertasLidos(exibeAlertaLido){
            this.$emit("exibe-alertas-lidos", exibeAlertaLido);
        },

        emiteRastrosRotas(rastros) {
            this.$emit("rastros-rotas", rastros);
        },

        /**
         * @description disparada a cada 30 segundos, procura
         * atualizações nas informações sendo exibidas
         */
        async attRotas() {
            if (this.filtros.empresas.length && !this.requestControl) {
                var uri = this.baseUri + "all/rotas";
                this.requestControl = true;
                await new HttpRequest()
                    .Post(uri, { ...this.filtros, att: true })
                    .then((data) => {
                        if (data.status) {
                            this.atualizaTable(data.data.rotas);
                            this.atualizaMarcadores();
                        }
                    })
                    .finally(() => {
                        this.requestControl = false;
                    });
            }
        },

        /**
         * @param {array} nRotas - novas informações das rotas.
         * @description atualiza as informações na tabela
         * de acordo com o novo retorno do back-end
         */
        atualizaTable(nRotas) {
            this.rotasUsuario.forEach((r) => {
                if (typeof nRotas[r.rocodigo] !== "undefined") {
                    var keys = Object.keys(nRotas[r.rocodigo]);
                    keys.forEach((k) => {
                        r[k] = nRotas[r.rocodigo][k];
                    });
                }
            });
        },

        /**
         * @fires att-marcador-rota
         * @description separa os marcadores selecionados e
         * os envia para o mapa, atualizando as informações.
         * @see attRotas
         */
        atualizaMarcadores() {
            var rotas = this.rotasUsuario.filter((r) => {
                return r.selecionado;
            });
            rotas.forEach((r) => {
                r.itens_rota.forEach((i) => {
                    this.$emit("att-marcador-rota", i);
                });
            });
        },

        /**
         * @listens busca-rotas   - emitido pelo componente de filtros.
         * @param {string} search - o que o usuário digitar como
         * entrada de busca.
         */
        filtrarRotas(search) {
            this.buscaUsuario = search;
        },

        /**
         * @listens rota-excluida
         * @param {number} rocodigo - código da rota a ser removida.
         * @description caso uma rota tenha sido excluida,
         * essa função remove ela da tabela.
         */
        removeRota(rocodigo) {
            this.rotasUsuario = this.rotasUsuario.filter((r) => {
                return r.rocodigo !== rocodigo;
            });
            this.rmvRastroMapa(rocodigo);
        },

        /**
         * @todo O lance da altura também não ta legal, melhorar isso no futuro
         */
        emiteExpandir() {
            var view = window.screen.height * 0.75;
            if (this.tamTable === 200) this.tamTable = view;
            else this.tamTable = 200;
            this.$emit("expandir");
        },

        /**
         * @listens select-all - tabela de rotas.
         * @param {array} all  - todas as linhas da tabela.
         * @description primeiro emite o evento de limpar todas as rotas e paradas,
         * envia os dados para fazer a requisição para buscar os marcadores de velocidade, alertas e paradas
         * depois envia todas as selecionadas para o mapa.
         * @fires limpa-rotas
         * @author Desconhecido e Otávio 🦆 
         */
        enviaTodas(all) {
            if (all.length > 0) {
                var dadosRota = all.map(this.montaObjetoBuscarDadosRota);
                let dadosApi = {
                    dadosRota: dadosRota,
                };

                this.getDadosRotas(dadosApi);
            }else{
                this.emiteLimparTodosMarcadores();
            }
            this.$refs.filtrosRotas.resetaCompararRota();
            this.$emit("limpa-rotas");
            all.forEach((r) => {
                this.addRastroMapa(r);
            });
            this.rotasUsuario.forEach((r) => {
                if (all.includes(r)) r.selecionado = true;
                else r.selecionado = false;
            });
        },

        /** Faz os emits para chamar a função que limpa os marcadores de rotas do mapa 
         * @author Otávio 🦆
        */
        emiteLimparTodosMarcadores(){
            this.$emit("limpa-alertas-rotas");
            this.$emit("limpa-paradas-rotas");
            this.$emit("limpa-excesso-velocidade");
        },

        /**
        * @description valida para tratar casos que rotempo
        * são nulos e retornar no padrão 00:00:00
        * @author Otávio 🦆 
        */
        validaRoTempo(row){
            if(row.rotempo === null || row.rotempo === ''){
                return '00:00:00';
            }
            return row.rotempo;
        },

        /**
         * @param {object} tempo
         * @returns {string} tempo de vijavem formatado
         * @description recebe o objeto de tempo que vem
         * do back-end e devolve uma string com com as informações
         * de duração
         */
        arrumaRoTempo(tempo) {
            delete tempo.secondsAll;
            var duracao = Duration.fromObject(tempo);
            if (duracao.get("days")) return duracao.toFormat("dd'd' hh'h' mm'm'");
            return duracao.toFormat("hh'h' mm'm'");
        },

        /**
         * @param {object} obj
         * @param {object} obj.row
         * @param {string} obj.row.rocor - cor de fundo da rota
         */
        tableRowStyle({ row }) {
            if (!row.rocor) return;
            if (this.wc_hex_is_light(row.rocor)) {
                return {
                    "background-color": row.rocor,
                    color: "black",
                };
            } else {
                var bgcolor = this.rgbaColor(row.rocor);
                return {
                    "background-color": bgcolor,
                    color: "black",
                };
            }
        },

        /**
         * @listens filtros-rotas  - emitido pelo componente de filtros
         * @param {object}    filtros
         * @param {number[]}  filtros.empresas
         * @param {('0'|'1')} filtros.finalizada
         * @param {('0'|'1')} filtros.iniciada
         * @param {('0'|'1')} filtros.pendente
         * @param {string}    filtros.data
         * @description faz a chamada para o back-end enviando os filtros.
         */
        async procuraRotas(filtros) {
            clearInterval(this.intervaloRotas);
            this.$emit("limpa-rotas");
            this.filtros = filtros;
            this.rotasUsuario = [];
            this.info = "info";
            if (filtros.empresas.length && !this.requestControl) {
                this.info = "loading";
                var uri = this.baseUri + "all/rotas";
                this.requestControl = true;
                await new HttpRequest()
                    .Post(uri, filtros)
                    .then((data) => {
                        if (data.status) {
                            this.rotasUsuario = data.data.rotas ?? [];
                            if (this.rotasUsuario.length) this.info = "info";
                            else this.info = "error";
                            if (this.$route.name === "Finder")
                                this.intervaloRotas = setInterval(
                                    this.attRotas,
                                    this.attTempo
                                );
                            this.rotasUsuario.forEach((r) => {
                                Object.defineProperty(r, "selecionado", {
                                     value: false,
                                    writable: true,
                                    enumerable: true,
                                    configurable: false,
                                });
                            });
                            if (!this.justificativas.length) {
                                this.getJustificativas();
                            }
                        }
                    })
                    .finally(() => {
                        this.requestControl = false;
                    });
            } else {
                this.info = "info";
            }
        },

        /**
         * @listens select       - tabela de rotas
         * @param {array} arrRow - todas as linhas selecionadas
         * @param {object} row   - linha clicada
         */
         selectRow(ArrRow, row) {
            if (ArrRow.includes(row)) {
                var dadosRota = [this.montaObjetoBuscarDadosRota(row)];
                var dadosApi = {
                    dadosRota: dadosRota
                };
                this.rotasSelecionadas.push(row);
                this.getDadosRotas(dadosApi, row.rocodigo);
            } else {
                this.rotasSelecionadas = this.rotasSelecionadas.filter(r => r.rocodigo !== row.rocodigo);
                if (this.selectMarcadoresAlerta[row.rocodigo]) {
                    this.selectMarcadoresAlerta[row.rocodigo].forEach(alcodigo => {
                        this.$emit('limpa-alertas-rotas-id', alcodigo);
                    });
                    delete this.selectMarcadoresAlerta[row.rocodigo];
                }

                this.$emit("limpa-paradas-id", row.rocodigo);
                this.$emit("limpa-excesso-velocidade-id", row.rocodigo);
            }

            this.$refs.filtrosRotas.resetaCompararRota();
            if (ArrRow.includes(row)) this.addRastroMapa(row);
            else this.rmvRastroMapa(row.rocodigo, row.roplaca);
        },

        /**
         * @description itera sobre os dados selecionado, chamando as funções para montar o objeto
         * e fazer a requisição dos dados de marcadores
         * @author Otávio 🦆
        */
        atualizarDadosRotas() {
            if (this.rotasSelecionadas.length > 0) {
                let dados = this.rotasSelecionadas.map(this.montaObjetoBuscarDadosRota);
                this.getDadosRotas({ dadosRota: dados });
            }
        },

        /**
         * @description De acordo com o estado da variável loadingPanel valida se é para ativar ou desativar 
         * os checkbox das rotas
         * @author Otávio 🦆
        */
        desativarSelect() {
            return !this.loadingPanel;
        },

        /**
         * @description monta o objeto com dados de quando a rota foi iniciada, fianlizada e a placa
         * @author Otávio 🦆
        */
        montaObjetoBuscarDadosRota(rotas){
            return {
                dataIni: rotas.rodatahorainicio,
                dataFim: rotas.rodatahorafim,
                placa: rotas.roplaca
            };
        },

        /**
         * @fires remove-rota        - avisa o mapa que a rota
         * deve ser removida.
         * @param {number} rocodigo  - código do rastro a ser removido do mapa.
         * @description quando uma linha é descelecionada na tabela,
         * esse evento avisa o mapa que ela deve ser removida.
         */
        rmvRastroMapa(rocodigo, roplaca) {
            this.rotasUsuario.forEach((r) => {
                if (r.rocodigo === rocodigo) r.selecionado = false;
            });
            this.$emit("remove-rota", rocodigo, roplaca);
        },

        /**
         * @fires desenhar-rota
         * @description envia a rota a ser desenhada pro mapa
         * e já adiciona a descrição da justificativa caso houver uma
         */
        addRastroMapa(row) {
            this.rotasUsuario.forEach((r) => {
                if (r.rocodigo === row.rocodigo) r.selecionado = true;
            });
            this.$emit("desenhar-rota", row);
        },

        /**
         * @todo parte da troca de veículo, ainda não esta implementado.
         */
        getJustificativas() {
            new HttpRequest().Get(`${this.baseUri}get/justificativas`).then((data) => {
                if (data.status && data.data) {
                    this.justificativas = data.data.justificativas ?? [];
                }
            });
        },

        /**
         * @param {string} color - cor em hexa
         * @return {string} rgba - a cor no formato rgba
         */
        rgbaColor(color) {
            const hex = color.replace("#", "");
            const c_r = parseInt(hex.substr(0, 2), 16);
            const c_g = parseInt(hex.substr(2, 2), 16);
            const c_b = parseInt(hex.substr(4, 2), 16);
            return `rgba(${c_r}, ${c_g}, ${c_b}, 0.2)`;
        },

        /**
         * @param {string} color - cor em hexa
         * @returns {boolean} - Se a cor é clara dms
         */
        wc_hex_is_light(color) {
            if (color === undefined) color = "#ffffff";
            const hex = color.replace("#", "");
            const c_r = parseInt(hex.substr(0, 2), 16);
            const c_g = parseInt(hex.substr(2, 2), 16);
            const c_b = parseInt(hex.substr(4, 2), 16);
            const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
            return brightness > 155;
        },

        /**
        * @author Otávio 🦆
        * @description - vincula um grupo de alertas ao id para identificar os alertas por rota
        * isso facilitar na remoção dos marcadores do mapa
        */
        agruparAlertasPorId(id, alertas) {
            this.selectMarcadoresAlerta[id] = alertas.map(alerta => alerta.alcodigo);
        },

        /**
        * @author Otávio 🦆
        * @description - faz a requisição para buscar os dados de alertas, excesso de velocidade e paradas
        * quando recebe os dados da requisição
        * @param {Object} dados - O objeto de payload para fazer a requisação
        * @param {Number} id - Identificador da rota.
        */
        getDadosRotas(dadosRota, id) {
            var uri = this.baseUri + "dados/rota";
            if (Array.isArray(dadosRota.dadosRota)) {
                const dadosFiltrados = dadosRota.dadosRota.filter(dados => dados.dataIni);

                if (dadosFiltrados.length > 0) {
                    this.requestControl = true;
                    this.loadingPanel = true;

                    const dadosParaEnvio = { dadosRota: dadosFiltrados };
                    new HttpRequest()
                        .Post(uri, dadosParaEnvio)
                        .then(res => this.onRoutePost(res, id))
                        .finally(() => {
                            this.requestControl = false;
                            this.loadingPanel = false;
                        });
                }
            }
        },

        /**
         * @author Otávio 🦆
         * @description Processa a resposta da API após uma requisição POST para obter dados das rotas.
         * Este método é responsável por extrair informações de alertas, paradas e excessos de velocidade
         * da resposta da API e distribuir esses dados para as respectivas funções que irão gerar
         * os marcadores no mapa.
         * 
         * @param {Object} res - O objeto de resposta da API contendo os dados das rotas.
         * @param {Number} id - Identificador da rota.
         */
        onRoutePost(res, id) {
            var retornoApi = res.data;
            if (retornoApi) {
                var alertas = retornoApi.alertas || {};
                var paradas = retornoApi.paradas || {};
                var excessosVelocidades = retornoApi.excessoVelocidade || {};
                this.emiteAlertasRotas(alertas);
                this.agruparAlertasPorId(id, alertas);
                paradas.forEach(parada => {
                    this.emiteParadasRotas(parada, id);
                });
                excessosVelocidades.forEach(excessoVelocidade => {
                    this.emiteVelocidadeRotas(excessoVelocidade, id);
                });
            }
        }
    },
    watch: {
        /**
		 * @description monitora se o alerta foi lido para receber um boolean 
		 * se for true chama a função para atualizar os marcadores do mapa
		 * @author Otávio 🦆 
		 */
        alertaLido(newValue) {
            if (newValue) {
                this.atualizarDadosRotas();
            }
        }
    },

    computed: {
        /**
         * @description variavel computada para aplicar o filtro de busca
         * do usuário. rotasUsuario é a variável que guarda todos as rotas
         * encontradas, depois.
         * @returns {array} rotas que batem com oque o usuário digitou.
         */
        rotasToShow() {
            return this.rotasUsuario.filter((r) => {
                var reg = new RegExp(this.buscaUsuario.trim(), "i");
                if (r.roplaca.match(reg)) return true;
                if (r.veprefixo.match(reg)) return true;
                if (String(r.rocodigoexterno).match(reg)) return true;
                return false;
            });
        },
    },

    beforeDestroy() {
        this.intervaloRotas = clearInterval(this.intervaloRotas);
    },
};
</script>

<style lang="scss">
#painel-rotas {
    max-height: 25%;
    div .el-table-column--selection .cell {
        padding-left: 0 !important;
    }
    table {
        border-collapse: collapse !important;
    }
    .el-table__body {
        border-collapse: collapse !important;
    }
    .cargas-popover .popover {
        transform: translateY(-20px) !important;
    }
    .el-table__body,
    .el-table__footer,
    .el-table__header {
        border-collapse: collapse !important;
    }
    .el-table {
        margin-bottom: 0px;
    }
}
</style>
