<template>
	<div id='painel-coletivos-mapa'>
		<div>
			<filtros-painel-coletivo ref="filtrosColetivo" :clienteSelecionado='clienteSelecionado'
				@muda-cliente='mudaCliente' @filtro-usuario='updateFiltroUsuario' @filtros-coletivos='procuraLinhas'
				@fecha-info="$emit('fecha-info')" />
		</div>

		<div v-if='Object.keys(tableData).length'>
			<el-table ref="tabelaColetivos" :max-height='tamTable' :data='dadosFiltrados' :border='true'
				:row-style='tableRowStyle' @select-all='selecionaTudo' @select='selecionaLinha'>
				<el-table-column type='selection' />

				<el-table-column prop='placa' :sortable='true' width='150' align='center' label='Placa | Prefixo'>
					<template slot-scope="{row}">
						<p class="text-table">{{ row.placa }} | {{ row.prefixo }}</p>
					</template>
				</el-table-column>

				<el-table-column :sortable='true' width='200' align='center' label='Data Hora Início' prop='rotaHoraInicio'>
					<template slot-scope="{row}">
						<p class="text-table">{{ row.data }} {{
							row.rotaHoraInicio }}</p>
					</template>
				</el-table-column>

				<el-table-column :sortable='true' width='250' align='center' label='Data Hora Fim' prop='rotaHoraFim'>
					<template slot-scope="{row}">
						<p class="text-table">{{ row.data }} {{
							row.rotaHoraFim }}</p>
					</template>
				</el-table-column>

				<el-table-column :sortable='true' align='center' width='250' resizable label='Linha'
					:show-overflow-tooltip='true' prop='descLinha' />

				<el-table-column :sortable='true' align='center' width='100' label='Escala' :show-overflow-tooltip='true'
					prop='descEscala' />

				<el-table-column :sortable='true' align='center' label='Turno' width='80' :show-overflow-tooltip='true'
					prop='turno'>
					<template slot-scope="{row}">
						<p class="text-table">{{ row.turno }}</p>
					</template>
				</el-table-column>

				<el-table-column :sortable='true' align='center' label='Motorista' width='125' :show-overflow-tooltip='true'
					prop='motorista' />

				<el-table-column width='105' align='center' label='Progresso' :show-overflow-tooltip='true' :sortable='true'
					prop='progresso'>
					<template slot-scope="{row}">
						<b-progress height='25px'>
							<b-progress-bar :label="`${row.progresso.toFixed(2)}%`" :value='row.progresso'
								:title='`${row.progresso}%`' max='100' animated />
						</b-progress>
					</template>
				</el-table-column>

				<el-table-column width='70' label='Status'>
					<template slot-scope='{row}'>
						<div class='d-flex justify-content-center'>
							<div :class='`${row.statusLinha} circle circle-text`'>
								{{ row.statusRota }}
							</div>
						</div>
					</template>
				</el-table-column>

				<el-table-column width='200' label='Ajudantes'>
					<template slot-scope='{row}'>
						<span v-if="row.ajudantes.length > 0" class="text-truncate"
							style="max-width: 100px !important; min-width: 100px !important;">
							<span v-for="(ajudante, index) in row.ajudantes" v-bind:key="index + ajudante.codigoAjudante">
								<p class="text-table">{{ ajudante.nomeAjudante }}</p>
							</span>
						</span>

						<span class="text-table" v-else>Sem ajudantes</span>
					</template>
				</el-table-column>

				<el-table-column align='center' label='Ações' prop='' width='120'>
					<template slot-scope="{row}">
						<div class="row">
							<div @click="abrirModalEditarRota(row)"
								class="d-flex justify-content-center align-items-center hoverButton activeButton mr-4"
								style="cursor: pointer; width: max-content; border-radius: 50%; padding: 2px;">
								<baseIcon size="24" style="color: #000" :icon="mdiPlaylistEdit" />
							</div>

							<div @click="abrirModalFinalizarRota(row)"
								class="d-flex justify-content-center align-items-center text-truncate activeButton"
								style="cursor: pointer">
								<span>
									<baseIcon size="30" style="padding: 5px; color: #fff; background-color: #B23334; border-radius: 100%;"
										:icon="mdiProgressClose" />
								</span>
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div v-else>
			<status-information typeBar='div' :statusBar='info' :msgCentro='msgCentro' />
		</div>
	</div>
</template>

<script>
import FiltrosPainelColetivo from
	'@/components/Atom/Painel/Coletivos/FiltrosPainelColetivo.vue'
import StatusInformation
	from '@/components/Atom/StatusInformation/StatusInformation.vue'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { mdiProgressClose, mdiPlaylistEdit } from '@mdi/js'
import BaseIcon from '@/components/Atom/Icon/BaseIcon.vue'
import { conectionError } from '@/Services/Helpers/swellHeper';

export default {
	name: 'painelColetivo',

	components: {
		FiltrosPainelColetivo,
		StatusInformation,
		BaseIcon,
	},

	props: {
		clienteSelecionado: {
			Type: Array,
			required: true
		}
	},

	data() {
		return {
			auxDadosAntigos: [],
			tamTable: 200,
			userQ: '',
			urlBase: '/finder/coletivos/',
			mdiProgressClose: mdiProgressClose,
			mdiPlaylistEdit: mdiPlaylistEdit,
			tableData: [],
			itensRota: [],
			info: 'info',
			msgCentro: 'Selecione uma empresa',
			filtros: {},
			tempoIntervalo: 30000,
			todasRegioes: [],
			filtroManual: null,
			linhasSelecionadas: [],
		}
	},

	methods: {
		mudaCliente(v) {
			this.$emit('muda-cliente', v)
		},

		disableSelectAll() {
			return false;
		},

		atualizarColetivos() {
			// eslint-disable-next-line no-unused-vars
			var uri = `${this.urlBase}`
			new HttpRequest().Post()
		},

		/**
		 * @param {object} data 
		 * @param {number} data.linha - código da linha
		 * @param {object} data.info
		 * @param {number} data.info.rccodigohorario
		 * @param {string} data.info.listringrota
		 * @description emite a linha para o mapa, já com
		 * os campos prontos para ser só desenhada no mapa.
		 * @fires linha-coletivo
		 */
		emiteLinhaColetivo(data) {
			var itens = this.itensRota[data.linha]
			var linha = this.tableData.find((d) => {
				return d.codigoRota === data.linha
			})
			var obj = {
				'poli': data.info.listringrota,
				'rccodigohorario': data.info.rccodigohorario,
				'rccodigo': data.linha,
				'rccor': linha.corLinha,
				'itens': itens.map(e => {
					return {
						...e,
						rccodigo: data.linha
					}
				})
			}
			this.$emit('linha-coletivo', obj)
			if (data.rastroVeiculo.length < 3)
				this.emiteRastroVeiculo(data)
			if (data.desviosRota.length)
				this.emiteDesvioDeRota(data)
		},

		/**
		 * @listens select-all
		 * @todo fazer funcionar, investigar causas do não funcionamento
		 * Otávio 🦆 e desconhecido
		 */
		selecionaTudo(selected) {
			if (selected.length) {
				var arrEnviar = selected.map(s => {
					return {
						codigo: s.codigoRota,
						modulo: s.modulo
					}
				})

				this.$emit('select-all', selected)

				var uri = `${this.urlBase}bulk/linhas`
				new HttpRequest().Post(uri, { linhas: arrEnviar })
					.then((data) => {
						data.data.linhas.forEach(this.emiteLinhaColetivo)
					})
			} else {
				this.$emit('remove-todos-coletivos');
				this.$emit('removeLinhaColetivoSelecionada');
				this.$emit('select-all', []);
				this.filtroManual = null;
				this.linhasSelecionadas = [];
			}
		},

		/**
		 * @listens select
		 * @param {array} all
		 * @param {object} clicked 
		 * @param {number} clicked.codigoRota
		 * @param {number} clicked.modulo
		 * @param {string} clicked.corLinha
		 * @description procura pela poli no back-end e 
		 * envia para o mapa desenhar.
		 * @author Gui 🍺 e Otávio 🦆
		 */
		selecionaLinha(all, clicked) {
			if (all.includes(clicked)) {
				if (!this.linhasSelecionadas.some(item => item.codigoRota === clicked.codigoRota)) {
					this.linhasSelecionadas.push(clicked);
				}

				this.$emit('removeLinhaColetivoSelecionada');
				this.$emit('emiteLinhaColetivoSelecionada', clicked);
				var uri = `${this.urlBase}rastro/linha`;
				var obj = {
					codigo: clicked.codigoRota,
					modulo: clicked.modulo
				};

				new HttpRequest().Post(uri, obj).then((data) => {
					this.filtroManual = clicked;

					if (data.data?.info) {
						var itens = this.itensRota[clicked.codigoRota];

						this.$emit('linha-coletivo', {
							'poli': data.data.info.info.listringrota,
							'rccodigohorario': data.data.info.info.rccodigohorario,
							'rccodigo': clicked.codigoRota,
							'rccor': clicked.corLinha,
							'itens': itens.map(e => {
								return {
									...e,
									rccodigo: clicked.codigoRota
								};
							})
						});

						this.$emit('linha-selecionada', clicked);

						this.$emit('montar-regioes-no-mapa', {
							regioes: this.todasRegioes,
							filtroRegiao: [clicked.codigoRegiao],
							filtroCategoria: null
						});

						if (data.data.info.rastroVeiculo.length > 3) {
							this.emiteRastroVeiculo(data.data.info);
						}

						if (data.data.info.desviosRota.length) {
							this.emiteDesvioDeRota(data.data.info);
						}
					}
				});
			} else {
				this.linhasSelecionadas = this.linhasSelecionadas.filter(item => item.codigoRota !== clicked.codigoRota);

				this.$emit('remove-linha-coletivo', clicked.codigoRota);
				this.$emit('linha-desselecionada', clicked.codigoRota);
				this.$emit('removeLinhaColetivoSelecionada');
				this.filtroManual = null;

				this.$emit('montar-regioes-no-mapa', {
					regioes: this.todasRegioes,
					filtroRegiao: this.filtros.regiao || [],
					filtroCategoria: this.filtros.categoria || null
				});
			}
		},

		/**
		 * @description Seleciona manualmente uma linha específica da tabela
		 * @param {Object} linha - A linha a ser selecionada
		 * @author Otávio 🦆
		 */
		selecionarLinhaPorCodigo(codigoRota) {
			const linha = this.tableData.find(linha => linha.codigoRota === codigoRota);

			if (linha) {
				const tabela = this.$refs.tabelaColetivos;

				if (tabela) {
					tabela.clearSelection();

					tabela.$nextTick(() => {
						tabela.toggleRowSelection(linha, true);

						this.selecionaLinha([linha], linha, false);
					});
				}
			}
		},

		/**
		 * @param {object} data
		 * @param {object[]} data.desviosRota
		 * @param {number}   data.desviosRota[].codigo - código desvio
		 * @param {number[]} data.desviosRota[].latlng
		 * @param {number}   data.linha - código da linha
		 */
		emiteDesvioDeRota(data) {
			var obj = {
				latlng: data.desviosRota,
				codigo: data.linha,
			}
			this.$emit('desvio-rota-coletivo', obj)
		},

		/**
		 * @param {object} 	   data 
		 * @param {number} 	   data.linha - código da rota
		 * @param {number[][]} data.rastroVeiculo
		 * @fires rastro-veiculo-coletivo
		 */
		emiteRastroVeiculo(data) {
			var linha = this.tableData.find((d) => {
				return d.codigoRota === data.linha
			})
			var obj = {
				cor: '#00FF00',
				codigo: linha.codigoRota,
				latlng: data.rastroVeiculo.map(pos => {
					return pos.reverse()
				}),
			}
			this.$emit('rastro-veiculo-coletivo', obj)
		},

		/**
		 * @param {number} obj.distancia
		 * @return {string} distancia.0 km
		 */
		formataDistancia({ distancia }) {
			return `${distancia.toFixed(1)} km`
		},

		/**
		 * @listens filtros-coletivos
		 * @param {object}    newFiltros
		 * @param {number[]}  newFiltros.clientes
		 * @param {string}    newFiltros.data - dd/mm/yyyy
		 * @param {string[]}  newFiltros.statusRota
		 * @description faz a busca no back-end caso exista pelo menos uma
		 * empresa selecionada, e já atribui os retornos as variáveis certas.
		 * @author Gui 🍺 e Otávio 🦆
		 */
		procuraLinhas(newFiltros) {
			this.$emit('remove-todos-coletivos');
			this.filtros = newFiltros;
			this.tableData = [];
			this.$emit('removeLinhaColetivoSelecionada');

			if (this.filtros.clientes.length) {
				var uri = `${this.urlBase}consultar/linhas`;
				this.info = 'loading';

				new HttpRequest().Post(uri, this.filtros)
					.then((data) => {
						this.tableData = data.data?.coletivos ?? [];
						this.itensRota = data.data?.itens ?? [];
						this.todasRegioes = data.data?.regioes ?? [];

						this.$emit('atualizar-dados-tabela', this.tableData);

						this.$emit('montar-regioes-no-mapa', {
							regioes: data.data?.regioes,
							filtroRegiao: newFiltros.regiao,
							filtroCategoria: newFiltros.categoria
						});
					})
					.catch(() => {
						this.$emit('atualizar-dados-tabela', []);
					})
					.finally(() => {
						this.info = this.tableData.length ? 'info' : 'error';
						if (!this.tableData.length) {
							setTimeout(() => this.info = 'info', 6660);
						}
					});
			} else {
				this.$emit('atualizar-dados-tabela', []);
			}
		},

		/**
		 * @param {object}   newQ
		 * @param {number[]} newQ.clientes
		 * @param {string}   newQ.data - dd/mm/yyyy
		 * @param {string[]} newQ.statusRotas
		 * @description atualiza os filtros aplicados
		 * as informações exibidas.
		 */
		updateFiltroUsuario(newQ) {
			this.userQ = newQ
		},

		/**
		 * @param {object} object
		 * @param {object} object.row
		 * @param {string} object.row.corLinha - hexadeciaml
		 * @return {object} estilo da linha da tabela.
		 */
		tableRowStyle({ row }) {
			if (!row.corLinha) return
			if (this.wc_hex_is_light(row.corLinha)) {
				return {
					'background-color': row.corLinha,
					'color': 'black'
				}
			} else {
				var bgcolor = this.rgbaColor(row.corLinha)
				return {
					'background-color': bgcolor,
					'color': 'black'
				}
			}
		},

		/**
		 * @param {string} color - cor em hexa
		 * @return {string} rgba - a cor no formato rgba
		 */
		rgbaColor(color) {
			const hex = color.replace('#', '')
			const c_r = parseInt(hex.substr(0, 2), 16)
			const c_g = parseInt(hex.substr(2, 2), 16)
			const c_b = parseInt(hex.substr(4, 2), 16)
			return `rgba(${c_r}, ${c_g}, ${c_b}, 0.4)`
		},

		/**
		 * @param {string} color - cor em hexa
		 * @returns {boolean} Se a cor é clara dms
		 */
		wc_hex_is_light(color) {
			if (color === undefined) color = '#ffffff'
			const hex = color.replace('#', '')
			const c_r = parseInt(hex.substr(0, 2), 16)
			const c_g = parseInt(hex.substr(2, 2), 16)
			const c_b = parseInt(hex.substr(4, 2), 16)
			const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000
			return brightness > 155
		},

		/**
		 * @param {object} row
		 * @param {string} row.placa 
		 * @param {string} row.prefixo
		 * @param {string} row.descLinha
		 * @param {string} row.motorista
		 * @param {string} row.descEscala
		 * @param {string} row.descPonto
		 * @param {RegExp} regex
		 * @description verifica se algum dos campos da
		 * linha batem com a regex que foi passado
		 * @return {boolean}
		 */
		filtraTableData(row, regex) {
			if (row?.placa && typeof row.placa === 'string' && row.placa.match(regex)) return true
			if (row?.prefixo && typeof row.prefixo === 'string' && row.prefixo.match(regex)) return true
			if (row?.descLinha && typeof row.descLinha === 'string' && row.descLinha.match(regex)) return true
			if (row?.motorista && typeof row.motorista === 'string' && row.motorista.match(regex)) return true
			if (row?.descEscala && typeof row.descEscala === 'string' && row.descEscala.match(regex)) return true
			if (row?.descPonto && typeof row.descPonto === 'string' && row.descPonto.match(regex)) return true
			return false
		},

		clicaRow() {
			conectionError('Operação indisponível!')
		},

		abrirModalEditarRota(rota) {
			alert('Em desenvolvimento, editar rota coletivo');
		},

		abrirModalFinalizarRota(rota) {
			alert('Em desenvolvimento, finalizar rota coletivo');
		}
	},

	computed: {
		dadosFiltrados() {
			if (this.filtroManual) {
				return [this.filtroManual];
			}

			var regex = new RegExp(this.userQ, 'i');
			return this.tableData.filter(e => {
				return this.filtraTableData(e, regex);
			})
		}
	},

	watch: {
		dadosFiltrados: {
			handler(novoValor) {
				this.$nextTick(() => {
					novoValor.forEach(row => {
						if (this.linhasSelecionadas.some(item => item.codigoRota === row.codigoRota)) {
							this.$refs.tabelaColetivos.toggleRowSelection(row, true);
						}
					});
				});
			},
			deep: true,
		}
	}
}
</script>

<style lang='scss'>
#painel-coletivos-mapa {
	.botao-acoes-coletivos {
		cursor: pointer;
	}

	.progress-bar {
		color: black;
		font-size: 13px;
	}

	.circle {
		height: 25px !important;
		width: 25px !important;
		border-radius: 50% !important;
		margin-bottom: 10px !important;
		padding-top: 4px !important;
	}

	.circle-text {
		color: black !important;
		text-align: center !important;
		font-size: 12px !important;
		font-weight: bold !important;
	}

	.text-table {
		font-size: 10px !important;
		margin-top: 15px !important;
	}

	.hoverButton:hover {
		background-color: rgb(221, 221, 221)
	}

	.activeButton:active {
		scale: 90%;
	}

	.bgreen {
		background-color: #90ee90;
	}

	.bgrey {
		background-color: gray;
	}

	.breg {
		background-color: red;
	}

	.byellow {
		background-color: yellow;
	}

	.el-table {
		margin-bottom: 0px;
	}

	thead .el-table-column--selection .el-checkbox {
		display: none !important;
	}
}
</style>