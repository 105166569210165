/** eslint-disable  */
import { HttpRequest } from "../auth/HttpRequest.Service";
export class FiltrosService {
    constructor() {}
    public async dados_filtros(empresas: any = [], filtros: any = [], intervalo: string[] = []) {
        var data = await new HttpRequest().Get("/filtros/listagem/dadosFiltros", {
            empresas: empresas,
            filtros: filtros,
            intervalo: intervalo
        });
        if (data.status) {
            let versao = data.data.versao;
            versao = versao ? versao : "";
            localStorage.setItem("versaoRecente", versao);
        }
        return data.data;
    }
}

/**
 * Copia das legendas no dados filtros para facilitar um pouco a vida
 * Os créditos a cada um dos filtros se encontra no back-end 💖
 * <<--LEGENDAS-->>
 * MR  ->  MOTORISTAS
 * MA  ->  AJUDANTE
 * AA  ->  ADMINISTRATIVO
 * V   ->  VEICULOS;
 * GV  ->  GRUPO DE VEICULOS;
 * C   ->  COLABORADORES;
 * GC  ->  GRUPO DE COLABORADORES;
 * L   ->  LINHAS;
 * LP  ->  LINHAS PRINCIPAIS;
 * E   ->  ESCALAS;
 * JO  ->  JORNADA;
 * M   ->  MANUTENÇÃO;
 * RT  ->  ROTAS;
 * P   ->  PONTOS;
 * RE  ->  REGIÕES;
 * JU  ->  JUSTIFICATIVAS;
 * TC  ->  TIPOS DE CARTÃO;
 * CC  ->  CARTÕES COLABORADOR(RFID);
 * E  ->  EMPRESAS
 * U ->  USUÁRIOS
 * PIF -> PONTOS DE INICIO E FIM DE ROTA
 * OS -> ORDEM DE SERVIÇO
 * MO  -> MODELOS
 * MSA -> MODULOS SEM ASSOCIAÇÃO
 * S   -> SERIAL
 * I   -> IMEI
 * O   -> OPERADORA
 * CH  -> CHIP
 * IC  -> ICCID
 * CA  -> cargos
 * TAG -> Tags
 * CV -> Categoria Veiculos
 * @author Raul
 * @param $array = recebe array do que devera ir para o dados filtros. EX: $array = ['V', 'GV', 'C', 'GC'];
 * @param $clientes = ARRAY dos codigos dos clientes
 */
