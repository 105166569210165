<template>
    <div id="cardIconeAlerta" class="col-sm-1 p-0" v-if="alertaContagem">
        <div :title="title" v-b-toggle="id + '_tacodigo'" class="texto-indicador">
            <span class="numero-alerta" :class="'alerta-' + alerta.cor">
                {{ alertaContagem }}
            </span>
        </div>

        <div class="pl-2 pr-2 div-icon text-center" :class="defineCorAlerta">
            <div :title="title" v-b-toggle="id + '_tacodigo'" class="aling-itens pt-2">
                <baseIcon size="50" :icon="icon" />
            </div>

            <b-collapse :ref="`ref${id}listagem`" :id="id + '_tacodigo'" class="mt-2 disp-abs row" v-if="alertaContagem"
                accordion="my-accordion" role="tabpanel" @show="showCollapse" @hide="hideCollapse">
                <div class="arrow-top"></div>
                <panelEagle :id="'id-modal-' + id + '_tacodigo'" :loading="loading" class="overY">
                    <div class="col-sm-12 row nopadding cor-collapse pt-1 pb-2 pr-2">
                        <div class="col-sm-10 text-left pad-top-10">
                            {{ title }}:
                            {{ alertaContagem }}
                        </div>
                        <div class="col-sm-2">
                            <template>
                                <simpleButton title="Ler todas" v-if="permissaoEditar" @click="clickLerTodosAlertas"
                                    event="click" type="light-green" width="100%" :icon="mdiCheckBold" />
                            </template>
                        </div>
                    </div>

                    <div class="col-sm-12 row nopadding">
                        <div class="col-sm-12">
                            <hr />
                        </div>

                        <div class="text-left col-sm-12 row p-0" v-for="(dado, index) in datasetAlertas"
                            :key="index + '_alerta'">
                            <div class="col-sm-10 row">
                                <div class="col-sm-12 row nopadding text-left small">
                                    <div class="col-sm-3 nopadding">
                                        {{ dado.data }}
                                    </div>

                                    <div class="col-sm-9">
                                        {{ tituloModal }}
                                    </div>

                                    <div class="col-sm-3 nopadding text-left">
                                        {{ dado.hora }}
                                    </div>

                                    <div class="col-sm-9 text-left">
                                        {{ dado.alerta }}
                                    </div>

                                    <div class="col-sm-12 nopadding d-flex justify-content-start">
                                        <div class="col-sm-6 nopadding d-flex gap-2">
                                            <p>Código da rota:</p>
                                            <p>{{ dado.codigoRota }}</p>
                                        </div>
                                        <div class="col-sm-6 nopadding d-flex gap-2">
                                            <p>Código da carga:</p>
                                            <p>{{ dado.codigoCarga }}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-2 row nopadding small">
                                <div class="col-sm-6"></div>
                                <template v-if="permissaoEditar">
                                    <div class="col-sm-6 nopadding margin-bot-7" title="Ler"
                                        v-on:click="clickLerAlerta(dado, index)">
                                        <baseIcon size="18" class="iconezinho-verde cursor-pointer"
                                            :icon="mdiCheckBold" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-sm-6 nopadding margin-bot-7" />
                                </template>
                                <div class="col-sm-6" title="Localizar" v-on:click="clickIconeLocalizar(dado)">
                                    <baseIcon size="18" class="cursor-pointer iconezinho-azul" :icon="mdiTarget" />
                                </div>
                                <template v-if="permissaoEditar">
                                    <div class="col-sm-6 nopadding" title="Tratativas"
                                        v-on:click="clickIconeTratativas(dado, index)">
                                        <baseIcon size="18" class="iconezinho-amarelo cursor-pointer" :icon="mdiCogs" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-sm-6 nopadding"></div>
                                </template>
                            </div>

                            <div class="col-sm-12">
                                <hr />
                            </div>
                        </div>
                    </div>
                </panelEagle>
            </b-collapse>
        </div>
    </div>
</template>

<script lang="js" scoped>

import Vue from 'vue';
import {
    mdiCogs,
    mdiTarget,
    mdiCheckBold,
} from '@mdi/js'

export default Vue.extend({
    name: "TipoAlerta",
    components: {
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
        simpleButton: require('@/components/Atom/Buttons/SimpleButton.vue').default,
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
    },

    props: {
        id: {
            required: true,
            type: Number,
            default: 99,
        },
        title: {
            required: false,
            type: String,
            default: '',
        },
        icon: {
            type: [Object, String, Number],
            default: '',
        },
        tituloModal: {
            required: false,
            type: String,
            default: '',
        },
        alerta: {
            required: true,
            type: [Array, Object],
            default: function () { return [] }
        },
        permissaoEditar: {
            required: false,
            type: Boolean,
            default: false,
        },
        loading: {
            required: false,
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            mdiCogs,
            mdiTarget,
            mdiCheckBold,
            datasetAlertas: [],
            show: false,
            pagina: 1,
            ultimoIndex: null,
        }
    },

    methods: {
        clickIconeLocalizar(dado) {
            this.$emit('clickIconeLocalizar', dado)
        },

        clickIconeTratativas(dado, index) {
            this.$root.$emit('bv::toggle::collapse', this.id + '_tacodigo')
            this.$emit('clickIconeTratativas', dado, index)
        },

        clickLerTodosAlertas() {
            this.$emit('clickLerTodosAlertas', this.id)
        },

        clickLerAlerta(dado, index) {
            this.ultimoIndex = index
            this.$emit('clickLerAlerta', dado, index)
        },

        showCollapse() {
            this.show = true
            this.pagina = 0
            this.iniciaScroll()
            this.$emit('showCollapse', this.id)
        },

        hideCollapse() {
            this.ultimoIndex = null
            this.show = false
        },

        /**
         * @description Verifica se a div chegou ao final do scroll para controlar a
         * [pagina] atual da listagem de alertas.
         * @author Rafael
         */
        iniciaScroll() {
            this.pagina = 1
            var ref = `ref${this.id}listagem`
            var el = this.$refs[ref].$el
            el.addEventListener('scroll', (e) => {
                const {
                    scrollTop,
                    scrollHeight,
                    clientHeight
                } = el
                if (scrollTop + clientHeight >= scrollHeight - 5) {
                    this.pagina += 1
                }
            })
        }
    },

    computed: {
        defineCorAlerta() {
            if (this.alertaContagem && this.alerta.cor) {
                return 'card-borda-' + this.alerta.cor
            }
            return 'card-borda-preta'
        },
        alertaDados() {
            return this.alerta.dados
        },
        alertaContagem() {
            return this.alerta.contagem
        }
    },

    watch: {
        /**
         * @description Faz o controle dos registros que aparecem na listagem, setando os
         * Seta na variavel [datasetAlertas] 50 primeiros registros de [alertaDados] ou
         * array vazio.
         * @param {Boolean} value
         * @author Rafael
         */
        show(value) {
            if (value && this.alertaDados && this.alertaDados.length) {
                this.datasetAlertas = this.alertaDados.slice(0, 50)
            } else {
                this.datasetAlertas = []
            }
        },

        /**
         * @description Faz o controle da quantidade de alertas que aparecem na listagem,
         * com base no contador [pagina].
         * Seta na variavel [datasetAlertas] os 50 * [pagina] primeiros registros de
         * [alertaDados] ou array vazio.
         * @param {int} value
         * @author Rafael
         */
        pagina(value) {
            if (value && this.alertaDados && this.alertaDados.length) {
                let size = this.alertaDados.length
                let last = (50 * value)
                if (last > size) {
                    last = size
                }
                this.datasetAlertas = this.alertaDados.slice(0, last)
            } else {
                this.datasetAlertas = []
            }
        },

        /**
         * @description Aguarda uma alteracao da contagem de alertas para remover um
         * registro de [datasetAlertas], com base no [ultimoIndex].
         * @param {int} value
         * @param {int} oldValue
         * @author Rafael
         */
        alertaContagem(value, oldValue) {
            if (value < oldValue && this.ultimoIndex != null) {
                this.datasetAlertas.splice(this.ultimoIndex, 1)
            }
        },
    }
})
</script>

<style lang="scss">
#cardIconeAlerta {
    min-width: 85px;
    margin: 3px;

    .div-icon {
        position: relative;
        min-width: 70px !important;
        min-height: 70px !important;

        .disp-abs {
            overflow: visible;
            top: 65px;
            left: -200px;
            position: absolute;
            z-index: 2;
            max-height: 300px;
            overflow-y: auto;
            background-color: white;
            width: 470px;
            height: auto;
            border: 1px solid black;
            overflow: visible;
        }

        .iconezinho-verde {
            background-color: #95b65c;
            color: white;
            height: 20px;
            width: 20px;
            padding: 1px;
        }

        .iconezinho-amarelo {
            background-color: #d8b606;
            color: white;
            height: 20px;
            width: 20px;
            padding: 1px;
        }

        .iconezinho-azul {
            background-color: #428bca;
            color: white;
            height: 20px;
            width: 20px;
            padding: 1px;
        }

        .pad-top-10 {
            padding-top: 10px;
        }

        .margin-bot-7 {
            margin-bottom: 7px;
        }

        .cor-collapse {
            background-color: #f5f3ee;
        }
    }

    .texto-indicador {
        width: 100%;
        position: absolute;
        font-size: 15px !important;

        span.alerta-vermelho {
            background-color: red;
            border: 4px solid red;
            border-bottom: 0;
        }

        span.alerta-amarelo {
            background-color: #d8b606;
            border: 4px solid #d8b606;
            border-bottom: 0;
        }
    }

    span.numero-alerta {
        text-align: center;
        color: white;
        position: absolute;
        z-index: 1;
        min-width: 20px;
        min-height: 20px;
        right: -3px;
        top: -3px;
    }

    .texto-posicao {
        padding-right: 30px;
        margin: 0px !important;
        width: 100%;
        float: left !important;
        position: absolute;
        text-align: right;
    }

    .card-borda-preta {
        border: 3px solid black;
    }

    .card-borda-vermelho {
        border: 3px solid red;
    }

    .card-borda-amarelo {
        border: 3px solid #d8b606;
    }

    .arrow-top {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid black;
        width: 10px;
        height: 10px;
        margin: auto;
        position: absolute;
        z-index: 1111;
        top: -10px;
        left: 50%;
    }

    .overY {
        overflow-y: auto;
        max-height: 300px;
    }
}
</style>

<style lang="css" scoped>
.gap-2 {
    gap: 7px
}
</style>