/**
Componente compoe a tabela de monitoramento de linhas, contém as principais da rota da linha
*/

<template>
    <div class="divMasterLinhaPrincipal nopadding">
        <div v-for="(rotas) in principal.rotas" :key="rotas.unique">
            <div class="divTituloLP nopadding mt-3 d-flex" style="height: 40px; position: relative;">
                <div @click="rotas.rotaClicada = !rotas.rotaClicada" :style="'width:' + rotas.porCent" :class="'col-sm-12 opacidadeRota p-2 ' +
                    'progress-bar-' + rotas.status">
                </div>

                <!-- Ícone chevron -->
                <div @click="rotas.rotaClicada = !rotas.rotaClicada" class="row mt-2 ml-2 mr-2 mt-3 font-bold"
                    style="z-index: 40;">
                    <b-icon class="h5 mb-0" :icon="rotas.rotaClicada ? 'chevron-down' : 'chevron-right'" />
                </div>

                <!-- Placa -->
                <div @click="rotas.rotaClicada = !rotas.rotaClicada" class="row mt-2 mr-3"
                    style="width: max-content; max-width: 170px; min-width: 170px;">
                    <baseIcon size="20" style="color: #000" :icon="mdiCarBack" class="mt-2" />

                    <div class="d-flex justify-content-start align-items-center"
                        v-b-popover.hover.top="rotas.placa + ' | ' + rotas.prefixo">
                        <span class="font-bold ml-2 text-truncate" style="font-size: 11px;">
                            {{ rotas.placa + ' | ' + rotas.prefixo }}
                        </span>
                    </div>
                </div>

                <!-- Linha -->
                <div @click="rotas.rotaClicada = !rotas.rotaClicada" class="row mt-2 mr-5"
                    style="width: max-content; max-width: 280px; min-width: 280px;">
                    <div class="d-flex justify-content-start align-items-center">
                        <baseIcon size="20" style="color: #000" :icon="mdiTransitConnectionVariant" class="mt-2" />

                        <span v-b-popover.hover.top="rotas.lidescricao" class="font-bold ml-2 text-truncate mt-2"
                            style="font-size: 11px; max-width: 280px; min-width: 200px">
                            {{ rotas.lidescricao }}
                        </span>
                    </div>
                </div>

                <!-- Motorista -->
                <div @click="rotas.rotaClicada = !rotas.rotaClicada" class="row mt-2 mr-5"
                    style="width: max-content; max-width: 200px; min-width: 200px;">
                    <baseIcon size="20" style="color: #000" :icon="mdiAccount" class="mt-2" />

                    <div v-b-popover.hover.top="rotas.motorista" class="mt-2 text-truncate" style="max-width: 180px;">
                        <span class="font-bold ml-2" style="font-size: 11px;">
                            {{ rotas.motorista }}
                        </span>
                    </div>
                </div>

                <!-- Horário de inicio -->
                <div @click="rotas.rotaClicada = !rotas.rotaClicada" class="row mt-2 mr-5"
                    style="width: max-content; max-width: 200px; min-width: 150px;">
                    <baseIcon size="20" style="color: #000" :icon="mdiTableClock" class="mt-2" />

                    <div class="d-flex justify-content-start align-items-center"
                        v-b-popover.hover.top="rotas.dataHoraInicio">
                        <span class="font-bold ml-2 text-truncate" style="font-size: 11px;">
                            {{ rotas.dataHoraInicio }}
                        </span>
                    </div>
                </div>

                <!-- Horário final -->
                <div @click="rotas.rotaClicada = !rotas.rotaClicada" class="row mt-2 mr-5"
                    style="width: max-content; max-width: 200px; min-width: 150px;">
                    <baseIcon size="20" style="color: #000" :icon="mdiTableCheck" class="mt-2" />

                    <div class="d-flex justify-content-start align-items-center"
                        v-b-popover.hover.top="rotas.dataHoraFim">
                        <span class="font-bold ml-2 text-truncate" style="font-size: 11px;">
                            {{ rotas.dataHoraFim }}
                        </span>
                    </div>
                </div>

                <!-- Editar, Finalizar, Porcentagem -->
                <div class="row mt-2" style="gap: 10px; position: absolute; right: 10px; bottom: 3px;">
                    <!-- Editar Linha -->
                    <div v-b-popover.hover.top="'Editar'" v-if="rotas.status == 'I' || rotas.status == 'F'"
                        class="d-flex justify-content-center align-items-center hoverButton activeButton"
                        style="cursor: pointer; width: max-content; border-radius: 50%; padding: 2px;"
                        @click="abrirModalEditarLinha(rotas)">
                        <baseIcon size="24" style="color: #000" :icon="mdiPlaylistEdit" />
                    </div>

                    <!-- Porcentagem Realizada -->
                    <div style="width: max-content;" v-b-popover.hover.top="rotas.porCent"
                        class="d-flex justify-content-center align-items-center text-truncate">
                        <div :class="'border-color-' + rotas.porCent" style="
                        border-radius: 10px;
                        font-weight: bold;
                        padding: 4px;
                        background-color: RGB(177, 178, 181);
                        font-size: 12px;
                        z-index: 1000;
                        ">
                            <div class="mt-1">{{ rotas.porCent }}</div>
                        </div>
                    </div>

                    <!-- Finalizar Linha -->
                    <div v-b-popover.hover.top="'Finalizar'" v-if="rotas.status == 'I'"
                        class="d-flex justify-content-center align-items-center text-truncate activeButton">
                        <span @click="abrirModalFinalizarLinha(rotas)">
                            <baseIcon size="30"
                                style="padding: 5px; color: #fff; background-color: #B23334; border-radius: 100%;"
                                :icon="mdiProgressClose" />
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 nopadding divBlockLinhasSeparadas">
                <!-- Div que tem as informações dos pontos, chama o componente de criação dos dados -->
                <slide-up-down class="divAccordion" :active="rotas.rotaClicada" :use-hidden="true" :duration="500">
                    <linhaSeparada ref="linhaSeparada" @linhaSeparada="itensCriados" :key="rotas.codigo"
                        :arrayDados="rotas">
                    </linhaSeparada>
                </slide-up-down>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import SlideUpDown from 'vue-slide-up-down'
import {
    mdiCarBack,
    mdiTransitConnectionVariant,
    mdiAccount,
    mdiTableClock,
    mdiTableCheck,
    mdiPlaylistEdit,
    mdiProgressClose
} from '@mdi/js'

export default Vue.extend({
    name: 'MonitoramentoLinhaPrincipal',

    components: {
        SlideUpDown,
        'linhaSeparada': require('./MonitoramentoLinhaLinhaSeparada').default,
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,

    },

    data() {
        return {
            countOnibus: 0,
            onibus: [],
            countLinhasSeparadas: 0,
            linhasSeparadas: [],
            principal: this.arrayDados,
            mdiCarBack: mdiCarBack,
            mdiTransitConnectionVariant: mdiTransitConnectionVariant,
            mdiAccount: mdiAccount,
            mdiTableClock: mdiTableClock,
            mdiTableCheck: mdiTableCheck,
            mdiPlaylistEdit: mdiPlaylistEdit,
            mdiProgressClose: mdiProgressClose,
            alertaDiasLinha: false
        }
    },

    props: {
        arrayDados: {
            type: Object,
            required: true,
            default: function () { return {} }
        }
    },

    watch: {
        arrayDados() {
            this.principal = this.arrayDados;
        }
    },

    emits: ["abrirModalEditar", "abrirModalFinalizar"],

    methods: {
        /**
         * Emite um evento informando que uma nova linha foi criada.
         * 
         * A função dispara o evento `linhaCriada`, enviando o item como parâmetro
         * para que o componente pai possa capturar e processar a informação.
         * 
         * @param {Object} item - Objeto contendo os dados da linha criada.
         * 
         * @author Yuri 🇧🇷
         */
        itensCriados(item) {
            this.$emit('linhaCriada', item)
        },

        /**
         * Atualiza os dados do mapa fechando todas as instâncias do colapso do mapa.
         * 
         * A função percorre todas as referências armazenadas em `linhaSeparada`
         * e chama o método `abrirMapaCollapse(false)` para garantir que os mapas
         * sejam fechados.
         * 
         * @author Yuri 🇧🇷
         */
        atualizarDadosMapa() {
            this.$refs.linhaSeparada.forEach(ref => {
                ref.abrirMapaCollapse(false);
            });
        },

        /**
         * Emite um evento para abrir o modal de edição de linha, caso a data e hora da linha sejam válidas.
         * 
         * A função verifica se a data e hora da linha são permitidas através da `verificarDataHoraLinha()`.
         * Se a verificação retornar verdadeiro, dispara o evento `abrirModalEditar` com os dados das rotas.
         * 
         * @param {Object} rotas - Objeto contendo os dados das rotas a serem editadas.
         * 
         * @author Yuri 🇧🇷
         */
        abrirModalEditarLinha(rotas) {
            if (this.verificarDataHoraLinha(rotas.dataHoraInicio)) {
                this.$emit("abrirModalEditar", rotas);
            }
        },

        /**
         * Obtém a data atual no formato `DD/MM/YYYY`.
         * 
         * Formata a data atual como uma string no padrão dia/mês/ano, utilizando o objeto
         * `Date` para obter os valores de dia, mês e ano. Adiciona zeros à esquerda para
         * garantir que os valores de dia e mês tenham dois dígitos.
         * 
         * @author Yuri 🇧🇷
        */
        consultarDataAtual() {
            const now = new Date();

            const day = String(now.getDate()).padStart(2, '0');
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const year = now.getFullYear();

            return `${day}/${month}/${year}`;
        },

        /**
         * Verifica se a data e hora selecionadas para a linha estão dentro do período permitido.
         * 
         * A função converte a data e hora selecionadas para um objeto `Date` e compara com o limite de 30 dias anteriores à data atual.
         * Se a data for inferior ao limite permitido, exibe um alerta e impede a edição ou finalização da linha.
         * 
         * @returns {boolean} - Retorna `true` se a data estiver dentro do período permitido, caso contrário, retorna `false`.
         * 
         * @author Yuri 🇧🇷
         */
        verificarDataHoraLinha(dataHoraInicio) {
            if (!this.bloquearDemaisCamposIniciarLinha) {
                const [data, hora] = dataHoraInicio.split(" ");
                const [dia, mes, ano] = data.split("/");
                const dataFormatada = `${ano}-${mes}-${dia}`;
                const dataSelecionada = new Date(`${dataFormatada}T${hora}:00`);
                const limiteDias = new Date();

                limiteDias.setDate(limiteDias.getDate() - 30);

                if (dataSelecionada < limiteDias) {
                    this.toastShow('Atenção!', 'Rotas com período maior que 30 dias não podem ser editadas ou finalizadas!', 'warning');

                    this.alertaDiasLinha = true;
                } else {
                    this.alertaDiasLinha = false;
                }

                if (this.alertaDiasLinha) {
                    return false;
                } else {
                    return true;
                }
            }
        },

        /**
         * Emite um evento para abrir o modal de finalização de linha.
         * 
         * A função dispara o evento `abrirModalFinalizar`, enviando as rotas como parâmetro
         * para que o componente pai possa capturar e processar a informação.
         * 
         * @param {Object} rotas - Objeto contendo os dados das rotas a serem finalizadas.
         * 
         * @author Yuri 🇧🇷
         */
        abrirModalFinalizarLinha(rotas) {
            if (this.verificarDataHoraLinha(rotas.dataHoraInicio)) {
                this.$emit("abrirModalFinalizar", rotas);
            }
        },

        /**
         * @description Método para mostrar o toast na tela
         * @param {string} msg   - mensagem que vai aparecer no corpo do toast
         * @param {string} type  - qual o tipo do toast
         * @param {string} title - título para inserir no cabeçalho
         * @author Vitor Hugo 🐨
         */
        toastShow(title, msg, type) {
            this.$bvToast.toast(msg, {
                autoHideDelay: 2500,
                variant: type,
                title: title,
            });
        },
    }
})
</script>

<style lang="scss">
.divMasterLinhaPrincipal {
    text-align: left !important;
    font-size: 12px;
    height: 100%;

    .divTituloLP {
        font-size: 11px !important;
        background-color: #f2f2f2;
        border-bottom: 1px solid #d5d5d5;

        &:hover {
            cursor: pointer !important;
        }

        .divNomeLinhaPrincipal,
        .divIniciadasLinhaPrincipal,
        .divNomeLinhaPrincipal {
            padding-top: 5px !important;
        }
    }

    .divLinesBusoes {
        .divBusaoPrincipal {

            .divBorderBusao {
                border-bottom: 2px solid #888888;

                .busaoRota {
                    position: absolute;

                    .iconBusao {
                        float: right;
                        height: 40px;
                        bottom: 10px;
                        position: relative;

                        &:before {
                            margin-left: 0px;
                            font-size: 40px;
                        }
                    }

                    .divSpanOnibus {
                        height: 29px;

                        .spanPrefixoIdentOnibus {
                            float: right;
                            position: relative;
                            top: 5px;
                            padding-left: 5px;
                            padding-right: 5px;
                            left: 5px;
                            padding-top: 3px;
                            border: 1px solid #d5d5d5;
                            border-radius: 15px;
                            font-weight: bold;

                            &.onibus-none {
                                background-color: #e5e5e5 !important;
                            }

                            &.onibus-red {
                                background-color: #e7aaad !important;
                            }

                            &.onibus-orange {
                                background-color: #ffd08e !important;
                            }

                            &.onibus-green {
                                background-color: #428BCA !important;
                            }
                        }
                    }
                }
            }

        }
    }

    .divBlockLinhasSeparadas {
        .divAccordion {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }

        .divMasterTimeline {

            .timeLineTime {
                height: 54px;
                overflow-x: hidden;
                overflow-y: hidden;
                border: 1px solid #d5d5d5;

                .thisIsTimeLine {
                    display: table-cell;
                    position: absolute;
                    height: 70px;
                    padding-right: 20px;
                    vertical-align: bottom;

                    .timePorPonto {
                        border-bottom: 3px solid #d5d5d5;
                        padding-right: 0px;
                        position: relative;
                        float: left;
                        padding-top: 5px;
                        margin: 0px;
                        height: 35px;

                        .iconPonto {
                            float: right;
                            position: relative;
                            left: 9px;
                            z-index: 2;
                            top: 5px;

                            &::before {
                                margin-left: 0px;
                            }

                            &.iconStatus-green {
                                color: #95b65c;
                            }

                            &.iconStatus-red {
                                color: #E04B4A;
                            }

                            &.iconStatus-blue {
                                color: #428BCA;
                            }
                        }

                        .spanHoraPassou {
                            position: relative;

                            &.buttonTop {
                                top: 2px;
                            }

                            &.buttonBottom {
                                top: 15px;
                            }
                        }
                    }

                    .garagemTimePonto {
                        border-bottom: 3px solid #d5d5d5;
                        padding-right: 0px;
                        width: 30px;
                        position: relative;
                        float: left;
                        padding-top: 5px;
                        margin: 0px;
                        height: 35px;

                        .iconGaragem {
                            float: left;
                            bottom: 7px;
                            position: relative;

                            &::before {
                                margin-left: 0px;
                                font-size: 30px;
                            }
                        }
                    }

                    // 34px
                }

                .thisIsTheBusao {
                    .timeLineBusao {
                        height: 35px;
                        position: absolute;
                        padding-right: 0px;

                        .iconBusao {
                            position: relative;
                            float: right;

                            &::before {
                                font-size: 34px;
                                margin-left: 0px;
                            }
                        }

                        &.statusPontoProgress-red {
                            border-color: #e7aaad !important;
                        }

                        &.statusPontoProgress-orange {
                            border-color: #ffd08e !important;
                        }

                        &.statusPontoProgress-green {
                            border-color: #428BCA !important;
                        }
                    }
                }

            }
        }
    }
}
</style>

<style lang="css" scoped>
.SI {
    background-color: #a6cfff;
}

.SC {
    background-color: #f5c2c2;
}

.SP {
    background-color: #abe050;
}

.SJ {
    background-color: #ffd08e;
}

.progress-bar-red {
    background: #E04B4A !important;
    opacity: 0.4;
}

.progress-bar-organte {
    background: #ffd08e !important;
    opacity: 0.4;
}

.progress-bar-green {
    background: #abe050 !important;
    opacity: 0.4;
}

.border-color-red {
    border: 1px solid #E04B4A;
}

.border-color-blue {
    border: 1px solid #a6cfff;
}

.progress-bar-blue {
    background: #a6cfff !important;
    opacity: 0.4;
}

.border-color-orange {
    border: 1px solid #ff9900;
}

.border-color-green {
    border: 1px solid #abe050;
}

.opacidadeRota {
    position: absolute !important;
    height: 40px;
    bottom: 0;
}

.font-bold {
    font-weight: bold;
}

.hoverButton:hover {
    background-color: rgb(221, 221, 221)
}

.activeButton:active {
    scale: 90%;
}
</style>