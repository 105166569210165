Menu bonitnho que aparece no lado direito superior do mapa.
A variavel bool, que existe em algumas as funções que emitem eventos 
existe para controlar a ação do mapa sobre o evento enviado. 
Ex.: 
cadastrarRegiao: bool = true: 
    Muda a atividade no mapa para cadastrar-região, e ativa o freeDraw, 
cadastrarRegiao: bool = false: 
    Muda a atividade do mapa para '', e remove a região já desenhada.
<template>
    <div id='menuMapa' :class='classeMenu'>
        <div class='menuzito'>
            <b-card
            no-body
            :class='{
				"hexagono":true,
				"aguia-aberto":menuAberto,
				"aguia-fechado":!menuAberto}'
            v-b-tooltip.hover='menuAberto?"Fechar menu":"Abrir menu"' 
            @click='mudaMenu'>
                    <b-card
					class="hexagono"
					no-body>
                        <b-img src='android-chrome-192x192.png'
                        height='50px'
                        width="50px"/>
                    </b-card>
            </b-card>
            <b-card
            v-show='menuAberto && permissao("maphistoricodeposicao")'
            class='hexagono quadrado-aberto'
            v-b-tooltip.hover="'Histórico de posição'" 
            @click='histPosicao'>
                <b-card class="hexagono">
                    <base-icon
                    class='icone'
                    :icon='iconHistPosicao'
                    :size='tamIcone'/>
                </b-card>
            </b-card>
            <b-card
            v-if='menuAberto &&  permissao("mapbuscar")'
            align='center'
            class='hexagono busca-aberto'
            v-b-tooltip.hover="'Busca'" 
            @click='emiteBuscar'>
                <b-card class="hexagono">
                    <base-icon
                    class='icone'
                    :icon='iconBusca'
                    :size='tamIcone'/>
                </b-card>
            </b-card>
            <b-card
            v-if='menuAberto && menuMais'
            class='hexagono regiao-aberto'
            v-b-tooltip.hover="'Cadastros'" 
            @click="mostraOpcoesMais">
                <b-card class="hexagono">
                    <base-icon
                    class='icone'
                    :icon='iconMais'
                    :size='tamIcone'/>
                </b-card>
            </b-card>
            <b-card
            v-if='decideMenuPontosRegioes'
            class='hexagono pontos-aberto'
            v-b-tooltip.hover="'Pontos e regiões'" 
            @click="mostrarOpcoesPonto">
                <b-card class="hexagono">
					<b-spinner
					v-if='procurandoPontos'
					class='mx-auto my-auto'
					label='Procurando informações'/>
                    <base-icon
					v-else
                    class='icone'
                    :icon='iconPontos'
                    :size='tamIcone'/>
                </b-card>
            </b-card>
            <b-card
            v-if='menuAberto && permissao("mapdashboard")'
            event='click'
            @click='goback'
            v-b-tooltip.hover="'Menu principal'"
            class='hexagono voltar-aberto'>
                <b-card class="hexagono">
                    <base-icon
                    class='icone'
                    :icon='exit'
                    :size='tamIcone'/>
                </b-card>
            </b-card>
            <b-card
            v-if='menuAberto'
            class='hexagono logout-aberto'
            v-b-tooltip.hover="'Sair'" 
			id='logout-system'
            @click='logoutSistema'>
                <b-card class="hexagono">
                    <base-icon
                    class='icone'
                    :icon='iconLogout'
                    :size='tamIcone'/>
                </b-card>
            </b-card>
        </div>
        <!-- @mouseleave="fechaMenuPontos" -->
        <div
        v-if='subMenuPontos'
        class='subMenu'
        id='mais-pontos'>
            <b-form-checkbox
            v-if='verpontos'
            v-model='modelPontosColeta'
            @change='pontosColeta'>
                Pontos de coleta
            </b-form-checkbox>
            <b-form-checkbox
            v-if='verpontos'
            v-model='modelPontosEntrega'
            @change='pontosEntrega'>
            Pontos de entrega
            </b-form-checkbox>
            <b-form-checkbox
            v-if='verpontos'
            v-model='modelPontosReferencia'
            @change='pontosReferencia'>
                Pontos de referência
            </b-form-checkbox>
            <b-form-checkbox
			v-if='verregioes'
            v-model="modelRegioesUsuario"
            @change='regioes'>
                Regiões
            </b-form-checkbox>
        </div>
        <!-- @mouseleave='fechaOpcoesMais' -->
        <div
        v-if='subMenuMais'
        class='subMenu'
        id='menu-mais'>
            <b-form-checkbox
			v-if='cadastrarregioes'
            v-model="modelCadastrarRegiao"
            @change='cadastrarRegiao'>
                Cadastrar região
            </b-form-checkbox>
            <b-form-checkbox
            v-if='cadastrarpontos'
            v-model='modelCadastrarPonto'
            @change='cadastrarPonto'>
                Cadastrar ponto
            </b-form-checkbox>
			<b-form-checkbox
			v-if='cadastrarcategorias'
			v-model="modelCadastrarCategoria"
			@change='cadastrarCategoria'>
				Cadastrar categoria
			</b-form-checkbox>
            <b-form-checkbox
			v-if='pmedirarea'
            v-model='modelMedirFArea'
            @change='medirArea'>
                Medir área
            </b-form-checkbox>
        </div>
        <div class='legendas-mapa'
			:class="{'legendas-mapa-offset':modelMedirFArea || modelCadastrarRegiao}">
            <b-button
            v-if='qtdPontos != 0'
            style="width:116px; height:26px"
            class='mr-1'
            v-b-tooltip.hover='titleEye'
            @click='mostrarLegendas'>
                <base-icon
                :size='18'
                class='icon-eye'
                :icon='iconEye'/>
            </b-button>
            <legenda-mapa
            title='Grupos de veículo'
            v-show='this.legendaGrupo.length'
            class='mt-1 mr-1'
            :legenda='legendaGrupo'/>
            <legenda-mapa
            title='Veiculos do grupo selecionado'
            v-show='legendaRastro.length'
            class='mr-1'
            :legenda="legendaRastro"/>
        </div>
    </div>
</template>

<script>
import baseIcon from '@/components/Atom/Icon/BaseIcon.vue'
import LegendaMapa from '@/components/Atom/Painel/legendaMapa.vue'
import {mapGetters} from 'vuex'
import {
	mdiEye,
	mdiEyeOff,
	mdiLogout,
	mdiMapSearchOutline,
	mdiPlaylistPlus,
	mdiMapMarkerRadius,
	mdiArrowLeft,
	mdiVectorSquare
} from '@mdi/js'
export default {
	name:'menuMapa',
	props: {
		classeMenu:{
			type: String,
			default: ''
		},
		atividade:{
			type: String,
			default:'',
		},
		legendaGrupo:{
			type: Array,
			default:()=>{return []}
		},
		legendaRastro:{
			type: Array,
			default:()=>{return []}
		},
		qtdPontos:{
			type: Number,
			default: 0
		},
		permissoes:{
			type:[Object,Array],
			required:true
		}
	},
	components: {
		baseIcon,
		LegendaMapa
	},
	data() {
		return {
			iconBusca      : mdiMapSearchOutline,
			iconMais       : mdiPlaylistPlus,
			iconHistPosicao: mdiVectorSquare,
			iconPontos     : mdiMapMarkerRadius,
			exit           : mdiArrowLeft,
			iconEye        : mdiEye,
			iconLogout     : mdiLogout,
			titleEye       : 'Mostrar nome dos pontos',
			tamIcone:25,
			modelCheckMais:'',
			optSelecionadaPontos:[],
			//controles e checkboxes
			procurandoPontos     :false,
			menuAberto           :false,
			subMenuPontos        :false,
			subMenuMais          :false,
			modelCadastrarRegiao :false,
			modelCadastrarPonto  :false,
			modelPontosColeta    :false,
			modelPontosReferencia:false,
			modelPontosEntrega   :false,
			modelRegioesUsuario  :false,
			modelMedirFArea      :false,
			modelCadastrarCategoria: false,
		}
	},
	methods: {
		/**
		 * @description faz o logout do usuário.
		 */
		logoutSistema(){
			this.$router.push({name:'logout'})
		},

		/**
		 * @description mostra o spinner enquanto os pontos estão carregando.
		 */
		loadingPontos(){
			this.procurandoPontos = true
		},

		/**
		 * @description mostra o spinner enquanto os pontos estão carregando.
		 */
		endLoadingPontos(){
			this.procurandoPontos  = false
		},

		/**
		 * @description reseta os seletores de pontos/região do menu.
		 * Feito para ser acionada quando muda a empresa selecionada.
		 */
		resetMenu(){
			this.modelPontosColeta 		= false
			this.modelPontosReferencia  = false
			this.modelPontosEntrega 	= false
			this.modelRegioesUsuario 	= false
			this.modelCadastrarRegiao 	= false
			this.modelCadastrarPonto    = false
			this.modelCadastrarCategoria = false
		},

		/**
		 * @param {string} permissao
		 * @description procura se o usuário possui permissão para
		 * utilizar ferramentas do sistema. Apenas verifica p/
		 * visualização.
		 * @returns {boolean} se o usuário tem ou não acesso.
		 */
		permissao(permissao){
			if(typeof this.permissoes[permissao] === 'undefined')
				return false
			return this.permissoes[permissao].ppvisualizar
		},

		/**
		 * @listens click - hexagono do menu
		 * @fires historico-posicoes
		 */
		histPosicao(){
			this.$emit('historico-posicoes')
		},

		/**
		 * @param {boolean} bool
		 * @fires medir-area
		 * @listens click - sub menu mais opções
		 */
		medirArea(bool){
			this.modelCadastrarRegiao = false
			this.modelCadastrarPonto = false
			this.subMenuMais = false
			this.$emit('medir-area', bool)
		},

		/**
		 * @param {boolean} bool
		 * @fires cadastrar-regiao
		 * @listens click - sub menu mais opções
		 */
		cadastrarRegiao(bool){
			this.modelCadastrarPonto = false
			this.modelMedirFArea = false
			this.subMenuMais = false
			this.$emit('cadastrar-regiao', bool)
		},

		/**
		 * @param {boolean} bool
		 * @fires cadastrar-categoria
		 * @listens click - sub menu mais opções
		 * @author Otávio 🦆 
		 */
		 cadastrarCategoria(bool){
			this.modelCadastrarRegiao = false
			this.modelCadastrarPonto = false
			this.modelMedirFArea = false
			this.subMenuMais = false
			this.$emit('cadastrar-categoria', bool)
		},

		/**
		 * @param {boolean} bool
		 * @fires cadastrar-ponto
		 * @listens click - sub menu mais opções
		 */
		cadastrarPonto(bool){
			this.modelCadastrarRegiao = false
			this.modelMedirFArea = false
			this.subMenuMais = false
			this.$emit('cadastrar-ponto', bool)
		},

		/**
		 * @listens click
		 */
		mostraOpcoesMais(){
			this.subMenuMais = !this.subMenuMais
		},

		/**
		 * @listens mouseleave - menu mais opções
		 */
		fechaOpcoesMais(){
			this.subMenuMais = false
		},
		
		/**
		 * @listens click
		 * @description controla a exibição do sub menu de pontos.
		 */
		mostrarOpcoesPonto(){
			this.subMenuPontos = !this.subMenuPontos
		},

		/**
		 * @listens mouseleave - menu de pontos
		 */
		fechaMenuPontos(){
			this.subMenuPontos = false
		},

		/**
		 * @listens click - hexágono principal do menu.
		 * @description faz o menu encolher e garante que os
		 * sub menus desapareçam.
		 */
		mudaMenu(){
			this.menuAberto    = !this.menuAberto
			this.subMenuPontos = false
			this.subMenuMais   = false
			var exit = document.getElementById('logout-system')
			// exit.disable = true
		},

		/** 
		 * @listens click - botão de sair 
		 * @description volta pra dashboard 😎
		 * @author Gui 🍺
		 */
		goback(){
			this.$router.push('Dashboard')
		},

		/**
		 * @listens click - no botão de busca
		 * @fires buscar
		 */
		emiteBuscar(){
			this.$emit('buscar')
		},

		/**
		 * @listens change - check de pontos de coleta
		 * @fires pontos-coleta
		 * @description essas 4 próximas funções fazem a mesma coisa para
         * diferentes tipos de pontos & regioes
		 * @param {boolean} bool
		 */
		pontosColeta(bool){
			this.subMenuPontos = false
			this.$emit('pontos-coleta', bool)
		},

		/**
		 * @fires pontos-entrega
		 * @param {boolean} bool
		 */
		pontosEntrega(bool){
			this.subMenuPontos = false
			this.$emit('pontos-entrega', bool)
		},

		/**
		 * @fires pontos-referencia
		 * @param {boolean} bool
		 */
		pontosReferencia(bool){
			this.subMenuPontos = false
			this.$emit('pontos-referencia', bool)
		},

		/**
		 * @fires regioes
		 * @param {boolean} bool
		 */
		regioes(bool){
			this.subMenuPontos = false
			this.$emit('regioes', bool)
		},

		/**
		 * @listens click - botão com o olho
		 * @fires mostrar-legendas
		 * @description altera o ícone e dispara um evento com uma booleana
		 * para o mapa saber se deve ou não exibir a legenda dos pontos.
		 * @author Gui 🍺
		 */
		mostrarLegendas(){
			var hidden 
			if(this.iconEye === mdiEye){
				this.iconEye  = mdiEyeOff
				hidden = false
				this.titleEye = 'Ocultar nome dos pontos'
			}else{
				this.iconEye = mdiEye
				hidden = true
				this.titleEye = 'Mostrar nome dos pontos'
			}
			this.$emit('mostrar-legendas', hidden)
		},
		...mapGetters(['getModulos'])
	},
	watch: {
		atividade(newValue){
			if(newValue===''){
				this.modelCadastrarRegiao = false
				this.modelCadastrarPonto  = false
				this.modelMedirFArea      = false
				this.modelCadastrarCategoria = false
			}
		},
	},
	computed:{
		modulosCliente(){
			return this.getModulos()
		},
		//Abandon hope all ye who enter here

		temFinderPlus(){
			if(typeof this.modulosCliente['finder/plus'] ==='undefined')
				return false
			return true
		},

		menuMais(){
			if(!this.temFinderPlus) return false
			var regioes = this.cadastrarregioes
			var pontos = this.cadastrarpontos
			var medir = this.pmedirarea
			var categorias = this.cadastrarcategorias
			if(regioes || pontos || medir || categorias)
				return true
			return false
		},

		pmedirarea(){
			if(!this.temFinderPlus) return false
			if(typeof this.permissoes['mapferramentas'] === 'undefined')
				return false 
			return this.permissoes.mapferramentas.ppvisualizar
		},

		/**
		 * @description verifica se o usuário tem permissão para cadastrar
		 * @author Otávio 🦆 
		 */
		cadastrarcategorias(){
			if(!this.temFinderPlus) return false
			if(typeof this.permissoes['mapcategorias']==='undefined')
				return false
			return this.permissoes['mapcategorias'].picadastrar || this.permissoes['mapcategorias'].ppcadastrar
		},

		decideMenuPontosRegioes(){
			var keys = ['ppcadastrar', 'ppeditar', 'ppexcluir', 'ppvisualizar']
			var permissoesRegEPontos = false
			var pregioes = false
			var ppontos = false
			if(typeof this.permissoes.mappontos !== 'undefined'){
				keys.forEach((k)=>{
					if(!ppontos)
						ppontos = this.permissoes.mappontos[k]
				})
			}
			if(typeof this.permissoes.mapregiao !== 'undefined'){
				keys.forEach((k)=>{
					if(!pregioes)
						pregioes = this.permissoes.mapregiao[k]
				})
			}
			permissoesRegEPontos = ppontos || pregioes
			if(this.menuAberto && permissoesRegEPontos)
				return true
			return false
		},

		verpontos(){
			if(!this.temFinderPlus) return false
			if(typeof this.permissoes['mappontos'] === 'undefined')
				return false
			return this.permissoes.mappontos.ppvisualizar
		},

		editarpontos(){
			if(!this.temFinderPlus) return false
			if(typeof this.permissoes['mappontos'] === 'undefined')
				return false 
			return this.permissoes.mappontos.ppeditar
		},

		cadastrarpontos(){
			if(!this.temFinderPlus) return false
			if(typeof this.permissoes['mappontos'] === 'undefined')
				return false 
			return this.permissoes['mappontos'].ppcadastrar
		},

		excluirpontos(){
			if(!this.temFinderPlus) return false
			if(typeof this.permissoes['mappontos'] === 'undefined')
				return false 
			return this.permissoes['mappontos'].ppexcluir
		},

		verregioes(){
			if(!this.temFinderPlus) return false
			if(typeof this.permissoes['mapregiao'] ==='undefined')
				return false
			return this.permissoes['mapregiao'].ppvisualizar
		},

		editarregioes(){
			if(!this.temFinderPlus) return false
			if(typeof this.permissoes['mapregiao']==='undefined')
				return false
			return this.permissoes['mapregiao'].ppeditar
		},

		cadastrarregioes(){
			if(!this.temFinderPlus) return false
			if(typeof this.permissoes['mapregiao']==='undefined')
				return false
			return this.permissoes['mapregiao'].ppcadastrar
		},

		excluirreigoes(){
			if(!this.temFinderPlus) return false
			if(typeof this.permissoes['mapregiao']==='undefined')
				return false
			return this.permissoes['mapregiao'].ppexcluir
		}
	}
}
</script>
<style lang="scss" scoped>
#menuMapa{
    color: white;
    $background-fundo : #0070e8;
    $background-frente: rgb(68, 70, 67);
    .icon-eye{
        padding-bottom: 3px;
    }
    .menuzito{
        position: absolute;
        right: 55px;
        height: 200px;
        width: 10px;
    }
    .legendas-mapa{
        width: 120px;
        position: absolute;
        top: 200px;
        right: 20px;
    }

	.legendas-mapa-offset{
        right: 80px !important;
	}
    .hexagono{
        clip-path:
            polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
        height: 60px;
        width: 70px;
        background-color: $background-fundo;
        cursor: pointer;
        img{
            position:absolute;
            top:10px;
            left:11px;
        }
        .hexagono{
            position: absolute;
            background-color: $background-frente;
            height: 54px;
            width: 64px;
            box-shadow: none;
            top:2px;
            left: 2px;
            .icone{
                position: absolute;
                top: 10px;
                right: 16px;
                height: 30px;
                width: 30px;
            }
        }
    }
	.aguia-fechado{
		position: absolute;
		right: -25px;
	}
    .aguia-aberto{
		position: absolute;
        top: 46px;
        right: 30px;
    }
	.logout-aberto{
		position: absolute;
		top:15px;
		right: -23px;
	}
    .quadrado-aberto{
		position: absolute;
        top:16px;
        right: 84px;
    }
    .regiao-aberto{
		position: absolute;
        top: 77px;
        right: 83px;
    }
    .busca-aberto{
		position: absolute;
        top: -15px;
        right: 31px;
    }
    .voltar-aberto{
		position: absolute;
        top: 76px;
        right: -24px;
    }
    .pontos-aberto{
		position: absolute;
        top:107px;
        right: 29px;
    }
    #mais-pontos{
        right: 100px;
        top: 168px;
    }
    #menu-mais{
        right: 154px;
        top:138px;
    }
    .subMenu{
        align-items: flex-start;
        line-height: 1.8;
        color:white;
        font-weight: bolder;
        padding: 6px;
        display: flex;
        background-color: $background-frente;
        width: 170px;
        flex-direction: column;
        position: absolute;
        z-index: 666;
    }
    .controle-legenda-marcadores{
        width: 60px;
        height: 40px;
        background-color: $background-frente;
        cursor: pointer;
    }
}
</style>