import { render, staticRenderFns } from "./PopupRegiaoCategoria.vue?vue&type=template&id=2b1e35c2&scoped=true"
import script from "./PopupRegiaoCategoria.vue?vue&type=script&lang=js"
export * from "./PopupRegiaoCategoria.vue?vue&type=script&lang=js"
import style0 from "./PopupRegiaoCategoria.vue?vue&type=style&index=0&id=2b1e35c2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b1e35c2",
  null
  
)

export default component.exports