<template>
    <modalEagle id="informacoesOS-" title="Detalhes ordem serviço" size='lg'>
        <template slot="modalBody">
            <b-overlay :show="loadingModal">
                <div class="col-sm-12 row">
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Tipo da ordem de serviço</div>
                        <div align='center' class="pb-2"> {{ informacaoOS.ostdescricao ?
                            informacaoOS.ostdescricao : '' }} </div>
                    </div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Equipamento antigo</div>
                        <div align='center' class="pb-2"> {{ informacaoOS.osatual ?
                            informacaoOS.osatual :
                            'Sem equipamento' }}</div>
                    </div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Equipamento novo</div>
                        <div align='center' class="pb-2"> {{ informacaoOS.osnovo ?
                            informacaoOS.osnovo :
                            'Sem equipamento' }}</div>
                    </div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Data criação</div>
                        <div align='center' class="pb-2"> {{ transformadaDataCriacao(informacaoOS.osdataevento) }}</div>
                    </div>
                    <div :class="informacaoOS.osdataagendado ? 'col-sm-4 pl-0 pr-0 pb-3' : ''"
                        v-html="mostrarDataAgendado()"></div>
                    <div :class="informacaoOS.osdataconfirmacao ? 'col-sm-4 pl-0 pr-0 pb-3' : ''"
                        v-html="mostrarDataConfirmacao()"></div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Motivo</div>
                        <div style="overflow-wrap: break-word" align='center' class="pb-2"> {{ informacaoOS.osmotivo ?
                            informacaoOS.osmotivo : '' }}</div>
                    </div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Status</div>
                        <div align='center' class="pb-2"> {{ informacaoOS.osstatus == 'C' ? 'Cancelada' :
                            informacaoOS.osstatus == 'F' ? 'Concluida' :
                                informacaoOS.osstatus == 'A' ? 'Agendada' :
                                    informacaoOS.osstatus == 'P' ? 'Pendente' : '' }}</div>
                    </div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Placa</div>
                        <div align='center' class="pb-2"> {{ informacaoOS.osplaca ?
                            informacaoOS.osplaca : '' }}</div>
                    </div>
                    <div :class="informacaoOS.osdataconfirmacao ? 'col-sm-4 pl-0 pr-0 pb-3' : ''"
                        v-html="mostrarJustificativa()"></div>
                </div>
            </b-overlay>
        </template>

        <template >
            <b-overlay :show="loadingModal">
            <div class="col-sm-12 row nopadding d-flex align-items-center">
                <div class="col-sm-6 row " v-if="informacaoOS.osstatus == 'A' || informacaoOS.osstatus == 'P'">
                    <span class="negrito" style="font-size: 12.9px;">Deseja confirmar a execução da ordem de serviço {{informacaoOS.oscodigo}}?</span>
                </div>
                <div class="col-sm-6 row ">
                    <div class="col-sm-4">
                        <simpleButton class="" :icon="mdiArrowLeftThick" text="Voltar" event="click"
                            @click="fechaModalDetalhesOperacao" extraClass="voltarAmarelo">
                        </simpleButton>
                    </div>
                    <div class="col-sm-4" v-if="informacaoOS.osstatus == 'A' || informacaoOS.osstatus == 'P'">
                        <simpleButton class="" :icon="mdiCloseThick" text="Cancelar" event="click"
                            @click="cancelarOS" type="red" :disabled="loadingModal">
                        </simpleButton>
                    </div>
                    <div class="col-sm-4" v-if="informacaoOS.osstatus == 'A' || informacaoOS.osstatus == 'P'">
                        <simpleButton class="" :icon="mdiCheckBold" text="Confirmar" event="click"
                            @click="confirmarOS" type="blue" :disabled="loadingModal">
                        </simpleButton>
                    </div>
                </div>
            </div>
        </b-overlay>
        </template>
    </modalEagle>
</template>

<script>
import Vue from 'vue'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { DateTime } from 'luxon';
import { mdiCheckBold, mdiCloseThick, mdiArrowLeftThick } from '@mdi/js'
export default {
    name: 'modalInformacaoOS',

    data() {
        return {
            mdiCheckBold: mdiCheckBold,
            informacaoOS: [],
            loadingModal: false,
            mdiCloseThick: mdiCloseThick,
            mdiArrowLeftThick: mdiArrowLeftThick,
        }
    },

    components: {
        'modalEagle': require('@/components/Atom/Modal/ModalEagle').default,
        'simpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
    },

    methods: {

        /**
         * @description Essa função deve ser utilizada por uma referência para "ativar"
         * essa modal, os argumentos da função são os necessários para 
         * conseguir popular a modal com as infomações corretas
         * @param {number} idOS - codigo da OS
         * @author Vitor Hugo 
         */
        preparaModal(idOS) {
            this.$bvModal.show('informacoesOS-')
            this.loadingModal = true
            var objt = { id: idOS }
            new HttpRequest().Post('/administrativo/cadastros/agendamento/buscar/os', objt)
                .then((dados) => {
                    if (dados.code == 200) {
                        this.informacaoOS = dados.data.informacaoOS
                        this.loadingModal = false
                    }
                })
        },

        /**
         * @description Método para transformar a data e hora no padrão para ser mostrada
         * @param {date} data - data e hora no formato (yyyy-LL-dd HH:mm:ss)
         * @return data transformada pelo luxon
         * @author Vitor Hugo 
         */
        transformadaDataCriacao(data) {
            if (data != undefined) {
                return DateTime.fromFormat(data, 'yyyy-LL-dd HH:mm:ss').toFormat('dd/LL/yyyy HH:mm:ss')
            }
        },

        /**
         * @description Método para transformar a data no padrão para ser mostrada
         * @param {date} data - data no formato (yyyy-LL-dd)
         * @return data transformada pelo luxon
         * @author Vitor Hugo 
         */
        transformadaData(data) {
            if (data != undefined) {
                return DateTime.fromFormat(data, 'yyyy-LL-dd').toLocaleString(DateTime.DATE_SHORT)
            }
        },

        fechaModalDetalhesOperacao() {
            this.$bvModal.hide('informacoesOS-')
        },

        /**
         * Função para mostrar o campo de justificativa de cancelamento
         * somente se a OS possuir o status cancelada
         * @author Marco Duz
         */
        mostrarJustificativa() {
            if (this.informacaoOS.osstatus === 'C') {
                return `
                    <div align="center" class="pb-2 negrito">Justificativa cancelamento</div>
                    <div style="overflow-wrap: break-word"; align="center" class="pb-2">${this.informacaoOS.osjustificativacancelamento || ''}</div>
                `;
            }
            return '';
        },

        /**
         * Função para mostrar o campo data de confirmação
         * somente se a OS possuir o status cancelada ou finalizada(confirmada)
         * @author Marco Duz
         */
        mostrarDataConfirmacao() {
            let escrita
            if (this.informacaoOS.osdataconfirmacao) {
                if (this.informacaoOS.osstatus == 'C') {
                    escrita = 'cancelamento'
                }
                else if (this.informacaoOS.osstatus == 'F') {
                    escrita = 'confirmação'
                }
                return `
                    <div align='center' class="pb-2 negrito"> Data ${escrita}</div>
                    <div align='center' class="pb-2"> ${this.transformadaData(this.informacaoOS.osdataconfirmacao)}</div>
                `;
            }
            return '';
        },
        /**
         * Função para mostrar o campo data de agendamento
         * somente se a OS possuir essa informação
         * OS pendentes tem o campo como null
         * @author Marco Duz
         */
        mostrarDataAgendado() {
            if (this.informacaoOS.osdataagendado) {
                return `
                    <div align='center' class="pb-2 negrito"> Data agendamento</div>
                    <div align='center' class="pb-2"> ${this.transformadaData(this.informacaoOS.osdataagendado)}</div>
                `;
            }
            return '';
        },

        /**
         * Função fecha o modal e dispara o método para confirmar OS na tela de agendamento 
         * enviando o código da OS
         * @author Marco Duz
         */
        confirmarOS(){
            this.$emit('confirmar', this.informacaoOS.oscodigo);
            this.fechaModalDetalhesOperacao();
        },

        /**
         * Função fecha o modal e dispara o método para cancelar OS na tela de agendamento 
         * enviando o código da OS
         * @author Marco Duz
         */
        cancelarOS(){
            this.$emit('cancelar', this.informacaoOS.oscodigo);
            this.fechaModalDetalhesOperacao();
        },
    }
}
</script>

<style lang="scss">
.negrito {
    font-weight: bold;
}

.voltarAmarelo {
    background-color: #f4b610 !important;
    font-weight: bold !important;
}
</style>