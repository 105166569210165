<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div
    :class="'p-10 absolute z-max bottom-32 right-0 max-width-200 rounded-20 min-width-480 ' + 'card-' + dados.statusRota">
    <p class="text-center text-2xl mb-3 font-bold">Indicadores da rota:</p>

    <div class="d-flex justify-content-between gap-14">
      <div class="w-50">
        <div class="mb-2">
          <div class="d-flex gap-7 nopadding justify-content-between -mb-14">
            <p class="nopadding">Tempo Improdutivo:</p>
            <p class="font-bold nopadding">{{ dados.tempoImprodutivo }}</p>
          </div>

          <div class="d-flex gap-7 nopadding justify-content-between">
            <p class="nopadding">Tempo Produtivo:</p>
            <p class="font-bold nopadding">{{ dados.tempoProdutivo }}</p>
          </div>
        </div>

        <div>
          <div class="d-flex gap-7 nopadding justify-content-between -mb-14">
            <p class="nopadding">Tempo Parado:</p>
            <p class="font-bold nopadding">{{ dados.tempoParado }}</p>
          </div>

          <div class="d-flex gap-7 nopadding justify-content-between -mb-14">
            <p class="nopadding">Tempo Execução:</p>
            <p class="font-bold nopadding">{{ dados.tempoExecucao }}</p>
          </div>

          <div class="d-flex gap-7 nopadding justify-content-between">
            <p class="nopadding">Tempo Condução:</p>
            <p class="font-bold nopadding">{{ dados.tempoConducao }}</p>
          </div>
        </div>

        <div class="mb-2">
          <div class="d-flex gap-7 nopadding justify-content-between -mb-14">
            <p class="nopadding">Tempo 1º Intervalo:</p>
            <p class="font-bold nopadding">{{ dados.primeiroIntervalo }}</p>
          </div>

          <div class="d-flex gap-7 nopadding justify-content-between -mb-14">
            <p class="nopadding">Tempo 2º Intervalo:</p>
            <p class="font-bold nopadding">{{ dados.segundoIntervalo }}</p>
          </div>

          <div class="d-flex gap-7 nopadding justify-content-between">
            <p class="nopadding">Total Intervalo:</p>
            <p class="font-bold nopadding">{{ dados.totalIntervalo }}</p>
          </div>
        </div>
      </div>

      <div style="height: 180px; display: flex; align-items: stretch;">
        <div style="border-left: solid 1px gray; height: 100%;"></div>
      </div>

      <div class="w-50">
        <div class="mb-2">
          <div class="d-flex gap-7 nopadding justify-content-between -mb-14">
            <p class="nopadding">Km Improdutivo:</p>
            <p class="font-bold nopadding">{{ dados.kmImprodutivo }}</p>
          </div>

          <div class="d-flex gap-7 nopadding justify-content-between">
            <p class="nopadding">Km Produtivo:</p>
            <p class="font-bold nopadding">{{ dados.kmProdutivo }}</p>
          </div>
        </div>

        <div>
          <div class="d-flex gap-7 nopadding justify-content-between -mb-14">
            <p class="nopadding">Percorrido:</p>
            <p class="font-bold nopadding">{{ dados.kmTotal }}</p>
          </div>

          <div class="d-flex gap-7 nopadding justify-content-between">
            <p class="nopadding">Média Consumo:</p>
            <p class="font-bold nopadding">{{ dados.mediaConsumo }}</p>
          </div>
        </div>

        <div class="mb-2">
          <div class="d-flex gap-7 nopadding justify-content-between -mb-14">
            <p class="nopadding">Velocidade Max:</p>
            <p class="font-bold nopadding">{{ dados.velocidadeMaxima }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import Vue from 'vue'

export default Vue.extend({
  name: "IndicadorMapa",

  props: {
    dados: {
      Type: Array,
      required: true
    }
  },
})
</script>

<style lang="css" scoped>
.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 16px !important;
}

.font-bold {
  font-weight: bold !important;
}

.p-10 {
  padding: 10px !important;
}

.gap-7 {
  gap: 7px
}

.gap-14 {
  gap: 20px
}

.bg-white {
  background-color: white;
}

.absolute {
  position: absolute;
}

.z-max {
  z-index: 2100;
}

.bottom-32 {
  bottom: 235px !important;
}

.right-0 {
  right: 0 !important
}

.max-width-200 {
  max-width: 200px !important;
}

.min-width-480 {
  min-width: 480px !important;
}

.-mb-14 {
  margin-bottom: -14px !important;
}

.w-50 {
  width: 50% !important;
}

.card-P {
  border: 5px solid #db905f !important;
  background-color: #ffe5d4;
}

.card-I {
  border: 5px solid #379fd5 !important;
  background-color: #c7e0ed;
}

.card-F {
  border: 5px solid #4ea028 !important;
  background-color: #d3f0c5;
}

.rounded-20 {
  border-radius: 20px !important;
}
</style>