<!-- /**
Este componente é o datatable para listagem de páginas principais
Link documentação: https://element.eleme.io/#/en-US/component/layout

-> Propriedades:
1- titles - Array - Array de titulos da TH na listagem - EX: [{'number':'index no array de data', 'name':'',
'?width':'100px'}]
2- data - Array - Array de objetos seguindo o index do title para ser mostrado
3- deleteEdit - String - Tipo de botões que vão para a coluna de ações - EX:
'editDelete/editStatus/detaisCopyEditDelete'
4- widthAcoes - String - Tamanho em px com - EX: 70000000px
-> Slots:
acoes: Última coluna da tabela

-> Eventos:
1- editarCadastro - Emitido quando o botão de edição do cadastro for clicado - Parametros:
1.1- cod - Código do item cadastrado
2- excluirCadastro - Emitido quando botão de exclusão for clicado - Parametros:
2.1- cod - Código do item cadastrado
3- alterarStatusCadastro - Emitido quando o botão de alteração de status for clicado - Parametros:
3.1- cod - Código do item cadastrado
3.2- newStatus - Novo status a ser alterado
4- visualizarDetalhesCadastro - Emitido quando botão de visualização de detalhes for clicado - Parametros:
4.1- cod - Código do item cadastrado
*/ -->

<template>
    <div class="col-sm-12 nopadding" id="tableListagem">
        <div style="margin-bottom: 10px" v-if='filter'>
            <el-row>
                <el-col :span="6" class='mr-2 divDontPrint'>
                    <el-input style="border-radius: 8px !important;" placeholder="Buscar"
                        v-model="filters[0].value"></el-input>
                </el-col>
            </el-row>
        </div>

        <div v-if='!loading'>
            <div class="col-sm-12">
                <data-tables style="border-radius: 8px !important;" :data="data" :filters="filters"
                    :pagination-props="{ pageSizes: pageSizes }" :layout="layout">
                    <ex-table-column :width="widthSlotLivre" v-if="slotLivre" label=" ">
                        <template slot-scope="scope">
                            <slot :row='scope.row' name='slotLivre' />
                            <!-- </slot> -->
                        </template>
                    </ex-table-column>
                    <el-table-column v-for="title in titles" :prop="title.number" :label="title.name"
                        :key="title.number" :width='title.width' :class-name="tableRowClassName" sortable="custom" />
                    <el-table-column v-if='acoes' label="Ações" :width="widthAcoes"
                        :class-name="'div_acao ' + tableRowClassName">
                        <template slot-scope="scope">
                            <slot name='acoes' :row='scope.row' :scope='scope'>
                                <template>
                                    <basicButtonsListagemED v-if="deleteEdit == 'editDelete'"
                                        @editarCadastro="editarClick" @excluirCadastro="excluirClick"
                                        :id="scope.row['codigo']">
                                    </basicButtonsListagemED>
                                    <basicButtonsListagemEDE v-else-if="deleteEdit == 'editStatus'"
                                        @editarCadastro="editarClick" @alterarStatus="alterarStatusClick"
                                        :id="scope.row['codigo']" :status="scope.row['status']">
                                    </basicButtonsListagemEDE>
                                    <basicButtonsListagemDCED v-else-if="deleteEdit == 'detaisCopyEditDelete'"
                                        @visualizarDetalhes="visualizarDetalhesClick" @copiarCadastro="copiarClick"
                                        @editarCadastro="editarClick" @excluirCadastro="excluirClick"
                                        :id="scope.row['codigo']" :status="scope.row['status']">
                                    </basicButtonsListagemDCED>
                                </template>
                            </slot>
                        </template>
                    </el-table-column>
                </data-tables>
            </div>
        </div>

        <div class='col-sm-12' v-else>
            <status slot='tbody' typeBar='div' statusBar='loading' />
        </div>
    </div>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    components: {
        'status': require('@/components/Atom/StatusInformation/StatusInformation').default,
        'basicButtonsListagemED': require('../../../components/Atom/Buttons/BasicButtonsListagemED').default,
        'basicButtonsListagemEDE': require('../../../components/Atom/Buttons/BasicButtonsListagemEDE').default,
        'basicButtonsListagemDCED': require('../../../components/Atom/Buttons/BasicButtonsListagemDCED').default,
    },
    props: {
        layout: {
            type: String,
            default: 'tool, table, pagination'
        },
        widthSlotLivre: {
            type: String,
            default: '200px'
        },
        // habilitado:{
        //     type: Boolean,
        //     default: false
        // },
        loading: {
            type: Boolean,
            default: false
        },
        titles: {
            type: Array,
            required: true,
            default: function () { return [{ 'number': '', 'name': '' }] }
        },
        widthAcoes: {
            type: String,
            default: '200px'
        },
        data: {
            type: [Array],
            required: true,
            default: function () { return [] }
        },
        deleteEdit: {
            type: String,
            default: 'editDelete'
        },
        acoes: {
            type: Boolean,
            default: true
        },
        filter: {
            type: Boolean,
            default: true
        },
        slotLivre: {
            type: Boolean,
            default: false,
        },
        tableRowClassName: {
            type: String,
            default: ''
        },
        pageSizes: {
            type: Array,
            default: () => {
                return [10, 25, 50]
            }
        },
        paramFilters: {
            type: Array,
            required: false,
            default: function () {
                return []
            }
        },
    },
    data() {
        var optionsFilter: any = [];
        if (this.paramFilters.length == 0) {
            var info: any = this.titles;
            var sz = info.length;
            for (var a = 0; a < sz; a++) {
                optionsFilter.push(info[a].number);
            }
        } else {
            optionsFilter = this.paramFilters
        }
        return {
            filters: [{
                prop: optionsFilter,
                value: ''
            }],
        }

    },
    methods: {
        editarClick(cod: any) {
            this.$emit('editarCadastro', cod)
        },

        excluirClick(cod: any) {
            this.$emit('excluirCadastro', cod);
        },

        alterarStatusClick(cod: any, newStatus: any) {
            this.$emit('alterarStatus', cod, newStatus);
        },

        alterarStatusDesativarClick(cod: any, newStatus: any) {
            this.$emit('alterarStatusDesativar', cod, newStatus);
        },

        copiarClick(cod: any) {
            this.$emit('copiarCadastro', cod);
        },

        visualizarDetalhesClick(cod: any) {
            this.$emit('visualizarDetalhesCadastro', cod);
        },

        confirmaEmailClick(cod: any) {
            this.$emit('confirmaEmail', cod);
        }
    }
})
</script>

<style lang="scss">
#tableListagem {
    .div_acao {
        @media print {
            display: none;
        }
    }

    button {
        @media print {
            display: none;
        }
    }

    .el-col-6 {
        float: right !important;
        padding: 0 10px;
    }

    .el-table td,
    .el-table th {
        padding: 1px 0 !important;
    }

    .el-table {
        .cell {
            padding-right: 5px !important;
            line-height: 20px !important;
        }
    }

    .el-table th {
        background-color: #f5f5f5 !important;
        color: #514e4e;
        font-weight: 700;
    }

    .el-table__empty-block {
        padding: 0px !important;
        font-size: 14px;
        color: #FFF;
        text-align: center;
        background: #e04b4a !important;

        .el-table__empty-text {
            color: #FFF !important;
        }
    }

    .el-table__row {
        td {
            border: 1px solid #f5f5f5 !important;
        }
    }

    .el-input__inner {
        border-radius: 8px !important;
    }

    .pagination-bar {
        @media print {
            display: none;
        }

        .pagination-wrap {
            text-align: right;

            .el-pagination {
                .el-pagination__jump {
                    display: none;
                }
            }
        }
    }

    .green {
        background: #dff0d8;
    }

    .orange {
        background: #fcf8e3;
    }

    .red {
        background: #f2dede;
    }

    .bluee {
        background: #b3d7ff;
    }
}
</style>