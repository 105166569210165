/**
Este é o componente básico para relatorio tendo seus botões de exportação, impressão e gerar

-> Propriedades:
1- disabledDropdown - Boolean - para desabilitar o botão de exportar - EX: disabledDropdown="true"
2- formatEnable - Object - formatos de exportação que o usuário tem acesso.
EX: {pdf:true, xls:true, csv:false, tickets:false, personalizado:false}

-> Slot:
1- Sem Nome: espaço para colocar algo a mais

-> Eventos:
1- gerarRelatorio - Emite quando o botão de gerar for clicado - Sem parametros
2- exportarRelatorio - Emite quando um dos botões de exportação forem clicados - Parametros:
2.1- type - tipo de exportação que for escolhido
*/

<template>
    <div align="right" :class="disabledDropdown ? 'col-sm-12 nopadding' : 'hoverDropdown col-sm-12 nopadding'">
        <slot></slot>

        <simpleButton v-if="btnLegenda" id="popoverInfo" width="100px" :icon="mdiAlertCircleOutline" type="orange"
            text="Legenda" title="Legenda"></simpleButton>

        <span v-show="disabledExportar">
            <b-dropdown size="sm" variant="secondary" class="mt-1" :disabled="disabledDropdown">
                <template v-slot:button-content>
                    <basicIcon size='18' :icon='iconExportar' />
                    Exportar
                </template>
                <b-dropdown-item :disabled="loading.find(v => v == true)" class="exportPdf" v-if='formatEnable.pdf'
                    @click="ExportClick('pdf')">
                    <basicIcon v-if='!loading[0]' size='18' :icon="iconPdf" />
                    <b-spinner v-else small label="Exportando" />
                    PDF
                </b-dropdown-item>
                <b-dropdown-item :disabled="loading.find(v => v == true)" class="exportXls" v-if='formatEnable.xls'
                    @click="ExportClick('xls')">
                    <basicIcon v-if='!loading[1]' size='18' :icon="iconXls" />
                    <b-spinner v-else small label="Exportando" />
                    Excel
                </b-dropdown-item>
                <b-dropdown-item :disabled="loading.find(v => v == true)" class="exportCsv" v-if='formatEnable.csv'
                    @click="ExportClick('csv')">
                    <basicIcon v-if='!loading[2]' size='18' :icon='iconCsv' />
                    <b-spinner v-else small label="Exportando" />
                    CSV
                </b-dropdown-item>
                <b-dropdown-item v-show="exportaTickets" :disabled="loading.find(v => v == true)" class="exportTickets"
                    v-if='formatEnable.tickets' @click="ExportClick('tickets')">
                    <basicIcon v-if='!loading[3]' size='18' :icon='mdiCartCheck' />
                    <b-spinner v-else small label="Exportando" />
                    Tickets
                </b-dropdown-item>
                <b-dropdown-item v-show="exportaPersonalizado" :disabled="loading.find(v => v == true)"
                    class="exportPersonalizado" v-if='formatEnable.personalizado' @click="ExportClick('personalizado')">
                    <basicIcon v-if='!loading[3]' size='18' :icon="infoPersonalizado.icon" />
                    <b-spinner v-else small label="Exportando" />
                    {{ infoPersonalizado.text }}
                </b-dropdown-item>
                <slot name="exportacao"></slot>
            </b-dropdown>
        </span>

        <simpleButton text="Imprimir" :icon="iconPrint" type="light-blue" id="btnImprimir" event="imprimir"
            @imprimir="ImprimirClick" />
        <simpleButton text="Gerar" v-bind="{ ...$attrs }" :icon="iconGerar" type="green" id="btnGerar" event="gerar"
            @gerar="GerarClick" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import {
    mdiThumbUpOutline,
    mdiPrinter,
    mdiFileReplaceOutline,
    mdiFileDelimitedOutline,
    mdiFilePdfBox,
    mdiFileExcelOutline,
    mdiCartCheck,
    mdiAlertCircleOutline
} from '@mdi/js'

export default Vue.extend({
    components: {
        'simpleButton': require('./SimpleButton').default,

        basicIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
    },

    inheritAttrs: false,

    props: {
        loading: {
            type: Array,
            required: false,
            default: function () { return Array(false, false, false, false) },
        },

        disabledDropdown: {
            type: Boolean,
            default: false,
        },

        /*um objeto com os formatos que devem aparecer p/ exportação */
        formatEnable: {
            type: Object,
            default: function () {
                return {
                    pdf: true,
                    csv: true,
                    xls: true,
                    tickets: true,
                    personalizado: true
                }
            }
        },

        exportaTickets: {
            type: Boolean,
            default: false,
        },

        exportaPersonalizado: {
            type: Boolean,
            default: false,
        },

        infoPersonalizado: {
            type: Object,
            default: function () {
                return {
                    text: 'Coleta',
                    icon: mdiFileExcelOutline,
                }
            }
        },

        btnLegenda: {
            type: Boolean,
            default: false,
        },

        disabledExportar: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            iconGerar: mdiThumbUpOutline,
            iconPrint: mdiPrinter,
            iconExportar: mdiFileReplaceOutline,
            iconPdf: mdiFilePdfBox,
            iconXls: mdiFileExcelOutline,
            iconCsv: mdiFileDelimitedOutline,
            mdiCartCheck: mdiCartCheck,
            mdiAlertCircleOutline: mdiAlertCircleOutline,
        }
    },

    methods: {
        GerarClick() {
            this.$emit('gerarRelatorio')
        },
        ImprimirClick() {
            window.print();
        },
        ExportClick(type: string) {
            this.$emit('exportarRelatorio', type)
        }
    }
})
</script>

<style lang="scss">
.btn-group {
    .btn-secondary {
        font-size: 12px !important;
        line-height: 20px !important;
        font-weight: 400 !important;
        padding: 4px 5px !important;
        text-align: center !important;
        white-space: nowrap !important;
        border-radius: 8px !important;
        border: 1px solid #fff !important;
        vertical-align: middle !important;
        display: inline-block !important;
        margin-bottom: 0 !important;
        touch-action: manipulation !important;
        user-select: none !important;
        background-image: none !important;
        height: 30px !important;
        width: 100px !important;
        background-color: #428BCA !important;
        margin-right: 2px !important;

        span {
            &::before {
                font-size: 12px !important;
                margin-left: 0px;
            }
        }
    }

    .dropdown-menu {
        padding: 0;
        border-radius: 0px;
        width: 100px !important;
        min-width: 100px;


        .dropdown-item {
            color: #22262e !important;
            font-size: 12px !important;
            border: 1px solid #d5d5d5;
            font-weight: normal !important;
            padding: 2px 10px;
            text-align: center;

            span {
                &::before {
                    margin-left: 0px;
                }
            }

            &:active {
                background-color: #fff;
            }
        }
    }

}
</style>