<template>
    <div class='d-flex justify-content-around' id='FCategorias'>
        <div id='seletorEmpresa' class='d-flex align-items-center'>
            <multiselect 
            class='seletorFCategoria'
            :options='arrayEmpresas'
            track-by='value'
            label='description'
            placeholder='Selecione uma empresa'
            :preselectFirst='true'
            :showLabels='false'
            :allowEmpty='false'
            v-model='cacliente'>
                <template #noResult>
                    Nenhum resultado encontrado
                </template>
            </multiselect>
        </div>
        <div
        id='descricaoCategoria'
        class='d-flex align-items-center'> 
            <input
            placeholder='Descrição'
            type='text'
            class='form-categoria'
            v-model='cadescricao'>
        </div>
        <div
        id='botoes'
        class='d-flex align-items-center'>
            <loading-button
            type='blue'
            event='click'
            :disabled='categoriaInvalida'
            :icon='iconCheck'
            width='50'
            title='Salvar'
            :isLoading='salvando'
            @click='salvarCategoria'/>
            <simple-button
            width='50'
            event='click'
            type='red'
            :icon='iconClose'
            @click="cancelaCategoria"/>
        </div>
    </div>
</template>

<script>
import simpleButton from '@/components/Atom/Buttons/SimpleButton.vue'
import baseIcon from '@/components/Atom/Icon/BaseIcon.vue'
import Multiselect from 'vue-multiselect'
import loadingButton from '@/components/Atom/Buttons/LoadingButton.vue'
import {conectionError} from '@/Services/Helpers/swellHeper'
import { required } from 'vuelidate/lib/validators'
import { mdiCheckBold, mdiCloseThick } from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';

export default {
    components: {
        loadingButton,
        simpleButton,
        Multiselect,
        baseIcon,
    },
    props: {
        arrayEmpresas: {
            type: Array,
            required: true
        }
    },
    validations: {
        cadescricao: { required },
        cacliente: { required }
    },
    data() {
        return {
            iconCheck: mdiCheckBold,
            iconClose: mdiCloseThick,
            cadescricao: '',
            cacliente: '',
            baseUri: '/finder/mapa/',
            salvando: false,
            cacodigo: ''
        }
    },
    methods: {
        cancelaCategoria() {
            this.$emit('cancela-categoria')
        },

        /**
        * @description Busca as categorias da empresa selecionada
        * @param {object} empresa - Empresa selecionada no multiselect
        * @author Otávio 🦆 
        */
        buscarCategoriasPorEmpresa() {
            if (!this.cacliente || !this.cacliente.value) return;

            this.salvando = true;
            const uri = `${this.baseUri}buscar/categorias`;

            new HttpRequest().Post(uri, { clientes: [this.cacliente.value] })
                .then((data) => {
                    if (data.code === 200 || data.status) {
                        this.categorias = data.data.categorias || [];
                    } else {
                        conectionError();
                    }
                })
                .catch((error) => {
                    conectionError();
                })
                .finally(() => {
                    this.salvando = false;
                });
        },
        /**
         * Faz a request para salvar a categoria, 
         * e emite o evento de salvar só depois de, de fato, salvar.
         * @author Otávio 🦆 
         */
        salvarCategoria() {
            this.salvando = true
            var uri
            if (this.cacodigo === '') {
                uri = `${this.baseUri}salvar/categoria`
                new HttpRequest().Post(uri, this.objCategoria)
                    .then((data) => {
                        if (data.code === 201) {
                            this.$emit('categoria-salva', data.data.categoria)
                            this.cadescricao = ''
                        } else {
                            conectionError()
                        }
                    }).finally(() => { this.salvando = false })
            } else {
                uri = `${this.baseUri}editar/categoria`
                var obj = this.objCategoria
                obj.cacodigo = this.cacodigo
                new HttpRequest().Post(uri, obj)
                    .then((data) => {
                        if (data.data.success) {
                            this.$emit('categoria-editada', data.data.categoria)
                            this.cadescricao = ''
                        }
                    }).finally(() => { this.salvando = false })
            }
        }
    },
    computed: {
        /**
         * validate do select de categorias
         * @author Otávio 🦆 
         */
        categoriaInvalida() {
            return this.$v.$invalid
        },
        /**
         * Encapsula o obj
         * @author Otávio 🦆 
         */
        objCategoria() {
            return {
                categoria: {
                    cadescricao: this.cadescricao,
                    cacliente: this.cacliente.value
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>

#FCategorias {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-radius: 5px;
    width: auto;
    max-width: 600px;

    .form-categoria {
        margin-left: 10px;
        margin-right: 20px;
        height: 42px;
        width: 150px;
        opacity: 1 !important;
    }

    .seletorFCategoria {
        width: 250px;
        opacity: 1;
    }

    #botoes {
        display: flex;
        gap: 12px;
    }
    #botoes button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 30px;
    }

    #botoes svg {
        width: 24px;
        height: 24px;
    }
}


</style>