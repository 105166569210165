<template>
    <modal-eagle id='historicoLinhasRegiao' :noCloseOnEsc='true' :hardToClose='true' :noCloseOnBackdrop='true'>
        <template #modal-header>
            <h4>Histórico das linhas da região - </h4>
        </template>
        <template #modalBody>
            <b-overlay :show='gerando'>
                <div class='d-flex justify-content-around align-items-end'>
                    <div class='col-4 nopadding'>
                        <!-- Label para o date picker -->
                        <div class="form-group mb-0">
                            <label>Data</label>
                            <div class='filtro-campo'>
                                <el-date-picker id="periodoPickerHistorico" style="height: 40px; width: 100%;"
                                    v-model="modelData" :picker-options="pickerOptions"
                                    class="datePicker tamanhoFonteInfo" type="daterange" size="mini" range-separator="-"
                                    value-format="dd/MM/yyyy" format="dd/MM/yyyy" start-placeholder=""
                                    end-placeholder="" :disabled='gerando' @change="mudaDataFiltro">
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                    <div class='col-3'>
                        <div class="form-group mb-0">
                            <label for='horaInicio'>Horário inicial</label>
                            <input v-model='modelHoraIncio' type='time' :disabled='gerando' step='3'
                                class='form-control' id='horaInicio'>
                        </div>
                    </div>
                    <div class='col-3'>
                        <div class="form-group mb-0">
                            <label for='horaFinal'>Horário Final</label>
                            <input v-model='modelHoraFinal' type='time' :disabled='gerando' step='3'
                                class='form-control' id='horaFinal'>
                        </div>
                    </div>
                    <div class='col-2'>
                        <loading-button :isLoading='gerando' :icon='iconGerar' event="click" type='green' text=' Gerar'
                            @click='gerarHistoricoLinhas' />
                    </div>
                </div>
                <hr style="width:97%">
                <div class='mt-2'>
                    <div class="d-flex flex-row-reverse pb-2">
                        <div class='col-4'>
                            <input placeholder='Buscar' class='form-control' v-model='filtroUser'>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th class="largura-tr-td" style="width: 20px;"></th>
                                <th class="largura-tr-td">Linha</th>
                                <th class="largura-tr-td">Hora início</th>
                                <th class="largura-tr-td">Hora fim</th>
                                <th>Transbordo</th>
                            </tr>
                        </thead>
                        <tbody v-if='dataHistLinha.length && !gerando'>
                            <tr v-for="(linha, index) in dataHistLinhaShow" :key="index">
                                <td style="text-align: right; width: 20px;">
                                    <span class="botao" @click="abreInfoLinha(linha)">
                                        <baseIcon size="18" class="cursor-pointer" :icon="mdiFlagTriangle"
                                            :style="defineCorBandeira(linha)" />
                                    </span>
                                </td>
                                <td class="largura-tr-td" v-text='linha.descLinha'></td>
                                <td class="largura-tr-td" v-text='linha.rotaHoraInicio'></td>
                                <td class="largura-tr-td" v-text='linha.rotaHoraFim'></td>
                                <td :class='linha.classe' v-text='linha.temTransbordo'></td>
                            </tr>
                        </tbody>

                        <tbody v-else>
                            <tr>
                                <td colspan="5" class="text-center py-3">
                                    <status-information :statusBar='statusStatusInformation'
                                        :msgCentro='msgStatusInformation' />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-overlay>
        </template>
        <template #modalFooter>
            <simple-button :icon='iconClose' text='Fechar' type='red' event='click' @click='fechaModal' />
        </template>
    </modal-eagle>
</template>
<script>
import ModalEagle from '@/components/Atom/Modal/ModalEagle.vue'
import SimpleButton from '@/components/Atom/Buttons/SimpleButton.vue'
import loadingButton from '@/components/Atom/Buttons/LoadingButton.vue'
import { mdiThumbUp, mdiCloseThick, mdiFlagTriangle } from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import StatusInformation from '../StatusInformation/StatusInformation.vue'
import { pickerOptions } from "@/components/Atom/Painel/datePickerHelper.js"
import { DateTime } from 'luxon'
import Swal from 'sweetalert2'

export default {
    components: {
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
        ModalEagle,
        loadingButton,
        SimpleButton,
        StatusInformation
    },
    props: {
        clienteSelecionado: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            uriBase: '/finder/linhas/',
            iconGerar: mdiThumbUp,
            iconClose: mdiCloseThick,
            mdiFlagTriangle: mdiFlagTriangle,
            gerando: false,
            modelHoraIncio: '00:00:00',
            modelHoraFinal: '23:59:59',
            modelData: [
                DateTime.now().toFormat("dd/MM/yyyy"),
                DateTime.now().toFormat("dd/MM/yyyy")
            ],
            pickerOptions: pickerOptions,
            desenho: [],
            dataHistLinha: [],
            msgStatusInformation: 'Clique em gerar para obter as informações',
            statusStatusInformation: 'info',
            filtroUser: '',
            regiaoAtiva: null,
        }
    },
    methods: {
        /**
         * Manipula alterações no intervalo de data
         * @param {Array|null} data - Intervalo de datas selecionado
         * @author Otávio 🦆
         */
        mudaDataFiltro(data) {
            if (data) {
                this.dataHistLinha = [];
                this.verificaPeriodo();
            }
        },

        /**
         * Verifica se o período selecionado não excede 7 dias
         * @returns {boolean} - Retorna true se o período é válido
         * @author Otávio 🦆
         */
        verificaPeriodo() {
            if (!this.modelData || !Array.isArray(this.modelData) || this.modelData.length < 2) return true;

            var dataInicio = DateTime.fromFormat(this.modelData[0], "dd/MM/yyyy");
            var dataFim = DateTime.fromFormat(this.modelData[1], "dd/MM/yyyy");
            var diff = dataFim.diff(dataInicio, "days").toObject();

            if (diff.days > 6) {
                var novaDataFim = dataInicio.plus({ days: 6 });
                this.modelData = [this.modelData[0], novaDataFim.toFormat("dd/MM/yyyy")];

                Swal.fire({
                    title: 'Período ajustado',
                    text: 'O período máximo permitido é de 7 dias',
                    icon: 'info',
                    confirmButtonText: 'Ok'
                });

                return false;
            }

            return true;
        },

        /**
         * @param {object} dadoLinha
         * @description prepara os dados e monta o objeto para enviar para 
         * o info linha
         * @author Otávio 🦆 
         */
        abreInfoLinha(dadoLinha) {
            let primeiroEventoDate = new Date("2024-06-14T" + dadoLinha.primeiroEvento);
            let ultimoEventoDate = new Date("2024-06-14T" + dadoLinha.ultimoEvento);
            let linhaECodigo = dadoLinha.linha.split(' | ');

            if (dadoLinha.ativo && !dadoLinha.inativo) {
                const obj = {
                    data: this.modelData[0], // Usamos a data inicial do intervalo
                    horaIni: primeiroEventoDate.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }),
                    horaFim: ultimoEventoDate.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }),
                    linha: dadoLinha.linhaCodigo,
                    descricao: linhaECodigo[0],
                    codigo: linhaECodigo[1]
                };
                this.$emit('selecionar-linha-coletivo', dadoLinha);
                this.$emit('abre-info-linha', obj);
                this.filtroUser = '';
                this.fechaModal();
            } else {
                let message = 'Esta linha não está ativa para visualizar as informações detalhadas';
                let title = 'Linha não está ativa!';
                let icon = 'warning';

                if (dadoLinha.inativo) {
                    message = 'Ative a linha para ver as informações de trajeto e paradas.';
                    title = 'Linha Inativa';
                    icon = 'warning';
                }

                Swal.fire({
                    title: title,
                    text: message,
                    icon: icon,
                    confirmButtonText: 'Ok'
                });
            }
        },

        /**
         * Fecha o modal e reseta os dados.
         * @author Otávio 🦆
         */
        fechaModal() {
            this.dataHistLinha = []
            this.$emit('fecha-hist-linha-regiao')
            this.$bvModal.hide('historicoLinhasRegiao')
        },

        /**
         * @param {number[]} arg - desenho da região para verificar.
         * @param {number[]} clientes - clientes selecionados no mapa
         * @param {string|object} regiao - nome da região para exibir no título
         * @param {Array} linhasRegiao - linhas que pertencem à região
         * @param {Object} dataFiltros - Dados de filtro do FiltrosPainelColetivo
         * @description inicia o modal de histórico de linhas.
         * @author Otávio 🦆
         */
        showModal(arg, clientes, regiao = '', linhasRegiao = [], dataFiltros = null) {
            this.dataHistLinha = [];
            this.statusStatusInformation = 'info';
            this.msgStatusInformation = 'Clique em gerar para obter as informações';

            if (dataFiltros) {
                if (dataFiltros.data && Array.isArray(dataFiltros.data)) {
                    this.modelData = dataFiltros.data;
                }
                if (dataFiltros.horaInicio) {
                    this.modelHoraIncio = dataFiltros.horaInicio;
                }
                if (dataFiltros.horaFinal) {
                    this.modelHoraFinal = dataFiltros.horaFinal;
                }
            }

            this.gerando = true;
            this.statusStatusInformation = 'loading';
            this.msgStatusInformation = 'Carregando dados...';

            this.$bvModal.show('historicoLinhasRegiao');
            this.desenho = arg;

            if (typeof regiao === 'object' && regiao !== null) {
                this.regiaoAtiva = regiao;
            } else {
                this.regiaoAtiva = {
                    redescricao: regiao,
                    recodigo: null
                };
            }

            if (regiao) {
                this.$nextTick(() => {
                    const headerElement = document.querySelector('#historicoLinhasRegiao .modal-header h4');
                    if (headerElement) {
                        const displayName = typeof regiao === 'object' ? regiao.redescricao : regiao;
                        headerElement.textContent = `Histórico das linhas da região - ${displayName}`;
                    }

                    setTimeout(() => {
                        if (linhasRegiao && linhasRegiao.length > 0) {
                            this.dataHistLinha = linhasRegiao.map(linha => ({
                                descLinha: linha.descLinha,
                                rotaHoraInicio: linha.horarioIniciado || linha.rotaHoraInicio || '',
                                rotaHoraFim: linha.rotaHoraFim,
                                temTransbordo: linha.transbordo,
                                classe: linha.classe,
                                linha: `${linha.descLinha} | ${linha.codigoLinha}`,
                                linhaCodigo: linha.codigoLinha,
                                primeiroEvento: linha.rotaHoraInicio,
                                ultimoEvento: linha.rotaHoraFim || '',
                                ativo: true,
                                inativo: false,
                                statusRota: linha.statusRota || 'P'
                            }));

                            this.statusStatusInformation = 'success';
                            this.msgStatusInformation = 'Dados carregados com sucesso';
                        } else {
                            this.dataHistLinha = [];
                            this.statusStatusInformation = 'warning';
                            this.msgStatusInformation = 'Nenhuma linha encontrada para esta região';
                        }
                        this.gerando = false;
                    }, 800);
                });
            }
            
        },

        /**
         * Atualiza a lista de linhas exibidas no histórico com base nos novos dados recebidos.
         * @param {Array} linhas - Lista de linhas para atualizar no histórico.
         * @author Otávio 🦆
         */
        atualizarLinhas(linhas) {
            this.dataHistLinha = linhas && linhas.length > 0 ? linhas.map(linha => ({
                descLinha: linha.descLinha,
                rotaHoraInicio: linha.horarioIniciado || linha.rotaHoraInicio || '',
                rotaHoraFim: linha.rotaHoraFim,
                temTransbordo: linha.transbordo,
                classe: linha.classe,
                linha: `${linha.descLinha} | ${linha.codigoLinha}`,
                linhaCodigo: linha.codigoLinha,
                primeiroEvento: linha.rotaHoraInicio,
                ultimoEvento: linha.rotaHoraFim || '',
                ativo: true,
                inativo: false,
                statusRota: linha.statusRota || 'P'
            })) : [];

            if (this.dataHistLinha.length > 0) {
                this.statusStatusInformation = 'success';
                this.msgStatusInformation = 'Dados carregados com sucesso';
            } else {
                this.statusStatusInformation = 'warning';
                this.msgStatusInformation = 'Nenhuma linha encontrada para os filtros aplicados';
            }

            this.gerando = false;
        },


        /**
         * Determina o estilo do ícone com base no status da rota
         * @param {Object} linha - Objeto com informações da linha
         * @returns {Object} Objeto de estilo CSS com a cor apropriada
         * @author Otávio 🦆 
         */
        defineCorBandeira(linha) {
            if (linha.statusRota === 'F') {
                return { color: '#4ea028' };
            } else if (linha.statusRota === 'I') {
                return { color: '#379fd5' };
            } else if (linha.statusRota === 'P') {
                return { color: '#db905f' };
            } else {
                return { color: 'currentColor' };
            }
        },

        /**
         * @description Gera o histórico de linhas com os parâmetros selecionados
         * @author Otávio 🦆
         */
        gerarHistoricoLinhas() {
            this.gerando = true;
            this.statusStatusInformation = 'loading';
            this.msgStatusInformation = 'Carregando dados...';

            this.$emit('atualiza-data-filtro', {
                dataInicio: this.modelData[0],
                dataFim: this.modelData[1],
                horaInicio: this.modelHoraIncio,
                horaFinal: this.modelHoraFinal
            });
        },
    },
    computed: {
        /**
         * @description Filtra as linhas de dados com base no filtro do usuário.
         * @returns {Array} Um array filtrado de linhas que correspondem ao filtro do usuário.
         * @author Otávio 🦆
         */
        dataHistLinhaShow() {
            let regexp = new RegExp(this.filtroUser, 'gi')
            return this.dataHistLinha.filter((linha) => {
                return regexp.test(linha.linha)
            })
        }
    },
    watch: {
        /**
        * @description Reseta o status e a mensagem de informações quando a hora inicial é alterada.
        * @author Otávio 🦆
        */
        modelHoraIncio() {
            this.dataHistLinha = [];
            this.statusStatusInformation = 'info';
            this.msgStatusInformation = 'Clique em gerar para obter as informações';
        },

        /**
        * @description Reseta o status e a mensagem de informações quando a hora final é alterada.
        * @author Otávio 🦆
        */
        modelHoraFinal() {
            this.dataHistLinha = [];
            this.statusStatusInformation = 'info';
            this.msgStatusInformation = 'Clique em gerar para obter as informações';
        },

        /**
         * @description Reseta o status e a mensagem de informações quando a data é alterada.
         * @author Otávio 🦆
         */
        modelData() {
            this.dataHistLinha = [];
            this.statusStatusInformation = 'info';
            this.msgStatusInformation = 'Clique em gerar para obter as informações';
        },

        /**
         * @description Atualiza o status com base no valor de dataHistLinha.
         * Exibe uma mensagem de aviso ou carregamento, dependendo do estado.
         * @param {Array} newValue - O novo valor de dataHistLinha.
         * @author Otávio 🦆
         */
        dataHistLinha(newValue) {
            if (!newValue || newValue.length === 0) {
                if (this.gerando) {
                    this.statusStatusInformation = 'loading';
                    this.msgStatusInformation = 'Carregando dados...';
                } else {
                    this.statusStatusInformation = 'warning';
                    this.msgStatusInformation = 'Nenhuma linha encontrada para os filtros aplicados';
                }
            }
        }
    },
}
</script>

<style lang='scss' scoped>
#historicoLinhasRegiao {
    position: absolute;
    z-index: 300;
    top: 0.3%;
    left: 0.3%;

    label {
        margin-bottom: 5px !important;
        font-size: 14px;
    }

    input {
        padding-left: 5px !important;
        height: 40px;
    }

    .red {
        color: red;
    }

    .green {
        color: green;
    }

    table {
        width: 100%;

        tbody {
            max-height: 250px;
            overflow: auto;
            display: block;

            td {
                text-align: left;
                padding-top: 3px;
                padding-bottom: 3px;
                padding-left: 7px;
                font-size: 12px;
            }
        }

        .largura-tr-td {
            width: 200px;
        }

        th {
            padding-top: 5px;
            padding-left: 2px !important;
            background-color: #f5f5f5;
            border-bottom: 2px solid #dddddd;
        }

        tr {
            width: 100%;
            display: table;
            border-bottom: 1px solid #dddddd;

            .redTr {
                color: red;
            }

            .greenTr {
                color: #71c16c;
            }
        }
    }

    table thead,
    table tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
}

.el-date-editor.el-range-editor {
    display: flex !important;
    align-items: center !important;
}

.el-date-editor .el-range-separator {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
    line-height: 1 !important;
    vertical-align: middle !important;
    padding: 0 5px !important;
}

#periodoPickerHistorico.el-date-editor .el-range-separator {
    padding-bottom: 0 !important;
}
</style>