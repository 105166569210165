<template>
    <panelEagle :loading="loadingPanel" id="operacaoRotas">
        <div class="col-sm-12 row nopadding">
            <!-- Filtros -->
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiMapSearch" titulo="Operação de Rotas" />
                </div>

                <div class="col-sm-6">
                    <basicEIG :disabled="!estaValido" :loading="loadingExportar" @gerarRelatorio="gerarRelatorio"
                        @exportarRelatorio="exportarRelatorio" :disabledDropdown="!estaValido || !tamanhoRelatorio"
                        :btnLegenda="false" style="float:right;" :formatEnable="formatEnable" />
                </div>

                <slideUpAndDown>
                    <slot slot="filters">
                        <div class="col-sm-12">
                            <hr />
                        </div>

                        <div class="col-sm-12 row nopadding divDontPrint mb-3">
                            <div class="filter-grid">
                                <div class="filter-item">
                                    <InputRangeWithLimit name="selectData" titulo="Intervalo" :isObrigatorio="true"
                                        :intervalo="diasNoMes" @changeInput="changeData" class="select-container" />
                                </div>

                                <div class="filter-item">
                                    <selectAll @changeSelect="selectEmpresas" :isMultiple="true"
                                        nameForRadio="radiosEmpresas" :labels="labelsSelectEmpresa"
                                        :optionsArray="optionsSelectEmpresas" firstSelected="E"
                                        :selected="selectedEmpresa" :hasSelectAll="true" class="select-container" />
                                </div>

                                <div class="filter-item">
                                    <selectAll ref="selectVT" :disabled="$v.empresasArray.$invalid"
                                        :loading="loadingVel" @selectCriado="selectCriadoVeiculosTags"
                                        @changeCheck="changeCheckVeiculosTags" @changeSelect="selectVeiculosTags"
                                        :selected="selectedVeiculos" :isMultiple="true"
                                        nameForRadio="radiosVeiculosTags" :labels="labelsSelectVeiculosTags"
                                        :optionsArray="optionsVeiculosTags" firstSelected="V" :hasSelectAll="true"
                                        class="select-container" />
                                </div>

                                <div class="filter-item">
                                    <selectAll @changeSelect="selectRotas" :isMultiple="true" nameForRadio="radiosRotas"
                                        :labels="labelSelectRotas" :optionsArray="optionsSelectRotas" firstSelected="R"
                                        :selected="selectedRotas" :hasSelectAll="true" ref="selectRotas"
                                        :disabled="$v.empresasArray.$invalid" :loading="loadingVel"
                                        class="select-container" />
                                </div>
                            </div>

                            <div class="filter-grid">
                                <div class="filter-item">
                                    <selectAll @changeSelect="selectCargas" :isMultiple="true"
                                        nameForRadio="radiosCargas" :labels="labelSelectCargas"
                                        :optionsArray="optionsSelectCargas" firstSelected="CR"
                                        :selected="selectedCargas" :hasSelectAll="true" :loading="loadingVel"
                                        :disabled="$v.empresasArray.$invalid" class="select-container" />
                                </div>

                                <div class="filter-item">
                                    <SelectMarcadores :id="'select-segmentos'" ref="refSelectSegmentos"
                                        :isMultiple="true" :hasSelectAll="true" nameForRadio="radiosSegmentos"
                                        :labels="labelSelectSegmentos" firstSelected="SG"
                                        :optionsArray="optionsSegmentos" :extraClassParent="'select-container'"
                                        :selected="selectedSegmentos" :loading="loading"
                                        @changeSelect="selectSegmentos" />
                                </div>

                                <div class="filter-item">
                                    <SelectMarcadores :id="'select-unidade'" ref="refSelectUnidade" :isMultiple="true"
                                        :hasSelectAll="true" nameForRadio="radiosUnidade" :labels="labelSelectUnidades"
                                        firstSelected="UN" :optionsArray="optionsUnidade"
                                        :extraClassParent="'select-container'" :selected="selectedUnidade"
                                        :loading="loading" @changeSelect="selectUnidade" />
                                </div>

                                <div class="filter-item d-flex nopadding col-sm-12">
                                    <!-- Input de tempo parado -->
                                    <div class="col-sm-4 nopadding mr-1">
                                        <inputSimple label='Paradas' type='time' :value='horarioParadaSelecionada'
                                            @changeInput='changeHorarioInicial' />
                                    </div>

                                    <!-- Input Status -->
                                    <div class="col-sm-8 nopadding">
                                        <buttonsFilters @buttonsFiltersChange="changeStatus"
                                            :arrayButtons="arrayButtonsStatus" label="Status" :onlyOneSelected="false">
                                        </buttonsFilters>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </slot>
                </slideUpAndDown>
            </div>

            <!-- Indicadores -->
            <div class="indicador-container" v-if="tamanhoRelatorio > 0">
                <div class="indicador-card blue">
                    <div class="icon-container">
                        <base-icon :icon="mdiMapMarkerDistance" size="64" class="icon icon-large" />
                    </div>
                    <div class="content">
                        <div class="value">{{ tamanhoRelatorio }}</div>
                        <div class="label">Rotas</div>
                    </div>
                </div>

                <div class="indicador-card alertas">
                    <div class="icon-container">
                        <base-icon :icon="mdiAlertOctagonOutline" size="72" class="icon icon-large" />
                    </div>
                    <div class="content">
                        <div class="value">{{ totalAlertas }}</div>
                        <div class="label">Alertas</div>
                    </div>
                </div>

                <div class="indicador-card green">
                    <div class="icon-container">
                        <base-icon :icon="mdiPercentOutline" size="42" class="icon" />
                    </div>
                    <div class="content">
                        <div class="value">{{ eficienciaGeral }}%</div>
                        <div class="label">Eficiência geral</div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 mt-4 divTabela" v-if="tamanhoRelatorio > 0">
                <table class="table table-striped table-bordered tabela">
                    <thead>
                        <tr>
                            <th>Rota</th>
                            <th>Carga</th>
                            <th colspan="2">Início da Rota</th>
                            <th colspan="2">Fim da Rota</th>
                            <th>Tempo de Parada</th>
                            <th>Veículo</th>
                            <th>Motorista</th>
                            <th>Transportador</th>
                            <th>Porcentagem</th>
                            <th>Acionamento de sensor fora de ponto</th>
                            <th>Parada em desvio de rota</th>
                            <th>Excedente jornada</th>
                            <th>Atraso de entrega</th>
                            <th>Mau funcionamento da can</th>
                            <th>Temperatura sensor</th>
                            <th>Bump</th>
                            <th>Concentração de Co² alta</th>
                            <th>Baixa umidade</th>
                            <th>Área Risco</th>
                            <th>Pânico</th>
                            <th>Sem Comunicação</th>
                            <th>Reboque</th>
                            <th>Parada Proibida</th>
                            <th>Aproximação Ponto Referência</th>
                            <th>Bateria Removida</th>
                            <th>Freada brusca</th>
                            <th>Colisão</th>
                            <th>Desvio de rota</th>
                            <th>Aceleração brusca</th>
                            <th>Freada brusca em curva</th>
                            <th>Curva em alta velocidade</th>
                            <th>Aceleração brusca em curva</th>
                            <th>Bloqueio de sinal</th>
                            <th>Temperatura do motor</th>
                            <th>Veículo sem motorista associado</th>
                            <th>Excesso Velocidade</th>
                        </tr>
                    </thead>

                    <tbody>
                        <template v-for="(rota, index) in rotas">
                            <tr :key="'tags-' + index">
                                <td colspan="40" style="background-color: #ffffff;">
                                    <div class="tag-container">
                                        <template v-for="(tag, tagIndex) in rota.tags">
                                            <span :key="tagIndex" class="tag" :style="{ backgroundColor: tag.cor }">
                                                {{ tag.descricao }}
                                            </span>
                                        </template>
                                        <div class="rota-status"
                                            :class="{ 'tratada': temAlertaTratado(rota), 'nao-tratada': !temAlertaTratado(rota) }">
                                            <span>{{ temAlertaTratado(rota) ? 'Rota tratada' : 'Rota não tratada'
                                            }}</span>
                                            <base-icon :icon="mdiCheckBold" size="16" class="status-icon"
                                                v-if="temAlertaTratado(rota)" />
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr :key="'data-' + index">
                                <td>{{ rota.rota }}</td>
                                <td>{{ rota.cargaCodigo }}</td>
                                <td colspan="2">{{ rota.dataInicio }}</td>
                                <td colspan="2">{{ rota.dataFim }}</td>
                                <td>{{ rota.tempoParada }}</td>
                                <td>{{ rota.veiculo }}</td>
                                <td>{{ rota.motorista }}</td>

                                <td>{{ rota.transportador }}</td>
                                <td class="text-center" :class="{
                                    'porcentagem-verde': rota.porcentagem >= 50,
                                    'porcentagem-amarelo': rota.porcentagem < 50 && rota.porcentagem > 20,
                                    'porcentagem-vermelho': rota.porcentagem <= 20
                                }">{{ rota.porcentagem }}%</td>
                                <td class="text-center"
                                    :class="rota.acionamentoForaPonto.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.acionamentoForaPonto.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.paradaDesvioRota.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.paradaDesvioRota.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.excedenteJornada.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.excedenteJornada.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.atrasoEntrega.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.atrasoEntrega.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.mauFuncionamentoCan.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.mauFuncionamentoCan.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.temperaturaSensor.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.temperaturaSensor.total }}
                                </td>
                                <td class="text-center" :class="rota.bump.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.bump.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.concentracaoCO2Alta.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.concentracaoCO2Alta.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.baixaUmidade.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.baixaUmidade.total }}
                                </td>
                                <td class="text-center" :class="rota.areaRisco.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.areaRisco.total }}
                                </td>
                                <td class="text-center" :class="rota.panico.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.panico.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.semComunicacao.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.semComunicacao.total }}
                                </td>
                                <td class="text-center" :class="rota.reboque.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.reboque.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.paradaProibida.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.paradaProibida.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.aproximacaoPontoReferencia.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.aproximacaoPontoReferencia.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.bateriaRemovida.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.bateriaRemovida.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.freadaBrusca.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.freadaBrusca.total }}
                                </td>
                                <td class="text-center" :class="rota.colisao.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.colisao.total }}
                                </td>
                                <td class="text-center" :class="rota.desvioRota.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.desvioRota.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.aceleracaoBrusca.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.aceleracaoBrusca.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.freadaBruscaCurva.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.freadaBruscaCurva.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.curvaAltaVelocidade.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.curvaAltaVelocidade.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.aceleracaoBruscaCurva.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.aceleracaoBruscaCurva.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.bloqueioSinal.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.bloqueioSinal.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.temperaturaMotor.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.temperaturaMotor.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.veiculoSemMotorista.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.veiculoSemMotorista.total }}
                                </td>
                                <td class="text-center"
                                    :class="rota.excessoVelocidade.cor === 'vermelho' ? 'red' : 'orange'">
                                    {{ rota.excessoVelocidade.total }}
                                </td>
                            </tr>

                            <template v-for="(item, alertaIndex) in rota.alertas">
                                <tr v-if="!item.usuario" :key="'alerta-' + index + '-' + alertaIndex"
                                    class="linha-alerta">
                                    <td>
                                        <div
                                            style="display: flex; align-items: center; justify-content: center; width: 100%; gap: 8px;">
                                            <base-icon :icon="item.status === 'L' ? mdiCheckCircle : mdiMinusCircle"
                                                :size="20"
                                                :style="{ color: item.status === 'L' ? '#37a33c' : '#bd7120' }" />
                                        </div>
                                    </td>

                                    <td colspan="3">
                                        <strong>Alerta:</strong> <span class="alerta-tipo">{{ item.descricao }}</span>
                                    </td>

                                    <td class="alerta-item">
                                        <strong>Descrição:</strong> <span>{{ item.descricao }}</span>
                                    </td>

                                    <td class="alerta-item">
                                        <strong>Data e hora do alerta:</strong> <span>{{ item.data_hora }}</span>
                                    </td>

                                    <td>
                                        <div class="alerta-item endereco-container">
                                            <strong>Endereço:</strong> <span>{{ item.endereco }}</span>
                                            <b-button v-if="item.maps_url" class="btn-mapa btn-transparent" size="sm"
                                                color="black" @click="abrirMapa(item.maps_url)">
                                                <base-icon size="18" :icon="mdiMapSearch" />
                                            </b-button>
                                        </div>
                                    </td>
                                    <td colspan="26"></td>
                                </tr>

                                <tr v-else :key="'tratativa-' + index + '-' + alertaIndex" class="linha-tratativa">
                                    <td>
                                        <div
                                            style="display: flex; align-items: center; justify-content: center; width: 100%; gap: 8px;">
                                            <base-icon :icon="mdiCheckCircle" :size="20" style="color: #37a33c" />
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <strong>Tratativa do alerta:</strong> <span class="alerta-tipo">{{ item.alerta +
                                            ' - ' + item.data_hora_alerta }}</span>
                                    </td>
                                    <td class="alerta-item">
                                        <strong>Descrição:</strong> <span>{{ item.descricao }}</span>
                                    </td>
                                    <td class="alerta-item">
                                        <strong>Data e hora da tratativa:</strong> <span>{{ item.data_hora }}</span>
                                    </td>
                                    <td class="alerta-item">
                                        <strong>Usuário:</strong> <span>{{ item.usuario }}</span>
                                    </td>
                                    <td colspan="26"></td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>

            <div class="col-sm-12" v-if="tamanhoRelatorio == 0">
                <statusInformation typeBar="div" :statusBar="statusBar" />
            </div>
        </div>
    </PanelEagle>
</template>
<script>

import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
import { mdiMapSearch, mdiThumbUpOutline, mdiClose, mdiSyncCircle, mdiCheckAll, mdiCheck, mdiPrinter, mdiFileReplaceOutline, mdiMapMarkerDistance, mdiAlertOctagonOutline, mdiPercentOutline, mdiCheckBold, mdiCheckCircle, mdiMinusCircle } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import slideUpAndDown from '@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue';
import InputRangeWithLimit from '@/components/Atom/Datas/InputRangeWithLimit.vue';
import SelectAll from '@/components/Atom/Select/SelectAll.vue';
import PanelEagle from '@/components/Atom/Panel/PanelEagle.vue';
import { conectionError } from '@/Services/Helpers/swellHeper';
import { mapGetters } from 'vuex'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { DateTime } from 'luxon'

export default Vue.extend({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'reloperacaorotas',

    validations: {
        empresasArray: { required },
        veiculosArray: { required },
    },

    components: {
        tituloPage: require("@/components/Atom/Header/Titulo").default,
        basicEIG: require("@/components/Atom/Buttons/BasicButtonsRelatoriosEIG").default,
        statusInformation: require("@/components/Atom/StatusInformation/StatusInformation").default,
        'SelectMarcadores': require(
            '@/components/Atom/SpecificComponents/RotaManual/SelectMarcadores').default,
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
        slideUpAndDown,
        InputRangeWithLimit,
        SelectAll,
        PanelEagle,
        inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
        'buttonsFilters': require('@/components/Atom/Buttons/ButtonsFilters').default,
    },

    data() {
        return {
            formatEnable: { pdf: false, xls: true, csv: true, tickets: false, personalizado: false },
            arrayButtonsStatus: [
                {
                    'value': 'F', 'title': 'Finalizada', 'width': '48%',
                    'text': 'Finalizada', 'isSelected': true
                },
                {
                    'value': 'I', 'title': 'Iniciada', 'width': '48%',
                    'text': 'Iniciada', 'isSelected': false
                }
            ],
            filtroSelecionadoStatus: '',
            horarioParadaSelecionada: '00:00',
            url: '/roteirizador/relatorios/operacao/rota',
            mdiMapSearch: mdiMapSearch,
            mdiThumbUpOutline: mdiThumbUpOutline,
            mdiClose: mdiClose,
            mdiSyncCircle: mdiSyncCircle,
            mdiCheckAll: mdiCheckAll,
            mdiPrinter: mdiPrinter,
            mdiCheck: mdiCheck,
            mdiFileReplaceOutline: mdiFileReplaceOutline,
            optionsSelectEmpresas: new EmpresasService().Get(),
            optionsSelectCargas: [],
            optionsSegmentos: [],
            selectedVeiculos: [],
            optionsUnidade: [],
            optionsSelectRotas: [],
            empresasArray: [],
            veiculosArray: [],
            cargasArray: [],
            segmentosArray: [],
            unidadesArray: [],
            rotasArray: [],
            dataInput: "",
            optionsSelectVeiculos: [],
            optionsVeiculosTags: [],
            labelsSelectVeiculosTags: [
                { indexDFH: 'V', description: 'Veículos*' },
                { indexDFH: 'GV', description: 'Grupos*' }
            ],
            checkSelecionadoVeiculosTags: 'V',
            loadingVel: false,
            optionsContratos: [],
            loadingContratos: false,
            labelsselectSegmentos: [],
            labelsSelectEmpresa: [
                { indexDFH: "E", description: "Empresa*" },
            ],
            labelSelectCargas: [
                { indexDFH: "CR", description: "Cargas" },
            ],
            labelSelectSegmentos: [
                { indexDFH: "SG", description: "Segmentos" },
            ],
            labelSelectUnidades: [
                { indexDFH: "UN", description: "Unidade" },
            ],
            labelSelectRotas: [
                { indexDFH: "R", description: "Rotas" },
            ],
            selectedEmpresa: [],
            selectedCargas: [],
            selectedSegmentos: [],
            selectedUnidade: [],
            selectedRotas: [],
            loadingPanel: false,
            relatorioORiginal: [],
            rotas: [],
            relatorioArray: [],
            statusBar: 'info',
            tamanhoRelatorio: 0,
            diasNoMes: DateTime.now().daysInMonth,
            ativarBotaoIntegrarTodos: false,
            loadingExportar: [false, false, false],
            loadingButtonSelect: false,
            mdiMapMarkerDistance,
            mdiAlertOctagonOutline,
            mdiPercentOutline,
            mdiCheckCircle,
            mdiMinusCircle,
            mdiCheckBold,
            totalAlertas: 0,
            eficienciaGeral: 0,
        }
    },

    methods: {
        ...mapGetters(["getMaster"]),

        /**
        * @description Atualiza o filtro de status selecionado.
        * @param {any} event - Evento contendo o novo status selecionado.
        * @author Yuri 🇧🇷
        */
        changeStatus(event) {
            this.filtroSelecionadoStatus = event;
        },

        /**
        * @description Atualiza o horário inicial.
        * @param {Array} horarioParadaSelecionada - Horário parado selecionado.
        * @author Yuri 🇧🇷
        */
        changeHorarioInicial(horario) {
            this.horarioParadaSelecionada = horario;
        },

        /**
        * @description essa função monta o payload para fazer a requisição no endpoint de gerarRelatorio
        * @author Otávio 🦆 
        */
        async gerarRelatorio() {
            this.loadingPanel = true;
            this.rotas = [];

            const [dataInicio, dataFim] = this.dataInput.split(' - ');

            let statusSelecionado = this.filtroSelecionadoStatus;

            if (statusSelecionado == undefined) {
                statusSelecionado = [
                    "F",
                    "I"
                ];
            }

            const payload = {
                veiculo: this.checkSelecionadoVeiculosTags === 'V' ? this.veiculosArray : [],
                carga: this.cargasArray,
                rota: this.rotasArray,
                segmentos: this.segmentosArray,
                unidades: this.unidadesArray,
                empresas: this.empresasArray,
                data_inicio: `${dataInicio} 00:00:00`,
                data_fim: `${dataFim} 23:59:59`,
                horarioParadaSelecionada: this.horarioParadaSelecionada,
                filtroSelecionadoStatus: statusSelecionado
            };

            try {
                const response = await new HttpRequest().Post(this.url + '/gerar', payload);
                if (response.data?.rotas?.length) {
                    this.rotas = response.data.rotas;
                    this.statusBar = 'success';
                    this.tamanhoRelatorio = this.rotas.length;
                    this.totalAlertas = response.data.meta.total_alertas;
                    this.eficienciaGeral = response.data.meta.eficiencia_geral;
                } else {
                    this.statusBar = 'error';
                    this.tamanhoRelatorio = 0;
                    this.totalAlertas = 0;
                    this.eficienciaGeral = 0;
                }

            } catch (error) {
                this.statusBar = 'error';
                this.tamanhoRelatorio = 0;
                this.totalAlertas = 0;
                this.eficienciaGeral = 0;
                conectionError();
            }

            this.loadingPanel = false;
        },

        /**
        * @description recebe os valores do select de veiculos com tags
        * @author Otávio 🦆 
        */
        selectVeiculosTags(event) {
            this.limparRelatorio();
            this.veiculosArray = event;
        },

        /**
        * @description Verifica se a rota tem alerta lido
        * @author Otávio 🦆 
        */
        temAlertaTratado(rota) {
            return rota.alertaLido;
        },

        /**
        * @description monitora quando o rádio foi alterado
        * @author Otávio 🦆 
        */
        changeCheckVeiculosTags(check) {
            this.limparRelatorio();
            this.checkSelecionadoVeiculosTags = check;
            this.buscaDadosSelect();
        },

        /**
        * @description Captura os dados do do input de data
        * @author Otávio 🦆 
        */
        changeData(data) {
            this.limparRelatorio();
            this.dataInput = data;
            if (this.empresasArray.length) {
                this.buscaDadosSelect();
            }
        },

        /**
        * @description força atualização no mounted, caso precise
        * @author Otávio 🦆 
        */
        selectCriadoVeiculosTags(select) {
            this.limparRelatorio();
            this.veiculosSelect = select;
        },

        /**
        * @description Limpa os dados selecionados no filtro
        * @author Otávio 🦆 
        */
        limparSelectFiltros() {
            this.selectedRotas = [];
            this.selectedSegmentos = [];
            this.selectedCargas = [];
            this.selectedUnidade = [];
            this.selectedVeiculos = [];
        },

        /**
        * @description Captura os dados do select de empresa
        * @author Otávio 🦆 
        */
        selectEmpresas(event) {
            this.limparRelatorio();
            this.limparSelectFiltros();
            this.empresasArray = event;

            if (event.length) {
                this.buscaDadosSelect();
            } else {
                this.optionsSelectCargas = [];
                this.optionsVeiculosTags = [];
                this.optionsSegmentos = [];
                this.optionsSelectRotas = [];
                this.optionsUnidade = [];
            }
        },

        /**
         * Abre o Google Maps em uma nova aba com a localização específica
         * @param {number} latitude - Latitude do ponto
         * @param {number} longitude - Longitude do ponto
         * @author Otávio 🦆 
         */
        abrirMapa(url) {
            if (url != undefined) {
                window.open(url, '_blank');
            }
        },

        /**
         * @description Exporta os dados do relatório no formato especificado
         * @param {string} tipo  tipo de exportação (pdf, xls, csv)
         * @author Otávio 🦆 
         */
        exportarRelatorio(tipo) {
            var root = process.env.VUE_APP_ROOT;
            this.setLoadingExportar(tipo);

            const [dataInicio, dataFim] = this.dataInput.split(' - ');
            let url = `${this.url}/exportar`;

            let payload = {
                tipo: tipo,
                veiculo: this.checkSelecionadoVeiculosTags === 'V' ? this.veiculosArray : [],
                carga: this.cargasArray,
                rota: this.rotasArray,
                segmentos: this.segmentosArray,
                unidades: this.unidadesArray,
                empresas: this.empresasArray,
                data_inicio: `${dataInicio} 00:00:00`,
                data_fim: `${dataFim} 23:59:59`,
                arrayDados: this.rotas
            };

            new HttpRequest()
                .Post(url, payload)
                .then(response => {
                    this.setLoadingExportar();
                    const filePath = response.data?.data?.original?.data?.local;
                    if (filePath) {
                        window.open(root + '/' + filePath);
                    }
                })
                .catch(() => {
                    conectionError();
                    this.setLoadingExportar();
                });
        },

        /**
         * @description Define o estado de loading dos botões de exportação
         * @param {string|false} tipo Tipo de exportação ou false para resetar
         */
        setLoadingExportar(tipo = false) {
            let load = [tipo == "pdf", tipo == "xls", tipo == "csv"];
            this.loadingExportar = load;
        },

        /**
        * @description limpa o array de relatório, altera o statusBar para 'info' e seta o tamanho
        * padrão para o tamanhoRelatório.
        * @author Otávio 🦆 
        */
        limparRelatorio() {
            this.rotas = [];
            this.statusBar = 'info';
            this.tamanhoRelatorio = 0;
        },

        /**
        * @description limpa os arrays dos options dos selects dos filtros 
        * @author Otávio 🦆 
        */
        limparFiltros() {
            this.optionsSelectCargas = [];
            this.optionsVeiculosTags = [];
            this.optionsSegmentos = [];
            this.optionsUnidade = [];
            this.optionsSelectRotas = [];
        },

        /**
        * @description Captura os dados do select de pontos
        * @author Otávio 🦆 
        */
        selectCargas(event) {
            this.limparRelatorio();
            this.cargasArray = event;
        },

        /**
        * @description Captura os dados do select de segmentos
        * @author Otávio 🦆 
        */
        selectSegmentos(event) {
            this.limparRelatorio();
            this.segmentosArray = event;
        },

        /**
        * @description Captura os dados do select de unidade
        * @author Otávio 🦆 
        */
        selectUnidade(event) {
            this.limparRelatorio();
            this.unidadesArray = event;
        },

        /**
        * @description Captura os dados do select de rotas
        * @author Otávio 🦆 
        */
        selectRotas(event) {
            this.limparRelatorio();
            this.rotasArray = event;
        },

        /**
        * @description faz uma requisição para buscar os filtros dos selects usando o FiltrosServices 
        * @author Otávio 🦆 
        */
        async buscaDadosSelect() {
            this.loadingVel = true;
            this.loadingButtonSelect = true;
            const empresas = this.empresasArray;
            const filtroSelecionado = this.checkSelecionadoVeiculosTags;
            this.veiculosSelect = [];
            this.optionsVeiculosTags = [];
            const tiposFiltro = ['SG', filtroSelecionado, 'CR', 'RT', 'UN'];
            const intervalo = [this.dataInput];
            const dados = await new FiltrosService().dados_filtros(empresas, tiposFiltro, intervalo);
            if (dados) {
                this.optionsSelectCargas = dados['CR'];
                this.optionsVeiculosTags = dados[filtroSelecionado];
                this.optionsSegmentos = dados['SG'];
                this.optionsSelectRotas = dados['RT'];
                this.optionsUnidade = dados['UN'];
            }
            this.loadingVel = false;
        },

        /**
         * Atualiza os labels dos filtros, adicionando ou removendo asteriscos
         *  baseado na seleção
         * @author Otávio 🦆 
        */
        atualizarLabels() {
            const temSelecao = this.temFiltroSelecionado;

            if (temSelecao) {
                this.labelsSelectVeiculosTags = [
                    { indexDFH: 'V', description: 'Veículos' },
                    { indexDFH: 'GV', description: 'Grupos' }
                ];

                this.labelSelectCargas = [
                    { indexDFH: "CR", description: "Cargas" }
                ];

                this.labelSelectSegmentos = [
                    { indexDFH: "SG", description: "Segmentos" }
                ];

                this.labelSelectUnidades = [
                    { indexDFH: "UN", description: "Unidade" }
                ];

                this.labelSelectRotas = [
                    { indexDFH: "R", description: "Rotas" }
                ];
            } else {
                this.labelsSelectVeiculosTags = [
                    { indexDFH: 'V', description: 'Veículos*' },
                    { indexDFH: 'GV', description: 'Grupos*' }
                ];

                this.labelSelectCargas = [
                    { indexDFH: "CR", description: "Cargas*" }
                ];

                this.labelSelectSegmentos = [
                    { indexDFH: "SG", description: "Segmentos*" }
                ];

                this.labelSelectUnidades = [
                    { indexDFH: "UN", description: "Unidade*" }
                ];

                this.labelSelectRotas = [
                    { indexDFH: "R", description: "Rotas*" }
                ];
            }
        }
    },

    watch: {
        /**
        * @description monitora qual radio está preenchido 
        * @author Otávio 🦆 
        */
        checkSelecionadoVeiculosTags(value) {
            if (this.optionsSelectVeiculos[value]) {
                this.limparRelatorio();
                this.optionsVeiculosTags = this.optionsSelectVeiculos[value]
            }
        },

        /**
        * Monitora mudanças na seleção dos filtros para atualizar os asteriscos
        * @author Otávio 🦆 
        */
        temFiltroSelecionado: {
            handler() {
                this.atualizarLabels();
            },
            immediate: true
        }

    },

    computed: {
        /**
         * @description Controla o estado disabled dos selects de segmentos e unidades
         * @returns {Boolean}
         * @author Otávio 🦆 
         */
        loading() {
            return this.loadingVel || !this.empresasArray.length;
        },

        /**
         * Verifica se algum filtro foi selecionado
         * @returns {Boolean}
         * @author Otávio 🦆 
        */
        temFiltroSelecionado() {
            return (
                this.rotasArray.length > 0 ||
                this.cargasArray.length > 0 ||
                this.segmentosArray.length > 0 ||
                this.unidadesArray.length > 0 ||
                this.veiculosArray.length > 0
            );
        },

        /**
         * Valida se o formulário está pronto para gerar relatório
         * @returns {Boolean}
         * @author Otávio 🦆 
        */
        estaValido() {
            return this.empresasArray.length > 0 && this.temFiltroSelecionado;
        },
    },

    mounted() {
        this.empresasArray = [];

        if (!this.getMaster()) {
            if (this.optionsSelectEmpresas && this.optionsSelectEmpresas.length) {
                this.selectedEmpresa = this.optionsSelectEmpresas;
            }
        }
    }
});
</script>

<style scoped>
.campoObri {
    margin-top: 6px;
    font-size: 14px;
    text-align: left;
    margin-left: 10px;
    position: relative;
    bottom: 0;
}

.tabela {
    background-color: #e6e6e6;
    border-collapse: collapse;
    border: 1px solid #b8b8b8;
}

.table th {
    font-size: 12px;
    white-space: nowrap;
}

.tabela tr.linha-alerta td,
.tabela tr.linha-tratativa td {
    background-color: #ffffff !important;
}

.divTabela {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: auto;
}

.tabela th,
.tabela td {
    padding: 4px 8px;
    font-size: 0.85em;
    background-color: #e6e6e6;
    border: 1px solid #b8b8b8;
    border-bottom: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
}

.tabela th:nth-child(3),
.tabela td:nth-child(3) {
    min-width: 150px;
}

.tabela th:nth-child(4),
.tabela td:nth-child(4) {
    min-width: 150px;
}

.tabela th:nth-child(5),
.tabela td:nth-child(5) {
    min-width: 150px;
}

.tabela th:nth-child(2),
.tabela td:nth-child(2) {
    min-width: 75px;
}

.tabela th:nth-child(6),
.tabela td:nth-child(6) {
    min-width: 150px;
}

.tabela th:nth-child(7),
.tabela td:nth-child(7) {
    min-width: 150px;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 5px 10px;
    border-radius: 15px;
    justify-content: flex-start;
}

.tag {
    color: #fff;
    padding: 2px 6px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 0.75em;
    white-space: nowrap;
}

.tag.roxa {
    background-color: #8b008b;
}

.tag.vermelha {
    background-color: #dc143c;
}

.red {
    color: #ca3232 !important;
    font-weight: bold;
}

.orange {
    color: #bd7120 !important;
    font-weight: bold;
}

.tag.laranja {
    background-color: #ff8c00;
    border-radius: 8px;
    padding: 2px 6px;
    font-weight: bold;
    color: #fff;
}

.porcentagem-verde {
    color: #37a33c !important;
    font-weight: bold;
}

.porcentagem-amarelo {
    color: #bd7120 !important;
    font-weight: bold;
}

.porcentagem-vermelho {
    color: #ca3232 !important;
    font-weight: bold;
}

.alerta-container {
    background-color: #f9f9f9 !important;
    padding: 8px 15px !important;
    border: 1px solid #e0e0e0 !important;
}

.alerta-linha {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 4px;
}

.alerta-linha-detalhes {
    display: flex;
    align-items: center;
    gap: 30px;
    font-size: 12px;
}

.alerta-item {
    white-space: nowrap;
}

.alerta-tipo {
    color: #dc3545;
}

strong {
    color: #333;
    margin-right: 5px;
}

.endereco-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.btn-transparent {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    box-shadow: none !important;
    color: #69b04d !important;
}

.btn-transparent :deep(svg) {
    color: #69b04d !important;
    fill: #69b04d !important;
}

.btn-mapa {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
}

.filter-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    width: 100%;
    margin-bottom: 15px;
}

.icon-large {
    transform: scale(1.2);
}

.filter-item {
    min-width: 0;
}

.select-container {
    width: 100% !important;
}

.select-container :deep(.multiselect) {
    width: 100% !important;
}

:deep(.vue-multiselect) {
    margin: 0 !important;
}

:deep(.subTitleSelect) {
    padding-left: 0 !important;
}

:deep(.col-sm-12) {
    padding: 0 !important;
}

:deep(#select-segmentos),
:deep(#select-unidade) {
    width: 100% !important;
}

:deep(#select-segmentos .multiselect),
:deep(#select-unidade .multiselect) {
    width: 100% !important;
}

:deep(.nopadding) {
    padding: 0 !important;
}

:deep(.input-group) {
    width: 100% !important;

}

:deep(#select-segmentos .multiselect),
:deep(#select-unidade .multiselect) {
    width: 100% !important;
    margin: 0 !important;
}

.indicador-container {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin: 30px auto;
    width: 100%;
    max-width: 800px;
}

.indicador-card {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 12px 15px;
    border-radius: 6px;
    color: white !important;
    min-width: 150px;
    justify-content: center;
}

.blue {
    background-color: #3366cc;
}

.alertas {
    background-color: #ff6633;
}

.green {
    background-color: #28a745;
}

.icon-container {
    margin-right: 10px;
}

.blue .icon-container,
.alertas .icon-container {
    transform: translateX(20px);
}

.icon-container :deep(svg) {
    width: 60px;
    height: 60px;
    opacity: 0.4;
}

.icon {
    color: white;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    text-align: center;
    transform: translateX(-20px);
}

.value {
    font-size: 36px;
    font-weight: bold;
    line-height: 1;
}

.label {
    font-size: 18px;
    margin-top: 2px;
    font-weight: bold;
}

.rota-status {
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    border-radius: 20px;
    margin-left: 8px;
    font-size: 12px;
    font-weight: 500;
}

.rota-status.tratada {
    background-color: #2e7d32;
    color: white;
}

.rota-status.nao-tratada {
    background-color: #757575;
    color: white;
}

.status-icon {
    margin-left: 4px;
    color: white;
}
</style>
