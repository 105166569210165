<template>
    <panelEagle id="mediaconsumo" :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-4">
                    <tituloPage :icon="mdiGasStation" titulo="Média de consumo">
                    </tituloPage>
                </div>

                <div class="col-sm-8 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG :loading="loadingButton" :disabled="desabilitarGerar"
                        @exportarRelatorio="exportarRelatorio" @gerarRelatorio="gerarRelatorio"
                        :disabledDropdown='dadosMediaConsumo.length <= 0'>
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>

            <!-- Filtros -->
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12">
                        <hr>
                    </div>

                    <div class="col-sm-12 row nopadding mb-2">
                        <!-- Intervalo -->
                        <div class="col-sm-4 nopadding mt-2">
                            <inputRangeWithLimit opens="right" id='Intervalo' name='Intervalo' @changeInput='changeData'
                                :isObrigatorio='false' />
                        </div>

                        <!-- Empresa -->
                        <div class="col-sm-4 nopadding mt-2">
                            <selectAll :isMultiple="true" nameForRadio="desperdicioCombustivelEmpresas"
                                :hasSelectAll="true" :labels="[{ indexDFH: 'EM', description: 'Empresas*' }]"
                                :optionsArray="filtros.det.selectEmpresas.options" :selected="selectEmpresas"
                                firstSelected="EM" @changeSelect="changeEmpresa">
                            </selectAll>
                        </div>

                        <!-- Veículos, Grupos e Tags -->
                        <div class="col-sm-4 nopadding">
                            <div class="d-flex justify-content-start col-sm-12">
                                <!-- Radio de Veículos -->
                                <div class="d-flex justify-content-start" style="margin-left: 20px">
                                    <input style="cursor: pointer;" class="form-check-input" type="radio"
                                        name="grupoVeiculoTag" id="radioVeiculos" v-model="selectedRadio" :value="1"
                                        @change="selecionaTipo(1)">
                                    <label class="select-none" for="radioVeiculos" style="cursor: pointer">
                                        Veículos
                                    </label>
                                </div>

                                <!-- Radio de Grupos -->
                                <div class="d-flex justify-content-start" style="margin-left: 30px;">
                                    <input style="cursor: pointer;" class="form-check-input" type="radio"
                                        name="grupoVeiculoTag" id="radioGrupos" v-model="selectedRadio" :value="2"
                                        @change="selecionaTipo(2)">

                                    <label class="select-none" for="radioGrupos" style="cursor: pointer">
                                        Grupos
                                    </label>
                                </div>

                                <!-- Radio de Tags -->
                                <div class="d-flex justify-content-start" style="margin-left: 30px;">
                                    <input style="cursor: pointer;" class="form-check-input" type="radio"
                                        name="grupoVeiculoTag" id="radioTags" v-model="selectedRadio" :value="3"
                                        @change="selecionaTipo(3)">

                                    <label class="select-none" for="radioTags" style="cursor: pointer">
                                        Tags
                                    </label>
                                </div>
                            </div>

                            <div>
                                <!-- Select de Veículos -->
                                <div v-if="tipoSelecionado == 1">
                                    <selectAll :selected="selectedVeiculos" nameForRadio='veiculos'
                                        :loading='carregandoVeiculos' :optionsArray='optionsVeiculos' ref='selectV'
                                        :hasSelectAll='true' :isMultiple='true' @changeSelect='changeVeiculos'
                                        :disabled="disabledVeiculos" />
                                </div>

                                <!-- Select de Grupos -->
                                <div v-if="tipoSelecionado == 2">
                                    <selectAll nameForRadio='grupos' :loading='carregandoGrupos'
                                        :optionsArray='optionsGrupos' ref='selectGrupos' :hasSelectAll='true'
                                        :isMultiple='true' @changeSelect='changeGrupo' :disabled="disabledGrupos"
                                        :selected="selectedGrupos" />
                                </div>

                                <!-- Select de Tags -->
                                <div v-if="tipoSelecionado == 3">
                                    <selectAll nameForRadio='inputTags' :loading='carregandoTags'
                                        :optionsArray='optionsTags' ref='selectTags' :hasSelectAll='true'
                                        :isMultiple='true' @changeSelect='changeTags' :disabled="disabledTags"
                                        :selected="selectedTags" />
                                </div>
                            </div>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>

            <div class="col-sm-12">
                <hr>
            </div>
        </div>

        <!-- Indicadores -->
        <div v-if="dadosMediaConsumo.length > 0" class="d-flex justify-content-center mt-1 mb-2"
            style="width: 100%; margin: auto; gap: 10px">
            <!-- Km Rodados -->
            <div class="d-flex justify-content-center align-items-center"
                style="background-color: #2D49AF; border-radius: 10px; padding: 7px; min-width: 320px; max-width: 320px; width: 320px; gap: 20px; height: 110px;">
                <div>
                    <baseIcon class="text-white opacity-50" size="75" :icon="mdiCarTractionControl" />
                </div>

                <div class="mt-3">
                    <span class="d-flex justify-content-start align-items-end" style="gap: 5px">
                        <p class="text-white text-2xl font-bold nopadding" style="margin-bottom: -10px;"
                            v-b-popover.hover.top="indicadorTotalKm">
                            {{ indicadorTotalKm }}</p>

                        <div class="text-white text-lg">Km</div>
                    </span>
                    <p class="text-white nopadding text-start">Km. rodados</p>
                </div>
            </div>

            <!-- Consumo combustível -->
            <div class="d-flex justify-content-center align-items-center"
                style="background-color: #E1641E; border-radius: 10px; padding: 7px; min-width: 320px; max-width: 320px; width: 320px; gap: 20px; height: 110px;">
                <div>
                    <baseIcon class="text-white opacity-50" size="75" :icon="mdiGasStationInUse" />
                </div>

                <div class="mt-3">
                    <span class="d-flex justify-content-start align-items-end" style="gap: 5px">
                        <p class="text-white text-2xl font-bold nopadding" style="margin-bottom: -10px;"
                            v-b-popover.hover.top="indicadorTotalConsumo">
                            {{ indicadorTotalConsumo }}</p>

                        <div class="text-white text-lg">L</div>
                    </span>
                    <p class="text-white nopadding text-start">Consumo combustível</p>
                </div>
            </div>

            <!-- Média -->
            <div class="d-flex justify-content-center align-items-center"
                style="background-color: #1D6623; border-radius: 10px; padding: 7px; min-width: 320px; max-width: 320px; width: 320px; gap: 20px; height: 110px;">
                <div>
                    <baseIcon class="text-white opacity-50" size="75" :icon="mdiGaugeLow" />
                </div>

                <div class="mt-3">
                    <span class="d-flex justify-content-start align-items-end" style="gap: 5px">
                        <p class="text-white text-2xl font-bold nopadding" style="margin-bottom: -10px;"
                            v-b-popover.hover.top="indicadorTotalMediaConsumo">
                            {{ indicadorTotalMediaConsumo }}</p>

                        <div class="text-white text-lg">Km/L</div>
                    </span>
                    <p class="text-white nopadding text-start">Média</p>
                </div>
            </div>
        </div>

        <!-- Tabela -->
        <div class="col-sm-12 nopadding">
            <tableRelatorio htmlRel="">
                <slot slot="thead">
                    <tr class="cabecalhoTabela">
                        <th>Data</th>
                        <th>Km. rodados</th>
                        <th>Consumo combustível</th>
                        <th>Média</th>
                    </tr>
                </slot>

                <slot slot="tbody">
                    <template v-for="(dados, index) in dadosMediaConsumo">
                        <tr v-if='true' class="backColorTdPDF" :key="index">
                            <td colspan="4" class="nopadding mt-2">
                                <span class="newbadge">
                                    {{ dados.placa }}
                                </span>
                            </td>
                        </tr>

                        <tr v-if='true' :key="dados.placa + index + dados.totalKm" colspan="12" class="mt-2"
                            style="background-color: #819CF2;">
                            <td>{{ dados.intervalo }}</td>
                            <td>{{ dados.totalKm }} Km</td>
                            <td>{{ dados.totalConsumo }} L</td>
                            <td>{{ dados.totalMediaConsumo }} Km/L</td>
                        </tr>

                        <template v-for="(registros, veiculo) in dados.dados">
                            <tr v-if='true' :key="`${veiculo}_badge ${registros.consumoTotal}`">
                                <td>{{ registros.data }}</td>
                                <td>{{ registros.kmRodado }} Km</td>
                                <td>{{ registros.consumoTotal }} L</td>
                                <td>{{ registros.mediaConsumo }} Km/L</td>
                            </tr>
                        </template>
                    </template>

                    <statusInformation v-if='dadosMediaConsumo.length <= 0' typeBar="tr" :statusBar="infoTable"
                        colspanForTd="4">
                    </statusInformation>
                </slot>
            </tableRelatorio>
        </div>
    </panelEagle>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import {
    mdiGasStation,
    mdiCarTractionControl,
    mdiGasStationInUse,
    mdiGaugeLow
} from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'

export default Vue.extend({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'mediaconsumo',

    components: {
        tituloPage: require('@/components/Atom/Header/Titulo').default,
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
        slideUpAndDown: require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        inputRangeWithLimit: require('@/components/Atom/Datas/InputRangeWithLimit').default,
        statusInformation: require('@/components/Atom/StatusInformation/StatusInformation').default,
        basicButtonsRelatoriosEIG: require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
    },

    data() {
        return {
            desabilitarGerar: true,
            baseUri: '/performance/relatorios/media/consumo/',
            selectedRadio: 1,
            tipoSelecionado: 1,
            intervalo: '',
            mdiGasStation: mdiGasStation,
            mdiGaugeLow: mdiGaugeLow,
            mdiGasStationInUse: mdiGasStationInUse,
            mdiCarTractionControl: mdiCarTractionControl,
            loadingPanel: false,
            selectedVeiculos: [],
            selectedGrupos: [],
            selectedTags: [],
            optionsVeiculos: [],
            optionsGrupos: [],
            optionsTags: [],
            carregandoVeiculos: false,
            carregandoGrupos: false,
            carregandoTags: false,
            disabledVeiculos: true,
            disabledGrupos: true,
            disabledTags: true,
            veiculosSelecionados: [],
            tagsSelecionadas: [],
            gruposSelecionados: [],
            empresasSelecionadas: [],
            selectEmpresas: [],
            infoTable: 'info',
            filtros: {
                det: {
                    intervalo: '',
                    selectEmpresas: {
                        dados: [],
                        selected: [],
                        options: [],
                    },
                    selectColabs: {
                        dados: [],
                        selected: [],
                        options: [],
                        loading: false,
                    },
                    exportar: [false, false, false],
                },
                res: {
                    intervalo: '',
                    selectEmpresas: {
                        dados: [],
                        selected: [],
                        options: [],
                    },
                    selectColabs: {
                        dados: [],
                        selected: [],
                        options: [],
                        loading: false,
                    },
                    exportar: [false, false, false],
                },
            },
            relatorios: {
                det: {
                    dados: [],
                    info: 'info',
                    selecionado: true,
                },
                res: {
                    dados: [],
                    info: 'info',
                    selecionado: false,
                },
            },
            dadosMediaConsumo: [],
            indicadorTotalKm: 0,
            indicadorTotalConsumo: 0,
            indicadorTotalMediaConsumo: 0,
            loadingButton: [false, false, false],
        }
    },

    methods: {
        ...mapGetters(['getMaster']),
        /**
        * Reseta os filtros da tabela, limpando os dados dos filtros e da tabela.
        *
        * @returns {void}
        * @author Yuri 🇧🇷
        */
        resetFiltrosInfoTable() {
            this.limparDadosFiltrosVeiculosGruposTags();
            this.limparDadosTabela();
        },

        /**
         * Atualiza o intervalo de dados e reseta os filtros da tabela.
         *
         * @param {string} intervalo Novo intervalo de dados a ser aplicado.
         * @returns {void}
         * @author Yuri 🇧🇷
         */
        changeData(intervalo) {
            this.intervalo = intervalo;
            this.resetFiltrosInfoTable();
        },

        /**
         * Atualiza a empresa selecionada e reseta os filtros da tabela.
         *
         * @param {string} empresa Nova empresa selecionada.
         * @returns {void}
         * @author Yuri 🇧🇷
         */
        changeEmpresa(empresa) {
            this.empresasSelecionadas = empresa;
            this.resetFiltrosInfoTable();
            this.dados_filtros();

            if (this.empresasSelecionadas.length > 0) {
                this.desabilitarGerar = false;
            } else {
                this.desabilitarGerar = true;
            }
        },

        /**
         * Ativa ou desativa o estado de carregamento e desabilitação dos filtros.
         *
         * @param {boolean} bool Define se os filtros devem estar carregando e desabilitados.
         * @returns {void}
         * @author Yuri 🇧🇷
         */
        ativarDesativarLoadingDisabledFiltros(bool) {
            this.carregandoVeiculos = bool;
            this.carregandoGrupos = bool;
            this.carregandoTags = bool;
            this.disabledVeiculos = bool;
            this.disabledGrupos = bool;
            this.disabledTags = bool;
        },

        /**
         * Obtém os dados dos filtros com base nas empresas selecionadas.
         *
         * @throws {Error} Se houver falha na obtenção dos dados dos filtros.
         * @author Yuri 🇧🇷
         */
        async dados_filtros() {
            this.ativarDesativarLoadingDisabledFiltros(true);

            var dados = await new FiltrosService()
                .dados_filtros(this.empresasSelecionadas, ['V', 'GV', 'TAG']);

            if (dados) {
                this.optionsVeiculos = dados.V;
                this.optionsGrupos = dados.GV;
                this.optionsTags = dados.TAG;

                this.ativarDesativarLoadingDisabledFiltros(false);
            }
        },

        /**
        * Gera um relatório com base nos filtros selecionados pelo usuário.
        *
        * @author Yuri 🇧🇷
        */
        async gerarRelatorio() {
            this.loadingPanel = true;
            var body = {
                data: this.intervalo,
                empresas: this.empresasSelecionadas,
                tipoSelecionado: this.tipoSelecionado,
                veiculos: this.veiculosSelecionados,
                grupos: this.gruposSelecionados,
                tags: this.tagsSelecionadas,
            };

            new HttpRequest().Post(this.baseUri + 'gerar/relatorio', body)
                .then((data) => {
                    this.dadosMediaConsumo = data.data.dados.dados;
                    this.indicadorTotalKm = data.data.dados.indicador.indicadorTotalKm;
                    this.indicadorTotalConsumo = data.data.dados.indicador.indicadorTotalConsumo;
                    this.indicadorTotalMediaConsumo = data.data.dados.indicador.indicadorTotalMediaConsumo;

                    if (data.data.dados.dados.length <= 0) {
                        this.infoTable = 'error';
                    }
                })
                .catch(() => {
                    this.loadingPanel = false
                })
                .finally(() => {
                    this.loadingPanel = false
                })
        },

        /**
         * @description Função para exportar o relatório, em PDF,
         * Excel ou CSV e ao final abre ou faz o download do mesmo.
         * @param string, com o tipo da exportação que será o relatório.
         * @author Yuri 🇧🇷
         */
        async exportarRelatorio(tipo) {
            try {
                this.loadingButton = [tipo == 'pdf', tipo == 'xls', tipo == 'csv']
                this.loadingPanel = true;
                const url = this.baseUri + 'exportar/relatorio';
                const root = process.env.VUE_APP_ROOT;
                const indicadores = {
                    'indicadorTotalKm': this.indicadorTotalKm,
                    'indicadorTotalConsumo': this.indicadorTotalConsumo,
                    'indicadorTotalMediaConsumo': this.indicadorTotalMediaConsumo,
                };
                const obj = {
                    'tipo': tipo,
                    'arrayDados': JSON.stringify(this.dadosMediaConsumo),
                    indicadores
                }

                await new HttpRequest().Post(url, obj).then((data) => {
                    if (data.status) {
                        window.open(root + '/' + data.data.local)
                    }
                }).catch(() => {
                    this.loadingPanel = false;
                    this.loadingButton = [false, false, false, false]
                }).finally(() => {
                    this.loadingPanel = false;
                    this.loadingButton = [false, false, false, false]
                });
            } catch (error) {
                this.toastShow('Tente novamente mais tarde', 'danger')
                this.loadingPanel = false;
                this.loadingButton = [false, false, false, false]
            }
        },

        /**
         * Limpa os dados da tabela de consumo e redefine o estado da informação exibida.
         *
         * @author Yuri 🇧🇷
         */
        limparDadosTabela() {
            this.dadosMediaConsumo = [];
            this.infoTable = 'info';
        },

        /**
         * Atualiza a lista de veículos selecionados e limpa os dados da tabela.
         *
         * @param {Array} veiculos Lista de veículos selecionados.
         * @author Yuri 🇧🇷
         */
        changeVeiculos(veiculos) {
            this.veiculosSelecionados = veiculos;
            this.limparDadosTabela();
        },

        /**
         * Atualiza a lista de grupos selecionados e limpa os dados da tabela.
         *
         * @param {Array} grupos Lista de grupos selecionados.
         * @author Yuri 🇧🇷
         */
        changeGrupo(grupos) {
            this.gruposSelecionados = grupos;
            this.limparDadosTabela();
        },

        /**
         * Atualiza a lista de tags selecionadas e limpa os dados da tabela.
         *
         * @param {Array} tag Lista de tags selecionadas.
         * @author Yuri 🇧🇷
         */
        changeTags(tag) {
            this.tagsSelecionadas = tag;
            this.limparDadosTabela();
        },

        /**
        Função para selecionar qual tipo de filtro foi 
        selecionado.
        @param {tipo} number tipo para filtro selecionado, 
        @author Yuri 🇧🇷
        */
        selecionaTipo(tipo) {
            this.tipoSelecionado = tipo;
            this.limparDadosFiltrosVeiculosGruposTags();
        },

        /**
        * Limpa os filtros de veículos, grupos e tags, redefinindo as seleções.
        *
        * @author Yuri 🇧🇷
        */
        limparDadosFiltrosVeiculosGruposTags() {
            this.veiculosSelecionados = [];
            this.gruposSelecionados = [];
            this.tagsSelecionadas = [];
            this.selectedGrupos = [];
            this.selectedTags = [];
            this.selectedVeiculos = [];
        },
    },

    mounted() {
        let options = new EmpresasService().Get()
        this.filtros.det.selectEmpresas.options = options
        this.filtros.res.selectEmpresas.options = options
        if (!this.getMaster()) {
            this.filtros.det.selectEmpresas.selected = options
            this.filtros.res.selectEmpresas.selected = options
        }
    },
})
</script>

<style lang="css" scoped>
.text-white {
    color: white
}

.opacity-50 {
    opacity: 50%;
}

.text-lg {
    font-size: 20px;
}

.text-2xl {
    font-size: 30px;
}

.text-3xl {
    font-size: 50px;
}

.text-lg {
    font-size: 13px;
}

.text-start {
    text-align: start !important;
}

.text-center {
    text-align: center !important;
}

.text-end {
    text-align: end !important;
}

.text-justify {
    text-align: justify !important;
}

.font-bold {
    font-weight: bold !important;
}

.font-mediun {
    font-weight: 500;
}

#motoristaEconomico {
    .cabecalhoTabela {
        th {
            vertical-align: middle;
            text-align: left !important;
        }
    }

    .corpoTabela {
        td {
            vertical-align: middle;
            text-align: left;
            border: 1px solid #dee2e6;
        }
    }

    .fundoTotais {
        td {
            background: #d0d0d0 !important;
        }
    }
}
</style>
