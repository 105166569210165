/**
Este é o componente de select que tem o options em formato de tabela

-> Propriedades:
1 - optionsArray - Array - array de opções para o select - EX: [{value: 'valor da opção',
descricao1: 'Descricao da opção',
descricao2: 'Descricao da opção',
descricao3: 'Descricao da opção'}];
2 - messageNoOptions - String - Mensagem quando não tem options;
3 - optionsCabecalho - Array - Valores que ficam no cabeçalho da tabela do options - Ex: [{value: 'NFE'},
{value: 'Código'},
{value: 'Data'},
{value: 'Placa'},];
4 - loading - Boolean - Loading do select - Ex: loading ='true';
5 - disabled - Boolean - Disabled do select - Ex: disabled ='true';

-> Sem slot

-> Eventos:
1- changeSelect - Emite quando o select for alterado - Parametros:
1.1- arraySelect - Array de selecionados
1.2- event.value - valor do ultimo selecionado
1.3- event.descricao1 - descricao1 do ultimo selecionado
1.4- event.descricao2 - descricao2 do ultimo selecionado
1.5- event.descricao3 - descricao3 do ultimo selecionado
2- changeSearch - Emite quando for digitado no input - Parametros:
2.1- event - valor que esta digitando

-> Props do select:
1 - options - Array - Opções que ficarão n select
2 - internalSearch - Boolean - Retira a busca interna do select
3 - preserveSearch - Boolean - Deixa a busca realizada quando fechado o select
4 - loading - Boolean - Coloca o carregando do select
5 - disabled - Boolean - Desabilita o select
6 - input - Emite evanto quando selecionado ou descelecionado algo no select
7 - search-change - Emite evento quando digitado no input
8 - v-model - Atribui valor a variável value
-> Slots:
1 - singleLabel - Local onde mostra oq esta selecionado
2 - beforeList - Local que fica o cabeçalho do options
3 - option - Local onde estão os options
4 - noOptions - Mostrado quando não tem options
5 - noResult - Mostrado quando não tem resultados

-> Obs:
Documentação: https://vue-multiselect.js.org/#sub-getting-started
*/

<template>
    <div class="col-sm-12">
        <div class="col-sm-12 nopadding subTitleSelect divUnicaNoRadio">
            Buscar NFE
        </div>
        <div class="col-sm-12 nopadding">
            <multiselect v-bind='$attrs' v-model="value" :options="optionsSelect" :placeholder="'Selecione...'"
                selectLabel="•" selectedLabel="✔" deselectLabel="x" :internalSearch='false' :preserveSearch='true'
                :max-height="600" :loading='loading' :disabled='disabled' @input='selected' @search-change='busca'>
                <!-- Valor que é selecionado -->
                <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                        <span v-if="props.option.value" class="option__title">{{ props.option.value }}</span>
                        <span v-else class="option__title">Selecione...</span>
                    </span>
                </template>
                <!-- Cabeçalho no options -->
                <template slot="beforeList">
                    <div v-show="optionsSelect.length > 0" class="option__desc col-sm-12 row mt-2">
                        <div v-for='(dados, d) in optionsCabecalho' :key="d" class="option__title col-sm-3 pl-1 pr-1"
                            align='center'>{{ dados.value }}</div>
                    </div>
                </template>
                <!-- Valores do option -->
                <template slot="option" slot-scope="props">
                    <div class="option__desc col-sm-12 row pl-0 pr-0">
                        <div class="col-sm-3 pl-1 pr-1" align='center'>{{ props.option.value }}</div>
                        <div class="col-sm-3 pl-1 pr-1" align='center'>{{ props.option.descricao1 }}</div>
                        <div class="col-sm-3 pl-1 pr-1" align='center'>{{ props.option.descricao2 }}</div>
                        <div class="optionMaior col-sm-3 pl-1 pr-1" align='center'>{{ props.option.descricao3 }}</div>
                    </div>
                </template>
                <template slot="noResult">
                    Nenhum elemento encontrado
                </template>
                <template slot="noOptions">
                    {{ messageNoOptions }}
                </template>
                <template slot="clear" slot-scope="props">
                    <div class="multiselect__clear" v-if="value.length"
                        @mousedown.prevent.stop="clearAll(props.search)">
                        <BIcon icon="x-square-fill"></BIcon>
                    </div>
                </template>
            </multiselect>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import Vue from 'vue'
export default Vue.extend({
    name: 'selectTable',
    components: {
        Multiselect
    },
    data() {
        return {
            value: {},
            optionsSelect: [],
        }
    },

    props: {
        optionsArray: {
            type: Array,
            required: true,
        },
        messageNoOptions: {
            typeof: String,
            default: 'Nenhum elemento encontrado'
        },
        optionsCabecalho: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        /**
         * @listen emitido por uma referência
         * @description Método para retirar uma seleção do select
         * @returns {Array} value - valor de seleção vazio
         * @author Vitor Hugo 🐨
         */
        clearAll() {
            this.value = []
        },

        /**
         * @listen input - executado quando é selecionada uma linha da tabela do options
         * @description Método para emitir um evento para a página receber os dados selecionados
         * @param   {Array[]}      event             - dados selecionados
         * @param   {String}       event.value       - primeiro parâmetro da tabela ds options
         * @param   {String}       event.descricao1  - segundo parâmetro da tabela dos options
         * @param   {String}       event.descricao2  - terceiro parâmetro da tabela dos options
         * @param   {String}       event.descricao3  - quarto parâmetro da tabela dos options
         * @returns {Array|String} arraySelect|event - valor emitido pelo select 
         * @author Vitor Hugo 🐨
         */
        selected(event) {
            var arraySelect = []
            if (event != null) {
                arraySelect.push(event.value);
                this.$emit('changeSelect', arraySelect, event.value, event.descricao1, event.descricao2, event.descricao3);
            } else {
                this.$emit('changeSelect', arraySelect, '', '', '', '');
            }
        },

        /**
         * @listen search-change - executado quando é editado o input de busca
         * @description Método para emitir um evento quando o input de busca for editado
         * @param   {String} event      - valor informado no input
         * @returns {String} arrayBusca - valor emitido pelo input do select 
         * @author Vitor Hugo 🐨
         */
        busca(event) {
            var arrayBusca = ''
            if (event != null) {
                arrayBusca = event;
            }
            this.$emit('changeSearch', arrayBusca);
        }
    },

    watch: {
        optionsArray: function () {
            this.optionsSelect = []
            for (var a in this.optionsArray) {
                this.optionsSelect.push(this.optionsArray[a])
            }
        },
    }
})
</script>

<style lang='scss'>
.option__desc {
    padding-left: 1px !important;
    min-width: 350px;
}

.multiselect__tags {
    border-radius: 8px !important;
}

.subTitleSelect {
    font-size: 13px;
    text-align: left;
    padding-left: 5px !important;

    .form-group {
        margin-bottom: 0rem !important;
    }

    &.divUnicaNoRadio {
        .custom-control {
            padding-left: 0rem;

            .custom-control-label {
                &::before {
                    display: none;
                }

                &::after {
                    display: none;
                }
            }
        }
    }
}
</style>
